// Created By James Launder
// this is used to created a custom screenshot that the user can then download in the formats
import { toPng, toJpeg, toSvg } from "html-to-image";

const exportImage = async (element, type) => {
  const filter = (element) => {
    const exclusionClasses = ["remove-me", "secret-div"];
    return !exclusionClasses.some((classname) =>
      element.classList?.contains(classname)
    );
  };
  if (type == "png") {
    toPng(element.current, {
      cacheBust: true,
      backgroundColor: "#FFFFFF",
      filter: filter,
    })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = "wevrChart.png";
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.error(err);
      });
  }
  if (type == "jpeg") {
    toJpeg(element.current, {
      cacheBust: true,
      backgroundColor: "#FFFFFF",
      filter: filter,
    })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = "wevrChart.jpeg";
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.error(err);
      });
  }
  if (type == "svg") {
    toSvg(element.current, {
      cacheBust: true,
      backgroundColor: "#FFFFFF",
      filter: filter,
    })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = "wevrChart.svg";
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.error(err);
      });
  }
};

export default exportImage;
