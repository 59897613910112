import request from "./Api";
// most of these queries are for the home page.

const userInfo = (id) => {
  return request({
    url: "/user/" + id,
    method: "GET",
  });
};

const getHomepage = () => {
  return request({
    url: "/bundledHome",
    method: "GET",
  });
};

const getBlocks = () => {
  return request({
    url: "/homepageBlocks",
    method: "GET",
  });
};

const getSize = () => {
  return request({
    url: "/homepageSize",
    method: "GET",
  });
};
const getChains = () => {
  return request({
    url: "/homepageChains",
    method: "GET",
  });
};
const getNewsPage = (page) => {
  return request({
    url: `/News?page=${page}`,
    method: "GET",
  });
};

const ApiService = {
  userInfo,
  getHomepage,
  getNewsPage,
  getBlocks,
  getSize,
  getChains,
};

export default ApiService;
// export default class ApiService {
//   static getHomepage() {
//     return request({
// url: "/bundledHome",
// method: "GET",
//     });
//   }

//   static login(username, password) {
//     return request({
//       url: "/signin",
//       method: "POST",
//       data: { email: username, password: password },
//     });
//   }
// }

// export async function getHomepage() {
//   return await request({
//     url: "/bundledHome",
//     method: "GET",
//   });
// }
