import React from "react";
import { cn } from "../../utils/utils";
import {
  ArrowLeftIcon,
  ChevronDownIcon,
  CubeIcon,
} from "@heroicons/react/24/solid";
import { Disclosure } from "@headlessui/react";
import ImageWithFallback from "./ImageWithFallBack";

const SidebarMain = React.forwardRef(
  ({ className, sidebar, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(
        sidebar ? "block w-[248px]" : "hidden md:w-20 md:block",
        "h-[95vh] px-1 pb-4 transition-all font-roboto bg-surface transform",
        className
      )}
      {...props}
    />
  )
);

SidebarMain.displayName = "SidebarMain";

const SidebarHeader = React.forwardRef(
  ({ className, sidebar, setSidebar, onChange, ...props }, ref) => (
    <div
      ref={ref}
      className={cn("flex flex-row px-2 mx-auto py-2", className)}
      {...props}
    >
      <button
        onClick={() => {
          setSidebar(!sidebar);
        }}
        className="bg-surfaceVariant rounded-xl mr-2 p-2 border-2 group border-primaryContainer hover:bg-primary hover:text-white transition duration-0 md:block hidden"
      >
        <ArrowLeftIcon
          className={`${
            sidebar
              ? "transition  duration-75"
              : "rotate-180 transition duration-75"
          }object-scale-down h-8 w-8 group-hover:text-white text-onSurfaceVariant `}
        />
      </button>
      <div className={`${sidebar ? "relative w-full " : "hidden"}`}>
        <input
          type="text"
          name="search"
          id="search"
          placeholder="Search"
          className="relative w-full md:w-[163px] px-4 h-12 md:h-full rounded-xl border border-primaryContainer focus:border-primary focus:outline-none"
          onChange={onChange}
        />
      </div>
    </div>
  )
);

SidebarHeader.displayName = "SidebarHeader";

const SidebarItems = React.forwardRef(
  ({ className, children, ...props }, ref) => (
    <div ref={ref} className={cn("h-full overflow-auto", className)} {...props}>
      <div className="mx-auto w-full p-2 space-y-2 max-h-full">{children}</div>
    </div>
  )
);

SidebarItems.displayName = "SidebarItems";

const SidebarItem = React.forwardRef(
  (
    { sidebar, setSidebar, item, onClick, width, searchParams, ...props },
    ref
  ) => (
    <Disclosure as="div" ref={ref} {...props}>
      {({ open }) => (
        <>
          <Disclosure.Button className="flex w-full place-items-center bg-white justify-between p-2 rounded-lg font-medium text-lg border border-primaryContainer shadow-sm">
            <div className="grid grid-flow-col items-center">
              <ImageWithFallback
                src={process.env.API + "/" + item.icon}
                alt=""
                fallbackComponent={CubeIcon}
                className="object-scale-down h-8 w-8 mr-2 text-primary"
              />

              <div
                className={`${
                  !sidebar
                    ? "font-roboto font-light text-base text-black"
                    : "hidden"
                }`}
              >
                {item.category}
              </div>
            </div>
            <ChevronDownIcon
              className={`${
                open ? " transform" : "rotate-90"
              } h-6 w-6 text-onSurfaceVariant`}
            />
          </Disclosure.Button>
          <div
            className={`${
              open
                ? "bg-surfaceVariant border border-primaryContainer shadow-sm"
                : ""
            } rounded-xl`}
          >
            {item.children.map((subItem) => (
              <Disclosure.Panel
                as="ul"
                key={subItem.name}
                className="space-y-0 gap-0"
              >
                <li className="">
                  <button
                    key={subItem.name}
                    onClick={() => {
                      onClick(subItem);
                      if (width <= 768) {
                        setSidebar(!sidebar);
                      }
                    }}
                    className={`${
                      searchParams.get("name") === subItem.name
                        ? "bg-primary text-white"
                        : "hover:bg-primary/50 "
                    } w-full flex rounded-lg p-2 font-light`}
                  >
                    <span>{subItem.name}</span>
                  </button>
                </li>
              </Disclosure.Panel>
            ))}
          </div>
        </>
      )}
    </Disclosure>
  )
);

SidebarItem.displayName = "SidebarItem";

const SidebarIcon = React.forwardRef(
  ({ className, sidebar, setSidebar, item, ...props }, ref) => (
    <div ref={ref} className={cn("h-full overflow-auto", className)} {...props}>
      <button
        onClick={() => setSidebar(!sidebar)}
        className="flex w-full bg-white  p-2 rounded-lg border-2 border-primaryContainer"
      >
        <img
          className="object-scale-down h-8 w-8"
          src={process.env.API + "/" + item.icon}
          alt=""
        />
      </button>
    </div>
  )
);

SidebarIcon.displayName = "SidebarIcon";

export { SidebarMain, SidebarHeader, SidebarItems, SidebarItem, SidebarIcon };
