import React, { useState } from "react";
import { Disclosure } from "@headlessui/react";
import { ChevronLeftIcon, CubeIcon } from "@heroicons/react/24/solid";

const SidebarItem = ({ category, icon, tables, sidebar, onTableSelect }) => {
  const [imgError, setImgError] = useState(false);

  return (
    <Disclosure as="div">
      {({ open }) => (
        <>
          <Disclosure.Button className="flex w-full place-items-center bg-white justify-between p-2 rounded-lg font-medium text-lg border border-primaryContainer shadow-sm">
            <div className="grid grid-flow-col items-center">
              {/*  */}
              {imgError ? (
                <CubeIcon className="object-scale-down h-8 w-8 mr-2 text-primary" />
              ) : (
                <img
                  className="object-scale-down h-8 w-8 mr-2 text-primary"
                  // src={addresses}
                  src={process.env.API + "/" + icon}
                  alt=""
                  onError={() => setImgError(true)}
                />
              )}

              <div
                className={`${
                  sidebar ? "font-roboto font-light text-base" : "hidden"
                }`}
              >
                {category}
              </div>
            </div>
            <ChevronLeftIcon
              className={`${
                open ? "transform -rotate-90" : ""
              } h-6 w-6 text-onSurfaceVariant`}
            />
          </Disclosure.Button>
          <Disclosure.Panel className="space-y-1 bg-surfaceVariant rounded-lg mt-1">
            {tables.map((table) => (
              <button
                key={table._id}
                className="w-full flex justify-between items-center rounded-lg p-2 font-light hover:bg-primary hover:text-white transition-all"
                onClick={() => onTableSelect(table)}
              >
                <span>{table.name}</span>
              </button>
            ))}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default SidebarItem;
