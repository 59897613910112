// Created by James Launder
import UseNavQuery from "./UseNavQuery";

// shows the description text
function ChartFooter() {
  const { description: description } = UseNavQuery();
  return (
    <div className="pb-6 mx-8 py-2 font-roboto border-t border-secondary text-center">
      <p>{description}</p>
    </div>
  );
}

export default ChartFooter;
