import PaymentGateway from "./paymentGateway.js";
import CoinbasePayment from "./coinbasePayment.js";
import classNames from "../../utils/ClassNames.js";
export default function PaymentButton({
  price,
  enabled,
  id,
  name,
  sale,
  salePercentage,
  couponName = null,
  couponCode,
}) {
  function countTill() {
    var num = price / (1 + salePercentage / 100);
    var str = String(num);
    let point = str.indexOf(".");
    return point;
  }
  return (
    <p
      className={classNames(
        sale && !enabled ? "pt-4" : "pt-8",
        "text-4xl font-monda text-gray-900 "
      )}
    >
      {sale && !enabled ? (
        <div className="flex flex-col">
          <span className="flex items-center">
            <span className="line-through text-gray-700 text-2xl mr-4">
              ${price}
            </span>
            <span className="rounded-full bg-tertiary/10 px-2.5 py-1 text-sm font-semibold leading-5 text-tertiary">
              {couponName ? (
                <span>
                  -${salePercentage}% {couponName}
                </span>
              ) : (
                `-${salePercentage}%`
              )}
            </span>
          </span>
          <span>
            $
            {(price - (price * salePercentage) / 100).toLocaleString("en-US", {
              maximumFractionDigits: countTill() > 3 ? 0 : 2,
            })}
            <span className="text-base font-medium text-gray-500">USD</span>
          </span>
        </div>
      ) : (
        <span>
          ${price}
          <span className="text-base font-medium text-gray-500">/$USD mo</span>
        </span>
      )}

      <p
        className={classNames(
          sale && !enabled ? "mt-6" : "mt-10",
          "text-base font-roboto font-medium text-gray-500"
        )}
      >
        {enabled ? (
          <CoinbasePayment cartItem={id} packageName={name} />
        ) : (
          <PaymentGateway
            cartItem={id}
            packageName={name}
            couponCode={couponCode}
          />
        )}
      </p>
    </p>
  );
}
