// created by Matt Webb
// This handles all the Stripe payment

import dayjs from "dayjs";
import { useNavigate } from "react-router";
var isBetween = require("dayjs/plugin/isBetween");
dayjs.extend(isBetween);
const url = process.env.API;
const paymentGateway = ({ cartItem, packageName, couponCode }) => {
  const navigate = useNavigate();
  function getToken() {
    if (localStorage.getItem("user") != null) {
      return JSON.parse(localStorage.getItem("user")).accessToken;
    } else {
      return "";
    }
  }
  function getTier() {
    if (localStorage.getItem("user") == null) {
      return "NoUser";
    } else if (JSON.parse(localStorage.getItem("user")).tier > 0) {
      return "Active";
    } else {
      return "NotActive";
    }
  }

  function getPaidBy() {
    return JSON.parse(localStorage.getItem("user")).paidBy;
  }
  // if the subscription ends within 5 days let them pay again if they paid with crypto
  function subsctionEnds() {
    return dayjs(
      JSON.parse(localStorage.getItem("user")).subscriptionEnd
    ).isBetween(dayjs(), dayjs().add(5, "day"), "day");
  }

  // call to middleware to pass user through to stripe
  const handleCheckout = () => {
    fetch(`${url}/stripe/create-checkout-session`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      // Send along all the information about the items
      body: JSON.stringify({
        id: cartItem,
        quantity: 1,
        code: couponCode,
      }),
    })
      .then((res) => {
        if (res.ok) return res.json();
        return res.json().then((json) => Promise.reject(json));
      })
      .then(({ url }) => {
        window.location = url;
      })
      .catch((e) => {
        console.error(e.error);
      });
  };

  // this is called if the user has a current subscription
  const handleBilling = () => {
    fetch(process.env.API + `/stripe/billing`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      // Send along all the information about the items
      body: JSON.stringify({}),
    })
      .then((res) => {
        if (res.ok) return res.json();
        return res.json().then((json) => Promise.reject(json));
      })
      .then(({ url }) => {
        window.location = url;
      })
      .catch((e) => {
        console.error(e.error);
      });
  };
  if (getTier() === "NoUser") {
    return (
      <button
        onClick={() => navigate("/signin")}
        className="w-full rounded-md bg-primary py-2 text-white hover:bg-wevr-Accent-Light hover:shadow-md duration-75"
      >
        Buy {packageName}
      </button>
    );
  }
  if (getTier() === "Active") {
    return (
      <div>
        {getPaidBy() === "Coinbase" ? (
          <div>
            {" "}
            {subsctionEnds() ? (
              <button
                onClick={() => handleBilling()}
                className="w-full rounded-md bg-primary py-2 text-white hover:bg-wevr-Accent-Light hover:shadow-md duration-75"
              >
                Upgrade {packageName}
              </button>
            ) : (
              <button
                onClick={() => navigate("/UserSettings")}
                className="w-full rounded-md bg-primary py-2 text-white hover:bg-wevr-Accent-Light hover:shadow-md duration-75"
              >
                Active subscription
              </button>
            )}
          </div>
        ) : (
          <button
            onClick={() => handleBilling()}
            className="w-full rounded-md bg-primary py-2 text-white hover:bg-wevr-Accent-Light hover:shadow-md duration-75"
          >
            Upgrade {packageName}
          </button>
        )}
      </div>
    );
  }
  return (
    <div>
      <button
        onClick={() => handleCheckout()}
        className="w-full rounded-md bg-primary py-2 text-white hover:bg-wevr-Accent-Light hover:shadow-md duration-75"
      >
        Buy {packageName}
      </button>
    </div>
  );
};
export default paymentGateway;
