import React from "react";
import {
  Select,
  SelectValue,
  SelectTrigger,
  SelectItem,
  SelectContent,
} from "../../Ui/select";
//selectedChain, was in params, add back in
const ChainSelect = ({ setSelectedChain }) => {
  return (
    <Select onValueChange={setSelectedChain} className="w-full">
      <SelectTrigger className="border border-gray-300 rounded-md px-3 py-2 text-left focus:outline-none focus:ring-2 focus:ring-blue-500">
        <SelectValue placeholder="Select a chain" className="text-gray-500" />
      </SelectTrigger>
      <SelectContent className="bg-white shadow-md rounded-md">
        <SelectItem value="ethereum" className="px-3 py-2 hover:bg-gray-100">
          Ethereum
        </SelectItem>
        <SelectItem value="bitcoin" className="px-3 py-2 hover:bg-gray-100">
          Bitcoin
        </SelectItem>
      </SelectContent>
    </Select>
  );
};

export default ChainSelect;
