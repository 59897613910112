//hooks
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export const useSelectedTable = (currentTableStores, blockchain) => {
  const [searchParams] = useSearchParams();
  const [selectedTable, setSelectedTable] = useState(null);

  useEffect(() => {
    const tableName = searchParams.get("name");
    const interval = searchParams.get("interval") || "days";
    const selectedBlockchain = searchParams.get("blockchain").toLowerCase();
    if (
      tableName &&
      currentTableStores.length > 0 &&
      selectedBlockchain.toLowerCase() == blockchain.toLowerCase()
    ) {
      const table = currentTableStores.find(
        (table) => table.name === tableName
      );
      if (table) {
        setSelectedTable({ ...table, interval });
      } else {
        setSelectedTable(null);
      }
    }
  }, [currentTableStores, searchParams, blockchain]);

  return {
    selectedTable,
    setSelectedTable,
  };
};
