import { useQuery } from "@tanstack/react-query";
import request from "../../../Api/Api.js";

export default function UseGetUserFootprints() {
  function getToken() {
    if (localStorage.getItem("user") != null) {
      return JSON.parse(localStorage.getItem("user")).accessToken;
    } else {
      return "";
    }
  }
  const { isLoading, isError, isFetching, data, error } = useQuery({
    queryKey: ["getFootprints"],
    queryFn: async () => {
      const data = await request.get("/footprint", {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return data;
    },
    staleTime: 1000,
  });

  return { isLoading, isError, isFetching, data, error };
}
