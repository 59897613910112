import { useQuery } from "@tanstack/react-query";
import request from "../../../Api/Api.js";

function getToken() {
  if (localStorage.getItem("user") != null) {
    return JSON.parse(localStorage.getItem("user")).accessToken;
  } else {
    return "";
  }
}

export function UseTransactions() {
  const address = new URLSearchParams(location.search).get("address");

  const { isLoading, isError, data, error } = useQuery({
    queryKey: ["getTransactions", address], // Unique key for the query, including the address
    queryFn: async () => {
      const data = await request.get(`/getTxns?address=${address}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return data;
    },
  });
  return { isLoading, isError, data, error };
}

export function UsePercentile(percentile, blockchain) {
  const { isLoading, isError, data, error } = useQuery({
    queryKey: [`getPercentileMetrics`, percentile, blockchain], // Dynamic queryKey based on state
    queryFn: async () => {
      const data = await request.get(
        `/getPercentileMetrics?percentile=${encodeURIComponent(
          percentile
        )}&blockchain=${blockchain}`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return data;
    },
  });

  return { isLoading, isError, data, error };
}
