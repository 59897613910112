// created by James Launder
// small and simple chart used for the small dashboard charts.

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
function SmallChart(props) {
  //   const { theme } = useContext(ThemeContext);
  const [searchParams] = useSearchParams();
  var newStyle = document.createElement("style");
  newStyle.appendChild(
    document.createTextNode(
      "\
@font-face {\
  font-family: 'Monda';\
  font-style: sans-serif;\
  font-weight: 400;\
  src: url('https://fonts.googleapis.com/css2?family=Monda&display=swap')\
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;\
}\
"
    )
  );

  document.head.appendChild(newStyle);
  const dataSource = [
    {
      name: props.name,
      data: props.data[0]?.values,
      color: "#FA9173",
      backgroundColor: "transparent",
      animation: false,
      lineWidth: 1,
      shadow: false,
      states: {
        hover: {
          lineWidth: 1,
        },
      },
      marker: {
        radius: 1,
        states: {
          hover: {
            radius: 2,
          },
        },
      },
      fillOpacity: 0.25,
    },
  ];
  const [options, setChartOptions] = useState({
    exporting: {
      enabled: false,
      enableImages: true,
    },
    chart: {
      type: "area",
      backgroundColor: "transparent",
    },
    title: {
      text: "",
    },
    xAxis: {
      visible: false,
      type: "datetime",
    },
    yAxis: {
      visible: false,
    },
    legend: {
      enabled: false,
    },
    series: dataSource,
    credits: {
      enabled: false,
    },
    accessibility: {
      enabled: false,
    },
  });

  useEffect(() => {
    setChartOptions({
      series: dataSource,
    });
    window.dispatchEvent(new Event("resize"));
  }, []);

  useEffect(() => {
    var updated = {
      name: props.name,
      data: props.data[0]?.values,
      color: "#FA9173",
      backgroundColor: "transparent",
      animation: false,
      lineWidth: 1,
      shadow: false,
      states: {
        hover: {
          lineWidth: 1,
        },
      },
      marker: {
        radius: 1,
        states: {
          hover: {
            radius: 2,
          },
        },
      },
      fillOpacity: 0.25,
    };
    dataSource.splice(0, 1, updated);
    setChartOptions({
      series: dataSource,
    });
  }, [searchParams.get("blockchain")]);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      containerProps={{
        style: {
          height: props.height,
        },
      }}
      options={options}
    />
  );
}
export default SmallChart;
