import { useQuery } from "@tanstack/react-query";
import request from "../../Api/Api.js";
import { useSearchParams } from "react-router-dom";

export default function UseNavQuery() {
  const [searchParams] = useSearchParams();

  const {
    isLoading,
    isError,
    isFetching,
    data: queryData,
  } = useQuery({
    queryKey: ["data-names-" + searchParams.get("blockchain")],
    queryFn: async () => {
      const data = await request
        .get("/stores/" + searchParams.get("blockchain"))
        .catch(function (error) {
          return error.response;
        });
      return data;
    },
    staleTime: Infinity,
  });

  var name = "";
  var description = "";
  var dataSource = "";
  var sources = "";
  var resolution = "";
  if (searchParams.get("name") && (!isLoading || !isFetching)) {
    name = searchParams.get("name");
    const firstLoadValues = queryData?.data?.graphNames.find(
      (key) => key.name === searchParams.get("name")
    );
    if (!firstLoadValues) {
      description = "Error";
      resolution = "Error";
      dataSource = "Error";
    } else {
      description = firstLoadValues.description;
      resolution = firstLoadValues.resolution;
      dataSource = firstLoadValues.dataSource;
      sources = firstLoadValues.sources;
    }
  }

  return {
    isLoading,
    isError,
    isFetching,
    queryData,
    name: name,
    description: description,
    resolution: resolution,
    dataSource: dataSource,
    sources: sources,
  };
}
