import { useLocation, useNavigate } from "react-router-dom";
import HeaderCard from "./HeaderCard.js";
import PieChartContainer from "./PieChartContainer.js";
import ProfitChartContainer from "./ProfitChartContainer.js";
import TokenTable from "./TokenTable.js";
import {
  UseAccBal,
  UseEthBalance,
  UseTotalTokenValue,
} from "../API/PortfolioQueries.js";
import { useEffect } from "react";

function DisplayPortfolio() {
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("user") === null) {
      navigate("/Footprint");
    }
  }, []);
  // Use the useNavigate hook

  const location = useLocation();
  const address = new URLSearchParams(location.search).get("address");

  if (!address) {
    return <div>Please enter an address to view portfolio.</div>;
  }

  const {
    isLoading: ethLoading,
    isError: ethError,
    data: ethbalance,
  } = UseEthBalance();

  //token
  const {
    isLoading: tokenValueLoading,
    isError: tokenValueError,
    data: tokenValueData,
    error: tokenValueErrorDetails,
  } = UseTotalTokenValue();

  const topTokens = tokenValueData?.data
    .sort(
      (a, b) =>
        parseFloat(b.token_holdings_in_usd) -
        parseFloat(a.token_holdings_in_usd)
    )
    .slice(0, 5);

  const {
    isLoading: balanceLoading,
    data: balanceData,
    isError: balanceError,
  } = UseAccBal();

  const totalValue =
    topTokens && Array.isArray(topTokens)
      ? topTokens.reduce(
          (acc, token) => acc + parseFloat(token.token_holdings_in_usd),
          0
        )
      : 0;

  return (
    <div className="flex flex-col gap-4 font-roboto p-2 px-4 pb-8">
      <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <HeaderCard
          value={ethbalance?.balanceEth}
          loading={ethLoading}
          error={ethError}
          name={"Eth Value"}
          suffix={"ETH"}
          decimals={3}
        />
        <HeaderCard
          value={totalValue}
          loading={tokenValueLoading}
          error={tokenValueError}
          name={"Token Value"}
          suffix={"USD"}
          decimals={2}
          prefix={"$"}
        />
        <HeaderCard
          value={
            parseFloat(ethbalance?.balanceEth || 0) *
              parseFloat(tokenValueData?.data[0]?.latest_eth_price) +
            (totalValue || 0)
          }
          loading={ethLoading || tokenValueLoading}
          error={ethError || tokenValueError}
          name={"Total Value"}
          suffix={"USD"}
          decimals={2}
          prefix={"$"}
        />
        <HeaderCard
          value={tokenValueData?.data[0]?.latest_eth_price}
          loading={tokenValueLoading}
          error={tokenValueError}
          name={"Current Ethereum Price"}
          suffix={"USD"}
          decimals={2}
          prefix={"$"}
        />
      </div>
      <div className="grid grid-cols-1 w-full gap-6">
        <PieChartContainer
          tokenValueLoading={tokenValueLoading}
          totalValue={totalValue}
          error={tokenValueError}
          tokenValueData={tokenValueData}
        />
        <ProfitChartContainer
          balanceLoading={balanceLoading}
          balanceData={balanceData}
          error={balanceError}
        />
      </div>
      <div className="grid grid-cols-1 w-full gap-6">
        <TokenTable
          tokenValueLoading={tokenValueLoading}
          tokenValueError={tokenValueError}
          tokenValueData={tokenValueData}
          tokenValueErrorDetails={tokenValueErrorDetails}
        />
      </div>
    </div>
  );
}

export default DisplayPortfolio;
