import * as React from "react";

const TwistComponentPrimary = (props) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 850.394 850.394"
    style={{
      enableBackground: "new 0 0 850.393677 850.393677",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".st8,.st9{fill:none;stroke:#fcbdab;stroke-miterlimit:10}.st8{stroke-width:2;stroke-linecap:round}.st9{stroke-width:1.5}"
      }
    </style>
    <path
      className="st8"
      d="M385.455 389.742c-1.017-2.484-3.396-7.272-5.542-10.863-1.091-1.826-4.564-7.448-10.641-13.634-2.885-2.936-8.068-8.134-16.295-12.637-4.007-2.193-8.315-4.55-14.632-5.653-6.624-1.155-11.882-.306-15.63.333-7.183 1.224-12.319 3.59-15.296 4.988-5.05 2.37-8.482 4.758-12.637 7.648 0 0-2.322 1.616-12.636 9.644h0c-.887.665-2.771 2.771-3.658 3.436"
    />
    <path
      className="st8"
      d="M388.496 388.551c-.446-1.56-1.327-4.04-2.393-6.651-.623-1.527-1.363-3.034-2.022-4.423a36.974 36.974 0 0 0-2.618-4.608 103.849 103.849 0 0 0-7.263-9.662c-1.848-2.184-4.506-4.972-8.034-7.893-2.345-1.94-5.193-4.03-8.128-5.787-1.433-.858-2.925-1.892-4.7-2.87-1.417-.78-3.046-1.611-4.943-2.362a37.277 37.277 0 0 0-7.065-2.03c-2.378-.413-5.078-.649-7.8-.54-3.81.15-6.91.867-9.384 1.373-6.805 1.392-11.727 3.548-14.698 4.971-4.782 2.293-8.173 4.533-12.172 7.433-1.017.738-3.526 2.103-13.301 10.441-2.148 1.833-4.767 5.376-6.008 6.784"
    />
    <path
      className="st8"
      d="M392.562 389.403c-.25-1.551-1.853-6.07-2.659-8.77-.47-1.58-1.116-3.117-1.654-4.558a27.61 27.61 0 0 0-2.329-4.788c-1.412-2.314-3.673-5.93-6.791-10.118-1.909-2.564-4.562-5.467-7.913-8.45-2.391-2.13-5.383-4.497-8.117-6.274-1.485-.965-3.055-2.243-5.08-3.47-1.455-.884-3.238-1.91-5.42-2.855-1.936-.838-4.335-1.735-7.259-2.355-.19-.04-.381-.08-.573-.115-2.273-.417-5.383-.775-8.744-.591-4.325.236-7.74 1.212-10.447 1.849-6.43 1.513-11.136 3.504-14.098 4.955-4.516 2.211-7.811 4.382-11.707 7.216-2.034 1.479-4.505 2.877-13.967 11.24-3.406 3.01-6.762 7.98-8.358 10.131"
    />
    <path
      className="st8"
      d="M394.578 388.388c-.053-1.544-.328-6.233-.874-9.02-.318-1.633-.86-3.208-1.287-4.696a23.34 23.34 0 0 0-2.04-4.967c-1.22-2.2-3.3-6.03-6.32-10.574-1.96-2.952-4.61-5.972-7.792-9.009-2.434-2.323-5.566-4.978-8.105-6.759-1.536-1.076-3.189-2.588-5.46-4.072-1.497-.978-3.433-2.203-5.897-3.346-2.027-.941-4.66-2.031-7.966-2.775-.21-.047-.42-.094-.633-.135-2.168-.416-5.688-.905-9.687-.642-4.842.318-8.572 1.55-11.511 2.325-6.06 1.597-10.544 3.46-13.498 4.938-4.252 2.128-7.45 4.232-11.243 7-3.047 2.223-5.426 3.716-14.632 12.038-4.65 4.204-8.757 10.586-10.708 13.479"
    />
    <path
      className="st8"
      d="M397.724 387.36c.143-1.535.066-6.383-.22-9.258-.165-1.686-.592-3.303-.919-4.832-.285-1.334-.754-3.142-1.752-5.147-1.034-2.08-2.915-6.139-5.847-11.03-2.006-3.346-4.653-6.486-7.671-9.566-2.473-2.524-5.742-5.473-8.095-7.246-1.583-1.193-3.325-2.927-5.84-4.674-1.54-1.07-3.628-2.49-6.373-3.838-2.12-1.04-4.985-2.326-8.672-3.194-.23-.054-.46-.108-.694-.155-2.064-.412-5.993-1.036-10.631-.692-5.357.396-9.403 1.88-12.574 2.8-5.697 1.653-9.953 3.413-12.899 4.922-3.987 2.041-7.088 4.08-10.778 6.783-4.058 2.971-6.328 4.576-15.297 12.836-5.88 5.414-10.752 13.191-13.058 16.827"
    />
    <path
      className="st8"
      d="M400.543 387.045c.339-1.527.788-7.246.761-10.21-.013-1.738-.317-3.398-.55-4.967-.22-1.471-.589-3.321-1.464-5.326-.854-1.956-2.52-6.254-5.375-11.487-2.043-3.744-4.688-7.008-7.55-10.124-2.507-2.73-5.912-5.977-8.084-7.731-1.628-1.316-3.466-3.263-6.22-5.276-1.585-1.159-3.825-2.777-6.85-4.33-2.214-1.137-5.313-2.614-9.379-3.614-.249-.06-.5-.122-.754-.174-1.96-.406-6.299-1.17-11.575-.744-5.873.474-10.236 2.2-13.638 3.276-5.338 1.689-9.362 3.365-12.298 4.905-3.725 1.953-6.726 3.93-10.314 6.567-5.066 3.723-7.219 5.447-15.962 13.635-7.092 6.64-12.748 15.795-15.408 20.174"
    />
    <path
      className="st8"
      d="M403.425 385.539c.535-1.52 1.446-6.918 1.68-9.97.14-1.79-.037-3.494-.184-5.104-.147-1.608-.427-3.497-1.174-5.505-.68-1.828-2.116-6.372-4.904-11.944-2.074-4.144-4.716-7.534-7.429-10.681-2.534-2.94-6.075-6.49-8.072-8.217-1.669-1.444-3.61-3.594-6.6-5.877-1.632-1.247-4.025-3.06-7.327-4.822-2.31-1.233-5.638-2.91-10.086-4.034-.268-.067-.538-.135-.813-.194a42.789 42.789 0 0 0-12.52-.794c-6.39.55-11.073 2.508-14.7 3.75-4.985 1.709-8.773 3.316-11.7 4.89-3.463 1.86-6.363 3.779-9.85 6.35-6.07 4.48-8.103 6.322-16.626 14.433-8.288 7.886-14.743 18.4-17.758 23.522"
    />
    <path
      className="st8"
      d="M406.849 384.532c.732-1.511 1.562-7.09 2.056-10.229.292-1.843.25-3.59.184-5.24-.068-1.745-.274-3.67-.885-5.685-.515-1.696-1.706-6.492-4.432-12.4-2.098-4.546-4.739-8.066-7.308-11.239-2.556-3.155-6.232-7.007-8.061-8.703-1.703-1.579-3.757-3.922-6.98-6.479-1.68-1.332-4.226-3.34-7.804-5.313-2.405-1.327-5.964-3.205-10.792-4.454-.288-.074-.578-.148-.874-.214-1.754-.389-6.912-1.452-13.463-.845-6.908.612-11.916 2.804-15.765 4.227-4.637 1.714-8.184 3.263-11.1 4.872-3.203 1.767-6 3.629-9.384 6.135-7.072 5.238-8.984 7.2-17.292 15.23-9.465 9.15-16.738 21.006-20.108 26.87"
    />
    <path
      className="st8"
      d="M409.9 382.729c.928-1.504 2.052-6.464 2.805-9.692.445-1.896.538-3.682.552-5.376.016-1.88-.133-3.84-.597-5.864-.358-1.564-1.29-6.613-3.96-12.857-2.117-4.95-4.755-8.6-7.187-11.797-2.569-3.376-6.382-7.53-8.05-9.189-1.728-1.72-3.905-4.247-7.36-7.08-1.729-1.418-4.427-3.619-8.28-5.806-2.502-1.42-6.29-3.499-11.499-4.873-.307-.08-.617-.16-.934-.234-1.652-.378-7.218-1.584-14.407-.895-7.423.691-12.765 3.084-16.828 4.702-4.295 1.71-7.598 3.209-10.5 4.855-2.945 1.67-5.64 3.478-8.92 5.919-8.07 6.004-9.861 8.078-17.957 16.028-10.622 10.432-18.734 23.61-22.458 30.217"
    />
    <path
      className="st8"
      d="M412.882 380.621c1.124-1.496 2.61-5.534 3.624-8.85.597-1.949.83-3.774.92-5.512a30.366 30.366 0 0 0-.309-6.044c-.21-1.431-.869-6.733-3.488-13.313-2.132-5.354-4.766-9.137-7.066-12.354-2.575-3.601-6.528-8.057-8.038-9.675-1.745-1.87-4.058-4.571-7.74-7.683-1.78-1.503-4.632-3.895-8.758-6.297-2.6-1.513-6.615-3.794-12.205-5.293a36.424 36.424 0 0 0-.995-.253c-1.55-.368-7.523-1.717-15.35-.947-7.94.77-13.625 3.346-17.892 5.178-3.959 1.699-7.013 3.15-9.9 4.838-2.689 1.572-5.278 3.328-8.456 5.703-9.064 6.774-10.736 8.957-18.622 16.827-11.759 11.733-20.729 26.215-24.808 33.564"
    />
    <path
      className="st8"
      d="M415.866 377.834c1.32-1.487 3.166-3.925 4.44-7.33 1.649-4.405 1.478-8.13 1.268-11.87-.074-1.304-.445-6.853-3.017-13.77-2.141-5.759-4.771-9.675-6.945-12.912-2.571-3.83-6.666-8.583-8.027-10.161-1.748-2.028-4.211-4.892-8.12-8.284-1.83-1.588-4.835-4.171-9.234-6.79-2.853-1.698-7.437-4.38-13.967-5.985-1.447-.356-7.829-1.848-16.294-.998-8.456.85-14.495 3.587-18.955 5.654-6.71 3.11-10.59 5.275-17.292 10.308-10.053 7.55-11.608 9.836-19.287 17.625-12.873 13.057-22.724 28.82-27.158 36.912"
    />
    <path
      className="st8"
      d="M215.638 250.186a102.445 102.445 0 0 0-21.948 24.608c-10.642 16.74-13.663 32.293-14.632 38.575-.993 6.436-2.572 20.886 1.33 37.244 6.453 27.048 24.136 43.572 40.57 58.527 28.042 25.518 55.688 37.257 75.82 44.561 22.345 8.107 44.966 14.107 71.164 16.073 26.584 1.995 54.104-3.007 65.077-15.145"
    />
    <path
      className="st8"
      d="M215.538 250.485c-4.065 3.5-9.253 8.605-14.424 15.447a93.576 93.576 0 0 0-5.852 8.771 97.96 97.96 0 0 0-8.027 17.16c-3.157 8.915-4.492 16.241-4.898 20.24-.71 7.002-1.82 20.918 1.975 35.817 2.839 11.143 7.664 20.224 13.074 28.04 8.436 12.186 18.935 21.461 28.926 30.32 27.007 23.948 53.212 35.17 72.162 42.167 15.1 5.576 29.85 9.955 44.31 12.766a235.55 235.55 0 0 0 27.396 3.757c8.797.692 17.474.77 25.558.241 12.436-.812 22.97-3.313 30.272-7.04 3.458-1.761 6.56-4.75 8.972-7.398"
    />
    <path
      className="st8"
      d="M215.438 250.784c-3.774 3.47-8.546 8.545-13.302 15.246-1.766 2.488-3.539 5.353-5.302 8.583a93.717 93.717 0 0 0-7.108 16.684c-2.682 8.502-3.893 15.513-4.11 19.54-.408 7.579-1.018 20.941 2.62 34.39 3.095 11.439 8.349 20.435 13.765 28.027 8.821 12.367 19.517 21.407 29.665 30.167 25.948 22.399 50.73 33.1 68.503 39.772 15.936 5.982 31.07 10.384 44.634 13.084 9.103 1.812 18.26 3.134 27.616 3.89 8.972.736 17.7.821 25.773.366 13.26-.747 23.808-3.48 30.566-6.923 3.367-1.708 7.693-5.44 10.206-8.181"
    />
    <path
      className="st8"
      d="M215.338 251.084c-3.482 3.44-7.842 8.48-12.18 15.044-1.57 2.378-3.152 5.18-4.752 8.394a89.84 89.84 0 0 0-6.19 16.209c-2.222 8.08-3.28 14.787-3.321 18.84-.085 8.16-.171 20.955 3.265 32.962 3.36 11.738 9.039 20.643 14.455 28.016 9.215 12.543 20.095 21.357 30.405 30.012 24.866 20.873 48.242 31.046 64.845 37.378 16.769 6.394 32.282 10.837 44.958 13.402 9.306 1.883 18.538 3.244 27.834 4.023 9.148.78 17.929.878 25.989.49 14.086-.675 24.647-3.647 30.86-6.805 3.276-1.655 6.581-4.313 9.195-7.149"
    />
    <path
      className="st8"
      d="M215.239 251.383c-3.192 3.41-7.144 8.409-11.058 14.842-1.378 2.266-2.77 5.005-4.202 8.207a86.409 86.409 0 0 0-5.272 15.732c-1.78 7.65-2.654 14.064-2.533 18.14.259 8.746.717 20.96 3.91 31.535 3.635 12.038 9.734 20.848 15.145 28.004 9.615 12.715 20.666 21.315 31.145 29.858 23.762 19.371 45.75 29.006 61.187 34.983 17.6 6.815 33.495 11.297 45.28 13.72 9.51 1.957 18.818 3.355 28.055 4.156 9.323.826 18.156.94 26.204.617 14.912-.6 25.486-3.815 31.155-6.69 3.184-1.602 7.064-4.369 9.78-7.298"
    />
    <path
      className="st8"
      d="M215.139 251.682c-2.9 3.38-6.45 8.332-9.935 14.641-1.188 2.15-2.393 4.824-3.653 8.018-1.902 4.82-3.349 9.917-4.353 15.257-1.355 7.211-2.02 13.34-1.745 17.44.623 9.33 1.643 20.955 4.555 30.107 3.925 12.337 10.433 21.05 15.836 27.993 10.022 12.88 21.228 21.28 31.884 29.703 22.636 17.893 43.253 26.977 57.53 32.59 18.426 7.242 34.705 11.759 45.603 14.038 9.713 2.03 19.096 3.464 28.274 4.288 9.498.87 18.383 1.004 26.418.741 15.739-.515 26.325-3.98 31.45-6.571 3.094-1.55 7.524-4.418 10.342-7.441"
    />
    <path
      className="st8"
      d="M215.04 251.982c-2.61 3.348-5.766 8.248-8.813 14.44-1 2.031-2.024 4.638-3.104 7.829-1.532 4.524-2.685 9.448-3.434 14.78-.951 6.768-1.377 12.615-.957 16.741 1.007 9.911 2.604 20.94 5.2 28.68 4.237 12.631 11.137 21.248 16.526 27.98 10.438 13.042 21.782 21.256 32.624 29.55 21.49 16.439 40.752 24.958 53.871 30.194 19.25 7.684 35.916 12.227 45.928 14.357 9.914 2.11 19.374 3.574 28.493 4.42 9.673.915 18.61 1.073 26.633.867 16.565-.425 27.159-4.157 31.744-6.454 3-1.503 6.611-3.974 9.53-7.091"
    />
    <path
      className="st8"
      d="M215.638 250.186c-2.318 3.318-5.79 10.253-8.389 16.333-.817 1.912-1.664 4.45-2.554 7.641-1.177 4.224-2.037 8.977-2.515 14.305-.568 6.322-.728 11.887-.17 16.04 1.41 10.49 3.597 20.916 5.846 27.253 4.584 12.916 11.845 21.443 17.216 27.97 10.861 13.194 22.327 21.243 33.364 29.394 20.326 15.01 38.248 22.947 50.213 27.8 20.067 8.138 37.126 12.698 46.25 14.675 10.117 2.192 19.654 3.685 28.713 4.553 9.849.96 18.84 1.143 26.85.992 17.39-.328 27.997-4.324 32.037-6.337 2.909-1.45 6.621-3.809 9.642-7.02"
    />
    <path
      className="st8"
      d="M214.84 252.58c-2.027 3.288-4.436 8.063-6.568 14.037-.64 1.79-1.32 4.258-2.005 7.453-.839 3.92-1.41 8.506-1.597 13.828-.205 5.877-.073 11.159.62 15.342 1.83 11.06 4.617 20.88 6.49 25.825 4.993 13.177 12.555 21.634 17.907 27.957 11.292 13.34 22.86 21.25 34.103 29.24 19.145 13.606 35.741 20.944 46.555 25.406 20.876 8.612 38.335 13.172 46.574 14.993 10.316 2.28 19.932 3.795 28.932 4.686 10.023 1.004 19.066 1.216 27.064 1.117 18.217-.226 28.84-4.482 32.333-6.22 2.82-1.391 6.888-3.976 10.01-7.28"
    />
    <path
      className="st8"
      d="M214.74 252.88c-1.735 3.256-3.802 7.962-5.446 13.835-.466 1.668-.995 4.065-1.454 7.264-.52 3.618-.81 8.04-.679 13.353.135 5.436.585 10.427 1.407 14.642 2.268 11.625 5.663 20.833 7.136 24.397 5.531 13.385 13.27 21.824 18.597 27.945 11.732 13.48 23.38 21.276 34.842 29.086 17.95 12.229 33.232 18.948 42.898 23.012 21.674 9.11 39.542 13.65 46.898 15.31 10.514 2.375 20.21 3.906 29.15 4.82 10.2 1.048 19.295 1.302 27.28 1.241 19.043-.087 29.68-4.648 32.627-6.102 2.73-1.338 6.366-3.727 9.59-7.125"
    />
    <path
      className="st8"
      d="M218.636 246.888c-2.998 3.298-7.194 14.154-8.319 19.925-.615 3.152-1.61 9.558-.665 19.952 1.76 19.364 8.557 33.943 9.976 36.912 6.416 13.425 13.985 22.01 19.287 27.934 12.18 13.608 23.885 21.33 35.582 28.931 16.742 10.878 30.719 16.957 39.24 20.617 22.456 9.646 40.75 14.132 47.221 15.63 23.22 5.374 42.067 6.318 56.865 6.318 19.869 0 30.518-4.815 32.921-5.986 2.639-1.284 6.514-3.557 9.84-7.049"
    />
    <path
      className="st8"
      d="M245.702 358.665c10.004 5.405 23.403 10.664 30.458 13.563 8.776 3.607 15.684 5.905 19.953 7.316 8.207 2.713 14.98 4.952 24.275 6.984 12.265 2.68 21.916 3.469 28.599 3.99a237 237 0 0 0 22.28.665c7.278-.122 11.95-.22 18.29-1.33 6.344-1.11 11.452-2.004 17.292-5.32 3.75-2.13 9.239-5.247 12.478-11.744 2.858-5.73 2.472-11.095 2.153-15.525-.584-8.126-3.497-14.028-5.653-18.29-2.6-5.14-5.26-8.568-8.272-12.405a110.238 110.238 0 0 0-13.343-14.198"
    />
    <path
      className="st8"
      d="M245.93 358.88c2.096 1.229 4.421 2.53 6.889 3.846 4.134 2.219 8.25 4.16 12.036 5.85a715.603 715.603 0 0 0 14.15 6.141c8.17 3.434 14.85 5.668 19.267 7.16 7.838 2.65 14.477 4.85 23.444 6.885 8.914 2.027 16.953 3.293 23.937 4.067 2.018.224 3.95.395 5.776.57 3.05.29 8.449.72 15.352.885 2.504.067 5.136.088 7.856.069 3.782-.027 6.814-.073 9.416-.218 3.275-.168 6.297-.474 9.66-1.042 2.08-.346 3.992-.682 5.81-1.067 4.435-.938 8.208-2.146 12.2-4.491 1.938-1.14 4.112-2.5 6.131-4.16 2.206-1.821 4.536-4.372 6.207-7.888.18-.375.347-.75.5-1.124 2.215-5.344 1.767-10.264 1.409-14.538-.634-7.557-3.294-13.21-5.403-17.457-2.431-4.849-4.96-8.166-7.795-11.898-3.138-4.13-7.588-9.473-13.771-15.006"
    />
    <path
      className="st8"
      d="M246.159 359.096a242.965 242.965 0 0 0 7.365 4.445c4.61 2.674 9.014 4.875 12.965 6.72 5.068 2.368 10.494 4.868 15.36 6.945 7.574 3.234 14.018 5.428 18.583 7.005 7.47 2.578 13.973 4.748 22.612 6.785 8.624 2.042 16.969 3.62 24.826 4.61 2.005.252 4.017.455 6.002.673 3.314.365 8.907.847 15.963 1.097 2.669.095 5.408.142 8.172.147 4.12.006 7.323-.046 9.798-.169 3.511-.174 6.678-.478 10.063-1.022 2.205-.355 4.192-.698 6.05-1.09 4.879-1.027 8.73-2.314 12.678-4.705 2.074-1.256 4.223-2.72 6.095-4.299a23.17 23.17 0 0 0 6.59-9.193c2.135-5.375 1.6-10.253 1.178-14.66-.67-6.988-3.075-12.4-5.152-16.623-2.246-4.567-4.653-7.768-7.318-11.391-3.15-4.282-7.685-9.97-14.2-15.815"
    />
    <path
      className="st8"
      d="M246.387 359.311a256.892 256.892 0 0 0 7.842 5.045c5.08 3.138 9.778 5.588 13.894 7.59 5.089 2.477 10.909 5.301 16.57 7.75 6.984 3.02 13.186 5.186 17.9 6.848 7.101 2.503 13.468 4.646 21.78 6.687 8.332 2.056 16.984 3.934 25.714 5.151 1.992.278 4.084.515 6.227.778 3.58.439 9.366.988 16.576 1.31 2.833.125 5.678.196 8.487.222 4.46.042 7.833-.01 10.18-.118 3.747-.173 7.06-.476 10.467-1.003 2.329-.36 4.39-.715 6.288-1.114 5.323-1.115 9.252-2.485 13.157-4.918 2.21-1.375 4.331-2.944 6.059-4.438 1.949-1.685 4.338-4.425 6-8.218.168-.384.327-.769.473-1.156 2.056-5.407 1.436-10.242.947-14.781-.69-6.42-2.864-11.585-4.9-15.79-2.073-4.279-4.344-7.374-6.842-10.884-3.159-4.437-7.781-10.468-14.628-16.623"
    />
    <path
      className="st8"
      d="M246.616 359.527a239.795 239.795 0 0 0 8.319 5.644c5.543 3.613 10.543 6.299 14.822 8.46 5.111 2.583 11.325 5.731 17.78 8.553 6.399 2.797 12.354 4.944 17.215 6.693 6.737 2.423 12.965 4.544 20.948 6.588 8.043 2.071 17.002 4.239 26.604 5.694 1.98.3 4.151.574 6.453.881 3.844.513 9.824 1.132 17.187 1.522 2.997.159 5.95.253 8.803.3 4.798.079 8.343.025 10.563-.07 3.983-.17 7.44-.472 10.87-.981 2.453-.364 4.59-.733 6.526-1.139 5.768-1.203 9.772-2.66 13.637-5.13a48.767 48.767 0 0 0 6.022-4.578c1.818-1.62 4.245-4.449 5.896-8.383.163-.388.318-.778.46-1.172 1.976-5.439 1.275-10.231.717-14.903-.7-5.852-2.656-10.768-4.65-14.956-1.901-3.99-4.03-6.984-6.366-10.377-3.163-4.595-7.877-10.965-15.055-17.431"
    />
    <path
      className="st8"
      d="M246.844 359.742a231.994 231.994 0 0 0 8.796 6.245c6.013 4.077 11.31 7.006 15.752 9.33 5.134 2.685 11.742 6.156 18.99 9.356 5.815 2.567 11.523 4.698 16.53 6.537 6.373 2.34 12.461 4.442 20.116 6.49 7.752 2.085 17.021 4.532 27.493 6.235 1.968.32 4.218.634 6.678.986 4.11.586 10.283 1.277 17.8 1.734 3.16.192 6.22.31 9.117.377 5.138.117 8.853.062 10.946-.02 4.22-.166 7.822-.469 11.274-.962 2.578-.368 4.788-.75 6.765-1.163 6.211-1.292 10.288-2.839 14.115-5.343 2.475-1.62 4.547-3.393 5.986-4.717 1.688-1.554 4.153-4.472 5.793-8.547.158-.393.308-.789.446-1.19 1.897-5.47 1.12-10.22.486-15.023-.698-5.286-2.449-9.952-4.4-14.123-1.73-3.7-3.71-6.596-5.888-9.87-3.163-4.756-7.974-11.463-15.484-18.24"
    />
    <path
      className="st8"
      d="M247.073 359.958a222.452 222.452 0 0 0 9.273 6.844c6.482 4.542 12.076 7.713 16.68 10.2 5.16 2.786 12.163 6.576 20.2 10.16 5.234 2.333 10.693 4.45 15.846 6.381 6.01 2.252 11.957 4.34 19.284 6.39 7.462 2.101 17.042 4.817 28.382 6.779 1.956.338 4.285.694 6.904 1.089 4.374.66 10.741 1.425 18.41 1.947 3.326.226 6.492.369 9.434.454 5.476.157 9.363.1 11.328.029 4.456-.16 8.204-.464 11.678-.941a103.11 103.11 0 0 0 7.003-1.187c6.656-1.38 10.804-3.02 14.594-5.557 2.607-1.744 4.654-3.618 5.95-4.857 1.556-1.489 4.062-4.494 5.69-8.711.152-.397.298-.798.431-1.206 1.819-5.5.973-10.209.256-15.145-.685-4.72-2.243-9.134-4.149-13.289-1.563-3.409-3.387-6.21-5.411-9.364-3.159-4.92-8.07-11.96-15.912-19.047"
    />
    <path
      className="st8"
      d="M247.301 360.173a213.368 213.368 0 0 0 9.75 7.444c6.952 5.007 12.845 8.418 17.609 11.07 5.185 2.885 12.587 6.988 21.41 10.964 4.654 2.096 9.865 4.198 15.162 6.225 5.648 2.161 11.453 4.239 18.452 6.292 7.17 2.116 17.066 5.089 29.272 7.32 1.944.356 4.351.754 7.128 1.193 4.64.735 11.2 1.574 19.023 2.16 3.49.26 6.762.428 9.75.53 5.813.2 9.872.139 11.71.079 4.691-.153 8.585-.458 12.08-.92a99.847 99.847 0 0 0 7.242-1.212c7.1-1.469 11.319-3.203 15.074-5.77 2.737-1.87 4.76-3.844 5.912-4.996 1.425-1.425 3.974-4.516 5.587-8.876.148-.401.29-.808.418-1.222 1.74-5.532.835-10.2.026-15.266-.664-4.156-2.041-8.315-3.899-12.456-1.398-3.117-3.061-5.828-4.934-8.857-3.148-5.09-8.167-12.457-16.34-19.856"
    />
    <path
      className="st8"
      d="M247.53 360.389a205.461 205.461 0 0 0 10.226 8.043c7.422 5.472 13.614 9.121 18.538 11.94 5.212 2.982 13.016 7.39 22.62 11.768 4.075 1.857 9.038 3.943 14.478 6.07 5.288 2.066 10.95 4.136 17.62 6.192 6.88 2.13 17.091 5.351 30.16 7.863 1.934.372 4.42.813 7.355 1.297a238.041 238.041 0 0 0 19.635 2.371c3.653.303 7.032.488 10.064.608 6.153.243 10.383.177 12.092.128 4.929-.142 8.969-.446 12.485-.9a94.824 94.824 0 0 0 7.48-1.235c7.544-1.558 11.832-3.388 15.553-5.983 2.867-1.998 4.866-4.071 5.876-5.136 1.292-1.362 3.886-4.538 5.483-9.041.144-.405.28-.818.404-1.238 1.66-5.564.711-10.194-.204-15.387-.634-3.594-1.842-7.495-3.648-11.623-1.235-2.824-2.732-5.447-4.458-8.35-3.129-5.263-8.263-12.954-16.768-20.664"
    />
    <path
      className="st8"
      d="M247.758 360.605a198.985 198.985 0 0 0 10.704 8.642c7.891 5.937 14.39 9.815 19.466 12.81 5.245 3.07 13.453 7.774 23.83 12.572a288.939 288.939 0 0 0 13.794 5.914 266.1 266.1 0 0 0 16.788 6.094c6.59 2.145 17.12 5.601 31.05 8.405 1.922.386 4.486.872 7.579 1.4a248.01 248.01 0 0 0 20.247 2.584c3.817.336 7.303.552 10.38.685 6.49.295 10.893.217 12.475.177 5.164-.13 9.35-.442 12.888-.88a93.681 93.681 0 0 0 7.719-1.259c7.988-1.646 12.343-3.574 16.032-6.196a34.518 34.518 0 0 0 5.839-5.275c1.157-1.3 3.806-4.557 5.38-9.206.14-.409.27-.827.39-1.255 1.582-5.594.609-10.191-.435-15.508-.595-3.034-1.648-6.672-3.397-10.789-1.074-2.53-2.399-5.068-3.98-7.843a86.585 86.585 0 0 0-17.197-21.472"
    />
    <path
      className="st8"
      d="M247.987 360.82a193.746 193.746 0 0 0 11.18 9.242c8.361 6.402 15.157 10.523 20.396 13.68a278.386 278.386 0 0 0 25.04 13.375c5.254 2.474 15.526 7.11 29.065 11.754 6.3 2.16 17.15 5.842 31.94 8.947 5.104 1.072 15.419 3.071 28.662 4.3 12.693 1.179 21.434 1.033 23.553.99 10.259-.214 16.654-1.198 21.25-2.144 8.431-1.734 12.853-3.76 16.51-6.408 3.12-2.26 5.073-4.529 5.802-5.416 1.11-1.347 4.193-5.17 5.654-10.64 1.502-5.627.542-10.2-.665-15.63-.85-3.824-2.56-9.78-6.651-17.292-3.063-5.624-8.456-13.95-17.625-22.28"
    />
    <path
      className="st8"
      d="M232.483 418.9a317.492 317.492 0 0 1-14.32-12.313c-6.284-5.735-10.839-9.92-16.227-16.158-3.712-4.298-7.519-8.747-11.52-15.46-4.902-8.222-7.312-15.17-8.091-17.528-.611-1.85-2.672-8.3-3.759-17.103-.397-3.214-1.176-10.885-.268-20.732a100.2 100.2 0 0 1 4.901-22.956 101.506 101.506 0 0 1 8.843-19.166c4.638-7.809 9.156-13.034 12.12-16.426 5.562-6.37 10.517-10.783 15.023-14.64a450.61 450.61 0 0 1 30.594-23.944c19.322-13.808 31.863-22.27 48.329-33.697a3028.636 3028.636 0 0 0 28.391-19.943"
    />
    <path
      className="st8"
      d="M229.778 417.214a473.96 473.96 0 0 1-7.774-6.324c-2.466-2.047-4.904-4.168-7.383-6.332a278.593 278.593 0 0 1-4.65-4.134c-4.609-4.206-8.406-7.994-12.43-12.647-3.685-4.262-7.46-8.665-11.408-15.206a93.97 93.97 0 0 1-4.74-8.898 90.998 90.998 0 0 1-3.366-8.323c-.73-2.055-2.736-8.34-3.914-16.81a83.914 83.914 0 0 1-.61-5.827 102.745 102.745 0 0 1 0-14.585c.352-5.002 1.43-13.1 4.427-22.688 1.482-4.74 4.145-11.79 8.735-19.606a107.993 107.993 0 0 1 5.742-8.782c2.433-3.345 4.655-5.987 6.419-8.061 5.503-6.423 10.494-10.997 15.127-15.057 14.765-12.936 27.657-22.065 32.367-25.406 18.473-13.102 31.076-21.717 47.474-33.2 11.822-8.25 21.51-15.157 27.834-19.727"
    />
    <path
      className="st8"
      d="M227.074 415.53a724.247 724.247 0 0 1-8.191-6.51c-2.752-2.213-5.304-4.386-7.803-6.492-2.251-1.898-3.621-3.08-4.913-4.239-5.019-4.506-9.027-8.552-13.02-13.163-3.659-4.227-7.4-8.583-11.296-14.954a94.044 94.044 0 0 1-4.732-8.738c-1.684-3.563-2.759-6.488-3.39-8.175-.85-2.26-2.81-8.38-4.068-16.518-.21-1.36-.48-3.31-.688-5.734a107.308 107.308 0 0 1-.266-14.356c.248-5.091 1.239-13.03 3.954-22.422 1.516-5.243 4.163-12.35 8.627-20.045a114.163 114.163 0 0 1 5.768-8.982c2.39-3.375 4.608-6.092 6.434-8.279 5.421-6.492 10.472-11.21 15.23-15.472 15.134-13.545 28.367-22.796 34.142-26.87 17.605-12.42 30.289-21.163 46.62-32.7 11.302-7.936 20.76-14.735 27.275-19.513"
    />
    <path
      className="st8"
      d="M224.37 413.844a1138.2 1138.2 0 0 1-8.609-6.693c-3.038-2.38-5.71-4.598-8.222-6.653-2.605-2.131-3.932-3.244-5.176-4.344-5.432-4.804-9.648-9.11-13.609-13.68-3.634-4.191-7.344-8.5-11.186-14.7a93.947 93.947 0 0 1-4.723-8.58c-1.612-3.347-2.7-6.202-3.413-8.026-.97-2.464-2.895-8.42-4.224-16.225a83.618 83.618 0 0 1-.766-5.642 110.587 110.587 0 0 1-.53-14.128c.14-5.18 1.018-12.967 3.48-22.154 1.54-5.751 4.182-12.913 8.519-20.485a122.23 122.23 0 0 1 5.795-9.181c2.351-3.401 4.565-6.193 6.449-8.497 5.355-6.55 10.448-11.423 15.334-15.888 15.5-14.153 29.075-23.528 35.914-28.332 16.729-11.752 29.502-20.61 45.765-32.202 10.784-7.623 20.013-14.313 26.718-19.298"
    />
    <path
      className="st8"
      d="M221.665 412.158c-2.425-1.83-5.488-4.17-9.025-6.876-3.326-2.545-6.12-4.805-8.642-6.814-2.963-2.36-4.244-3.408-5.44-4.45-5.848-5.096-10.268-9.667-14.198-14.195-3.608-4.157-7.285-8.42-11.074-14.449a93.697 93.697 0 0 1-4.716-8.419c-1.537-3.132-2.641-5.917-3.436-7.878-1.088-2.668-2.988-8.459-4.378-15.932-.265-1.422-.581-3.3-.846-5.55-.428-3.646-.824-8.39-.793-13.9.03-5.27.776-12.912 3.006-21.886 1.556-6.262 4.215-13.467 8.41-20.925a134.323 134.323 0 0 1 5.822-9.38c2.315-3.427 4.524-6.294 6.464-8.715 5.292-6.603 10.426-11.637 15.438-16.304 15.868-14.762 29.781-24.262 37.688-29.796 15.846-11.09 28.687-20.095 44.91-31.703 10.248-7.334 19.265-13.89 26.16-19.083"
    />
    <path
      className="st8"
      d="M218.961 410.473c-2.487-1.847-5.7-4.256-9.442-7.06-3.615-2.709-6.534-5.009-9.061-6.975-3.325-2.585-4.558-3.57-5.704-4.555a139.527 139.527 0 0 1-14.788-14.711c-3.582-4.122-7.227-8.338-10.962-14.196-1.763-2.773-3.346-5.536-4.709-8.26-1.46-2.919-2.582-5.632-3.46-7.73-1.206-2.873-3.089-8.496-4.532-15.64a83.702 83.702 0 0 1-.924-5.457c-.484-3.597-.971-8.254-1.057-13.671-.086-5.362.515-12.863 2.531-21.619 1.56-6.777 4.235-14.03 8.304-21.365 1.937-3.493 3.917-6.656 5.848-9.58 2.279-3.45 4.483-6.393 6.479-8.932 5.23-6.655 10.402-11.85 15.54-16.72 16.236-15.37 30.487-24.998 39.462-31.259 14.96-10.435 27.898-19.545 44.055-31.205 9.729-7.021 18.518-13.468 25.603-18.868"
    />
    <path
      className="st8"
      d="M216.257 408.788c-2.55-1.864-5.913-4.342-9.86-7.245-3.903-2.87-6.948-5.209-9.48-7.134-3.69-2.806-4.872-3.733-5.967-4.661-6.693-5.67-11.51-10.784-15.377-15.228-3.557-4.086-7.17-8.255-10.852-13.943-1.722-2.668-3.305-5.367-4.7-8.1-1.382-2.706-2.524-5.347-3.483-7.582-1.326-3.077-3.199-8.532-4.688-15.346a85.014 85.014 0 0 1-1.002-5.366c-.54-3.549-1.122-8.117-1.322-13.443-.204-5.452.242-12.815 2.058-21.351 1.552-7.294 4.252-14.593 8.196-21.805 1.93-3.532 3.921-6.756 5.874-9.779a133.072 133.072 0 0 1 6.494-9.15c5.173-6.705 10.38-12.064 15.645-17.136 16.602-15.978 31.19-25.738 41.235-32.722 14.071-9.783 27.11-18.992 43.2-30.706 9.21-6.707 17.77-13.046 25.045-18.653"
    />
    <path
      className="st8"
      d="M213.553 407.102c-2.611-1.88-6.126-4.426-10.277-7.428-4.193-3.033-7.365-5.407-9.9-7.295-4.058-3.021-5.186-3.894-6.23-4.766-7.122-5.948-12.132-11.342-15.967-15.744-3.53-4.051-7.111-8.174-10.74-13.69-1.682-2.565-3.263-5.2-4.693-7.942-1.301-2.494-2.465-5.061-3.506-7.433-1.445-3.282-3.315-8.565-4.842-15.054a87.237 87.237 0 0 1-1.08-5.273c-.6-3.5-1.278-7.98-1.587-13.214-.327-5.542-.044-12.77 1.584-21.084 1.53-7.813 4.256-15.163 8.088-22.245 1.917-3.574 3.927-6.855 5.9-9.978a133.722 133.722 0 0 1 6.51-9.368c5.117-6.754 10.356-12.278 15.748-17.552 16.97-16.587 31.89-26.48 43.009-34.185a1796.865 1796.865 0 0 0 42.344-30.208c8.695-6.39 17.023-12.623 24.487-18.438"
    />
    <path
      className="st8"
      d="M210.848 405.417a3957.775 3957.775 0 0 1-10.694-7.613c-4.487-3.187-7.782-5.604-10.32-7.455-4.428-3.232-5.5-4.055-6.493-4.871-7.556-6.22-12.752-11.9-16.556-16.26-3.504-4.017-7.053-8.092-10.629-13.438a92.406 92.406 0 0 1-4.685-7.782c-1.219-2.283-2.406-4.777-3.529-7.284-1.564-3.487-3.437-8.598-4.997-14.762a90.47 90.47 0 0 1-1.159-5.18c-.661-3.45-1.436-7.844-1.85-12.987-.454-5.631-.34-12.725 1.11-20.816 1.493-8.334 4.274-15.726 7.98-22.685 1.91-3.613 3.934-6.954 5.927-10.178 2.177-3.52 4.368-6.689 6.524-9.585 5.063-6.801 10.333-12.491 15.852-17.968 17.337-17.195 32.588-27.228 44.782-35.648 12.29-8.486 25.567-17.843 41.49-29.71 8.191-6.055 16.275-12.2 23.93-18.223"
    />
    <path
      className="st8"
      d="M208.144 403.732c-2.735-1.915-6.555-4.59-11.111-7.797-4.775-3.35-8.2-5.8-10.74-7.616-4.803-3.437-5.815-4.214-6.756-4.977-7.998-6.482-13.373-12.458-17.146-16.776-3.478-3.98-6.994-8.01-10.517-13.185a92.658 92.658 0 0 1-4.677-7.623 109.94 109.94 0 0 1-3.552-7.135c-1.684-3.692-3.566-8.628-5.152-14.469a94.865 94.865 0 0 1-1.238-5.089c-.724-3.4-1.598-7.705-2.114-12.758-.586-5.72-.644-12.68.635-20.548 1.44-8.857 4.293-16.288 7.873-23.125 1.904-3.651 3.945-7.05 5.954-10.377a138.503 138.503 0 0 1 6.539-9.803c5.02-6.84 10.31-12.705 15.955-18.384 17.704-17.803 33.269-28 46.556-37.111 11.386-7.857 24.78-17.29 40.634-29.211a1028.293 1028.293 0 0 0 23.372-18.008"
    />
    <path
      className="st8"
      d="M205.44 402.046a6085.898 6085.898 0 0 1-11.528-7.98c-15.395-10.685-16.856-11.804-18.18-12.859-8.446-6.735-13.994-13.016-17.735-17.292-5.005-5.721-10.077-11.518-15.075-20.396a100.566 100.566 0 0 1-10.198-26.16c-1.715-7.267-4.287-18.63-2.217-32.81 2.122-14.535 8.018-24.48 13.745-34.141 6.756-11.396 14.213-20.262 22.613-28.82 18.072-18.413 33.971-28.74 48.33-38.575 15.264-10.456 36.831-25.865 62.593-46.506M378.524 378.768c-4.613.181-9.948.764-12.8 1.33-3.812.758-7.203 1.432-11.268 3.35-3.926 1.853-7.56 4.631-9.435 6.294"
    />
    <path
      className="st8"
      d="M378.109 377.157c-1.03-.162-3.268-.425-4.451-.594-1.207-.172-2.39-.341-4.854-.489-1.033-.062-2.386.025-3.858.166-1.661.16-3.063.488-4.235.75-4.054.906-7.563 1.713-11.521 3.641-2.116 1.03-3.866 2.132-5.607 3.355-1.82 1.271-5.098 4.42-6.225 5.417"
    />
    <path
      className="st8"
      d="M377.11 375.618c-1.162-.36-3.74-1.547-6.11-1.963-.568-.1-3.243-.53-5.04-.684a32.205 32.205 0 0 0-4.894-.044c-2.187.15-3.957.612-5.369.954-4.298 1.04-7.919 2.006-11.773 3.933-2.375 1.188-4.254 2.43-6.343 3.933-2.086 1.49-6.135 5.524-7.401 6.64"
    />
    <path
      className="st8"
      d="M375.864 373.572c-1.297-.557-4.44-1.899-6.318-2.577-1.68-.607-4.311-.9-6.431-1.128-1.098-.118-3.277-.394-5.93-.253-2.712.145-4.85.734-6.501 1.158-4.544 1.166-8.272 2.304-12.026 4.225-2.634 1.348-4.642 2.729-7.078 4.511-2.352 1.707-7.338 6.301-8.742 7.537"
    />
    <path
      className="st8"
      d="M374.118 371.244c-1.43-.755-5.287-1.473-7.51-2.416-1.919-.815-3.805-1.692-6.338-2.064-1.13-.166-3.722-.602-6.965-.463-3.238.14-5.744.854-7.635 1.362-4.791 1.286-8.622 2.608-12.278 4.518-2.89 1.51-5.03 3.026-7.814 5.088-2.618 1.925-7.53 6.337-9.073 7.692"
    />
    <path
      className="st8"
      d="M370.821 369.777c-1.563-.952-3.605-2.26-6.169-3.472-2.16-1.021-4.284-2.115-7.227-2.644-1.163-.21-4.167-.81-8-.673-3.765.134-6.638.973-8.768 1.566-5.04 1.404-8.971 2.915-12.531 4.81-3.147 1.674-5.417 3.324-8.55 5.666-2.883 2.144-7.707 6.712-9.389 8.187"
    />
    <path
      className="st8"
      d="M371.707 368.584c-1.696-1.15-6.107-3.32-9.01-4.8-2.404-1.228-4.767-2.526-8.116-3.226-1.199-.25-4.613-1.008-9.037-.883-4.29.137-7.532 1.09-9.9 1.77-5.29 1.518-9.319 3.225-12.784 5.102-3.401 1.842-5.805 3.622-9.285 6.243-3.15 2.363-7.97 7.002-9.79 8.597"
    />
    <path
      className="st8"
      d="M368.337 366.181c-1.83-1.347-4.354-3.168-7.596-4.92-2.648-1.432-5.254-2.925-9.005-3.807-1.234-.29-5.058-1.216-10.072-1.092-4.816.131-8.428 1.206-11.034 1.974-5.539 1.632-9.664 3.536-13.036 5.394-3.654 2.013-6.193 3.92-10.02 6.821-3.416 2.58-8.314 7.019-10.273 8.733"
    />
    <path
      className="st8"
      d="M367.095 364.383c-1.963-1.545-4.73-3.62-8.309-5.644-2.894-1.637-5.745-3.316-9.895-4.388-1.271-.329-5.503-1.424-11.107-1.302-5.342.125-9.324 1.321-12.168 2.178-5.788 1.744-10.009 3.85-13.288 5.686-3.904 2.187-6.58 4.219-10.756 7.4-3.681 2.798-7.744 7.01-9.841 8.844"
    />
    <path
      className="st8"
      d="M365.853 362.585c-2.096-1.742-5.107-4.072-9.023-6.368-3.139-1.841-6.24-3.697-10.783-4.97-1.31-.366-5.95-1.631-12.144-1.51-5.867.118-10.22 1.43-13.3 2.381-6.043 1.845-10.354 4.164-13.541 5.978-4.153 2.364-6.969 4.517-11.491 7.977-3.948 3.017-8.754 6.625-10.99 8.58"
    />
    <path
      className="st8"
      d="M364.611 360.786a69.927 69.927 0 0 0-9.736-7.092c-3.385-2.045-6.738-4.07-11.673-5.55-1.349-.404-6.395-1.84-13.18-1.72-6.392.112-11.114 1.548-14.433 2.585-6.29 1.966-10.696 4.48-13.793 6.27-4.4 2.545-7.356 4.815-12.227 8.555a204.482 204.482 0 0 0-9.972 8.167M240.692 425.05a111.223 111.223 0 0 0-9.536 23.552c-.602 2.177-3.233 11.941-3.99 26.603-.468 9.06-1.22 26.45 4.877 47.443 5.066 17.447 12.505 29.264 18.179 38.131 13.808 21.577 28.681 34.7 40.792 45.226 12.347 10.731 41.794 30.874 66.244 43.52"
    />
    <path
      className="st8"
      d="M244.965 428.687c-2.628 4.897-5.189 10.485-7.777 18.692A125.591 125.591 0 0 0 236.11 451c-.708 2.509-3.298 12.067-4.117 26.22-.06 1.007-.12 2.11-.18 3.3-.545 10.607-.292 26.315 4.62 43.547 4.812 16.886 11.812 28.453 17.217 37.128 1.31 2.104 2.62 4.125 3.946 6.052 11.453 16.641 23.956 28.312 34.88 38.314 8.923 8.21 25.673 20.211 42.62 30.82 7.118 4.456 14.243 8.674 20.903 12.207"
    />
    <path
      className="st8"
      d="M249.42 432.018c-2.579 4.88-4.711 9.672-7.286 17.812a140.981 140.981 0 0 0-1.07 3.569c-.815 2.84-3.332 12.193-4.244 25.837-.067 1-.132 2.088-.203 3.255-.704 11.566-.209 27.18 4.205 42.995 4.557 16.326 11.12 27.64 16.256 36.125 1.246 2.06 2.479 4.035 3.738 5.902 10.327 15.31 22.201 27.056 33.124 37.605 9.056 8.746 25.026 20.34 40.742 30.45 6.921 4.453 13.749 8.642 20.058 12.082"
    />
    <path
      className="st8"
      d="M254.524 434.082c-2.529 4.864-4.883 10.126-7.444 18.2a156.941 156.941 0 0 0-1.062 3.515c-.923 3.17-3.387 12.32-4.371 25.455-.075.994-.146 2.066-.226 3.21-.872 12.525-.133 28.048 3.79 42.442 4.299 15.769 10.428 26.83 15.294 35.122 1.182 2.015 2.34 3.946 3.532 5.754 9.21 13.97 20.43 25.816 31.366 36.895 9.177 9.296 24.374 20.481 38.864 30.08 6.724 4.455 13.256 8.611 19.213 11.957"
    />
    <path
      className="st8"
      d="M259.276 436.836c-2.48 4.848-4.702 9.89-7.25 17.897a173.92 173.92 0 0 0-1.053 3.463c-1.033 3.5-3.447 12.446-4.5 25.071-.081.988-.16 2.045-.247 3.167-1.05 13.483-.066 28.917 3.375 41.89 4.036 15.211 9.736 26.017 14.332 34.118 1.117 1.97 2.2 3.855 3.324 5.605 8.109 12.62 18.657 24.579 29.61 36.184 9.295 9.85 23.713 20.638 36.985 29.71 6.525 4.461 12.763 8.58 18.368 11.833"
    />
    <path
      className="st8"
      d="M264.093 439.462c-2.43 4.833-4.587 9.783-7.12 17.722-.342 1.07-.688 2.209-1.046 3.41-1.143 3.83-3.51 12.572-4.626 24.689-.09.98-.177 2.023-.271 3.122-1.236 14.44-.007 29.79 2.96 41.337 3.768 14.658 9.044 25.206 13.371 33.116 1.053 1.925 2.061 3.763 3.117 5.456 7.02 11.258 16.88 23.345 27.852 35.474 9.412 10.405 23.044 20.809 35.107 29.34 6.323 4.472 12.27 8.55 17.524 11.708"
    />
    <path
      className="st8"
      d="M269.383 442.167c-2.381 4.817-4.944 9.596-7.465 17.469a212.074 212.074 0 0 0-1.037 3.357c-1.254 4.16-3.577 12.697-4.753 24.305-.099.975-.194 2.002-.294 3.078-1.432 15.398.042 30.666 2.546 40.785 3.491 14.106 8.35 24.395 12.409 32.113.988 1.88 1.924 3.67 2.909 5.306 5.951 9.885 15.1 22.114 26.095 34.764 9.527 10.963 22.365 20.996 33.229 28.97 6.117 4.49 11.778 8.52 16.68 11.584"
    />
    <path
      className="st8"
      d="M273.613 444.943c-2.332 4.801-4.242 9.338-6.749 17.144-.327 1.02-.67 2.124-1.03 3.304-1.366 4.49-3.647 12.822-4.879 23.923-.108.968-.21 1.98-.316 3.034-1.64 16.353.079 31.544 2.13 40.232 3.203 13.557 7.66 23.583 11.448 31.11.923 1.836 1.79 3.575 2.701 5.157 4.902 8.498 13.32 20.884 24.338 34.054 9.639 11.522 21.675 21.198 31.35 28.6 5.905 4.517 11.286 8.488 15.836 11.459"
    />
    <path
      className="st8"
      d="M278.913 446.54c-2.282 4.785-4.61 10.26-7.103 17.998-.32.997-.662 2.082-1.021 3.251a134.62 134.62 0 0 0-5.007 23.54c-.117.962-.228 1.96-.34 2.99-1.857 17.307.104 32.426 1.717 39.68 2.894 13.013 6.966 22.771 10.486 30.106.859 1.792 1.659 3.479 2.494 5.009 3.875 7.098 11.515 19.671 22.58 33.344 9.731 12.096 20.971 21.416 29.473 28.23 5.683 4.554 10.792 8.457 14.99 11.334"
    />
    <path
      className="st8"
      d="M283.69 449.226c-2.232 4.77-4.454 10.092-6.934 17.764-.314.972-.655 2.039-1.013 3.198-1.602 5.145-3.82 13.068-5.134 23.156-.128.956-.246 1.938-.362 2.946-2.088 18.26.113 33.31 1.302 39.127 2.55 12.478 6.274 21.96 9.524 29.104.794 1.747 1.536 3.378 2.286 4.86 2.878 5.683 9.739 18.437 20.823 32.633 9.847 12.652 20.252 21.652 27.595 27.86 5.448 4.608 10.299 8.426 14.146 11.21"
    />
    <path
      className="st8"
      d="M288.468 451.912c-2.183 4.754-4.3 9.924-6.766 17.53-1.917 5.911-5.07 15.803-6.65 28.82-2.332 19.21.104 34.196.886 38.574 2.659 14.878 7.347 25.486 10.642 32.811 1.915 4.258 7.96 17.203 19.065 31.924 9.963 13.207 19.515 21.9 25.717 27.49a223.329 223.329 0 0 0 13.302 11.085M305.055 456.597c-.42 5.728-.577 12.96.147 21.269.216 2.477.864 9.116 2.66 17.292 1.285 5.848 5.014 20.932 14.632 38.575 3.083 5.654 9.661 16.873 23.5 32.367 19.76 22.124 39.068 35.882 48.772 42.565 64.24 44.238 132.337 70.864 157.847 80.253 3.169 1.167 32.741 11.933 71.385 20.396 5.442 1.192 20.89 4.49 41.235 5.764 19.227 1.205 29.764-.217 38.132-2.217a104.945 104.945 0 0 0 17.292-5.764"
    />
    <path
      className="st8"
      d="M303.448 456.015c-.49 4.27-.93 9.454-.953 15.363-.008 2.033.062 4.182.113 6.442.064 2.834.478 9.568 1.95 17.856.113.636.262 1.36.404 2.174 1.074 6.155 3.401 15.161 7.389 24.946a167.029 167.029 0 0 0 5.949 13.037c1.427 2.791 3.41 6.43 6.239 10.805 3.669 5.646 9.204 13.638 17.42 23.009 17.048 19.445 33.846 32.138 43.983 39.4 1.1.784 2.122 1.504 3.059 2.16 13.68 9.592 27.393 18.162 40.754 25.901 48.47 28.126 93.24 45.473 111.817 52.874 3.738 1.49 22.759 8.758 49.723 16.361 8.243 2.325 16.875 4.508 25.67 6.617 4.172 1 11.049 2.497 19.688 3.89 7.394 1.191 15.446 2.233 24.003 2.846 3.441.246 6.545.401 9.388.478 15.163.43 24.299-1.14 31.104-2.835a101.365 101.365 0 0 0 17.957-6.34"
    />
    <path
      className="st8"
      d="M301.841 455.432c-.67 4.305-1.379 9.615-1.657 15.731-.093 2.053-.097 4.261-.17 6.612-.1 3.193.078 10.025 1.24 18.42.092.669.223 1.418.328 2.251.927 7.334 3.207 16.743 6.723 25.91 1.719 4.483 3.61 9.04 5.8 13.578a113.063 113.063 0 0 0 6.216 11.261c3.46 5.495 9.032 14.047 17.603 23.999 16.319 18.947 32.467 31.212 42.363 38.437 1.055.765 2.04 1.47 2.948 2.118 13.325 9.519 26.529 17.833 39.332 25.322 47.151 27.682 90.648 44.785 107.965 51.976 5.011 2.08 24.626 9.93 52.327 18.188 8.972 2.675 18.077 5.082 27.075 7.371 5.182 1.319 12.402 2.928 20.78 4.382 8.355 1.45 16.786 2.641 25.365 3.325 3.707.295 6.977.477 9.933.567 16.521.532 26.294-1.347 32.919-3.062 7.975-2.065 14.3-4.815 18.622-6.917"
    />
    <path
      className="st8"
      d="M300.234 454.85c-.85 4.34-1.821 9.775-2.36 16.098-.177 2.072-.266 4.341-.454 6.782-.274 3.553-.335 10.486.529 18.982.071.704.18 1.48.254 2.33.743 8.523 2.977 18.342 6.056 26.874 1.717 4.76 3.536 9.5 5.652 14.12 1.602 3.493 3.582 7.438 6.192 11.716 3.26 5.339 8.865 14.452 17.786 24.99 15.607 18.433 31.072 30.306 40.743 37.474 1.008.747 1.958 1.436 2.838 2.075 12.969 9.445 25.664 17.504 37.909 24.744 45.833 27.236 88.015 44.2 104.112 51.076 6.282 2.683 26.488 11.126 54.931 20.016 9.701 3.032 19.274 5.678 28.48 8.125 6.191 1.646 13.754 3.376 21.874 4.875 9.313 1.718 18.125 3.054 26.726 3.803 3.972.347 7.408.552 10.477.655 17.88.634 28.288-1.559 34.735-3.289 8.29-2.225 14.829-5.216 19.287-7.493"
    />
    <path
      className="st8"
      d="M298.628 454.268c-1.031 4.375-2.259 9.935-3.064 16.465-.258 2.091-.442 4.42-.738 6.952-.458 3.914-.76 10.948-.182 19.545.05.738.132 1.54.18 2.407.525 9.718 2.717 19.954 5.39 27.84 1.707 5.04 3.453 9.962 5.503 14.659 1.68 3.85 3.671 7.941 6.169 12.172 3.06 5.182 8.703 14.851 17.968 25.98 14.91 17.908 29.69 29.384 39.123 36.513.963.727 1.875 1.402 2.727 2.033 12.613 9.37 24.8 17.174 36.486 24.164 44.516 26.792 85.361 43.665 100.261 50.177 7.547 3.298 28.344 12.34 57.535 21.844 10.428 3.395 20.467 6.287 29.884 8.88a313.494 313.494 0 0 0 22.967 5.366c10.271 1.998 19.464 3.472 28.088 4.283 4.238.399 7.84.627 11.022.742 19.24.737 30.281-1.775 36.55-3.516 8.603-2.39 15.357-5.617 19.952-8.07"
    />
    <path
      className="st8"
      d="M297.02 453.685c-1.21 4.41-2.69 10.096-3.766 16.832-.338 2.112-.624 4.5-1.022 7.123-.65 4.273-1.193 11.412-.892 20.108.026.773.081 1.6.104 2.485.275 10.912 2.432 21.576 4.723 28.803 1.69 5.326 3.366 10.43 5.356 15.2 1.755 4.209 3.762 8.443 6.145 12.629 2.862 5.024 8.549 15.244 18.152 26.97 14.224 17.37 28.308 28.46 37.502 35.55.918.708 1.793 1.368 2.616 1.99 12.258 9.297 23.936 16.846 35.064 23.586 43.197 26.347 82.694 43.16 96.409 49.278 8.805 3.928 30.195 13.569 60.139 23.672 11.152 3.763 21.658 6.903 31.287 9.633a334.27 334.27 0 0 0 24.062 5.86c11.226 2.289 20.802 3.892 29.449 4.761 4.504.453 8.272.702 11.567.83 20.597.839 32.273-1.994 38.365-3.743 8.916-2.558 15.886-6.019 20.617-8.646"
    />
    <path
      className="st8"
      d="M295.414 453.103c-1.392 4.445-3.117 10.257-4.47 17.2-.416 2.13-.809 4.577-1.307 7.292-.849 4.63-1.635 11.876-1.602 20.672.003.806.03 1.66.03 2.562-.008 12.104 2.123 23.206 4.057 29.767 1.655 5.617 3.272 10.897 5.207 15.742 1.824 4.568 3.853 8.942 6.121 13.083 2.666 4.865 8.403 15.632 18.335 27.961 13.551 16.823 26.929 27.533 35.882 34.588.872.687 1.71 1.334 2.506 1.948 11.901 9.223 23.071 16.516 33.64 23.006 41.88 25.903 80.02 42.671 92.557 48.38 10.056 4.577 32.043 14.808 62.743 25.499 11.876 4.135 22.848 7.525 32.692 10.387 9.206 2.676 17.798 4.786 25.155 6.352 12.18 2.593 22.14 4.316 30.811 5.24 4.769.51 8.704.778 12.111.918 21.957.941 34.265-2.217 40.18-3.97 9.228-2.732 16.415-6.42 21.284-9.222"
    />
    <path
      className="st8"
      d="M293.807 452.52c-1.572 4.48-3.539 10.42-5.174 17.567-.492 2.151-.996 4.655-1.59 7.463-1.055 4.985-2.086 12.338-2.312 21.235-.022.84-.024 1.72-.046 2.64-.318 13.293 1.794 24.841 3.39 30.731 1.604 5.913 3.176 11.366 5.06 16.283a126.312 126.312 0 0 0 6.098 13.538c2.47 4.706 8.265 16.012 18.517 28.953 12.888 16.268 25.552 26.603 34.263 33.625.827.667 1.628 1.3 2.394 1.905 11.546 9.149 22.207 16.186 32.218 22.428 40.561 25.457 77.34 42.193 88.705 47.48 11.293 5.251 33.886 16.057 65.347 27.327a648.542 648.542 0 0 0 34.096 11.141c10.206 3.033 19.143 5.27 26.249 6.845 13.128 2.91 23.478 4.741 32.172 5.72 5.034.565 9.135.852 12.656 1.004 23.315 1.044 36.255-2.443 41.996-4.196 9.537-2.912 16.942-6.822 21.948-9.799"
    />
    <path
      className="st8"
      d="M292.2 451.938c-1.752 4.516-3.956 10.585-5.877 17.934-.568 2.172-1.185 4.73-1.874 7.633-1.266 5.337-2.543 12.8-3.023 21.798-.047.874-.079 1.78-.121 2.717-.656 14.48 1.447 26.481 2.725 31.696 1.523 6.216 3.075 11.836 4.91 16.823a135.55 135.55 0 0 0 6.075 13.995c2.278 4.544 8.138 16.384 18.7 29.943 12.235 15.706 24.177 25.671 32.643 32.663.782.646 1.545 1.265 2.284 1.862 11.19 9.075 21.342 15.857 30.794 21.849 39.244 25.012 74.654 41.723 84.854 46.58 12.514 5.96 35.725 17.315 67.95 29.156a646.458 646.458 0 0 0 35.501 11.895c11.204 3.397 20.486 5.758 27.342 7.337 14.074 3.243 24.816 5.17 33.534 6.198 5.299.626 9.567.928 13.2 1.093 24.675 1.146 38.245-2.672 43.812-4.423 9.844-3.097 17.47-7.224 22.613-10.375"
    />
    <path
      className="st8"
      d="M290.593 451.356c-1.932 4.55-4.37 10.752-6.58 18.3a268.388 268.388 0 0 0-2.158 7.803c-1.481 5.69-3.006 13.26-3.733 22.362-.073.908-.134 1.84-.197 2.795-1.02 15.66 1.084 28.121 2.06 32.66 1.402 6.525 2.971 12.306 4.761 17.364 2.002 5.657 4.148 10.435 6.051 14.45 2.097 4.379 8.023 16.748 18.883 30.934 11.59 15.139 22.814 24.725 31.023 31.7.738.625 1.463 1.231 2.173 1.82 10.834 9.001 20.479 15.528 29.372 21.27 37.925 24.568 71.963 41.258 81.001 45.681 13.713 6.712 37.546 18.623 70.555 30.983a630.095 630.095 0 0 0 36.905 12.65c12.2 3.766 21.828 6.25 28.436 7.83 15.014 3.592 26.15 5.61 34.895 6.677 5.562.693 9.999 1.003 13.745 1.18 26.033 1.248 40.234-2.902 45.627-4.65 10.15-3.288 17.999-7.625 23.278-10.951"
    />
    <path
      className="st8"
      d="M288.986 450.773a168.592 168.592 0 0 0-7.284 18.668c-2.256 6.981-5.871 18.399-7.156 33.77-1.407 16.835.704 29.759 1.392 33.625 2.649 14.878 7.331 25.46 10.642 32.811 1.9 4.22 7.92 17.101 19.065 31.924 10.954 14.567 21.435 23.799 29.403 30.739 11.348 9.884 21.134 16.618 30.012 22.467 36.607 24.123 69.267 40.795 77.149 44.783 14.878 7.526 39.397 19.846 73.159 32.81 32.916 12.64 56.313 18.865 67.838 21.726 26.552 6.593 40.882 7.948 50.547 8.425 27.391 1.35 42.22-3.136 47.442-4.878a105.746 105.746 0 0 0 23.943-11.528"
    />
    <path
      className="st8"
      d="M366.954 586.997c9.887 6.243 16.974 11.528 34.432 21.765 13.493 7.91 22.8 12.302 37.079 18.788 4.523 2.055 26.881 12.163 53.182 22.683 17.372 6.949 35.909 14.363 61.852 20.95 16.2 4.112 27.905 6.028 43.896 8.646 14.32 2.344 23.16 3.322 33.586 3.325 12.622.004 20.165-1.422 22.946-1.995a97.926 97.926 0 0 0 21.948-7.316"
    />
    <path
      className="st8"
      d="M367.987 587.87c4.783 3.09 9.145 6.093 14.39 9.553 5.06 3.316 11.709 7.593 21.474 13.337 1.596.939 3.154 1.84 4.683 2.712 10.8 6.138 20.341 10.785 33.977 17.062 4.981 2.296 25.946 11.784 50.356 21.583 17.1 6.91 34.903 13.746 58.53 20.065 14.756 3.946 26.076 6.373 37.752 8.465 2.669.478 5.43 1 8.343 1.52 7.466 1.328 13.74 2.321 19.773 3.01 5.33.608 10.287.927 15.607 1.024 4.552.071 8.56-.021 12.04-.223 6.126-.355 10.128-1.027 12.194-1.394a85.212 85.212 0 0 0 4.496-.94 96.027 96.027 0 0 0 18.75-6.475"
    />
    <path
      className="st8"
      d="M369.021 588.744c4.791 3.165 9.386 6.394 14.937 10.067 4.807 3.18 11.856 7.753 22.357 13.948 1.647.97 3.27 1.913 4.88 2.834 10.396 5.92 20.733 11.115 35.362 17.925 5.439 2.537 25.032 11.356 47.53 20.484 16.843 6.834 33.874 13.225 55.208 19.18 16.11 4.496 28.418 7.46 39.546 9.59 2.79.533 5.68 1.145 8.75 1.732 7.533 1.442 14.147 2.583 20.765 3.428 5.563.711 10.807 1.136 16.407 1.316 4.68.15 8.895.127 12.67-.021 6.337-.25 10.525-.858 12.853-1.218a79.891 79.891 0 0 0 4.742-.904 95.327 95.327 0 0 0 15.53-4.769 98.086 98.086 0 0 0 4.273-1.842"
    />
    <path
      className="st8"
      d="M370.055 589.617c4.8 3.241 9.633 6.688 15.482 10.582 4.562 3.037 12.006 7.913 23.242 14.558a465.047 465.047 0 0 0 5.077 2.956c9.992 5.703 21.126 11.445 36.747 18.789 5.897 2.778 24.104 10.961 44.703 19.385 16.578 6.779 32.828 12.778 51.886 18.294 17.467 5.055 30.758 8.565 41.341 10.715 2.91.592 5.93 1.288 9.155 1.946 7.603 1.55 14.556 2.84 21.759 3.846 5.796.81 11.327 1.335 17.206 1.607 4.81.222 9.232.273 13.299.18 6.55-.15 10.924-.69 13.513-1.04 1.804-.245 3.442-.559 4.988-.87a95.408 95.408 0 0 0 20.856-6.746"
    />
    <path
      className="st8"
      d="M371.089 590.49c4.808 3.317 9.88 6.98 16.028 11.097 4.317 2.891 12.155 8.074 24.126 15.169 1.75 1.035 3.505 2.06 5.274 3.078 9.587 5.485 21.518 11.775 38.132 19.652 6.354 3.02 23.174 10.572 41.877 18.286 16.311 6.726 31.767 12.387 48.564 17.408 18.822 5.627 33.095 9.682 43.135 11.841 3.03.652 6.181 1.43 9.56 2.159 7.674 1.656 14.965 3.093 22.753 4.263 6.03.906 11.847 1.533 18.006 1.899 4.938.293 9.568.416 13.927.381 6.762-.054 11.323-.524 14.174-.863 1.917-.228 3.629-.533 5.234-.834 7.532-1.412 13.528-3.463 17.182-4.897a99.444 99.444 0 0 0 4.726-1.984"
    />
    <path
      className="st8"
      d="M372.123 591.364c4.817 3.392 10.13 7.27 16.573 11.611 4.074 2.744 12.305 8.234 25.01 15.78 1.801 1.067 3.623 2.133 5.472 3.2 9.183 5.267 21.91 12.105 39.517 20.515 6.812 3.26 22.241 10.19 39.051 17.186 16.044 6.678 30.693 12.042 45.242 16.524 20.173 6.213 35.43 10.81 44.93 12.966 3.15.715 6.431 1.57 9.966 2.372 7.743 1.757 15.373 3.344 23.745 4.68 6.264 1 12.367 1.73 18.805 2.19 5.067.363 9.905.559 14.557.584 6.973.038 11.721-.36 14.834-.687 2.03-.213 3.816-.507 5.48-.799 8.066-1.414 14.396-3.565 18.008-4.96 1.95-.753 3.63-1.46 4.953-2.056"
    />
    <path
      className="st8"
      d="M373.156 592.238c4.827 3.467 10.38 7.557 17.12 12.125 3.83 2.596 12.454 8.395 25.895 16.39a565 565 0 0 0 5.668 3.323c8.78 5.05 22.302 12.434 40.902 21.378 7.27 3.502 21.306 9.814 36.225 16.087 15.774 6.633 29.608 11.733 41.92 15.638 21.519 6.826 37.762 11.95 46.724 14.092 3.269.78 6.683 1.708 10.371 2.584 7.815 1.857 15.784 3.593 24.74 5.098 6.497 1.093 12.886 1.926 19.604 2.483 5.196.43 10.24.697 15.185.784 7.186.127 12.12-.197 15.494-.51 2.142-.199 4.004-.48 5.726-.763 8.603-1.414 15.267-3.666 18.834-5.024a100.93 100.93 0 0 0 5.18-2.127"
    />
    <path
      className="st8"
      d="M374.19 593.111c4.835 3.543 10.632 7.844 17.666 12.64 3.588 2.447 12.603 8.555 26.78 17a595.156 595.156 0 0 0 5.864 3.446c8.375 4.831 22.694 12.764 42.287 22.24 7.727 3.744 20.368 9.446 33.399 14.989 15.502 6.594 28.515 11.453 38.597 14.752 22.852 7.48 40.094 13.1 48.519 15.218 3.387.85 6.934 1.845 10.777 2.797 7.886 1.954 16.193 3.839 25.732 5.515a259.09 259.09 0 0 0 20.404 2.774c5.327.488 10.577.835 15.815.987 7.397.214 12.517-.036 16.154-.334a82.021 82.021 0 0 0 5.971-.728c9.139-1.41 16.137-3.766 19.661-5.088a101.432 101.432 0 0 0 5.406-2.198"
    />
    <path
      className="st8"
      d="M375.224 593.984c4.844 3.619 10.885 8.128 18.211 13.155 3.347 2.297 12.753 8.715 27.664 17.611a626.99 626.99 0 0 0 6.062 3.568c7.971 4.613 23.086 13.093 43.672 23.104 8.185 3.984 19.427 9.083 30.573 13.888 15.226 6.566 27.413 11.197 35.274 13.868 24.161 8.206 42.422 14.256 50.314 16.342 3.503.927 7.186 1.982 11.183 3.01 7.959 2.05 16.607 4.066 26.725 5.934 6.97 1.26 13.93 2.302 21.204 3.065 5.455.557 10.913.97 16.443 1.188 7.61.3 12.916.126 16.814-.157a84.698 84.698 0 0 0 6.218-.693c9.675-1.404 17.007-3.864 20.487-5.15a101.714 101.714 0 0 0 5.632-2.27"
    />
    <path
      className="st8"
      d="M376.258 594.858c4.852 3.694 11.149 8.395 18.757 13.67 3.109 2.14 12.901 8.875 28.548 18.22a655.917 655.917 0 0 0 6.259 3.69c7.567 4.396 23.478 13.424 45.057 23.968 8.643 4.225 18.482 8.728 27.746 12.79 14.945 6.55 26.304 10.96 31.953 12.981 25.412 9.09 44.747 15.42 52.108 17.468 3.618 1.007 7.44 2.112 11.589 3.224 8.034 2.13 17.016 4.316 27.718 6.35 7.204 1.356 14.45 2.498 22.003 3.357 5.585.627 11.251 1.096 17.073 1.39 7.823.371 13.313.28 17.474.02a88.236 88.236 0 0 0 6.463-.658c10.213-1.384 17.88-3.958 21.313-5.215a99.013 99.013 0 0 0 5.86-2.341"
    />
    <path
      className="st8"
      d="M377.292 595.731c4.861 3.77 11.399 8.684 19.302 14.185 3.225 2.244 15.722 10.881 35.889 22.644 7.163 4.177 23.87 13.752 46.442 24.83 27.148 13.323 48.364 21.745 53.55 23.786 26.487 10.423 47.07 16.59 53.904 18.594 10.757 3.154 23.428 6.835 40.705 10.204 12.864 2.509 26.008 4.401 40.505 5.24 12.747.737 19.557.256 24.843-.426 10.747-1.386 18.75-4.06 22.139-5.279 2.52-.906 4.6-1.766 6.086-2.412"
    />
    <path
      className="st9"
      d="M153.09 237.313a211.64 211.64 0 0 1 15.54-17.504c4.65-4.69 12.112-11.774 26.074-22.441 11.042-8.436 10.771-7.364 30.189-21.086 18.287-12.922 32.524-23.764 42.073-31.215 4.485-3.5 9.622-7.552 12.242-9.653"
    />
    <path
      className="st9"
      d="M156.551 231.927c3.634-4.57 8.962-10.873 15.507-17.437.763-.764 1.585-1.579 2.487-2.435 5.264-4.999 12.44-11.306 23.61-19.8 9.827-7.453 11.16-7.73 25.039-17.4 1.54-1.072 3.225-2.251 5.08-3.558 17.481-12.331 31.523-22.934 41.98-31.054 4.41-3.433 9.43-7.385 12.216-9.608"
    />
    <path
      className="st9"
      d="M160.012 226.542c3.592-4.504 9.07-10.983 15.475-17.371.777-.772 1.599-1.583 2.49-2.412 5.793-5.386 13.002-11.577 23.63-19.617 9.676-7.284 11.6-7.977 24.984-17.292a1152.853 1152.853 0 0 0 46.951-34.431 1577.34 1577.34 0 0 0 12.19-9.563"
    />
    <path
      className="st9"
      d="M163.474 221.156c3.549-4.438 9.177-11.093 15.442-17.304.79-.78 1.614-1.586 2.494-2.39 6.325-5.768 13.563-11.847 23.65-19.432 9.525-7.115 12.038-8.225 24.928-17.185 1.555-1.08 3.228-2.245 5.047-3.52 15.871-11.147 29.523-21.273 41.795-30.73 4.259-3.298 9.049-7.052 12.165-9.518"
    />
    <path
      className="st9"
      d="M166.935 215.77c3.507-4.372 9.285-11.202 15.41-17.237a77.675 77.675 0 0 1 2.498-2.367c6.861-6.147 14.11-12.137 23.668-19.249 9.36-6.965 12.479-8.473 24.875-17.078 1.56-1.082 3.23-2.241 5.03-3.499 15.065-10.556 28.523-20.444 41.703-30.569 4.182-3.231 8.857-6.885 12.138-9.473"
    />
    <path
      className="st9"
      d="M170.397 210.384c3.463-4.306 9.392-11.312 15.376-17.17a69.86 69.86 0 0 1 2.503-2.344c7.403-6.52 14.67-12.41 23.687-19.066 9.209-6.797 12.919-8.72 24.82-16.97 1.567-1.085 3.23-2.238 5.014-3.48 14.26-9.964 27.522-19.613 41.61-30.406 4.107-3.165 8.666-6.72 12.112-9.43"
    />
    <path
      className="st9"
      d="M173.858 204.999c3.421-4.24 9.5-11.422 15.344-17.104a63.752 63.752 0 0 1 2.507-2.32c7.949-6.888 15.23-12.68 23.705-18.883 9.058-6.63 13.36-8.968 24.766-16.864 1.574-1.088 3.232-2.234 4.998-3.46 13.454-9.372 26.522-18.783 41.517-30.244a1635.99 1635.99 0 0 0 12.086-9.384"
    />
    <path
      className="st9"
      d="M177.32 199.613c3.378-4.174 9.607-11.532 15.31-17.037a58.667 58.667 0 0 1 2.511-2.298c8.501-7.249 15.794-12.949 23.725-18.7 8.909-6.458 13.8-9.215 24.711-16.755 1.581-1.091 3.234-2.23 4.982-3.44 12.648-8.781 25.521-17.953 41.424-30.083 3.955-3.03 8.284-6.386 12.06-9.34"
    />
    <path
      className="st9"
      d="M180.781 194.227c3.336-4.108 9.715-11.641 15.279-16.97.86-.82 1.7-1.59 2.514-2.275 9.058-7.603 16.367-13.202 23.744-18.516 8.773-6.269 14.24-9.463 24.657-16.649 1.587-1.094 3.235-2.227 4.964-3.42 11.843-8.189 24.521-17.123 41.332-29.921 3.88-2.964 8.093-6.22 12.034-9.294"
    />
    <path
      className="st9"
      d="M184.243 188.842c3.293-4.043 9.822-11.752 15.245-16.903a50.559 50.559 0 0 1 2.519-2.253c9.621-7.95 16.928-13.473 23.763-18.332 8.622-6.1 14.68-9.711 24.602-16.542 1.594-1.097 3.236-2.224 4.948-3.4 11.038-7.598 23.52-16.294 41.24-29.76a1424.39 1424.39 0 0 0 12.007-9.25"
    />
    <path
      className="st9"
      d="M187.704 183.456c3.797-4.645 12.273-14.622 17.736-19.066 23.91-19.447 31.899-23.297 48.329-34.584 13.204-9.07 28.922-19.543 58.06-42.183"
    />
    <path
      className="st8"
      d="M461.67 408.79c-3.602 6.569-12.7 21.59-15.249 25.72-5.385 8.729-6.406 10.666-8.994 14.356-1.526 2.175-3.302 4.614-4.408 5.763M389.833 309.049c6.394 3.903 28.507 17.868 32.514 20.605 8.47 5.784 10.328 7.23 13.923 9.65 2.65 1.786 4.827 3.229 6.193 4.327M199.712 398.086c3.037 2.45 11.06 7.236 19.608 12.592 3.51 2.199 8.7 5.308 14.244 9.134"
    />
    <path
      transform="rotate(34.317 319.414 127.829)"
      style={{
        fill: "none",
        stroke: "#fcbdab",
        strokeWidth: 2,
        strokeMiterlimit: 10,
      }}
      d="M290.483 98.898h57.862v57.862h-57.862z"
    />
  </svg>
);

export default TwistComponentPrimary;
