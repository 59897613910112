import { useQuery } from "@tanstack/react-query";
import request from "../../../Api/Api.js"; // Adjust path as necessary

function getToken() {
  if (localStorage.getItem("user") != null) {
    return JSON.parse(localStorage.getItem("user")).accessToken;
  } else {
    return "";
  }
}

export function useSavedQueries() {
  return useQuery({
    queryKey: ["getSavedQueries"],
    queryFn: async () => {
      const { data } = await request.get("/savedQueries", {
        headers: { Authorization: `Bearer ${getToken()}` },
      });
      return data;
    },
  });
}
