const sciToZero = (num, len = 0) => {
  // num is the number in scientific notation
  // len is the desired length of the string
  var str = String(num); // convert to string
  if (str.includes("e")) {
    // check if scientific notation
    var [coef, exp] = str.split("e"); // split coefficient and exponent
    exp = Number(exp); // convert exponent to number
    if (exp < 0) {
      // negative exponent
      var zeros = -exp - 1; // number of zeros to add
      str = "0." + "0".repeat(zeros) + coef.replace(".", ""); // add zeros and remove decimal point
    } else {
      // positive exponent
      var digits = coef.replace(".", "").length; // number of digits in coefficient
      // eslint-disable-next-line no-redeclare
      var zeros = exp - digits + 1; // number of zeros to add
      str = coef.replace(".", "") + "0".repeat(zeros); // add zeros and remove decimal point
    }
  }
  return str.padStart(len, "0"); // pad with leading zeros if needed
};

const smallNumber = (value) => {
  var str = sciToZero(value);

  // Find the index of the decimal point
  let point = str.indexOf(".");
  // Initialize a counter variable
  let count = 0;
  // Loop through the string from the index of the decimal point + 1
  for (let i = point + 2; i < str.length; i++) {
    // If the current character is a zero, increment the counter
    if (str[i] == "0") {
      count++;
    }
    // Else, break the loop
    else {
      break;
    }
  }
  // Return the counter as the result

  return count;
};
const removeZeros = (value) => {
  let str = (value ?? 0).toString();
  // Find the index of the decimal point
  let point = str.indexOf(".");
  if (point === -1) {
    return value;
  }
  // Otherwise, remove the leading zeros after the decimal point
  // and parse the remaining string as a number
  else {
    let result = str.slice(point + 1).replace(/^0+/, "");
    let final = result.slice(0, 4);
    return Number(final);
  }
};

export { sciToZero, smallNumber, removeZeros };
