import { useQuery } from "@tanstack/react-query";
import request from "../../Api/Api.js";

export default function UseNotificationQuery() {
  function getToken() {
    if (localStorage.getItem("user") != null) {
      return JSON.parse(localStorage.getItem("user")).accessToken;
    } else {
      return "";
    }
  }
  const { isLoading, isError, isFetching, data, refetch } = useQuery({
    queryKey: ["allNotifications"],
    queryFn: async () => {
      const data = await request
        .get("/notification", {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        })
        .catch(function (error) {
          return error.response;
        });
      return data;
    },
    staleTime: 1000,
  });

  return { isLoading, isError, isFetching, data, refetch };
}
