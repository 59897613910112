import * as React from "react";
import { cn } from "../../utils/utils";

const CardLoading = ({ height = "h-6", width = "w-full", className = "" }) => {
  // Use cn to concatenate classes
  const classes = cn(
    `${width} ${height} bg-gray-300 rounded animate-pulse`,
    className
  );

  return <div className={classes}></div>;
};

export default CardLoading;
