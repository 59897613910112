import { useMutation } from "@tanstack/react-query";
import request from "../../../Api/Api.js"; // Adjust path as necessary
import { useToast } from "../../Ui/use-toast";

function getToken() {
  if (localStorage.getItem("user") != null) {
    return JSON.parse(localStorage.getItem("user")).accessToken;
  } else {
    return "";
  }
}

export function useCreateSavedQuery() {
  const { toast } = useToast();

  return useMutation({
    mutationFn: (newQuery) =>
      request.post("/savedQueries", newQuery, {
        headers: { Authorization: `Bearer ${getToken()}` },
      }),
    onSuccess: () => {
      toast({
        variant: "success",
        title: "Success!",
        description: "Query created successfully!",
      });
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: `Failed to create query: ${
          error?.message || "An error occurred"
        }`,
        variant: "destructive",
      });
    },
  });
}
