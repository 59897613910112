import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { HexColorPicker } from "react-colorful";

export default function VisualizationConfigModal({
  isOpen,
  onClose,
  onSubmit,
  allKeys,
}) {
  const cancelButtonRef = useRef(null);

  // New states for chart configuration
  const [chartType, setChartType] = useState("line");
  const [xAxisVariable, setXAxisVariable] = useState("");
  const [title, setTitle] = useState(""); // State for chart title

  useEffect(() => {
    if (allKeys.length > 0 && xAxisVariable === "") {
      setXAxisVariable(allKeys[0]);
    }
  }, [isOpen, allKeys]);
  // pass this into the dialog sectiopn and then into the
  const style = [
    {
      name: "Line",
      data: "line",
    },
    {
      name: "Bar",
      data: "column",
    },
    {
      name: "Spline",
      data: "spline",
    },
    {
      name: "Area",
      data: "area",
    },
    {
      name: "Scatter",
      data: "scatter",
    },
    {
      name: "Pie",
      data: "pie",
    },
  ];

  // Updated states for multiple Y-axis configurations
  const [yAxes, setYAxes] = useState([{ variable: "", color: "#000000" }]); // Default to one axis

  // Function to handle adding a new Y-axis configuration
  const addYAxis = () => {
    setYAxes([...yAxes, { variable: "", color: "#000000" }]); // Default color for new axis
  };

  // Function to handle removing a Y-axis configuration
  const removeYAxis = (index) => {
    const newYAxes = [...yAxes];
    newYAxes.splice(index, 1);
    setYAxes(newYAxes);
  };

  // Function to update a specific Y-axis configuration
  const updateYAxis = (index, key, value) => {
    const newYAxes = [...yAxes];
    newYAxes[index][key] = value;
    setYAxes(newYAxes);
  };

  // Pass the selected configuration back to the parent component
  const handleSubmit = () => {
    onSubmit({
      chartType,
      xAxisVariable,
      yAxes,
      title, // Include the title in the onSubmit payload
    });
    onClose();
  };
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                      {/* Close Button */}
                      <div className="flex justify-end">
                        <button
                          type="button"
                          className="text-gray-400 hover:text-gray-500"
                          onClick={onClose} // Use onClose passed as a prop
                          ref={cancelButtonRef}
                        >
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>

                      {/* Form fields for chart configuration */}

                      <div className="mt-2">
                        <div className="mb-4">
                          <label
                            htmlFor="chartTitle"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Chart Title
                          </label>
                          <input
                            type="text"
                            id="chartTitle"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                            placeholder="Enter chart title"
                          />
                        </div>
                        <div className="mb-4">
                          <label
                            htmlFor="chartType"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Chart Type
                          </label>
                          <select
                            id="chartType"
                            value={chartType}
                            onChange={(e) => setChartType(e.target.value)}
                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                          >
                            {/* Dynamically generate options from the style array */}
                            {style.map((option) => (
                              <option key={option.data} value={option.data}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="mb-4">
                          <label
                            htmlFor="xAxisVariable"
                            className="block text-sm font-medium text-gray-700"
                          >
                            X-Axis Variable
                          </label>
                          <select
                            id="xAxisVariable"
                            value={xAxisVariable}
                            onChange={(e) => {
                              setXAxisVariable(e.target.value);
                            }}
                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                          >
                            {/* Dynamically generate options from allKeys */}
                            {allKeys.map((key) => (
                              <option key={key} value={key}>
                                {key}
                              </option>
                            ))}
                          </select>
                        </div>

                        {yAxes.map((yAxisConfig, index) => (
                          <div key={index} className="flex items-center mb-4">
                            <div className="flex-grow">
                              <label
                                htmlFor={`yAxisVariable-${index}`}
                                className="block text-sm font-medium text-gray-700"
                              >
                                Y-Axis Variable
                              </label>
                              <select
                                id={`yAxisVariable-${index}`}
                                value={yAxisConfig.variable}
                                onChange={(e) =>
                                  updateYAxis(index, "variable", e.target.value)
                                }
                                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                              >
                                {allKeys.map((key) => (
                                  <option key={key} value={key}>
                                    {key}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="ml-4">
                              <div className="w-24 h-24 relative">
                                {" "}
                                {/* Tailwind classes for width, height, and positioning */}
                                <HexColorPicker
                                  color={yAxisConfig.color}
                                  onChange={(color) =>
                                    updateYAxis(index, "color", color)
                                  }
                                  style={{ width: "100%", height: "100%" }} // Ensure the canvas fills the div
                                />
                              </div>
                            </div>
                            <button
                              type="button"
                              className="ml-4 text-gray-400 hover:text-gray-500"
                              onClick={() => removeYAxis(index)}
                            >
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg-gray-50 px-4 py-3 sm:flex sm:justify-between sm:items-center sm:px-6">
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-primaryDark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:w-auto sm:text-sm"
                    onClick={addYAxis}
                  >
                    Add another Y variable
                  </button>

                  <div className="sm:flex sm:flex-row-reverse sm:space-x-0 sm:space-x-reverse">
                    <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-primaryDark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={handleSubmit}
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={onClose} // Use onClose passed as a prop
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
