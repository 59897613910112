// created by james launder
// sign in page which contained ability to sign in via twitter and github to reenable this go back to past commit
// mostly a simple form just sends data to db and verify

/* eslint-disable jsx-a11y/label-has-associated-control */
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import request from "../../Api/Api.js";
import { storeToken } from "../../Services/authService";
import VerifiedNotification from "./VerifiedNotification";
import EmailModal from "./EmailModal";
import { GoogleLogin } from "@react-oauth/google";
import ErrorNotification from "./ErrorNotification.js";
import Background from "../Background.js";
import logo from "../../Assets/WevrLogoPrimary.svg";
const schema = yup
  .object({
    username: yup.string().required(),
    password: yup.string().required("Password is required"),
  })
  .required();

function SignIn() {
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [show, setShow] = useState(true);
  const [open, setOpen] = useState(false);
  const [ErrorNotif, setErrorNotif] = useState(false);
  let { confirmationCode } = useParams();
  let location = useLocation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  useEffect(() => {
    if (JSON.parse(localStorage.getItem("user"))) {
      navigate("/");
    }
  }, []);
  const mutation = useMutation({
    mutationFn: async ({ username, password }) => {
      setLoading(true);
      return await request.post("/signin", {
        username: username,
        password: password,
      });
    },

    onSuccess: (res) => {
      setLoading(false);
      storeToken(res.data);
      window.location.reload();
    },
    onError: (error) => {
      setLoading(false);
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      setMessage(resMessage);
    },
  });
  const onSubmit = (data) => {
    mutation.mutate({ username: data.username, password: data.password });
  };

  const googleMutation = useMutation({
    mutationFn: async ({ credential, clientId }) => {
      setLoading(true);
      return await request.post("/google", {
        credential: credential,
        clientId: clientId,
      });
    },

    onSuccess: (res) => {
      setLoading(false);
      storeToken(res.data);
      window.location.reload();
    },
    onError: (error) => {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      setLoading(false);
      setMessage(resMessage);
      setErrorNotif(true);
    },
  });
  const onGoogleSubmit = (data) => {
    googleMutation.mutate({
      credential: data.credential,
      clientId: data.clientId,
    });
  };
  useEffect(() => {
    if (location.state?.pathname) {
      setOpen(true);
    }
  }, []);

  return (
    <div className="relative overflow-hidden">
      <div className="relative z-20">
        {confirmationCode ? (
          <VerifiedNotification show={show} setShow={setShow} />
        ) : (
          <div></div>
        )}
        {ErrorNotif ? (
          <ErrorNotification
            setErrorNotif={setErrorNotif}
            ErrorNotif={ErrorNotif}
            message={message}
          />
        ) : (
          <div></div>
        )}
      </div>
      <div>
        <div className="min-h-[75vh] grid place-items-center relative z-0">
          <div className="flex flex-col grow gap-4 max-w-6xl">
            <Background />

            <div className="pt-4 px-4 font-roboto flex flex-col">
              <img
                src={logo}
                alt="wevr.ai"
                className="h-12 md:h-16 self-center pb-2"
              />
              {/* <div className=" font-semibold text-center text-2xl sm:text-4xl ml-2 pb-2 text-gray-900">
                wevr.ai
              </div> */}
              <div className="text-center text-gray-600 text-lg sm:text-2xl">
                Login to explore
              </div>
            </div>
            <div className=" bg-surface border border-primaryContainer shadow-md rounded-2xl  p-4 w-full">
              <form className="pt-4 " onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-col">
                  <label className="block text-md font-roboto font-light">
                    Username:
                  </label>
                  <input
                    type="text"
                    name="username"
                    placeholder="Username"
                    className={`block border border-onSurfaceVariant w-full p-3 rounded-lg mb-4 form-control focus:border-primary focus:outline-none ${
                      errors.username ? "is-invalid" : ""
                    }`}
                    {...register("username")}
                  />
                  <div className="font-semibold text-red-500 invalid-feedback -mt-2">
                    {errors.username?.message}
                  </div>
                </div>
                <div className="flex flex-col">
                  <label className="block text-md font-roboto font-light">
                    Password:{" "}
                  </label>
                  <input
                    name="password"
                    type="password"
                    placeholder="Password"
                    className={`block border border-onSurfaceVariant w-full p-3 rounded-lg mb-4 form-control focus:border-primary focus:outline-none ${
                      errors.password ? "is-invalid" : ""
                    }`}
                    {...register("password")}
                  />
                  <div className="font-semibold text-red-500 invalid-feedback -mt-2">
                    {errors.password?.message}
                  </div>
                </div>

                {message ? (
                  <div className="form-group pt-2">
                    <div
                      className="alert alert-danger font-semibold text-red-500"
                      role="alert"
                    >
                      {message}
                    </div>
                  </div>
                ) : (
                  <div className="form-group pt-2"></div>
                )}
                <div className="flex justify-center py-4">
                  {isLoading ? (
                    <div>
                      <svg
                        aria-hidden="true"
                        className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-primary"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <button
                      type="submit"
                      className="px-6 py-2 w-full font-roboto font-light rounded-lg bg-surfaceVariant border border-primaryContainer shadow-md hover:text-white hover:bg-primary"
                      disabled={isLoading}
                    >
                      Sign in
                    </button>
                  )}
                </div>
                <div className="flex flex-row gap-4 font-roboto mb-2 text-gray-900 font-semibold">
                  <Link to="/ForgotPassword" className="hover:underline ">
                    Forgot your password?
                  </Link>

                  <Link to="/SignUp" className="flex group">
                    <div className="hidden sm:block">
                      {/* eslint-disable-next-line react/no-unescaped-entities */}
                      Don't have an account?&nbsp;{" "}
                    </div>
                    <div className="text-primary group-hover:underline">
                      Sign up for free
                    </div>
                  </Link>
                </div>
              </form>
              <div className="border-t-2 border-secondary flex flex-col sm:flex-row sm:justify-between">
                <div className="py-2 sm:py-4">
                  <GoogleLogin
                    // type={"icon"}
                    size={"large"}
                    text={"signin"}
                    shape={"rectangular"}
                    onSuccess={(credentialResponse) => {
                      onGoogleSubmit(credentialResponse);
                    }}
                    onError={() => {
                      console.error("Login Failed");
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EmailModal open={open} setOpen={setOpen} />
    </div>
  );
}

export default SignIn;
