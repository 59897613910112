// created by james launder
// simple faw page pretty sure it was taken from tailwind ui cant remember tho
import { useNavigate } from "react-router";
import Background from "../Background";

const faqs = [
  {
    question: "What payment options do you offer?",
    answer:
      "We offer many payment options as Stripe processes our payment for credit and debit cards, the available options can be viewed at checkout. We also offer crypto payment which is processed via Coinbase.",
  },
  {
    question: "Do you provide a free trial?",
    answer:
      "We do not provide a free trial. However, we do provide a free base tier that can be used to check out the features we offer!",
  },
  {
    question: "Can I cancel my plan at any time?",
    answer:
      "If paid via Stripe (credit card/debit card), you will not be charged at the next billing cycle and maintain access till the end of your current subscription. If paid via crypto, this is not a subscription but a once off payment and your access will end at the current subscription period.",
  },
  {
    question: "Can I upgrade my plan at any time?",
    answer:
      "If paid via Stripe (credit card/debit card), you can upgrade at any time via the manage button in user settings and the cost will be calculated based off the remaining time of your current subscription. If paid via crypto, you cannot upgrade until near the end of your current subscription period unless you contact us.",
  },

  // More questions...
];
function Faq() {
  const navigate = useNavigate();
  return (
    <div className="relative z-0 bg-surface overflow-hidden">
      <div>
        <div className="relative z-10 top-[190px] left-[350px] mx-auto max-w-6xl md:block hidden">
          <div className="relative">
            <Background />
          </div>
        </div>
      </div>

      <div className="relative z-20 mx-auto max-w-7xl h-full px-6 py-12 sm:pt-32 lg:py-20 lg:px-8">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="lg:col-span-5 bg-white border border-primaryContainer rounded-lg p-4 h-fit shadow-md">
            <h1 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
              Frequently asked questions
            </h1>
            <p className="mt-4 text-base leading-7 text-gray-600">
              Can’t find the answer you’re looking for? Reach out to our{" "}
              <button
                onClick={() => navigate("/Contact")}
                className="font-semibold text-indigo-600 hover:text-indigo-500"
              >
                customer support
              </button>{" "}
              team. Or check out our{" "}
              <a
                href="https://docs.wevr.ai"
                target="_blank"
                rel="noopener noreferrer"
                className="font-semibold text-indigo-600 hover:text-indigo-500"
              >
                documentation
              </a>
            </p>
          </div>
          <div className="mt-10 lg:col-span-7 lg:mt-0 bg-white border border-primaryContainer shadow-md rounded-lg p-4 h-fit">
            <dl className="space-y-10">
              {faqs.map((faq) => (
                <div key={faq.question}>
                  <dt className="text-base font-semibold leading-7 text-gray-900">
                    {faq.question}
                  </dt>
                  <dd className="mt-2 text-base leading-7 text-gray-600">
                    {faq.answer}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Faq;
