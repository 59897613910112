// created by james launder
// small header that is shown when a used is below a certain tier
import { ArrowTrendingUpIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
function UpgradeHeader() {
  const navigate = useNavigate();
  return (
    <div className="w-full grid content-center font-roboto mt-2">
      <div className="flex flex-row justify-between place-items-center mx-8 px-2 pt-2 pb-2 bg-primary border border-primaryContainer shadow-md rounded-lg">
        <div className="flex flex-row space-x-2 text-white">
          <ArrowTrendingUpIcon className="sm:block hidden h-6 w-6" />
          <div className="sm:text-base text-sm">
            To see the latest data you must upgrade to a higher tier
          </div>
        </div>
        <div>
          <button
            className="px-3 py-2 bg-surfaceVariant hover:bg-primary hover:text-white border border-primaryContainer shadow-md font-semibold rounded-lg hover:shadow-xl text-sm"
            onClick={() => {
              navigate("/Pricing");
            }}
          >
            Upgrade
          </button>
        </div>
      </div>
    </div>
  );
}

export default UpgradeHeader;
