import React, { useState, useEffect } from "react";
import TableView from "./TableView";

const TableGrid = ({
  blockchain,
  isLoggedIn,
  tableStores,
  onGranularityChange,
  onFilterChange,
}) => {
  const [selectedTables, setSelectedTables] = useState([]);

  useEffect(() => {
    if (tableStores) {
      const specificTables = getSpecificTables(tableStores, blockchain);
      setSelectedTables(specificTables);
    }
  }, [tableStores, blockchain]);

  const getSpecificTables = (tables, blockchain) => {
    const ethTables = [
      "Top Unrealized Profit",
      "Top Gas Consumers",
      "Top OpenSea NFT Sales Volume",
      "Shia Holders",
    ];
    const btcTables = [
      "Top Inflows",
      "Top Outflows",
      "Top Bitcoin Transaction Counts by Address",
      "Top Bitcoin Block Miners",
    ];

    let desiredTables;
    if (blockchain === "ethereum") {
      desiredTables = ethTables;
    } else if (blockchain === "bitcoin") {
      desiredTables = btcTables;
    } else {
      console.warn("Unknown blockchain:", blockchain);
      return [];
    }

    const filteredTables = tables.filter((table) =>
      desiredTables.includes(table.name)
    );
    return filteredTables;
  };
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
      {selectedTables.map((table) => (
        <div key={table.name} className="flex flex-col h-[1000px]">
          <TableView
            selectedTable={table}
            blockchain={blockchain}
            onGranularityChange={onGranularityChange}
            onFilterChange={onFilterChange}
            isLoggedIn={isLoggedIn}
            isGrid={true}
          />
        </div>
      ))}
    </div>
  );
};

export default TableGrid;
