import { useRef, useState } from "react";
import useWindowDimensions from "../../Hooks/useWindowDimensions";
import { useSidebarState } from "../../utils/useSidebarState";
import {
  SidebarHeader,
  SidebarItem,
  SidebarItems,
  SidebarMain,
} from "../Ui/Sidebar";
import { useTableStores } from "./API/GetTableStores";
import { useSelectedTable } from "./UseSelectedTable";
import { useSearchParams } from "react-router-dom";
import { CubeIcon } from "@heroicons/react/24/solid";
import ImageWithFallback from "../Ui/ImageWithFallBack";
import { useBlockchainData } from "./UseBlockchainData";

export default function TableSidebar() {
  const { width } = useWindowDimensions();
  const [imgError, setImgError] = useState(false);
  const { isLoading, isError, isFetching, tablesData } = useTableStores();
  const sidebar = useSidebarState((state) => state.state);
  const setSidebar = useSidebarState((state) => state.setSidebar);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchState, setSearchState] = useState(false);
  const [filteredList, setFilteredList] = useState([]);
  const firstLoad = useRef(true);
  const {
    blockchain,
    tableStoresLoading,
    tableStoresError,
    tableStoresErrorDetails,
    blockchainLoading,
    blockchainData,
    blockchainErrorState,
    currentTableStores,
    setCurrentTableStores, // Get the setter function from the hook
  } = useBlockchainData();
  const { selectedTable, setSelectedTable } = useSelectedTable(
    currentTableStores,
    blockchain
  );
  const group = {};
  // groups each chart by its category
  const groupCategory = (item) => {
    item.forEach(({ category, icon, ...rest }) => {
      // console.log(icon);
      group[(category, icon)] = group[(category, icon)] || {
        category,
        icon,
        children: [],
      };
      group[(category, icon)].children.push(rest);
    });
    return Object.values(group);
  };

  const handleTableSelect = (table) => {
    // if (firstLoad.current) {
    //   firstLoad.current === false;
    //   return;
    // }
    searchParams.set("name", table.name);
    searchParams.set("interval", table.interval || "days");

    setSearchParams(searchParams);
    if (typeof onTableSelect === "function") {
      setSelectedTable(table);
    }
  };

  const filterBySearch = (event) => {
    const query = event.target.value;
    if (query) {
      setSearchState(true);
    } else {
      setSearchState(false);
    }
    var updatedList = tablesData?.tableStores;
    updatedList = updatedList.filter((item) => {
      return item.name.toLowerCase().startsWith(query.toLowerCase());
    });
    setFilteredList(updatedList);
  };

  const SearchItems = ({ items }) => (
    <ul className="mt-2">
      {items.map((key) => (
        <div key={key.name} className="w-full px-2">
          <li
            key={key.name}
            // className="group w-full flex items-center bg-white dark:bg-gray-600/40 border-gray-400 shadow-md rounded-md pl-11 pr-2 py-3 text-md font text-black dark:text-white hover:text-gray-900 hover:bg-gray-50"
          >
            <button
              key={key.name}
              onClick={() => {
                // params(key.name);
                setSearchState(false);
              }}
              className={`${
                searchParams.get("name") === key.name
                  ? "bg-primary text-white"
                  : "hover:bg-primary/30 bg-primaryContainer"
              } group w-full flex justify-between rounded-lg p-2`}
            >
              <span>{key.name}</span>
            </button>
          </li>
        </div>
      ))}
    </ul>
  );

  if (isError) {
    return <div>Error</div>;
  }
  // isloading
  if (isLoading) {
    return (
      <div className="w-[0px] md:w-[248px] min-h-screen transition-all font-roboto bg-surface">
        <div className="flex flex-row px-2 mx-auto py-2 animate-pulse">
          <div className="mx-auto w-full rounded-xl p-2 space-y-2 mt-2">
            <div className="flex h-14 w-full place-items-center bg-gray-300 justify-between p-2 rounded-lg font-medium text-lg border border-primaryContainer"></div>
            <div className="flex h-14 w-full place-items-center bg-gray-300 justify-between p-2 rounded-lg font-medium text-lg border border-primaryContainer"></div>
            <div className="flex h-14 w-full place-items-center bg-gray-300 justify-between p-2 rounded-lg font-medium text-lg border border-primaryContainer"></div>
            <div className="flex h-14 w-full place-items-center bg-gray-300 justify-between p-2 rounded-lg font-medium text-lg border border-primaryContainer"></div>
            <div className="flex h-14 w-full place-items-center bg-gray-300 justify-between p-2 rounded-lg font-medium text-lg border border-primaryContainer"></div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <SidebarMain sidebar={sidebar}>
      <SidebarHeader
        sidebar={sidebar}
        setSidebar={setSidebar}
        onChange={filterBySearch}
      />
      <SidebarItems>
        {sidebar ? (
          searchState ? (
            <SearchItems items={filteredList} />
          ) : (
            groupCategory(tablesData?.data?.tableStores)?.map((item) => (
              <SidebarItem
                siderbar={sidebar}
                setSidebar={setSidebar}
                item={item}
                onClick={handleTableSelect}
                width={width}
                searchParams={searchParams}
                key={item.category}
              />
            ))
          )
        ) : (
          groupCategory(tablesData?.data?.tableStores)?.map((item) => (
            <div key={item.category}>
              <button
                onClick={() => setSidebar(!sidebar)}
                className="flex w-full bg-white  p-2 rounded-lg border-2 border-primaryContainer"
              >
                <div className="grid grid-flow-col">
                  <ImageWithFallback
                    src={process.env.API + "/" + item.icon}
                    alt=""
                    fallbackComponent={CubeIcon}
                    className="object-scale-down h-8 w-8 mr-2 text-primary"
                  />
                </div>
              </button>
            </div>
          ))
        )}
      </SidebarItems>
    </SidebarMain>
  );
}
