// created by james launder
// This component is used when a user has got a forgot password link to then reset the password.

/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import request from "../../Api/Api";
import Background from "../Background";
import { useToast } from "../Ui/use-toast";
import { ArrowPathIcon } from "@heroicons/react/24/outline";

const schema = yup
  .object({
    password: yup
      .string()
      .required("Please enter your password")
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        "Password must contain at least 8 characters, one uppercase, one number and one special case character"
      ),
    confirmPwd: yup
      .string()
      .required("Please confirm your password")
      .oneOf([yup.ref("password")], "Password doesn't match"),
  })
  .required();
function ResetPass() {
  let { confirmationCode } = useParams();
  let navigate = useNavigate();
  const [successful, setSuccessful] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const { toast } = useToast();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const mutation = useMutation({
    mutationFn: async ({ code, password }) => {
      setLoading(true);

      return await request.put("/resetPassword", {
        code: code,
        newPassword: password,
      });
    },

    onSuccess: () => {
      setLoading(false);
      setSuccessful(true);
      toast({
        variant: "success",
        title: "Success!",
        description: "Successfully updated password",
      });
      navigate("/signin");
      window.location.reload();
    },
    onError: (error) => {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      setMessage(resMessage);
      setSuccessful(false);
      setLoading(false);
    },
  });
  const onSubmit = (data) => {
    mutation.mutate({
      code: confirmationCode,
      password: data.password,
    });
  };
  //   const userVerify = useQuery("user-verify", () =>
  //     request.get("/user/confirm/" + confirmationCode)
  //   );
  //   if (userVerify.isLoading) {
  //     return <div>Confirming your account</div>;
  //   }

  //   if (userVerify.isError) {
  //     return <div>{userVerify.error.response.data.message}</div>;
  //   }
  // console.log(confirmationCode);
  return (
    <div className="bg-surface w-full font-roboto relative z-0 overflow-hidden">
      <div className="min-h-[75vh] grid place-items-center py-2 font-roboto relative z-0 overflow-x-hidden">
        <div className="flex flex-col grow gap-4 max-w-6xl">
          <div className="relative z-10 top-[-40px] md:top-[-40px] lg:top-[-70px] sm:left-[-200px] md:left-[-150px]">
            <Background />
          </div>

          <div className="relative z-20 pt-4 px-4">
            <div className="font-roboto font-semibold text-4xl pb-2">
              Reset Password
            </div>
          </div>
          <div className="relative z-20 bg-white rounded-md p-6 w-full shadow-md lg:w-96">
            <form className="pt-4" onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-col">
                <label className="block text-md font-roboto font-light">
                  New Password:
                </label>
                <input
                  name="password"
                  type="password"
                  placeholder="Password"
                  className={`block border border-onSurfaceVariant w-full p-3 rounded-lg mb-4 form-control focus:border-primary focus:outline-none ${
                    errors.password ? "is-invalid" : ""
                  }`}
                  {...register("password")}
                />
                <div className="invalid-feedback">
                  {errors.password?.message}
                </div>
              </div>
              <div className="flex flex-col form-group">
                <label className="block text-md font-roboto font-light">
                  Confirm New Password:
                </label>
                <input
                  name="confirmPwd"
                  type="password"
                  placeholder="Confirm Password"
                  className={`block border border-onSurfaceVariant w-full p-3 rounded-lg mb-4 form-control focus:border-primary focus:outline-none ${
                    errors.confirmPwd ? "is-invalid" : ""
                  }`}
                  {...register("confirmPwd")}
                />
                <div className="invalid-feedback">
                  {errors.confirmPwd?.message}
                </div>
              </div>
              {message && (
                <div className="form-group">
                  <div
                    className={
                      successful ? "alert alert-success" : "alert alert-danger"
                    }
                    role="alert"
                  >
                    {message}
                  </div>
                </div>
              )}
              <div className="py-4 grid grid-cols-3">
                <button
                  type="submit"
                  className="bg-surfaceVariant group hover:bg-primary hover:text-white text-gray-900 mt-3 inline-flex gap-4 w-full justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm  sm:mt-0 sm:w-auto"
                  disabled={loading}
                >
                  {loading ? (
                    <ArrowPathIcon className="h-5 w-5 animate-spin group-hover:text-white" />
                  ) : (
                    <></>
                  )}
                  Reset
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPass;
