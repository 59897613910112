// Created By James Launder
// kinda sketch page that just holds the legal things

import { useState } from "react";

function Legal() {
  const [currPDF, setCurrPDF] = useState(
    new URL("../../Assets/WevrMasterAgreement.pdf", import.meta.url).toString()
  );

  const [selected, setSelected] = useState(0);
  const master = new URL(
    "../../Assets/WevrMasterAgreement.pdf",
    import.meta.url
  ).toString();
  const terms = new URL(
    "../../Assets/WevrTerms&Conditions.pdf",
    import.meta.url
  ).toString();
  const privacy = new URL(
    "../../Assets/PrivacyPolicy.pdf",
    import.meta.url
  ).toString();

  return (
    <div className="w-full h-[70vh] font-roboto">
      <div className="h-16 flex flex-row divide-x ">
        <button
          onClick={() => {
            setCurrPDF(master);
            setSelected(0);
          }}
          className={`${
            selected == 0
              ? "border-b-4 border-primary  font-semibold"
              : "text-black"
          } w-1/2`}
        >
          Master Agreement
        </button>
        <button
          onClick={() => {
            setCurrPDF(terms);
            setSelected(1);
          }}
          className={`${
            selected == 1
              ? "border-b-4 border-primary  font-semibold"
              : "text-black"
          } w-1/2`}
        >
          Terms and Conditions
        </button>
        <button
          onClick={() => {
            setCurrPDF(privacy);
            setSelected(2);
          }}
          className={`${
            selected == 2
              ? "border-b-4 border-primary  font-semibold"
              : "text-black"
          } w-1/2`}
        >
          Privacy Policy
        </button>
      </div>

      {/* <embed src={currPDF} type="application/pdf" width="100%" height="100%">
       */}
      <iframe src={currPDF} title="Legal" width="100%" height="100%" />
      {/* <p>Error finding file </p>
      </embed> */}
    </div>
  );
}

export default Legal;
