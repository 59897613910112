// Created by James Launder
// this context allows all the components to access the User which is the query data returned from middleware
// this allows this data to be used to verify as its checked every time the location changes
// causes one more re-render but thats just the cost of security ig
import { createContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import useValidateUserQuery from "./useValidateUserQuery.js";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const [signOut, setSignOut] = useState(false); // set to true when signing out
  let location = useLocation();
  const validateUser = useValidateUserQuery();
  const checkUser = () => {
    if (validateUser.isError) {
      localStorage.removeItem("user");
      setUserData(null);
      setSignOut(true);
    } else {
      if (
        !(validateUser.isFetching && validateUser.isLoading) &&
        validateUser.data != null
      ) {
        setUserData(validateUser?.data?.data);
        // storeToken(validateUser?.data?.data);
      }
    }
  };
  useEffect(() => {
    signOut ? localStorage.removeItem("user") : null;
  }, [signOut]);
  useEffect(() => {
    checkUser();
  }, [validateUser]);
  useEffect(() => {
    if (localStorage.getItem("user") != null) {
      validateUser.refetch();
    }
  }, [location]);

  return (
    <UserContext.Provider
      value={{ userData, signOut, setSignOut, validateUser }}
    >
      {children}
    </UserContext.Provider>
  );
};
