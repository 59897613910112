// Created by James Launder
// the top level for all of charting

import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import ChartingDashBoard from "./ChartingDashboard.js";
import MainSection from "./MainSection.js";
import ChartErrorPopup from "./ChartErrorPopup.js";
import NotificationPopup from "./NotificationPopup.js";
import { useSidebarState } from "../../utils/useSidebarState.js";

function Charting() {
  const [searchParams] = useSearchParams();
  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const sidebar = useSidebarState((state) => state.state);
  // console.log("charting rendered");

  return (
    <div className=" max-w-full h-full">
      {/* <NavBar setSidebar={setSidebar} sidebar={sidebar} /> */}
      <div className="flex flex-row relative z-0 w-full mx-auto">
        {/* <div className="absolute z-20 md:z-0 md:relative border-r-2 border-secondary">
            <VertNav
            // setGraphId={setGraphId}
            />
          </div> */}

        {searchParams.get("name") ? (
          <MainSection
            sidebar={sidebar}
            showError={show}
            setShowError={setShow}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
          />
        ) : (
          <ChartingDashBoard sidebar={sidebar} />
        )}
      </div>
      <div className="absolute z-30">
        <NotificationPopup />
        <ChartErrorPopup show={show} setShow={setShow} message={errorMessage} />
      </div>
    </div>
  );
}

export default Charting;
