// DataBar.js
import React from "react";
import { useQuery } from "@tanstack/react-query";
import request from "../../Api/Api.js";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import watermarkIcon from "../../Assets/WevrLogoSecondary.svg";
import {
  TableCellsIcon as TableIcon,
  ChartBarIcon,
  ArrowDownTrayIcon,
  PencilSquareIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/react/24/solid";
import BlockchainDropdown from "./BlockchainDropdown.js";
function DataBar({ blockchain, blockchainData, onBlockchainChange }) {
  const {
    isLoading: tickerLoading,
    isError: tickerError,
    data: tickerData,
  } = useQuery({
    queryKey: ["ticker-" + blockchain],
    queryFn: () =>
      request.get(
        "/ticker/" + blockchain.charAt(0).toUpperCase() + blockchain.slice(1)
      ),
  });

  const colour = (first, last) => {
    if (first > last) {
      return false;
    }
    return true;
  };

  const isPrice = (string) => {
    if (string.includes("Price")) {
      return true;
    }
    return false;
  };

  if (tickerLoading) {
    return (
      <div className="h-16 animate-pulse w-full justify-center flex">
        <img
          src={watermarkIcon}
          alt="..."
          className="h-6 pb-1 md:h-14 md:w-14 self-center"
        />
      </div>
    );
  }

  if (tickerError) {
    return (
      <div className="h-16 w-full justify-center flex">
        <ExclamationCircleIcon
          alt="..."
          className="h-14 w-14 self-center text-red-500"
        />
      </div>
    );
  }

  return (
    <div className="md:h-16 bg-surface w-full flex flex-col md:flex-row md:divide-x-2 divide-secondary font-roboto border-b border-secondar text-roboto">
      <div
        className="md:w-[250px]
        px-[0.9rem] grid grid-flow-col place-items-center md:justify-between md:py-0 py-2 "
      >
        {/* Add the buttons on the far left */}
        <BlockchainDropdown
          blockchainData={blockchainData}
          onBlockchainChange={onBlockchainChange}
        />
        {/* <div className="flex flex-row h-4/5 border-r-2 px-4 border-secondary place-items-center text-sm xl:text-lg">
          <a
            href="/Explore?blockchain=Ethereum"
            className="bg-gray-200 text-gray-700 px-4 py-2 text-sm font-medium focus:outline-none rounded-l-md flex items-center hover:bg-primary hover:text-white"
          >
            <ChartBarIcon className="w-4 h-4 mr-2" />
            Charts
          </a>
          <a
            href="/Explore/Tables?blockchain=ethereum"
            className="bg-gray-200 text-gray-700 px-4 py-2 text-sm font-medium focus:outline-none rounded-r-md flex items-center hover:bg-primary hover:text-white"
          >
            <TableIcon className="w-4 h-4 mr-2" />
            Tables
          </a>
        </div> */}
      </div>
      <div className="w-full overflow-auto">
        <div className="px-4 grid grid-flow-col justify-start place-items-center max-w-full md:py-0 pb-2 h-full">
          {tickerData.data.map((data) => (
            <div
              key={data.name}
              className="flex flex-row h-4/5 gap-2 border-r-2 px-4 border-secondary place-items-center text-sm xl:text-lg"
            >
              <div className="font-roboto text-center whitespace-nowrap overflow-visible text-gray-700">
                {data.name}
              </div>
              <div className="font-monda text-center flex text-gray-700">
                <div className={`${isPrice(data.name) ? "block" : "hidden"} `}>
                  $
                </div>
                {data.data[0][1].toLocaleString("en-US", {
                  maximumFractionDigits: 2,
                })}
              </div>
              <div>
                <ChevronUpIcon
                  className={`${
                    colour(data.data[1][1], data.data[0][1])
                      ? "text-green-500"
                      : "text-red-500 rotate-180"
                  } h-4 w-4 md:h-6 md:w-6`}
                />
              </div>
              <div
                className={`${
                  colour(data.data[1][1], data.data[0][1])
                    ? "text-green-500"
                    : "text-red-500"
                } font-monda font-bold align-baseline md:text-sm`}
              >
                {(
                  ((data.data[0][1] - data.data[1][1]) / data.data[0][1]) *
                  100
                ).toFixed(1)}
                %
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default DataBar;
