import { useQuery } from "@tanstack/react-query";
import request from "../../../Api/Api.js";

export default function UseTransactionQuery() {
  function getToken() {
    if (localStorage.getItem("user") != null) {
      return JSON.parse(localStorage.getItem("user")).accessToken;
    } else {
      return "";
    }
  }
  const address = new URLSearchParams(location.search).get("address");

  const { isLoading, isError, data, error } = useQuery({
    queryKey: ["getTransactions", address],
    queryFn: async () => {
      const data = request.get(`/getTxns?address=${address}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return data;
    },
  });

  return { isLoading, isError, data, error };
}
