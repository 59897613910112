import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const PortfolioPieChart = ({ data, totalValue }) => {
  const freshData = data?.data ?? [];
  var other = 0.0;
  var newData = [];
  for (const slice of freshData) {
    if ((slice.token_holdings_in_usd / totalValue) * 100 < 1) {
      other += slice.token_holdings_in_usd;
    } else {
      newData.push({
        name: slice.tokenDetails.name, // Accessing `name` from `tokenDetails`
        y: parseFloat(slice.token_holdings_in_usd), // Accessing `token_holdings_in_usd` directly
      });
    }
  }
  newData.push({
    name: "Other", // Accessing `name` from `tokenDetails`
    y: parseFloat(other), // Accessing `token_holdings_in_usd` directly
  });

  // Check if data is valid
  if (!newData || !Array.isArray(newData) || newData.length === 0) {
    return <p>No data available</p>;
  }

  const options = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
    },
    title: {
      text: undefined,
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.percentage:.1f} %",
        },
      },
    },
    series: [
      {
        name: "Share",
        colorByPoint: true,
        data: newData,
      },
    ],
    credits: {
      enabled: false, // This line removes the Highcharts credit
    },
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default PortfolioPieChart;
