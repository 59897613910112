import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

export default function NoAlerts() {
  return (
    <div className=" min-h-[50vh] flex flex-col justify-center items-center w-full mx-auto">
      <div className="flex items-center gap-8 py-6">
        <div className="rounded-full bg-surfaceVariant p-4">
          <ExclamationTriangleIcon className="w-16 h-16 text-onSurfaceVariant" />
        </div>
        <div>
          <div className="text-5xl leading-6 text-gray-600 ">No Alerts!</div>
        </div>
      </div>
      <div className=" text-gray-400 text-2xl">
        Add some new alerts at the top.
      </div>
    </div>
  );
}
