import { CheckIcon } from "@heroicons/react/24/solid";
import PaymentButton from "./PaymentButton.js";
import classNames from "../../utils/ClassNames.js";

export default function PricingCard({
  name,
  description,
  period,
  enabled,
  priceMonthly,
  idMonthly,
  priceQuarterly,
  idQuarterly,
  priceYearly,
  idYearly,
  features,
  popular = false,
  sale = false,
  salePercentage,
  couponName,
  couponCode,
}) {
  return (
    <div
      className={classNames(
        popular
          ? "border-2 border-primary shadow-2xl scale-105"
          : "border border-primaryContainer shadow-md scale-100",
        "  rounded-lg  divide-y divide-gray-200 bg-white relative z-0"
      )}
    >
      {popular ? (
        <div className="absolute top-[-15px] right-[-5px] z-10 rounded-full bg-primary px-2.5 py-1 text-xs font-semibold leading-5 text-white">
          Most Popular
        </div>
      ) : null}

      <div className="p-6">
        <h2 className="text-lg leading-6 font-medium text-gray-900">{name}</h2>
        <p className="mt-4 text-sm text-gray-500">{description}</p>
        <p>
          {/* ternary conditions for each month, depending on usestate -> period, unsure if logic is most optimal as it is very messy */}
          {period === "Monthly" ? (
            <PaymentButton
              price={priceMonthly}
              enabled={enabled}
              id={idMonthly}
              name={name}
              sale={sale}
              salePercentage={salePercentage}
              couponName={couponName}
              couponCode={couponCode}
            />
          ) : period === "Quarterly" ? (
            <PaymentButton
              price={priceQuarterly}
              enabled={enabled}
              id={idQuarterly}
              name={name}
              sale={sale}
              couponName={couponName}
              salePercentage={salePercentage}
              couponCode={couponCode}
            />
          ) : period === "Yearly" ? (
            <PaymentButton
              price={priceYearly}
              enabled={enabled}
              id={idYearly}
              name={name}
              sale={sale}
              salePercentage={salePercentage}
              couponName={couponName}
              couponCode={couponCode}
            />
          ) : (
            <span className="text-4xl font-extrabold text-gray-900">
              ${priceMonthly}
            </span>
          )}{" "}
        </p>
      </div>

      <div className="pt-6 pb-8 px-6">
        <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          What's included
        </h3>
        <ul className="mt-6 space-y-4">
          {features.map((feature) => (
            <li key={feature} className="flex space-x-3">
              <CheckIcon
                className="flex-shrink-0 h-5 w-5 text-green-500"
                aria-hidden="true"
              />
              <span className="text-sm text-gray-500">{feature}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
