// created by James Launder
// This is the redirect page that is shown from a stripe successful payment
import { CheckBadgeIcon } from "@heroicons/react/24/outline";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import request from "../../Api/Api.js";
import Error from "../../Error.js";
import { storeToken } from "../../Services/authService.js";
function CheckoutSuccess() {
  const navigate = useNavigate();
  function getToken() {
    if (localStorage.getItem("user") != null) {
      return JSON.parse(localStorage.getItem("user")).accessToken;
    } else {
      return "";
    }
  }
  const { isLoading, isError, data } = useQuery({
    queryKey: ["stripe-success"],
    queryFn: () =>
      request.get("/stripe/checkoutSucess/" + searchParams.get("session_id"), {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }),

    onSuccess: (res) => {
      localStorage.removeItem("user");
      storeToken(res.data.user);
    },
  });

  const [searchParams] = useSearchParams();
  if (isLoading) {
    return (
      <div className="bg-surface h-[75vh] w-full grid place-items-center p-4 font-roboto ">
        <div className="bg-white p-4 px-4 flex flex-col items-center rounded-lg h-fit animate-pulse shadow-lg">
          <CheckBadgeIcon className="h-24 w-24 my-4 text-white" />
          <div className="text-4xl font-semibold pt-1 px-4 pb-12 text-white">
            Payment Successful
          </div>

          <div className="text-white">g</div>
          <div className="text-white pb-4">
            Check out some features by clicking below
          </div>
          <div className="p-4 flex gap-4 pb-4">
            <button className="px-3 py-2 font-roboto font-light text-primaryContainer bg-primaryContainer rounded-lg hover:bg-primary hover:text-white">
              Explore
            </button>
            <button className="px-3 py-2 font-roboto font-light text-primaryContainer bg-primaryContainer rounded-lg hover:bg-primary hover:text-white">
              Explore
            </button>
          </div>
        </div>
      </div>
    );
  }
  if (isError) {
    return <Error />;
  }
  return (
    <div className="bg-surface h-[75vh] w-full grid place-items-center p-4 font-roboto">
      <div className="bg-white p-4 px-4 flex flex-col items-center rounded-lg h-fit shadow-md">
        <CheckBadgeIcon className="h-24 w-24 my-4 text-green-500" />
        <div className="text-4xl font-semibold pt-1 px-4 pb-12 text-gray-800">
          Payment Successful
        </div>

        <div className="text-gray-500">
          Thanks {data.data.customer.email} for your subscription!
        </div>
        <div className="text-gray-500 pb-4">
          Check out some features by clicking below
        </div>
        <div className="p-4 flex gap-4 pb-4">
          <button
            className="px-3 py-2 font-roboto font-light bg-primaryContainer rounded-lg hover:bg-primary hover:text-white"
            onClick={() => {
              navigate("/Explore?blockchain=Ethereum");
            }}
          >
            Explore
          </button>
          <button
            className="px-3 py-2 font-roboto font-light bg-primaryContainer rounded-lg hover:bg-primary hover:text-white"
            onClick={() => {
              navigate("/Footprint");
            }}
          >
            Footprint
          </button>
        </div>
      </div>
    </div>
  );
}

export default CheckoutSuccess;
