import { useState } from "react";
import { Outlet } from "react-router-dom";
import Footer from "./Components/Footer";
import Nav from "./Components/Nav";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { UserProvider } from "./Services/userContext";
import ScrollToTop from "./Services/scrollToTop";
import CouponBanner from "./Components/Ui/CouponBanner";
import { Toaster } from "./Components/Ui/toaster";
function Root() {
  const handleDeclineCookie = () => {
    //remove google analytics cookies
    Cookies.remove("_ga_E7XCZRLHGN");
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
  };
  const [coupon, setCoupon] = useState(true);
  // Main screen has the nav bar, main section and footer.
  return (
    <div className="relative z-0 max-w-full">
      <CouponBanner enabled={coupon} setEnabled={setCoupon} />

      <ScrollToTop />
      <UserProvider>
        <header className="relative z-30">
          <Nav />
        </header>
        <main>
          <Outlet />
          <Toaster />
        </main>
        <footer>
          <Footer />
        </footer>

        <div className="mx-auto px-20 rounded-lg">
          <CookieConsent
            style={{
              background: "#FA9173",
              color: "#FFFFFF",
            }}
            buttonStyle={{
              color: "#000000",
              borderRadius: "0.5rem",
              backgroundColor: "#E6DCDC",
            }}
            declineButtonStyle={{
              color: "#000000",
              borderRadius: "0.5rem",
              backgroundColor: "#EF4444",
            }}
            expires={5}
            enableDeclineButton
            flipButtons
            // debug={true}
            onDecline={handleDeclineCookie}
          >
            This website uses cookies to enhance the user experience.
          </CookieConsent>
        </div>
      </UserProvider>
    </div>
  );
}

export default Root;
