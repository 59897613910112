//hooks
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import request from "../../Api/Api.js";
import { useAllTableStores } from "./API/GetAllTableStores.js";

export const useBlockchainData = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const blockchain = searchParams.get("blockchain") || "Ethereum";
  const {
    data: tableStoresData,
    isLoading: tableStoresLoading,
    isError: tableStoresError,
    error: tableStoresErrorDetails,
  } = useAllTableStores();
  const {
    data: blockchainData,
    isLoading: blockchainLoading,
    isError: blockchainError,
    error: blockchainErrorDetails,
  } = useQuery({
    queryKey: ["blockchains"],
    queryFn: () => request.get("/blockchains"),
  });

  const [currentTableStores, setCurrentTableStores] = useState([]);
  const [blockchainErrorState, setBlockchainErrorState] = useState(null);

  useEffect(() => {
    if (tableStoresData && tableStoresData[blockchain.toLowerCase()]) {
      setCurrentTableStores(tableStoresData[blockchain.toLowerCase()]);
    }
  }, [tableStoresData, blockchain]);

  useEffect(() => {
    if (blockchainError) {
      setBlockchainErrorState(blockchainErrorDetails);
    }
  }, [blockchainError]);

  return {
    blockchain,
    searchParams,
    setSearchParams,
    tableStoresLoading,
    tableStoresError,
    tableStoresErrorDetails,
    blockchainLoading,
    blockchainData,
    blockchainErrorState,
    currentTableStores,
    setCurrentTableStores, // Return the setter function
  };
};
