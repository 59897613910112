// created by James Launder
// contains the chart name and share function
// also used as a part of the full screen and screenshot functions

import { Fragment, useState } from "react";
import {
  ArrowsPointingOutIcon,
  BellAlertIcon,
  ShareIcon,
  StarIcon,
  ClipboardIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/react/24/solid";
import { Popover, Transition } from "@headlessui/react";
import {
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";
import exportImage from "../../Services/exportImage";
import { Tooltip } from "react-tooltip";
import { usePopper } from "react-popper";
import NotificationDialog from "./NotificationDialog";
import { useSearchParams } from "react-router-dom";
import LoginDialog from "./LoginDialog";
function ChartHeader(props) {
  const [openNotification, setOpenNotification] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const [searchParams] = useSearchParams();
  const href = window.location.href;
  let [referenceElement, setReferenceElement] = useState();
  let [popperElement, setPopperElement] = useState();
  let { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [12, 4],
        },
      },
    ],
  });
  return (
    // covers the whole section
    <div className="w-full grid content-center font-roboto py-1">
      {props.handle.active ? (
        <div className="flex flex-row justify-center pt-2 mx-8 px-2 border-b border-secondary pb-2">
          {/* Left div */}
          <div className="flex flex-row gap-4 place-items-center">
            <div className="text-2xl font-semibold place-items-center">
              {props.name}
            </div>
          </div>
        </div>
      ) : (
        <div className=" flex flex-row justify-between items-center pt-2 mx-8 border-b border-secondary pb-2">
          {/* Left div */}
          <div className="flex flex-row space-x-4 place-items-center relative h-full grow">
            <div className="text-lg md:text-2xl font-semibold place-items-center border-r border-secondary pr-4">
              {props.name}
            </div>
            <div>
              {/* <div className="favs text-sm md:text-md hidden sm:flex flex-row place-items-center divide-x divide-secondary bg-primaryContainer rounded-lg px-2 relative lg:h-full h-fit"> */}
              <button className="group bg-surfaceVariant rounded-lg p-2 w-full shadow-md border border-primaryContainer favs">
                <StarIcon className="h-5 w-5 self-center text-onSurfaceVariant group-hover:text-primary" />
              </button>
              {/* <div className="font-monda text-center px-2">500k</div> */}
              <Tooltip anchorSelect=".favs" place="top">
                Coming Soon!
              </Tooltip>
            </div>
            {/* </div> */}
          </div>
          {/* right div need alerts, share and fullscreen hidden below medium device size*/}
          <span className="hidden relative md:grid grid-cols-3 rounded-lg bg-surfaceVariant items-center shadow-md border border-primaryContainer">
            <>
              <button
                className="group flex flex-row place-content-center p-2
                hover:bg-primary hover:text-white pl-2 rounded-lg py-2.5 col-span-1"
                onClick={() => {
                  localStorage.getItem("user") === null
                    ? setOpenLogin(true)
                    : setOpenNotification(true);
                }}
              >
                <BellAlertIcon className="h-5 w-5 mr-2 self-center text-onSurfaceVariant group-hover:text-white" />
                Alerts
              </button>
            </>
            <Popover as="div" className="">
              {({ open }) => (
                <>
                  <div>
                    <Popover.Button
                      className={`${
                        open ? "bg-primary text-white" : ""
                      } group flex flex-row place-content-center p-2 hover:bg-primary hover:text-white pl-2 rounded-lg col-span-1 py-2.5`}
                    >
                      <ShareIcon
                        className={`${
                          open ? " text-white" : ""
                        } h-5 w-5 mr-2 self-center text-onSurfaceVariant group-hover:text-white`}
                      />
                      Share
                    </Popover.Button>
                  </div>

                  {open && (
                    <Transition
                      as={Fragment}
                      enter="transition-opacity ease-out duration-75"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Popover.Panel className="absolute mt-2 border-2 w-[290px] -ml-24 border-primaryContainer rounded-xl z-30">
                        <div className="grid grid-cols-2 divide-x divide-secondary bg-surface rounded-t-xl p-2 pr-2">
                          <div className="">
                            <h1 className="font-semibold">Download</h1>
                            <ul className="font-light text-sm">
                              <li className="py-1  ">
                                <button
                                  className="underline underline-offset-auto hover:text-blue-500"
                                  onClick={() => {
                                    exportImage(props.divRef, "png");
                                  }}
                                >
                                  PNG
                                </button>
                              </li>
                              <li className="py-1">
                                <button
                                  className="underline underline-offset-auto hover:text-blue-500"
                                  onClick={() => {
                                    exportImage(props.divRef, "jpeg");
                                  }}
                                >
                                  JPEG
                                </button>
                              </li>
                              <li className="py-1">
                                <button
                                  className="underline underline-offset-auto hover:text-blue-500"
                                  onClick={() => {
                                    exportImage(props.divRef, "svg");
                                  }}
                                >
                                  SVG
                                </button>
                              </li>
                            </ul>
                          </div>
                          <div className="pl-2">
                            <h1 className="font-semibold">Share</h1>
                            <ul>
                              <li>
                                <TwitterShareButton
                                  title={props.name + "\n"}
                                  url={href}
                                >
                                  <div className="grid grid-flow-col place-items-center">
                                    <TwitterIcon size={18} round={true} />
                                    <p className="pl-2 py-1 text-sm font-light hover:text-blue-500">
                                      Twitter
                                    </p>
                                  </div>
                                </TwitterShareButton>
                              </li>
                              <li>
                                <LinkedinShareButton
                                  title={props.name + "\n"}
                                  url={href}
                                >
                                  <div className="grid grid-flow-col place-items-center">
                                    <LinkedinIcon size={18} round={true} />
                                    <p className="pl-2 py-1 text-sm font-light hover:text-blue-500">
                                      LinkedIn
                                    </p>
                                  </div>
                                </LinkedinShareButton>
                              </li>
                              <li>
                                <TelegramShareButton
                                  title={props.name + "\n"}
                                  url={href}
                                >
                                  <div className="grid grid-flow-col place-items-center">
                                    <TelegramIcon size={18} round={true} />
                                    <p className="pl-2 py-1 text-sm font-light hover:text-blue-500">
                                      Telegram
                                    </p>
                                  </div>
                                </TelegramShareButton>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="rounded-b-xl bg-surface w-full p-2">
                          <h1 className="font-semibold border-t-2 border-secondary mx-2 py-1">
                            Link
                          </h1>
                          <button
                            onClick={() => {
                              navigator.clipboard.writeText(href);
                            }}
                            className=" flex group items-center bg-white w-full p-2 rounded-xl border  border-secondary"
                          >
                            <p className="text-clip overflow-hidden h-6 bg-surface rounded-lg text-center font-light">
                              {href}
                            </p>
                            <div className="">
                              {/* <button
                                onClick={() => {
                                  navigator.clipboard.writeText(href);
                                }}
                                className="p-2 ml-2 group flex place-content-center hover:bg-primary hover:text-white rounded-lg"
                              > */}
                              <ClipboardIcon
                                className="h-5 w-5 self-center text-onSurfaceVariant group-hover:text-primary mb-[0.5]"
                                aria-hidden="true"
                              />
                              {/* </button> */}
                            </div>
                          </button>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  )}
                </>
              )}
            </Popover>
            <button
              onClick={() => {
                props.handle.enter();
              }}
              className="group flex flex-row place-content-center p-2 hover:bg-primary hover:text-white pl-2 rounded-lg col-span-1 py-2.5"
            >
              <ArrowsPointingOutIcon className=" h-5 w-5 mr-2 self-center text-secondary group-hover:text-white" />
              Full
            </button>
          </span>
          <div className="block md:hidden">
            <Popover as="div" className="">
              {({ open }) => (
                <>
                  <div>
                    <Popover.Button
                      ref={setReferenceElement}
                      className={`${
                        open ? "bg-primary text-white" : ""
                      } group flex flex-row p-2 place-content-center bg-surfaceVariant hover:bg-primary shadow-md hover:text-white border border-primaryContainer hover:border-primary rounded-lg col-span-1`}
                    >
                      <EllipsisHorizontalIcon
                        className={`${
                          open ? " text-white" : ""
                        } h-5 w-5 self-center text-onSurfaceVariant group-hover:text-white`}
                      />
                    </Popover.Button>
                  </div>

                  {open && (
                    <Transition
                      as={Fragment}
                      enter="transition-opacity ease-out duration-75"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Popover.Panel
                        className="absolute border-2 border-primaryContainer bg-white rounded-xl z-30 w-fit -ml-6"
                        ref={setPopperElement}
                        style={styles.popper}
                        {...attributes.popper}
                      >
                        <div className="flex flex-col divide-y p-2">
                          {/* social media share */}
                          <div className="flex justify-evenly gap-4 ">
                            <div className="py-2">
                              <TwitterShareButton
                                title={props.name + "\n"}
                                url={href}
                              >
                                <div className="flex flex-col justify-center items-center text-center">
                                  <div>
                                    <TwitterIcon size={32} round={true} />
                                  </div>

                                  <p className="py-1">Twitter</p>
                                </div>
                              </TwitterShareButton>
                            </div>
                            <div className="py-2">
                              <TelegramShareButton
                                title={props.name + "\n"}
                                url={href}
                              >
                                <div className="flex flex-col justify-center items-center text-center">
                                  <TelegramIcon size={32} round={true} />
                                  <p className="py-1">Telegram</p>
                                </div>
                              </TelegramShareButton>
                            </div>
                            <div className="py-2">
                              <button
                                onClick={() => {
                                  navigator.clipboard.writeText(href);
                                }}
                                className="flex flex-col justify-center items-center text-center"
                              >
                                <ClipboardIcon className="h-8 w-8 text-primary" />{" "}
                                <p className="py-1">Copy</p>
                              </button>
                            </div>
                          </div>

                          {/* Fullscreen */}
                          <button
                            onClick={() => {
                              props.handle.enter();
                            }}
                            className="flex justify-center items-center gap-2 p-2"
                          >
                            <ArrowsPointingOutIcon className=" h-8 w-8  self-center text-primary" />
                            Full Screen
                          </button>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  )}
                </>
              )}
            </Popover>
          </div>
        </div>
      )}
      <NotificationDialog
        open={openNotification}
        setOpen={setOpenNotification}
        blockchain={searchParams.get("blockchain")}
        optimistic={false}
      />
      <LoginDialog open={openLogin} setOpen={setOpenLogin} />
    </div>
  );
}

export default ChartHeader;
