import { useQuery } from "@tanstack/react-query";
import request from "../../../Api/Api.js";

function getToken() {
  if (localStorage.getItem("user") != null) {
    return JSON.parse(localStorage.getItem("user")).accessToken;
  } else {
    return "";
  }
}

export default function usePremadeQueries() {
  const { isLoading, isError, isFetching, data, error } = useQuery({
    queryKey: ["getPremadeQueries"],
    queryFn: async () => {
      const { data } = await request.get("/premadeQueries", {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return data;
    },
    staleTime: 1000,
  });

  return { isLoading, isError, isFetching, data, error };
}
