// Created By James Launder
// user settings page, this contains all the components to display info about the acc and interact withit

import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useMutation } from "@tanstack/react-query";
import request from "../../Api/Api";
import ChangePass from "./ChangePass";
import UpdateUsername from "./UpdateUsername";
import UpdateEmail from "./UpdateEmail";
import Background from "../Background";
import { UserContext } from "../../Services/userContext";
import useUserInfoQuery from "./useUserInfoQuery";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";

function UserSettings() {
  const { user, signOut, setSignOut } = useContext(UserContext);
  const [selectedTier, setselectedTier] = useState("T1");
  const [source, setSource] = useState("Normal");
  const navigate = useNavigate();
  const userInfo = useUserInfoQuery();
  // available tiers
  const [tier] = useState({
    0: "Basic",
    1: "Standard",
    2: "Professional",
    3: "Industry",
  });
  const [selectedMail, setSelectedMail] = useState("False");
  const [mail] = useState({
    true: "Yes",
    false: "No",
  });

  const [open, setOpen] = useState(false);
  const [openUsername, setOpenUsername] = useState(false);
  const [openEmail, setOpenEmail] = useState(false);
  // checks if the user had an error when verifying then redirects
  useEffect(() => {
    if (user === 401 || user === 404) {
      navigate("/signin");
    }
  }, [user]);
  function getToken() {
    if (localStorage.getItem("user") != null) {
      return JSON.parse(localStorage.getItem("user")).accessToken;
    } else {
      return "";
    }
  }
  function getTier() {
    if (localStorage.getItem("user") != null) {
      return JSON.parse(localStorage.getItem("user")).tier > 0;
    } else {
      return null;
    }
  }
  // sends user to stripe billing to manage the subscription
  const handleBilling = () => {
    fetch(process.env.API + `/stripe/billing`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      // Send along all the information about the items
      body: JSON.stringify({}),
    })
      .then((res) => {
        if (res.ok) return res.json();
        return res.json().then((json) => Promise.reject(json));
      })
      .then(({ url }) => {
        window.location = url;
      })
      .catch((e) => {
        console.error(e.error);
      });
  };

  // gets all the user info to show the user
  if (getTier() != null) {
    const mutation = useMutation({
      mutationFn: async ({ mailList }) => {
        return await request.put(
          "/user/updateMailList",
          {
            mail: mailList,
          },
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem("user")).accessToken
              }`,
            },
          }
        );
      },

      onSuccess: (res) => {
        setSelectedMail(mail[res.data.data]);
      },
      onError: (error) => {
        console.error(error);
      },
    });
    const onSubmit = (data) => {
      mutation.mutate({
        mailList: data,
      });
    };
    useEffect(() => {
      userInfo.refetch();
    }, [openUsername, openEmail]);
    useEffect(() => {
      setSelectedMail(mail[userInfo.data?.data.user.mailList]);
      setselectedTier(userInfo.data?.data.user.tier);
      setSource(userInfo.data?.data.user.source);
    }, [userInfo.data]);

    if (userInfo.isLoading) {
      return (
        <div className="px-4 py-5 sm:p-6 h-[75vh]">
          <div className="bg-white flex h-full shadow rounded-md justify-center items-center divide-gray-200 dark:bg-primary-DM overflow-auto">
            <div className="text-center ">
              <svg
                role="status"
                className="inline w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-primary"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="min-h-[75vh] grid place-items-center py-2 font-roboto overflow-x-hidden sm:mb-8">
        <div className="flex flex-col gap-4 max-w-6xl grow pb-4 px-2">
          <Background />
          <div className="pt-4 px-4">
            <div className=" font-semibold text-center text-2xl sm:text-4xl ml-2 pb-2 text-gray-900">
              Account Information
            </div>
          </div>
          <div className=" bg-white rounded-2xl p-4 grid grid-cols-1 sm:grid-cols-2 sm:gap-y-4 border border-surfaceVariant shadow-md">
            <div className="px-2 py-2 h-16 items-center flex">
              <h1 className="text-md sm:text-xl font-semibold">Username</h1>
            </div>
            <div className="px-2 py-2 h-16 items-center flex justify-between">
              <h1 className="text-md sm:text-xl">
                {userInfo.data?.data.user.username}
              </h1>
              <button
                onClick={() => {
                  setOpenUsername(true);
                }}
                className="text-md sm:text-xl bg-surfaceVariant rounded-md px-3 py-2 hover:bg-primary hover:text-white border border-primaryContainer shadow-sm"
              >
                Update
              </button>
            </div>
            <div className="px-2 py-2 h-16 items-center flex">
              <h1 className="text-md sm:text-xl font-semibold">Email</h1>
            </div>
            <div className="px-2 py-2 h-16 items-center flex justify-between">
              <h1 className="text-md sm:text-xl">
                {userInfo.data?.data.user.email}
              </h1>
              {source != "" ? (
                <div></div>
              ) : (
                <button
                  onClick={() => {
                    setOpenEmail(true);
                  }}
                  className="text-md sm:text-xl rounded-md px-3 py-2 hover:bg-primary hover:text-white bg-surfaceVariant border border-primaryContainer shadow-sm"
                >
                  Update
                </button>
              )}
            </div>
            <div className="px-2 py-2 h-16 items-center flex">
              {source != "" ? (
                <h1 className="text-md sm:text-xl font-semibold">
                  Registered With
                </h1>
              ) : (
                <h1 className="text-md sm:text-xl font-semibold">Password</h1>
              )}
            </div>
            <div className="px-2 py-2 h-16 items-center flex">
              {source != "" ? (
                <div className="text-md sm:text-xl ">{source}</div>
              ) : (
                <button
                  onClick={() => {
                    setOpen(true);
                  }}
                  className="text-md sm:text-xl bg-surfaceVariant border border-primaryContainer shadow-sm rounded-md px-3 py-2 hover:bg-primary hover:text-white"
                >
                  Change Password
                </button>
              )}
            </div>
            <div className="px-2 py-2 h-16 items-center flex">
              <h1 className="text-md sm:text-xl font-semibold">
                Marketing email
              </h1>
            </div>
            <div className="px-2 py-2 flex gap-2">
              <span className="grid grid-flow-col space-x-1 rounded-xl bg-surfaceVariant border border-primaryContainer shadow-sm">
                {Object.keys(mail).map((category) => (
                  <button
                    key={category}
                    onClick={() => {
                      onSubmit(category);
                    }}
                    className={`${
                      selectedMail === mail[category]
                        ? "bg-primary text-white text-bold"
                        : "hover:bg-primary/30"
                    } rounded-xl py-2.5 px-3 text-black`}
                  >
                    {mail[category]}
                  </button>
                ))}
              </span>
            </div>
            <div className="px-2 py-2 items-center flex gap-2">
              <h1 className="text-md sm:text-xl font-semibold">
                Subscription level
              </h1>
            </div>
            <div className="px-2 py-2 flex gap-2">
              <div className="block sm:hidden">{tier[selectedTier]}</div>
              <span className="grid-flow-col space-x-2 rounded-xl bg-surfaceVariant border border-primaryContainer shadow-sm hidden sm:grid">
                {Object.keys(tier).map((category) => (
                  <button
                    key={category}
                    onClick={() => {
                      navigate("/pricing");
                    }}
                    className={`${
                      selectedTier == category
                        ? "bg-primary text-white text-bold"
                        : ""
                    } rounded-xl py-2.5 px-3 text-black`}
                  >
                    {tier[category]}
                  </button>
                ))}
              </span>
            </div>
            {userInfo.data?.data.user.paidBy ? (
              <div className="px-2 py-2 h-16 items-center flex">
                <h1 className="text-md sm:text-xl font-semibold">
                  Subscribed with
                </h1>
              </div>
            ) : (
              <div></div>
            )}
            {userInfo.data?.data.user.paidBy ? (
              <div className="px-2 py-2 h-16 items-center flex justify-between">
                <h1 className="text-md sm:text-xl pr-2">
                  {userInfo.data?.data.user.paidBy}
                </h1>
                {userInfo.data?.data.user.paidBy === "Stripe" ? (
                  <button
                    onClick={() => {
                      handleBilling();
                    }}
                    className="text-md sm:text-xl bg-surfaceVariant border border-primaryContainer shadow-sm rounded-md px-3 py-2 hover:bg-primary hover:text-white"
                  >
                    Manage
                  </button>
                ) : (
                  <div></div>
                )}
              </div>
            ) : (
              <div></div>
            )}
            {getTier() ? (
              <div className="px-2 py-2 h-16 items-center flex">
                <h1 className="text-md sm:text-xl font-semibold">
                  Subscription ends
                </h1>
              </div>
            ) : (
              <div></div>
            )}
            {getTier() ? (
              <div className="px-2 py-2 h-16 items-center flex">
                <h1 className="text-md sm:text-xl ">
                  {moment(userInfo.data?.data.user.subscriptionEnd).format(
                    "MMMM Do YYYY, h:mm a"
                  )}
                </h1>
              </div>
            ) : (
              <div></div>
            )}
            <div className="px-2 py-2 items-center flex">
              <h1 className="text-md sm:text-xl font-semibold">Created on</h1>
            </div>
            <div className="px-2 py-2 items-center flex">
              <h1 className="text-md sm:text-xl">
                {moment(userInfo.data?.data.user.createdAt).format(
                  "MMMM Do YYYY, h:mm a"
                )}
              </h1>
            </div>
            <div className="px-2 py-2 col-span-2 place-content-center mt-4">
              <button
                className="text-md sm:text-xl bg-surfaceVariant border border-primaryContainer shadow-sm rounded-md px-3 py-2 w-56 hover:bg-primary hover:text-white"
                onClick={() => {
                  // console.log("sign out called");
                  setSignOut(true);
                  localStorage.removeItem("user");
                  navigate("/");
                  window.location.reload();
                }}
              >
                Sign out
              </button>
            </div>
          </div>
        </div>
        <ChangePass open={open} setOpen={setOpen} />
        <UpdateUsername open={openUsername} setOpen={setOpenUsername} />
        <UpdateEmail open={openEmail} setOpen={setOpenEmail} />
      </div>
    );
  } else {
    if (localStorage.getItem("user") == null) {
      return (
        <div className=" min-h-[75vh] flex flex-col justify-center items-center w-full mx-auto">
          <div className="flex items-center gap-8 py-4">
            <div className="rounded-full bg-surfaceVariant p-4">
              <ExclamationCircleIcon className="w-16 h-16 text-onSurfaceVariant" />
            </div>
            <div>
              <div className="text-5xl md:leading-6 text-gray-600 ">
                Not logged in!
              </div>
            </div>
          </div>
          <div className=" text-gray-400 text-2xl gap-6 flex flex-col items-center">
            Login to see your settings
            <button
              className="bg-surfaceVariant px-4 py-2 rounded-md text-black hover:bg-primary hover:text-white"
              onClick={() => navigate("/signin")}
            >
              Login
            </button>
          </div>
        </div>
      );
    }
  }
}

export default UserSettings;
