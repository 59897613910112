// Created by Matt webb
// main payment page that is shown to the user, contains the data for each plan

import { useState } from "react";
import { Switch } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/solid";
import wevr from "../../Assets/WevrLogoPrimary.svg";
import { useNavigate } from "react-router";

import TwistComponentPrimary from "../Homepage/TwistComponentPrimary.js";
import PricingCard from "./PricingCard.js";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const SubscriptionButtons = [
  { id: 1, title: "Monthly" },
  { id: 2, title: "Quarterly" },
  { id: 3, title: "Yearly" },
];

const base = [
  {
    name: "Base",
    price: "Free",

    description: "Sign up to gain access to our charts!",
    features: [
      "Chart resolution: Daily (delayed by up to 60 days)",
      "Access to all charts on every blockchain listed",
      "Compare: 1 Charts",
      "Alerts: 1",
      // "Dashboard: No Custom (Coming soon)",
      "Footprint: 1 Address",
      "Favourite: 1 Chart (Coming soon)",

      "Number of seats: 1 ",
    ],
  },
];

const tiers = [
  {
    name: "Standard",
    priceMonthly: 29,
    priceMonthlyDefault: 29,
    cryptoPriceMonthly: 25,

    priceQuarterly: 82,
    priceQuarterlyDefault: 82,
    cryptoPriceQuarterly: 78,

    priceYearlyDefault: 300,
    cryptopriceYearly: 290,
    priceYearly: 300,

    popular: false,
    sale: true,
    salePercentage: 50,
    couponName: null,
    couponCode: "DRHwWmNe",

    idMonthly: "StandardMonthly",
    idQuarterly: "StandardQuarterly",
    idYearly: "StandardYearly",
    description: "All the basics",
    features: [
      "Chart resolution: up to Hourly",
      "Historical data: access to all our historical data ",
      "Access to all charts on every blockchain listed",
      "Compare: 2 Charts",
      "Alerts: 5",
      // "Dashboard: 1 Custom (Coming soon)",
      "Footprint: 3 Addresses",
      "Favourite: 5 Charts (Coming soon)",

      "Number of seats: 1 ",
    ],
  },
  {
    name: "Professional",

    priceMonthly: 59,
    priceMonthlyDefault: 59,
    cryptoPriceMonthly: 50,

    popular: true,
    sale: true,
    salePercentage: 50,
    couponName: null,
    couponCode: "DRHwWmNe",

    idMonthly: "ProfessionalMonthly",
    idQuarterly: "ProfessionalQuarterly",
    idYearly: "ProfessionalYearly",

    priceQuarterly: 160,
    priceQuarterlyDefault: 160,
    cryptoPriceQuarterly: 145,

    priceYearlyDefault: 600,
    cryptopriceYearly: 500,
    priceYearly: 600,

    description: "Access to block time data",
    features: [
      "Chart resolution: up to Block",
      "Historical data: access to all our historical data ",
      "Access to all charts on every blockchain listed",
      "Compare: 3 Charts",
      "Alerts: 10",
      // "Dashboard: 3 Custom (Coming soon)",
      "Footprint: 5 Addresses",
      "Favourite: 10 Charts (Coming soon)",

      "Number of seats: 1 ",
    ],
  },
  {
    name: "Industry",

    priceMonthly: 500,
    priceMonthlyDefault: 500,
    cryptoPriceMonthly: 440,

    popular: false,
    sale: true,
    salePercentage: 50,
    couponName: null,
    couponCode: "DRHwWmNe",

    idMonthly: "IndustryMonthly",
    idQuarterly: "IndustryQuarterly",
    idYearly: "IndustryYearly",
    priceQuarterly: 1400,
    priceQuarterlyDefault: 1400,
    cryptoPriceQuarterly: 1300,

    priceYearlyDefault: 5500,
    cryptopriceYearly: 5000,
    priceYearly: 5500,
    description: "All inclusive analytics",
    features: [
      "Chart resolution: up to Block",
      "Historical data: access to all our historical data ",
      "Access to all charts on every blockchain listed",
      "Compare: 5 Charts",
      "Alerts: 50",
      // "Dashboard: 20 Custom (Coming soon)",
      "Footprint: 20 Addresses",
      "Favourite: 50 Charts (Coming soon)",

      "Access our API with up to 100 requests per minute (Coming soon)",
      // "Access to CSV download 100 per month (Coming soon)",
      "Number of seats: 1",
    ],
  },
];

const enterprise = [
  "Chart resolution: up to Block",
  "Historical data: access to all our historical data ",
  "Access to all our blockchains",
  "Create custom datasets using your own private blockchains",
  "Private access to custom designed features upon request",
  "Access to our features such as Dashboard, Footprint and Compare",
  "Access our API with up to 600 requests per minute (Coming soon)",
  "Number of seats: Custom ",
];

function Payment() {
  const [enabled, setEnabled] = useState(false);
  const [period, setPeriod] = useState("Monthly");
  const navigate = useNavigate();
  const handleColor = (row) => {
    setPeriod(row.id);
  };

  {
    /* code is sloppy here .. -> made price monthly default which is not ideal as i couldnt figure out how to stop enabled true continually being pressed and decreasing the number / didnt know how to change back to default value on else after its been clicked */
  }
  if (enabled === true) {
    {
      tiers.map(
        (tier) => (
          (tier.priceMonthly = tier.cryptoPriceMonthly),
          (tier.priceQuarterly = tier.cryptoPriceQuarterly),
          (tier.priceYearly = tier.cryptopriceYearly)
        )
      );
    }
  } else {
    {
      tiers.map(
        (tier) => (
          (tier.priceMonthly = tier.priceMonthlyDefault),
          (tier.priceQuarterly = tier.priceQuarterlyDefault),
          (tier.priceYearly = tier.priceYearlyDefault)
        )
      );
    }
  }
  function getTier() {
    // var token = JSON.parse(localStorage.getItem("user")).accessToken;
    if (localStorage.getItem("user") == null) {
      return false;
      // return JSON.parse(localStorage.getItem("user")).tier > 0;
    } else {
      return true;
    }
  }
  return (
    <div className=" overflow-hidden pb-20 bg-surface z-0">
      <div>
        <div className="relative top-[190px] left-[350px] mx-auto max-w-6xl md:block hidden z-10">
          <div className="relative">
            <TwistComponentPrimary className="absolute -z-10 rotate-[-20deg] top-[-250px] sm:top-[-300px] lg:top-[-350px] xl:top-[-600px] 2xl:top-[-650px] left-[-450px] sm:left-[-550px] md:left-[-550px] lg:left-[-650px] xl:left-[-750px] 2xl:left-[-850px] h-[800px] w-[800px] lg:h-[900px] lg:w-[900px] xl:h-[1300px] xl:w-[1300px] 2xl:h-[1300px] 2xl:w-[1300px] blur-sm block" />
            <TwistComponentPrimary className="absolute -z-10 rotate-[-120deg] top-[-250px] sm:top-[-300px] lg:top-[-350px] xl:top-[-700px] 2xl:top-[-750px] left-[200px] sm:left-[250px] md:left-[250px] lg:left-[250px] xl:left-[50px] 2xl:left-[50px] h-[800px] w-[800px] md:h-[800px] md:w-[800px] lg:h-[900px] lg:w-[900px] xl:h-[1300px] xl:w-[1300px] 2xl:h-[1300px] 2xl:w-[1300px] blur-sm block" />
          </div>
        </div>
        {/* <div className="absolute inset-x-0 bottom-0 left-0  h-60 bg-gradient-to-t from-primaryfeatures2 -z-10" /> */}
      </div>
      {/* Header and Page Header */}

      <div className="relative z-20">
        <div className="max-w-7xl mx-auto pt-24 px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:flex-col sm:align-center">
            <h1 className="text-5xl font-extrabold text-gray-900 sm:text-center">
              Pricing Plans
            </h1>
            <p className="mt-5 text-xl text-gray-700 sm:text-center flex-wrap max-w-xl mx-auto">
              Gain access to the vast arrange of added features such as the news
              index, dashboards, live data alongside many other features.
            </p>

            <h1 className=" text-xl font-extrabold text-gray-900 sm:text-center flex py-2 self-center justify-center px-2">
              Crypto Payment:
              <Switch
                checked={enabled}
                onChange={setEnabled}
                className={classNames(
                  enabled ? "bg-tertiary" : "bg-gray-200",
                  " mx-2 my-1 inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    enabled ? "translate-x-5 " : "translate-x-0",
                    "  pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200 "
                  )}
                />
              </Switch>
            </h1>

            <div className="relative mt-6 bg-gray-200 rounded-lg p-0.5 flex self-center sm:mt-8">
              {SubscriptionButtons.map((list) => (
                <button
                  key={list.id}
                  onClick={() => {
                    handleColor(list);
                    setPeriod(list.title);
                  }}
                  className={classNames(
                    list.title === period ? "bg-white" : "border-transparent",
                    "ml-0.5 relative border  rounded-md py-2  w-1/2 text-sm font-medium text-gray-700 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-wevr-Blue focus:z-10 sm:w-auto sm:px-8"
                  )}
                >
                  {list.title}
                </button>
              ))}
            </div>
          </div>
          <div className="mt-12 space-y-8 sm:mt-16  sm:space-y-0 sm:grid sm:grid-cols-1 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-4 ">
            {/* Base */}
            {base.map((tier) => (
              <div
                key={tier.name}
                className="border border-primaryContainer rounded-lg shadow-md divide-y divide-gray-200 bg-white"
              >
                <div className="p-6">
                  <h2 className="text-lg leading-6 font-medium text-gray-900">
                    {tier.name}
                  </h2>
                  <p className="mt-4 text-sm text-gray-500">
                    {tier.description}
                  </p>
                  <p className="mt-8">
                    {/* ternary conditions for each month, depending on usestate -> period, unsure if logic is most optimal as it is very messy */}

                    <span className="text-4xl font-monda text-gray-800">
                      {tier.price}
                      <span className="text-base font-roboto font-medium text-gray-500">
                        {getTier() ? (
                          <button
                            onClick={() => navigate("/UserSettings")}
                            className="w-full rounded-md bg-primary mt-10 py-2 text-white hover:bg-wevr-Accent-Light hover:shadow-md duration-75"
                          >
                            Logged In
                          </button>
                        ) : (
                          <button
                            onClick={() => navigate("/signin")}
                            className="w-full rounded-md bg-primary mt-10 py-2 text-white hover:bg-wevr-Accent-Light hover:shadow-md duration-75"
                          >
                            Sign Up
                          </button>
                        )}
                      </span>
                    </span>
                  </p>
                </div>

                <div className="pt-6 pb-8 px-6">
                  <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    What's included
                  </h3>
                  <ul className="mt-6 space-y-4">
                    {tier.features.map((feature) => (
                      <li key={feature} className="flex space-x-3">
                        <CheckIcon
                          className="flex-shrink-0 h-5 w-5 text-green-500"
                          aria-hidden="true"
                        />
                        <span className="text-sm text-gray-500">{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
            {tiers.map((tier) => (
              <PricingCard
                key={tier.name}
                name={tier.name}
                description={tier.description}
                period={period}
                enabled={enabled}
                priceMonthly={tier.priceMonthly}
                idMonthly={tier.idMonthly}
                priceQuarterly={tier.priceQuarterly}
                idQuarterly={tier.idQuarterly}
                priceYearly={tier.priceYearly}
                idYearly={tier.idYearly}
                features={tier.features}
                popular={tier.popular}
                sale={tier.sale}
                salePercentage={tier.salePercentage}
                couponName={tier.couponName}
                couponCode={tier.couponCode}
              />
            ))}
          </div>
          {/* Feature list */}
          <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-16 lg:px-8">
            <div className="max-w-3xl mx-auto text-center">
              <h2 className="text-3xl font-extrabold text-gray-900">
                All the data you need and want in one place.
              </h2>
              <p className="mt-4 text-lg text-gray-500">
                Wevr.ai prides itself on gathering all types of unique data to
                help traders find a potential edge in the market below are some
                features that differentiate ourselves from the rest.
              </p>
            </div>
          </div>
          <div className="relative mt-8 sm:max-w-5xl lg:max-w-4xl xl:max-w-7xl mx-auto bg-white/25">
            {/* Decorative background */}
            <div className=" border border-gray-200 flex rounded-lg shadow-sm p-8 z-0">
              <div className="absolute inset-0 -z-10 blur-md">
                <svg
                  className="w-full h-full"
                  width="1080"
                  height="416"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3576 24.95C3447.45 25.65 3321.92 46.07 3194.87 62.95C3135.23 70.86 3075.35 76.84 3015.2 79.01C2954.2 81.2 2894.77 80.91 2834.71 93.91C2742.37 113.91 2656.71 154.49 2567.33 183.76C2468.33 216.17 2367.6 228.89 2264.5 209.76C2107 180.54 1955.43 121.94 1802.56 75.49C1724.18 51.68 1645.03 29.08 1564.33 14.57C1501.88 3.34001 1435.33 -4.91997 1372.58 8.94003C1258.05 34.23 1145.08 63.69 1028.95 81.5C926.36 97.25 822.95 105.02 719.18 106.61C600.68 108.43 488.03 91.3 372.98 63.82C249.82 34.41 127.45 3.05001 -0.0200195 1.98001V0.430022C127.43 1.53002 249.81 32.88 372.98 62.26C487.98 89.71 600.64 106.86 719.13 105.07C822.86 103.49 926.28 95.78 1028.86 80.07C1145.03 62.28 1258.03 32.83 1372.58 7.37002C1435.29 -6.56997 1501.88 1.63004 1564.34 12.85C1645.04 27.35 1724.19 49.98 1802.54 73.85C1955.43 120.31 2106.98 179.02 2264.48 208.21C2367.58 227.33 2468.42 214.57 2567.48 182.15C2656.82 152.9 2742.37 112.34 2834.63 92.36C2894.72 79.36 2954.15 79.63 3015.21 77.47C3075.36 75.34 3135.21 69.34 3194.88 61.41C3321.94 44.5 3447.47 23.97 3576.05 23.41L3576 24.95Z"
                    fill="#FA9173"
                  />
                  <path
                    d="M3576 54.86C3448.63 57.27 3323.42 74.86 3197.16 89.86C3137.71 96.92 3078.08 102.41 3018.25 104.94C2957.56 107.5 2898.25 107.2 2838.57 120.13C2743.81 140.65 2655.57 181.84 2563.74 211.67C2464.09 244.04 2364.74 256.45 2261.05 237.32C2103.52 208.26 1951.39 152.08 1798.05 107.1C1719.31 84.02 1639.88 62.1 1559.05 47.57C1496.73 36.4 1430.15 27.47 1367.3 39.8C1253.48 62.12 1141.45 91.47 1026.76 109.8C924.488 126.197 821.19 135.383 717.63 137.29C598.99 139.47 486.57 122.51 371.55 94.29C248.84 64.21 127.19 32.47 0 31.86V30.04C127.23 30.66 248.94 62.38 371.65 92.42C486.7 120.58 599.16 137.53 717.81 135.42C821.402 133.566 924.736 124.444 1027.05 108.12C1141.85 89.79 1253.95 60.43 1367.85 37.93C1430.68 25.52 1497.26 34.42 1559.57 45.61C1640.4 60.11 1719.8 82.11 1798.51 105.25C1951.85 150.33 2103.94 206.68 2261.45 235.77C2365.08 254.91 2464.45 242.5 2564.04 210.14C2655.7 180.36 2743.7 139.22 2838.27 118.74C2897.99 105.8 2957.27 106.12 3017.95 103.57C3077.81 101.05 3137.47 95.51 3196.95 88.38C3323.29 73.23 3448.54 55.38 3576.01 53.08L3576 54.86Z"
                    fill="#FA9173"
                  />
                  <path
                    d="M3576 84.77C3450.73 87.86 3326.71 101.5 3202.1 113.44C3143.23 119.08 3084.26 123.55 3025.17 125.97C2964.17 128.47 2904.6 128.48 2844.61 141.49C2745.82 162.91 2653.42 205.07 2557.5 235.83C2456.17 268.33 2356.69 281.18 2251.25 262.42C2093.36 234.33 1940.25 181.08 1786.06 138.18C1706.77 116.13 1626.89 95.18 1545.75 81.03C1483.25 70.16 1416.61 61.03 1353.47 72.09C1241.23 91.78 1130.72 120.7 1018.25 139.64C916.95 156.69 814.89 167.19 712.16 169.42C593.98 171.97 482.37 154.8 368.09 125.6C246.63 94.57 126.37 62.21 0 61.77V59.68C126.44 60.09 246.78 92.43 368.38 123.39C482.74 152.5 594.38 169.65 712.65 167.12C815.46 164.92 917.65 154.52 1019.02 137.51C1131.67 118.62 1242.31 89.65 1354.68 69.79C1417.79 58.63 1484.43 67.79 1546.91 78.67C1628.02 92.82 1707.91 113.88 1787.11 136.02C1941.26 179.09 2094.26 232.55 2252.11 260.73C2357.39 279.53 2456.82 266.73 2558 234.25C2653.63 203.56 2745.63 161.48 2844.13 140.13C2904.08 127.13 2963.62 127.13 3024.57 124.64C3083.73 122.2 3142.75 117.64 3201.68 111.89C3326.43 99.7 3450.56 85.75 3576 82.68V84.77Z"
                    fill="#FA9173"
                  />
                  <path
                    d="M3576 114.68C3453.49 117.68 3331.43 126.39 3209.17 134.25C3151.23 137.97 3093.25 141.01 3035.22 142.95C2973.4 145.02 2913.22 145.63 2852.42 158.82C2748.3 181.44 2650.42 224.82 2549.11 256.82C2445.29 289.62 2344.37 303.44 2236.3 285.4C2077.76 258.93 1923.41 209.05 1768.1 168.7C1688.1 147.92 1607.63 128.15 1526.1 114.7C1463.18 104.33 1396.41 95.35 1332.84 105.4C1222.84 122.78 1114.42 150.97 1004.75 170.5C905.16 188.23 804.85 200.09 703.66 202.63C586.42 205.57 476.11 187.88 363.14 157.49C243.36 125.27 125.14 92.03 0.0500488 91.62V89.31C125.25 89.72 243.65 122.88 363.58 155C476.68 185.28 587.1 202.93 704.43 200C805.76 197.48 906.24 185.73 1005.98 168.05C1115.89 148.57 1224.51 120.31 1334.69 102.76C1398.22 92.64 1464.98 101.64 1527.86 112.06C1609.4 125.56 1689.86 145.44 1769.76 166.32C1924.93 206.91 2079.17 257.07 2237.63 283.69C2345.47 301.8 2446.24 288.07 2549.83 255.3C2650.66 223.41 2748.08 180.08 2851.74 157.57C2912.42 144.4 2972.58 143.85 3034.31 141.73C3092.44 139.73 3150.51 136.58 3208.53 132.73C3331 124.51 3453.24 115.37 3575.99 112.35L3576 114.68Z"
                    fill="#FA9173"
                  />
                  <path
                    d="M3576 144.59C3456.62 146.92 3337.26 149.98 3217.89 152.94C3100.02 155.86 2977.3 147.94 2861.6 173.08C2751.13 197.08 2646.72 242.08 2539.07 275.51C2432.14 308.74 2328.76 323.91 2217.38 306.91C2057.97 282.56 1902.14 236.36 1745.51 198.91C1664.74 179.61 1583.51 161.25 1501.39 148.7C1437.88 138.99 1370.92 130.47 1306.82 139.64C1199.57 155 1093.69 182.17 987.26 202.32C889.86 220.75 791.84 233.94 692.66 236.81C576.72 240.21 468.07 221.75 356.87 189.99C239.23 156.4 123.49 122.21 0 121.59V118.95C123.63 119.5 239.63 153.64 357.46 187.11C468.83 218.73 577.64 237.11 693.7 233.79C793.08 230.95 891.32 217.88 988.93 199.51C1095.65 179.42 1201.78 152.15 1309.28 136.62C1373.34 127.37 1440.28 135.95 1503.73 145.72C1585.82 158.35 1666.96 176.85 1747.66 196.28C1904.16 233.97 2059.86 280.51 2219.18 305.06C2330.24 322.17 2433.38 307.13 2540.01 273.94C2647.07 240.62 2750.87 195.75 2860.74 171.94C2976.42 146.86 3099.21 154.74 3217.07 151.37C3336.71 147.96 3456.34 144.37 3576 141.97V144.59Z"
                    fill="#FA9173"
                  />
                  <path
                    d="M3576 174.51C3459.85 175.81 3343.88 172.7 3227.77 170.14C3109.64 167.54 2987.85 159.95 2871.77 185.19C2754.24 210.74 2642.67 257.44 2527.91 292.49C2417.42 326.22 2310.91 342.95 2195.73 327.26C2035.28 305.38 1877.81 263.1 1719.73 228.87C1638.09 211.18 1556.08 194.36 1473.3 182.87C1409.07 173.94 1341.9 166.08 1277.18 174.5C1076.48 200.58 883.62 264.98 680.06 271.62C565.63 275.35 458.94 255.99 349.81 222.78C234.63 187.71 121.7 152.34 0 151.51V148.59C121.88 149.39 235.05 184.66 350.52 219.59C459.88 252.65 566.72 271.92 681.31 268.22C885.48 261.63 1078.9 197.49 1280.15 171.09C1344.81 162.61 1411.96 170.54 1476.15 179.54C1558.86 191.15 1640.78 208.13 1722.34 225.98C1880.27 260.53 2037.58 303.2 2197.92 325.33C2312.69 341.16 2418.92 324.59 2529.03 290.9C2643.03 256.02 2753.92 209.49 2870.74 184.1C2986.8 158.87 3108.74 166.5 3226.8 168.53C3343.23 170.53 3459.57 173.02 3576.03 171.61L3576 174.51Z"
                    fill="#FA9173"
                  />
                  <path
                    d="M3576 204.42C3462.88 204.52 3351 194.99 3238.3 186.5C3119.8 177.57 2999.3 170.6 2882.4 196.06C2757.4 223.3 2638.26 271.74 2516 308.51C2401.74 342.86 2291.56 361.14 2172.41 346.98C2010.81 327.77 1851.63 289.55 1692.03 258.64C1609.47 242.64 1526.63 227.45 1443.16 217.08C1378.16 209 1310.74 201.94 1245.34 209.63C1050.42 232.56 863.63 299.51 666.43 306.74C553.67 310.87 449.09 290.56 342.26 255.74C229.63 219.08 119.79 182.56 0 181.42V178.21C120 179.32 230.19 215.71 343.07 252.21C450.16 286.84 554.96 307.04 667.91 302.95C865.76 295.78 1053.25 229.1 1248.79 205.85C1314.11 198.08 1381.5 205.23 1446.42 213.4C1529.82 223.89 1612.57 239.27 1695.02 255.4C1854.46 286.66 2013.46 325.32 2174.94 344.82C2293.63 359.21 2403.43 341.11 2517.28 306.82C2638.73 270.24 2757.06 221.99 2881.28 194.93C2998.06 169.49 3118.73 176.56 3237.19 184.77C3350.19 192.62 3462.6 201.46 3576.03 201.23L3576 204.42Z"
                    fill="#FA9173"
                  />
                  <path
                    d="M3576 234.33C3347.55 232.04 3120.8 156.93 2893.21 206.64C2760.63 235.58 2633.83 285.79 2503.98 324.29C2444.64 341.88 2384.41 357.74 2322.98 366.15C2264.78 374.15 2206.98 372.45 2148.72 366.54C1985.92 350.04 1825.03 315.92 1663.87 288.38C1580.41 274.12 1496.72 260.55 1412.53 251.38C1346.75 244.17 1279.08 237.96 1213 244.92C1023.84 264.79 843.41 334.21 652.63 341.95C541.52 346.49 439.03 325.21 334.63 288.8C224.7 250.45 117.95 212.8 0.0400391 211.33V207.86C118.17 209.3 225.27 246.86 335.51 284.97C440.23 321.21 542.95 342.37 654.24 337.87C845.77 330.14 1026.95 261.05 1216.78 240.79C1282.78 233.79 1350.43 240.05 1416.12 247.36C1500.23 256.72 1583.82 270.48 1667.18 284.94C1828.18 312.86 1988.85 347.46 2151.51 364.27C2209.72 370.27 2267.35 372.01 2325.46 364.08C2386.52 355.73 2446.41 339.97 2505.4 322.45C2634.4 284.15 2760.31 234.15 2892 205.45C3119.23 155.84 3347.69 228.91 3576 230.91V234.33Z"
                    fill="#FA9173"
                  />
                  <path
                    d="M3576 264.21C3467.34 262.02 3365.33 239.97 3259.36 219.21C3140.44 195.88 3022.64 191.8 2903.74 217.82C2763.8 248.43 2629.54 300.35 2492.28 340.54C2430.08 358.76 2367.01 375.36 2302.73 384.36C2243.78 392.62 2185.07 391.43 2125.88 386.41C1961.88 372.53 1799.37 342.41 1636.71 318.19C1552.37 305.61 1467.86 293.64 1382.96 285.52C1316.42 279.15 1248.54 273.84 1181.77 279.92C997.92 296.66 824.06 368.64 639.26 376.92C529.76 381.86 429.26 359.72 327.16 321.67C219.83 281.67 116.16 242.94 0 241.18V237.44C116.37 239.17 220.42 277.77 328.06 317.56C430.46 355.42 531.18 377.45 640.88 372.56C826.4 364.3 1001.07 292.62 1185.57 275.56C1252.26 269.37 1320.1 274.78 1386.57 281.25C1471.38 289.5 1555.8 301.67 1640.03 314.45C1802.5 339.11 1964.83 369.69 2128.67 383.9C2187.76 389.02 2246.35 390.26 2305.22 382.04C2369.15 373.11 2431.88 356.6 2493.73 338.46C2630.08 298.46 2763.43 246.77 2902.47 216.35C3021.25 190.35 3139.19 194.7 3258.12 217.09C3364.48 237.09 3467.12 258.42 3576.02 260.49L3576 264.21Z"
                    fill="#FA9173"
                  />
                  <path
                    d="M3576 294.15C3515.91 292.47 3456.47 286.15 3397.92 272.26C3354.5 261.97 3312.07 248.05 3268.92 236.86C3150.38 206.12 3033.33 204.33 2913.61 230.56C2766.8 262.74 2625.61 316.24 2481.44 358.01C2416.62 376.8 2350.94 394.09 2284.03 403.63C2224.44 412.12 2164.94 411.28 2105.03 407.1C1939.92 395.57 1775.91 369.35 1611.87 348.2C1461.67 328.84 1305.06 302.91 1153.21 314.57C974.05 328.32 806.56 402.72 627.15 411.57C519.03 416.91 420.33 394.05 320.37 354.41C215.41 312.81 114.63 273.13 0 271.15V267.15C114.82 269.15 216 308.67 321.21 350.06C421.44 389.47 520.37 412.21 628.63 406.97C808.72 398.19 977.04 324.12 1156.78 309.97C1308.43 298.03 1465.05 324.47 1614.97 344.18C1778.84 365.73 1942.66 392.46 2107.62 404.28C2167.46 408.57 2226.86 409.45 2286.38 400.98C2352.95 391.51 2418.3 374.31 2482.79 355.6C2626.06 314.03 2766.37 260.73 2912.3 228.75C3031.94 202.54 3149.04 204.67 3267.67 234.43C3311.08 245.32 3353.79 258.86 3397.43 268.87C3456.19 282.34 3515.78 288.53 3575.98 290.16L3576 294.15Z"
                    fill="#FA9173"
                  />
                  <path
                    d="M3576 324.06C3516.62 322.18 3458.11 314.86 3401 298.23C3359.1 286.03 3318.67 269.48 3277.14 256.23C3159.53 218.61 3042.47 219.44 2922.37 245.8C2769.46 279.31 2622.12 334.21 2471.88 377.39C2404.83 396.66 2336.93 414.55 2267.79 424.53C2207.79 433.2 2147.73 432.53 2087.3 429.04C1921.22 419.48 1755.93 396.86 1590.67 378.51C1438.67 361.63 1282.13 339.31 1128.83 348.51C1039.2 353.9 952.48 372.44 866.28 396.73C784.13 419.88 702.57 440.87 616.9 445.45C509.9 451.16 412.65 427.81 314.5 386.69C211.63 343.61 113.43 303.13 0 301.06V296.77C113.63 298.84 212.12 339.21 315.27 382.11C413.65 423.02 511.06 446.32 618.27 440.66C704.14 436.12 786.01 415.31 868.42 392.28C954.99 368.09 1042.11 349.35 1132.05 343.71C1285.15 334.11 1441.68 356.93 1593.48 374.14C1758.52 392.87 1923.63 415.99 2089.63 425.8C2150.01 429.37 2209.98 430.06 2269.96 421.41C2338.82 411.49 2406.43 393.68 2473.19 374.47C2622.62 331.47 2769.14 276.76 2921.19 243.4C3041.28 217.06 3158.29 216.61 3276.06 253.3C3317.81 266.3 3358.51 282.5 3400.61 294.44C3457.93 310.7 3516.56 317.9 3576.02 319.77L3576 324.06Z"
                    className=" fill-test-white dark:fill-contrast-orange"
                  />
                  <path
                    d="M3576 353.97C3517 352.2 3459.13 344.34 3403.1 325.4C3362.42 311.65 3323.72 292.88 3283.52 277.87C3167.36 234.51 3049.52 238.03 2929.6 264.36C2771.74 299.03 2619.39 355.1 2464.17 399.41C2395.42 419.03 2325.84 437.41 2254.99 447.7C2194.79 456.46 2134.59 455.7 2073.99 452.7C1907.11 444.57 1740.83 425.21 1574.63 409.21C1421.15 394.48 1264.8 375.27 1110.35 381.53C1022.59 385.08 938.35 402.28 854.35 427.28C773.67 451.28 693.91 473.51 609.35 478.35C503.01 484.43 406.84 460.88 310.02 418.43C208.73 374.01 112.66 332.96 0.0200195 330.95V326.41C112.75 328.41 209.11 369.41 310.61 413.65C407.61 455.91 503.89 479.46 610.31 473.42C694.99 468.62 774.98 446.61 855.85 422.72C940.1 397.82 1024.71 380.43 1112.72 376.59C1267.02 369.84 1423.4 389.48 1576.72 404.52C1742.82 420.81 1908.97 440.66 2075.72 448.97C2136.33 451.97 2196.54 452.75 2256.72 443.97C2327.35 433.71 2396.72 415.4 2465.25 395.82C2619.79 351.67 2771.46 295.76 2928.65 261.24C3048.65 234.9 3166.31 231.77 3282.72 274.36C3323.1 289.13 3362.04 307.6 3402.89 321.14C3459.06 339.76 3517.04 347.57 3576.04 349.38L3576 353.97Z"
                    className="fill-test-white dark:fill-t-accent-DM"
                  />
                  <path
                    d="M3576 383.88C3517.14 382.63 3459.41 374.74 3404.05 354.1C3364.21 339.25 3326.84 318.78 3287.6 302.52C3173.28 255.14 3053.91 261.14 2934.94 287.28C2773.52 322.74 2617.57 379.64 2458.78 424.77C2389.02 444.59 2318.42 463.3 2246.56 473.77C2186.56 482.51 2126.62 481.29 2066.2 478.61C1898.84 471.2 1731.9 454.61 1565.02 440.49C1410.56 427.49 1254.44 410.73 1099.23 413.39C1012.75 414.88 930.23 430.99 847.85 456.64C767.92 481.55 689.28 504.95 605.13 510.05C498.97 516.48 403.33 493.12 307.2 449.51C206.89 403.99 112.32 362.63 0 360.89V356.06C112.34 357.86 207.1 399.19 307.53 444.54C403.71 487.99 499.4 511.4 605.53 505.03C689.69 499.97 768.46 476.76 848.46 452.03C931.02 426.48 1013.79 410.21 1100.46 408.37C1255.58 405.08 1411.75 422.15 1566.08 435.43C1732.88 449.79 1899.74 466.81 2067.08 474.29C2127.56 476.99 2187.57 478.12 2247.63 469.37C2319.39 458.92 2389.87 440.26 2459.52 420.45C2617.84 375.45 2773.32 318.64 2934.28 283.28C3053.44 257.1 3172.53 251.48 3287.17 298.35C3326.53 314.44 3364.09 334.68 3404.03 349.35C3459.47 369.76 3517.19 377.69 3576.03 379.05L3576 383.88Z"
                    className="fill-test-white dark:fill-t-accent-DM"
                  />
                  <path
                    d="M3576 413.79C3517.07 413.2 3459.32 405.56 3404.1 384.26C3364.4 368.94 3327.42 347.66 3288.38 330.85C3175.09 282.06 3055.17 289.79 2936.99 315.75C2774.2 351.51 2616.68 408.13 2456.43 453.3C2386.43 473.03 2315.6 491.77 2243.54 502.37C2183.54 511.2 2124.23 509.24 2063.87 506.45C1896.77 498.71 1729.97 484.45 1563.19 471.83C1408.35 460.07 1252.51 444.83 1097.11 443.97C1011.31 443.48 928.96 459.85 847.43 485.41C767.16 510.57 688.27 534.74 603.72 540.21C497.63 547.21 401.9 524.08 306.11 479.75C206.11 433.48 112.47 392.37 0.110107 390.83C0.110107 388.77 0.110107 387.74 0.110107 385.69C112.54 387.15 206.39 428.43 306.45 474.61C402.45 518.91 498.13 541.92 604.45 535.16C688.85 529.78 767.45 505.56 847.45 480.16C929.01 454.29 1011.08 438.4 1096.96 438.71C1252.41 439.28 1408.29 454.64 1563.15 466.71C1730.15 479.71 1897.15 494.36 2064.55 501.91C2124.7 504.63 2184.08 506.48 2243.85 497.75C2315.79 487.21 2386.63 468.4 2456.63 448.59C2616.89 403.21 2774.4 346.21 2937.27 310.42C3055.27 284.49 3175.63 276.89 3288.69 325.74C3327.59 342.55 3364.41 363.85 3403.98 379.15C3459.22 400.52 3517.03 408.09 3575.98 408.69L3576 413.79Z"
                    className="fill-test-white dark:fill-t-accent-DM"
                  />
                </svg>
              </div>
              <div className="w-full md:w-1/2 flex flex-col justify-between md:pr-12 z-0">
                <div className="text-4xl md:text-5xl font-semibold text-[#18181B] mb-4 flex justify-items-center">
                  <img
                    className="h-[40px] md:h-[48px] w-auto pr-4 self-center pt-2"
                    src={wevr}
                    alt=""
                  />
                  <div className="h-full text-center">Enterprise</div>
                </div>
                <div className="text-gray-800 text-md md:text-md break-words my-4">
                  For companies and teams, we offer custom built services with
                  access to the latest blocks, historical data and tools. We
                  also offer the ability to custom tailor our tools to your own
                  blockchain, creating the perfect service for you. Whether you
                  want to monitor transactions, track trends, optimize
                  performance, or generate insights, Wevr.ai can help you
                  achieve your goals. Contact us today!
                </div>
                <button
                  onClick={() => {
                    navigate("/Contact");
                  }}
                  className="bg-primary text-white shadow-sm hover:shadow-lg rounded-lg px-4 py-2 w-fit text-[min(4vw,1.25rem)] mt-4"
                >
                  Contact us
                </button>
              </div>
              <div className="w-1/2 md:flex hidden ml-10 justify-items-center">
                <ul className="space-y-4 flex flex-col justify-between">
                  {enterprise.map((feature) => (
                    <li key={feature} className="flex space-x-3 items-center">
                      <CheckIcon
                        className="flex-shrink-0 h-5 w-5 text-green-500"
                        aria-hidden="true"
                      />
                      <span className="text-md md:text-md text-gray-800">
                        {feature}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Payment;
