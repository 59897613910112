import { BellAlertIcon } from "@heroicons/react/24/outline";
import Bar from "./Bar";
import { PlusIcon } from "@heroicons/react/24/solid";
import NotificationDialog from "../Charting/NotificationDialog";
import { useState } from "react";
import classNames from "../../utils/ClassNames";

export default function Header({ currAlerts = 0, totalAlerts = 0 }) {
  const [openNotification, setOpenNotification] = useState(false);
  return (
    <div className="w-full flex-row justify-between flex my-2 border-b border-gray-500 pb-4">
      <div className="p-4 rounded-full bg-surfaceVariant text-center flex justify-center place-items-center">
        <BellAlertIcon className="md:w-10 md:h-10 h-8 w-8 text-onSurfaceVariant " />
      </div>
      <div className="flex-grow md:px-20 px-10 flex flex-col gap-2">
        <div className="text-lg md:text-xl font-semibold text-gray-600">
          {currAlerts}/{totalAlerts} Alerts
        </div>
        <Bar value={currAlerts} max={totalAlerts} />
      </div>
      <div className="flex items-center">
        <button
          className={classNames(
            "bg-surfaceVariant px-4 py-4 rounded-lg text-md md:text-lg flex items-center gap-4 add font-medium",
            currAlerts >= totalAlerts
              ? "bg-gray-200 text-gray-500"
              : "hover:bg-primary group"
          )}
          disabled={currAlerts >= totalAlerts}
          onClick={() => setOpenNotification(true)}
        >
          {currAlerts >= totalAlerts ? (
            <div>
              <div className="group-hover:text-white md:block hidden">
                Max Alerts!
              </div>

              <div className="group-hover:text-white md:hidden block">Max!</div>
            </div>
          ) : (
            <div className="flex items-center gap-4">
              <PlusIcon className="h-5 w-5 md:h-6 md:w-6 text-onSurfaceVariant group-hover:text-white" />
              <div className="group-hover:text-white md:block hidden">
                Add new!
              </div>
              <div className="group-hover:text-white md:hidden block">Add</div>
            </div>
          )}
        </button>
      </div>
      <NotificationDialog
        open={openNotification}
        setOpen={setOpenNotification}
        optimistic={true}
      />
    </div>
  );
}
