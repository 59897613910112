import { useNavigate } from "react-router";

function Error() {
  const navigate = useNavigate();
  return (
    <main className="grid min-h-fit place-items-center py-24 px-6 sm:py-32 lg:px-8 font-roboto">
      <div className="text-center">
        {/* <p className="text-2xl font-semibold text-primary">404</p> */}
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          Page not found
        </h1>
        <p className="mt-6 text-base leading-7 text-gray-600">
          Sorry, we couldn’t find the page you’re looking for.
        </p>
        <div className="mt-6 flex items-center justify-center gap-x-6">
          <button
            onClick={() => {
              navigate("/");
            }}
            className="rounded-lg bg-primaryContainer px-3 py-2 text-sm font-semibold text-black shadow-sm hover:text-white hover:bg-primary"
          >
            Go back home
          </button>
        </div>
      </div>
    </main>
  );
}

export default Error;
