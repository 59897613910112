import { Cog6ToothIcon, XMarkIcon } from "@heroicons/react/24/solid";
import NotificationDialog from "../Charting/NotificationDialog.js";
import { useState } from "react";
import DeleteDialog from "./DeleteDialog.js";
import UseCurrentValueQuery from "./UseCurrentValueQuery.js";
import { NavLink } from "react-router-dom";

export default function AlertCard({
  name,
  chart,
  blockchain,
  value,
  condition,
  resolution,
  source,
  freq,
  id,
}) {
  const [openNotification, setOpenNotification] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const { isLoading, isError, data } = UseCurrentValueQuery(
    blockchain,
    resolution,
    chart,
    source
  );
  function handleCurrentValue() {
    if (
      blockchain === null ||
      chart === null ||
      // selectedChart?.name === undefined ||
      resolution === null ||
      data?.data?.graphData === undefined
    ) {
      return "Waiting...";
    }
    if (isError) {
      return "Error";
    }
    if (isLoading) {
      return "Loading...";
    }

    return (
      data?.data?.graphData[0]?.data[1].toLocaleString("en-US", {
        maximumFractionDigits: 2,
      }) ?? "No data"
    );
    // return 1;
  }
  return (
    <div className="w-full flex flex-col gap-2 p-2 border border-500 shadow-md rounded-lg">
      <div className="w-full flex justify-between border-b py-2 text-gray-500 font-semibold leading-6 md:text-lg text-sm px-1">
        <div className="flex-grow grid grid-flow-col divide-x items-center">
          <div className="text-center md:block hidden">{name || "No Name"}</div>
          <NavLink
            className="text-center underline hover:text-primary"
            to={"/Explore?blockchain=" + blockchain}
          >
            {blockchain}
          </NavLink>
          <NavLink
            className="text-center underline hover:text-primary"
            to={
              "/Explore?blockchain=" +
              blockchain +
              "&name=" +
              chart +
              "&resolution=" +
              resolution +
              "&sma=None&ema=None&scale=Linear&type=Line"
            }
          >
            {chart}
          </NavLink>
        </div>
        <div className="border-l px-2 md:px-6 grid grid-flow-col gap-4 md:gap-6">
          <button
            className="rounded-lg border p-2 bg-surfaceVariant hover:bg-primary group"
            onClick={() => setOpenNotification(true)}
          >
            <Cog6ToothIcon className="h-5 w-5 md:h-6 md:w-6 text-onSurfaceVariant group-hover:text-white" />
          </button>
          <button
            className="rounded-lg border p-2 bg-surfaceVariant hover:bg-primary group"
            onClick={() => {
              setOpenDelete(true);
              // deleteNotification.mutate(id);
            }}
          >
            <XMarkIcon className="h-5 w-5 md:h-6 md:w-6 text-onSurfaceVariant group-hover:text-white" />
          </button>
        </div>
      </div>
      <div className="w-full border-b py-6 flex justify-center items-center gap-4 divide-x">
        <div className="flex gap-4 bg-primary/25 items-center justify-center rounded-lg text-3xl md:text-5xl p-4 text-center">
          <div>
            {value.toLocaleString("en-US", {
              maximumFractionDigits: 2,
            })}
          </div>
          <div className="italic">{condition}</div>
        </div>
        <div className="px-4 text-xl md:text-3xl h-full py-2 text-gray-500 flex flex-col items-center gap-2 text-center">
          <div className="text-gray-400 font-normal">Current Value</div>

          {handleCurrentValue()}
        </div>
      </div>
      <div className="w-full grid grid-flow-col divide-x py-2 text-gray-500 font-semibold leading-6 md:text-lg text-md">
        <div className="justify-center flex flex-col items-center gap-1">
          <div className="text-gray-400 font-normal">Resolution</div>
          {resolution}
        </div>
        <div className="justify-center flex flex-col items-center gap-1">
          <div className="text-gray-400 font-normal">Source</div>
          {source}
        </div>
        <div className="justify-center flex flex-col items-center gap-1">
          <div className="text-gray-400 font-normal">Frequency</div>
          {freq}
        </div>
      </div>
      <NotificationDialog
        open={openNotification}
        setOpen={setOpenNotification}
        edit={true}
        blockchain={blockchain}
        chartName={chart}
        resolution={resolution}
        sourceProp={source}
        conditionProp={condition}
        frequencyProp={freq}
        valueProp={value}
        alertNameProp={name}
        id={id}
      />
      <DeleteDialog open={openDelete} setOpen={setOpenDelete} id={id} />
    </div>
  );
}
