// Created by Matt Webb
// main News page that holds the query and calls the sub components
import { useQuery } from "@tanstack/react-query";
import ApiService from "../../Api/ApiService";
import { useState } from "react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import dayjs from "dayjs";
import ProgressBar from "./ProgressBar";
import SentimentBar from "../Charting/SentimentBar";
import { Tooltip } from "react-tooltip";
import Error from "../../Error.js";
import NewsChartContainer from "./NewsChartContainer";
function calculateText(value) {
  if (value == -5) {
    return "Terrible";
  } else if (value <= -2) {
    return "Poor";
  } else if (value < 2) {
    return "Neutral";
  } else if (value < 5) {
    return "Good";
  } else {
    return "Excellent";
  }
}
function News() {
  // thing we want to assoicate with button

  const [page, setPage] = useState(0);
  const {
    isLoading: statsLoading,
    isError: statsIsError,
    isFetching,
    isSuccess,
    data: statsData2,
  } = useQuery({
    queryKey: ["news", page],
    queryFn: async () => ApiService.getNewsPage(page),
    keepPreviousData: true,
  });
  const prevPage = () => {
    setPage((page) => page - 1);
    window.scrollTo(0, 0);
  };

  const nextPage = () => {
    setPage((page) => page + 1);
    window.scrollTo(0, 0);
  };

  if (statsLoading || isFetching) {
    return (
      <div className="max-w-7xl mx-auto font-roboto px-4">
        <div className="py-4 flex flex-col gap-2">
          <div className="grid md:grid-cols-12 bg-white mt-2 border-secondary border-2 text-roboto rounded-lg gap-2 w-full h-56">
            <div className="hidden md:block md:col-span-3 relative bg-gray-300 m-6 rounded-lg animate-pulse"></div>
            <div className="hidden md:block md:col-span-4 relative bg-gray-300 m-6 rounded-lg animate-pulse"></div>
            <div className="hidden md:block md:col-span-3 relative bg-gray-300 m-6 rounded-lg animate-pulse"></div>
            <div className="hidden md:block md:col-span-2 relative bg-gray-300 m-6 rounded-lg animate-pulse"></div>
            <div className="block md:hidden relative bg-gray-300 m-6 rounded-lg animate-pulse"></div>
          </div>
          <div className="grid md:grid-cols-12 bg-white mt-2 border-secondary border-2 text-roboto rounded-lg gap-2 w-full h-56">
            <div className="hidden md:block md:col-span-3 relative bg-gray-300 m-6 rounded-lg animate-pulse"></div>
            <div className="hidden md:block md:col-span-4 relative bg-gray-300 m-6 rounded-lg animate-pulse"></div>
            <div className="hidden md:block md:col-span-3 relative bg-gray-300 m-6 rounded-lg animate-pulse"></div>
            <div className="hidden md:block md:col-span-2 relative bg-gray-300 m-6 rounded-lg animate-pulse"></div>
            <div className="block md:hidden relative bg-gray-300 m-6 rounded-lg animate-pulse"></div>
          </div>
          <div className="grid md:grid-cols-12 bg-white mt-2 border-secondary border-2 text-roboto rounded-lg gap-2 w-full h-56">
            <div className="hidden md:block md:col-span-3 relative bg-gray-300 m-6 rounded-lg animate-pulse"></div>
            <div className="hidden md:block md:col-span-4 relative bg-gray-300 m-6 rounded-lg animate-pulse"></div>
            <div className="hidden md:block md:col-span-3 relative bg-gray-300 m-6 rounded-lg animate-pulse"></div>
            <div className="hidden md:block md:col-span-2 relative bg-gray-300 m-6 rounded-lg animate-pulse"></div>
            <div className="block md:hidden relative bg-gray-300 m-6 rounded-lg animate-pulse"></div>
          </div>
          <div className="grid md:grid-cols-12 bg-white mt-2 border-secondary border-2 text-roboto rounded-lg gap-2 w-full h-56">
            <div className="hidden md:block md:col-span-3 relative bg-gray-300 m-6 rounded-lg animate-pulse"></div>
            <div className="hidden md:block md:col-span-4 relative bg-gray-300 m-6 rounded-lg animate-pulse"></div>
            <div className="hidden md:block md:col-span-3 relative bg-gray-300 m-6 rounded-lg animate-pulse"></div>
            <div className="hidden md:block md:col-span-2 relative bg-gray-300 m-6 rounded-lg animate-pulse"></div>
            <div className="block md:hidden relative bg-gray-300 m-6 rounded-lg animate-pulse"></div>
          </div>
          <div className="grid md:grid-cols-12 bg-white mt-2 border-secondary border-2 text-roboto rounded-lg gap-2 w-full h-56">
            <div className="hidden md:block md:col-span-3 relative bg-gray-300 m-6 rounded-lg animate-pulse"></div>
            <div className="hidden md:block md:col-span-4 relative bg-gray-300 m-6 rounded-lg animate-pulse"></div>
            <div className="hidden md:block md:col-span-3 relative bg-gray-300 m-6 rounded-lg animate-pulse"></div>
            <div className="hidden md:block md:col-span-2 relative bg-gray-300 m-6 rounded-lg animate-pulse"></div>
            <div className="block md:hidden relative bg-gray-300 m-6 rounded-lg animate-pulse"></div>
          </div>
          <div className="grid md:grid-cols-12 bg-white mt-2 border-secondary border-2 text-roboto rounded-lg gap-2 w-full h-56">
            <div className="hidden md:block md:col-span-3 relative bg-gray-300 m-6 rounded-lg animate-pulse"></div>
            <div className="hidden md:block md:col-span-4 relative bg-gray-300 m-6 rounded-lg animate-pulse"></div>
            <div className="hidden md:block md:col-span-3 relative bg-gray-300 m-6 rounded-lg animate-pulse"></div>
            <div className="hidden md:block md:col-span-2 relative bg-gray-300 m-6 rounded-lg animate-pulse"></div>
            <div className="block md:hidden relative bg-gray-300 m-6 rounded-lg animate-pulse"></div>
          </div>
          <div className="grid md:grid-cols-12 bg-white mt-2 border-secondary border-2 text-roboto rounded-lg gap-2 w-full h-56">
            <div className="hidden md:block md:col-span-3 relative bg-gray-300 m-6 rounded-lg animate-pulse"></div>
            <div className="hidden md:block md:col-span-4 relative bg-gray-300 m-6 rounded-lg animate-pulse"></div>
            <div className="hidden md:block md:col-span-3 relative bg-gray-300 m-6 rounded-lg animate-pulse"></div>
            <div className="hidden md:block md:col-span-2 relative bg-gray-300 m-6 rounded-lg animate-pulse"></div>
            <div className="block md:hidden relative bg-gray-300 m-6 rounded-lg animate-pulse"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto font-roboto px-4">
      <div>
        {statsIsError ? (
          <Error />
        ) : (
          <div className="flex flex-col gap-2 py-4">
            <div className="hidden md:grid md:grid-cols-12 gap-2 md:text-xl text-center text-gray-900 py-2 border-secondary border-b rounded-t-lg">
              <div className="md:col-span-3 border-r px-4">Post</div>
              <div className="md:col-span-4 border-r px-4">Summary</div>

              <div className="md:col-span-3 border-r px-4">Related Chart</div>

              <div className="sentiment md:col-span-2 flex justify-center">
                <div className="flex items-center gap-1">
                  Sentiment{" "}
                  <InformationCircleIcon className="h-[18px] w-[18px] " />
                  {/* </Tooltip> */}
                  <Tooltip
                    anchorSelect=".sentiment"
                    place="bottom"
                    className="text-xs"
                  >
                    Sentiment is not always accurate and does not indicate
                    journalistic bias. This sentiment does not dictate the views
                    of journalistic outlets nor associated authors.
                  </Tooltip>
                </div>

                {/* <Tooltip
                    content="sentiment is not always accurate and does not indicate journalistic bias. This sentiment does not dictate the views of journalistic outlets nor associated authors "
                    direction="bottom"
                  > */}
              </div>
            </div>
            {statsData2.data.news.map((data) => (
              <div
                key={data.id}
                className="grid md:grid-cols-12 bg-white mt-2 border-primaryContainer border shadow-md text-roboto rounded-lg gap-2 py-2"
              >
                <div className="md:col-span-3 p-2 border-b md:border-r  md:border-b-0 px-6">
                  <a href={data.link} target="_blank" rel="noopener noreferrer">
                    <div className="relative mx-auto rounded-lg overflow-hidden">
                      <img
                        src={
                          data?.media_content != undefined
                            ? data?.media_content[0]["url"]
                            : ""
                        }
                        alt=""
                        className="object-contain"
                      />
                    </div>

                    <h1 className="text-md text-gray-600">{data.author}</h1>
                    <h2 className="text-sm text-gray-600">
                      Posted:{" "}
                      {dayjs(data.published * 1000).format("MM/DD/YYYY")}
                    </h2>
                  </a>
                </div>

                <div className="md:col-span-4 p-2 border-b md:border-r md:border-b-0 px-6">
                  <div className="text-lg lg:text-xl text-gray-900">
                    {data.title}
                  </div>
                  <div className="text-sm lg:text-md text-gray-600 mt-2">
                    {data.summary}
                  </div>
                </div>
                {data.blockchainChart ? (
                  <div className="md:col-span-3 px-2 flex items-center justify-center border-b md:border-r md:border-b-0">
                    <div className="block md:hidden lg:block relative w-full">
                      <NewsChartContainer
                        graphName={data.chartname}
                        blockchain={
                          data.blockchainChart.charAt(0).toUpperCase() +
                          data.blockchainChart.slice(1)
                        }
                        timePeriod={"14d"}
                        graphHeight={"140px"}
                        isSuccess={isSuccess}
                      />
                    </div>
                    <div className="hidden md:block lg:hidden relative w-full">
                      <NewsChartContainer
                        graphName={data.chartname}
                        blockchain={
                          data.blockchainChart.charAt(0).toUpperCase() +
                          data.blockchainChart.slice(1)
                        }
                        timePeriod={"14d"}
                        graphHeight={"100px"}
                        isSuccess={isSuccess}
                      />
                    </div>
                  </div>
                ) : (
                  <div>error</div>
                )}

                <div className="md:col-span-2 flex items-center justify-center ">
                  <div className="block md:hidden pb-2 relative px-4 w-full">
                    <div className=" mb-1 text-gray-600 ">
                      Sentiment: {calculateText(data.Sentiment)}{" "}
                    </div>
                    <SentimentBar value={data.Sentiment} />
                  </div>
                  <div className="hidden lg:block">
                    <ProgressBar
                      trackColor="#E6DCDC"
                      indicatorColor="#FA9173"
                      size={150}
                      progress={data.Sentiment}
                      trackWidth={10}
                      indicatorWidth={10}
                      spinnerMode={false}
                      secondLabel={"Neutral"}
                    />
                  </div>
                  <div className="hidden md:block lg:hidden">
                    <ProgressBar
                      trackColor="#E6DCDC"
                      indicatorColor="#FA9173"
                      size={90}
                      progress={data.Sentiment}
                      trackWidth={10}
                      indicatorWidth={10}
                      spinnerMode={false}
                      secondLabel={"Neutral"}
                    />
                    <div className="text-center text-bold text-2xl mt-2">
                      {calculateText(data.Sentiment)}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      {/*<GaugeSeries />*/}

      <nav
        className="flex items-center justify-between border-secondary border-t p-2 rounded-b-lg"
        aria-label="Pagination"
      >
        <div className="hidden sm:block">
          <p className="text-sm text-gray-600">
            Showing <span className="font-medium">Page : {page + 1} </span> of{" "}
            <span className="font-medium">{statsData2?.data.pages - 1}</span>
          </p>
        </div>
        <div className="flex justify-between sm:justify-end gap-4">
          <button
            disabled={page === 0}
            onClick={prevPage}
            className="bg-surfaceVariant px-4 py-2 text-black rounded-lg shadow-md hover:bg-primary hover:text-white border border-primaryContainer"
          >
            Previous
          </button>

          <button
            disabled={page === statsData2?.data.pages}
            onClick={nextPage}
            className="bg-surfaceVariant px-4 py-2 text-black rounded-lg shadow-md hover:bg-primary hover:text-white border border-primaryContainer"
          >
            Next
          </button>
        </div>
      </nav>
    </div>
  );
}

export default News;
