import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

export default function NetworkNotificationDialog({ open, setOpen, onSubmit }) {
  const cancelButtonRef = useRef(null);
  const [minValue, setMinValue] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const handleSubmit = () => {
    onSubmit(minValue, startDate, endDate);
    setOpen(false); // Close the dialog
  };
  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="text-center sm:mt-0 sm:text-left w-full">
                        {/* Title and close button */}
                        <div className="flex justify-between items-center mb-4">
                          {/* Your existing title and close button setup */}
                        </div>

                        {/* Input fields with labels */}
                        <div className="space-y-4">
                          <div className="flex items-center">
                            <label
                              htmlFor="minValue"
                              className="block text-sm font-medium text-gray-700 mr-4"
                            >
                              Minimum Value in Eth:
                            </label>
                            <input
                              type="number"
                              placeholder="0"
                              value={minValue}
                              onChange={(e) => setMinValue(e.target.value)}
                              className="flex-1 block w-full border border-gray-300 rounded-lg py-2 px-3 focus:border-primary focus:ring-primary"
                            />
                          </div>

                          <div className="flex items-center">
                            <label
                              htmlFor="startDate"
                              className="block text-sm font-medium text-gray-700 mr-4"
                            >
                              Start Date:
                            </label>
                            <input
                              type="date"
                              value={startDate}
                              onChange={(e) => setStartDate(e.target.value)}
                              className="flex-1 block w-full border border-gray-300 rounded-lg py-2 px-3 focus:border-primary focus:ring-primary"
                            />
                          </div>

                          <div className="flex items-center">
                            <label
                              htmlFor="endDate"
                              className="block text-sm font-medium text-gray-700 mr-4"
                            >
                              End Date:
                            </label>
                            <input
                              type="date"
                              value={endDate}
                              onChange={(e) => setEndDate(e.target.value)}
                              className="flex-1 block w-full border border-gray-300 rounded-lg py-2 px-3 focus:border-primary focus:ring-primary"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-primaryDark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={handleSubmit}
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
