// Created by James Launder
// provides the options for each additional chart once it is added to the chart.

import { Fragment, useState, useEffect, useRef } from "react";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { XMarkIcon } from "@heroicons/react/24/outline";
import ColourDropdown from "./ColourDropdown";
import { useSearchParams } from "react-router-dom";
import { useStoreQuery } from "./useStoreQuery";

function AdditionalOptions(props) {
  const DELIMITTER = "_";
  const [searchParams, setSearchParams] = useSearchParams();
  const firstRender = useRef(true);
  const [selectedColour, setSelectedColour] = useState(props.data.color);
  const queryState = useStoreQuery((state) => state.queryUrl);
  const removeQuery = useStoreQuery((state) => state.removeQuery);

  // finds the section of the URL relevant to this data and removes it.
  function deleteParamsEntry(params) {
    const key = "cmp";
    var value = "";
    if (props.data.source === null) {
      value =
        props.data.blockchain +
        DELIMITTER +
        props.data.name +
        DELIMITTER +
        props.data.resolution +
        DELIMITTER +
        props.data.color.name +
        DELIMITTER +
        props.data.style.name +
        DELIMITTER +
        props.data.scale.name;
      // gets all params and returns all params that dont fit the key and value
      const newEntries = Array.from(params.entries()).filter(
        ([k, v]) => !(k === key && v === value)
      );
      setSearchParams(newEntries);
    } else {
      value =
        props.data.blockchain +
        DELIMITTER +
        props.data.name +
        DELIMITTER +
        props.data.resolution +
        DELIMITTER +
        props.data.color.name +
        DELIMITTER +
        props.data.style.name +
        DELIMITTER +
        props.data.scale.name +
        DELIMITTER +
        props.data.source;
      const newEntries = Array.from(params.entries()).filter(
        ([k, v]) => !(k === key && v === value)
      );
      setSearchParams(newEntries);
    }
  }

  // very similar to delete but removes it then readds it with the changes to the url
  function updateSearchParams(params, newValue, keyChange) {
    const key = "cmp";
    var value = "";
    var updated = "";
    // create the new thing here!!!
    if (props.data.source === null) {
      switch (
        keyChange // certain key set value correctly
      ) {
        case "colour":
          updated =
            props.data.blockchain +
            DELIMITTER +
            props.data.name +
            DELIMITTER +
            props.data.resolution +
            DELIMITTER +
            newValue +
            DELIMITTER +
            props.data.style.name +
            DELIMITTER +
            props.data.scale.name;
          break;
        case "style":
          updated =
            props.data.blockchain +
            DELIMITTER +
            props.data.name +
            DELIMITTER +
            props.data.resolution +
            DELIMITTER +
            props.data.color.name +
            DELIMITTER +
            newValue +
            DELIMITTER +
            props.data.scale.name;
          break;
        case "scale":
          updated =
            props.data.blockchain +
            DELIMITTER +
            props.data.name +
            DELIMITTER +
            props.data.resolution +
            DELIMITTER +
            props.data.color.name +
            DELIMITTER +
            props.data.style.name +
            DELIMITTER +
            newValue;
          break;
      }
    } else {
      switch (
        keyChange // certain key set value correctly
      ) {
        case "colour":
          updated =
            props.data.blockchain +
            DELIMITTER +
            props.data.name +
            DELIMITTER +
            props.data.resolution +
            DELIMITTER +
            newValue +
            DELIMITTER +
            props.data.style.name +
            DELIMITTER +
            props.data.scale.name +
            DELIMITTER +
            props.data.source;
          break;
        case "style":
          updated =
            props.data.blockchain +
            DELIMITTER +
            props.data.name +
            DELIMITTER +
            props.data.resolution +
            DELIMITTER +
            props.data.color.name +
            DELIMITTER +
            newValue +
            DELIMITTER +
            props.data.scale.name +
            DELIMITTER +
            props.data.source;
          break;
        case "scale":
          updated =
            props.data.blockchain +
            DELIMITTER +
            props.data.name +
            DELIMITTER +
            props.data.resolution +
            DELIMITTER +
            props.data.color.name +
            DELIMITTER +
            props.data.style.name +
            DELIMITTER +
            newValue +
            DELIMITTER +
            props.data.source;
          break;
      }
    }
    if (props.data.source === null) {
      value =
        props.data.blockchain +
        DELIMITTER +
        props.data.name +
        DELIMITTER +
        props.data.resolution +
        DELIMITTER +
        props.data.color.name +
        DELIMITTER +
        props.data.style.name +
        DELIMITTER +
        props.data.scale.name;
      var newEntries = Array.from(params.entries()).filter(
        ([k, v]) => !(k === key && v === value)
      );
      newEntries.push(["cmp", updated]);
      setSearchParams(newEntries);
    } else {
      value =
        props.data.blockchain +
        DELIMITTER +
        props.data.name +
        DELIMITTER +
        props.data.resolution +
        DELIMITTER +
        props.data.color.name +
        DELIMITTER +
        props.data.style.name +
        DELIMITTER +
        props.data.scale.name +
        DELIMITTER +
        props.data.source;
      const newEntries = Array.from(params.entries()).filter(
        ([k, v]) => !(k === key && v === value)
      );
      newEntries.push(["cmp", updated]);

      setSearchParams(newEntries);
    }
  }
  // updates for the internal states
  const updateColour = (updated, name, blockchain, source, resolution) => {
    props.setAdditionalCharts((prevState) => {
      const newState = [...prevState];
      newState[props.index].color = updated;
      return newState;
    });
    props.setUpdateChart({
      key: "color",
      name: name,
      blockchain: blockchain,
      update: updated,
      source: source,
      resolution: resolution,
    });
  };
  const updateStyle = (updated, name, blockchain, source, resolution) => {
    updateSearchParams(searchParams, updated.name, "style");
    props.setAdditionalCharts((prevState) => {
      const newState = [...prevState];
      newState[props.index].style = updated;
      return newState;
    });
    props.setUpdateChart({
      key: "style",
      name: name,
      blockchain: blockchain,
      update: updated,
      source: source,
      resolution: resolution,
    });
  };
  const updateScale = (updated, name, blockchain, source, resolution) => {
    updateSearchParams(searchParams, updated.name, "scale");
    props.setAdditionalCharts((prevState) => {
      const newState = [...prevState];
      newState[props.index].scale = updated;
      return newState;
    });
    props.setUpdateChart({
      key: "scale",
      name: name,
      blockchain: blockchain,
      update: updated,
      source: source,
      resolution: resolution,
    });
  };

  // removes the chart from url, internal state and querystate
  const deleteChart = () => {
    var queryIndex = -1;
    if (props.data.source === null) {
      queryIndex = queryState.findIndex(
        (obj) =>
          obj.key ===
          props.data.blockchain + props.data.resolution + props.data.name
      );

      deleteParamsEntry(searchParams);
    } else {
      queryIndex = queryState.findIndex(
        (obj) =>
          obj.key ===
          props.data.blockchain +
            props.data.resolution +
            props.data.name +
            props.data.source
      );
      deleteParamsEntry(searchParams);
    }

    props.setRemove({
      name: props.data.name,
      blockchain: props.data.blockchain,
      source: props.data.source,
      resolution: props.data.resolution,
    });
    props.setAdditionalCharts((prevState) => {
      const newState = [...prevState];
      newState.splice(props.index, 1);
      return newState;
    });
    removeQuery(queryIndex);
  };

  // updates the correct params when something is changed.
  // if this runs on first load it can delete the param or not allow the change to occur
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    updateSearchParams(searchParams, selectedColour.name, "colour");

    updateColour(
      selectedColour,
      props.data.name,
      props.data.blockchain,
      props.data.source,
      props.data.resolution
    );
  }, [selectedColour]);

  return (
    <div className="font-roboto w-full">
      {props.graphData[props.index + 2]?.isLoading ||
      props.graphData[props.index + 2]?.isFetching ? (
        <div className="inline-block text-left animate-pulse">
          <div
            style={{
              boxSizing: "border-box",
              boxShadow: "inset 0px 0px 0px 2px " + props.data.color.hex,
            }}
            className="group flex place-content-center flex-row px-3 py-2 rounded-lg md:border-0 border-2 focus:outline-none"
          >
            Loading...
          </div>
        </div>
      ) : (
        <div>
          {props.graphData[props.index + 2]?.isError ? (
            <div className="inline-block text-left ">
              <button
                style={{
                  boxSizing: "border-box",
                  boxShadow: "inset 0px 0px 0px 2px " + props.data.color.hex,
                }}
                className="group flex place-content-center flex-row px-3 py-2 rounded-lg md:border-0 border-2 focus:outline-none hover:bg-primary/30"
                onClick={() => {
                  var queryIndex = -1;
                  if (props.data.source === null) {
                    queryIndex = queryState.findIndex(
                      (obj) =>
                        obj.key ===
                        props.data.blockchain +
                          props.data.resolution +
                          props.data.name
                    );
                    deleteParamsEntry(searchParams);
                  } else {
                    queryIndex = queryState.findIndex(
                      (obj) =>
                        obj.key ===
                        props.data.blockchain +
                          props.data.resolution +
                          props.data.name +
                          props.data.source
                    );
                    deleteParamsEntry(searchParams);
                  }
                  props.setAdditionalCharts((prevState) => {
                    const newState = [...prevState];
                    newState.splice(props.index, 1);
                    return newState;
                  });
                  removeQuery(queryIndex);
                }}
              >
                <div>Error!</div>
                <XMarkIcon
                  className="h-6 w-6 group-hover:text-white"
                  aria-hidden="true"
                />
              </button>
            </div>
          ) : (
            <Popover as="div" className="inline-block text-left ">
              {({ open }) => (
                <>
                  <div>
                    <Popover.Button
                      style={{
                        boxSizing: "border-box",
                        boxShadow:
                          "inset 0px 0px 0px 2px " + props.data.color.hex,
                      }}
                      className={`${
                        open ? "bg-primary/30 text-black" : "bg-white shadow-xl"
                      } group hidden md:grid place-content-center grid-flow-col px-3 py-2 hover:bg-primary/30 rounded-lg focus:outline-none`}
                    >
                      <div className="hidden md:grid grid-flow-col md:whitespace-nowrap md:overflow-visible">
                        {props.data.name}
                        <ChevronDownIcon
                          className={`${
                            open ? " text-black" : "text-onSurfaceVariant"
                          } h-5 w-5 ml-2 self-center text-onSurfaceVariant `}
                          aria-hidden="true"
                        />
                      </div>
                    </Popover.Button>
                  </div>
                  {open && (
                    <Transition
                      as={Fragment}
                      enter="transition-opacity ease-out duration-400"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Popover.Panel className="transform transition-all absolute px-2 mt-2 z-10 text-sm md:text-base divide-y divide-secondary rounded-lg bg-white border-2 border-secondary bg-opacity-70 backdrop-blur shadow-lg">
                        <div className="px-2 py-2 flex gap-2 justify-between items-center">
                          <div className="flex gap-2 py-1.5">
                            <h1 className="text-gray-700 min-w-[78px]">
                              Blockchain
                            </h1>
                            <div>{props.data.blockchain}</div>
                          </div>
                        </div>
                        <div className="px-2 py-2 flex gap-2 items-center">
                          <div className="flex gap-2 py-1.5">
                            <h1 className="text-gray-700 min-w-[78px]">
                              Resolution
                            </h1>
                            <div>{props.data.resolution}</div>
                          </div>
                        </div>
                        <div className="px-2 py-2 flex gap-2 items-center">
                          <h1 className="text-gray-700 pt-1 min-w-[78px]">
                            Colour
                          </h1>
                          <div className="w-full mb-1">
                            <ColourDropdown
                              title={""}
                              data={props.colour}
                              selected={selectedColour}
                              setSelected={setSelectedColour}
                            />
                          </div>
                        </div>

                        <div className="px-2 py-3 flex gap-2 items-center">
                          <h1 className="text-gray-700 min-w-[78px]">Style</h1>
                          <span className="grid grid-flow-col rounded-lg bg-white text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 w-full ">
                            {props.style.map((data) => (
                              <button
                                type="button"
                                key={data.name + " combo"}
                                onClick={() => {
                                  updateStyle(
                                    data,
                                    props.data.name,
                                    props.data.blockchain,
                                    props.data.source,
                                    props.data.resolution
                                  );
                                }}
                                className={`${
                                  props.data.style.name === data.name
                                    ? "bg-primary text-white text-bold"
                                    : "text-gray-900 hover:bg-primary/30 hover:text-gray-900"
                                } rounded-lg py-1.5 text-gray-900 `}
                              >
                                {data.name}
                              </button>
                            ))}
                          </span>
                        </div>
                        <div className="px-2 py-3 flex gap-2 items-center">
                          <h1 className="text-gray-700 min-w-[78px]">Scale</h1>

                          <span className="grid grid-flow-col rounded-lg bg-white text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 w-full">
                            {props.scale.map((data) => (
                              <button
                                type="button"
                                key={data.name + " combo"}
                                onClick={() => {
                                  updateScale(
                                    data,
                                    props.data.name,
                                    props.data.blockchain,
                                    props.data.source,
                                    props.data.resolution
                                  );
                                }}
                                className={`${
                                  props.data.scale.name === data.name
                                    ? "bg-primary text-white text-bold"
                                    : "text-gray-900 hover:bg-primary/30 hover:text-gray-900"
                                } rounded-lg py-1.5 text-gray-900 `}
                              >
                                {data.name}
                              </button>
                            ))}
                          </span>
                        </div>
                        <div className="px-2 py-2 flex gap-2 items-center bg-gray-50">
                          <div className="flex gap-2">
                            <button
                              className="py-2 px-3 bg-red-400 hover:bg-red-500 text-white rounded-lg"
                              onClick={() => {
                                deleteChart();
                              }}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  )}
                </>
              )}
            </Popover>
          )}
        </div>
      )}
    </div>
  );
}

export default AdditionalOptions;
