// Missing watermark redraw as breaks pagezzzzzz
import React, { useRef } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import watermarkIcon from "../../../Assets/WevrLogoSecondary.svg";
const ProfitChart = ({ data, name = "Eth" }) => {
  // Check if data is valid
  if (!data || !Array.isArray(data) || data.length === 0) {
    return <p>No data available</p>;
  }
  var watermark = "";

  const chartRef = useRef(null);
  var newStyle = document.createElement("style");
  newStyle.appendChild(
    document.createTextNode(
      "\
@font-face {\
  font-family: 'Monda';\
  font-style: sans-serif;\
  font-weight: 400;\
  src: url('https://fonts.googleapis.com/css2?family=Monda&display=swap')\
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;\
}\
"
    )
  );

  const dataSource = [
    {
      id: "first",
      name: name,
      data: data,
      zones: [
        {
          value: 0,
          color: "#f54242",
          fillColor: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: [
              [0, "rgba(255, 105, 104, 0)"],
              [1, "rgba(255, 105, 104, 0.3)"],
            ],
          },
        },
        {
          color: "#51bf36",
          fillColor: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: [
              [0, "rgba(34, 197, 94, 0.3)"],
              [1, "rgba(34, 197, 94, 0)"],
            ],
          },
        },
      ],
    },
  ];

  const options = {
    exporting: {
      enabled: false,
      enableImages: true,
    },
    chart: {
      type: "areaspline",
      style: {
        fontFamily: "Monda",
      },
      zoomType: "x",
      backgroundColor: "transparent",
      renderTo: this,
      events: {
        load: function () {
          var height_image = 100;
          var width_image = 152;
          // var height_image = 48;
          // var width_image = 20;
          var textX = this.plotLeft + this.plotWidth * 0.5;
          var textY = this.plotTop + this.plotHeight * 0.5;
          var position_x = textX - width_image / 2;
          var position_y = textY - height_image / 2;
          watermark = this.renderer
            .image(watermarkIcon, position_x, position_y, 152, 100)
            .add();
        },
      },
    },

    title: {
      text: undefined,
    },
    xAxis: {
      labels: {
        style: {
          fontSize: "13px",
        },
      },
    },
    yAxis: {
      opposite: false,
      labels: {
        style: {
          fontSize: "13px",
        },
      },
    },
    series: dataSource,
    credits: {
      enabled: false,
    },
    rangeSelector: {
      enabled: false,
    },
    navigator: {
      maskFill: "rgba(237, 212, 204, 0.35)",
    },
    plotOptions: {
      series: {
        lineWidth: 3,
      },
    },
  };

  return (
    <div className="h-full w-full">
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        constructorType={"stockChart"}
        containerProps={{
          style: {
            width: "100%",
            height: "100%",
          },
        }}
        ref={chartRef}
      />
    </div>
  );
};
export default ProfitChart;
