import React, { useEffect } from 'react';
import { NavButton } from "../Ui/NavButton";
import { BrowserProvider } from "ethers"; // Assuming BrowserProvider is valid in your ethers version

const ConnectWalletButton = ({ onAddressConnect }) => {
  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        // Requesting access to the user's accounts
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const provider = new BrowserProvider(window.ethereum);
        const accounts = await provider.listAccounts();

        // If the parent component provides the onAddressConnect function, call it with the first account
        if (onAddressConnect) {
          onAddressConnect(accounts[0].address);
        }
      } catch (error) {
        console.error("User denied account access:", error);
      }
    } else {
      console.error("Ethereum object doesn't exist!");
    }
  };

  useEffect(() => {
    const handleAccountsChanged = (accounts) => {
      if (accounts.length === 0) {
        // MetaMask is locked or the user has not connected any accounts
      } else if (onAddressConnect) {
        onAddressConnect(accounts[0]);
      }
    };

    if (window.ethereum) {
      window.ethereum.on('accountsChanged', handleAccountsChanged);
    }

    // Cleanup function to remove the event listener
    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
      }
    };
  }, [onAddressConnect]); // This effect depends on onAddressConnect

  return (
    <NavButton
      type="button"
      variant="semibold"
      onClick={connectWallet}
      className="rounded-md border border-onSurfaceVariant bg-white hover:bg-primary hover:text-white px-2 py-2.5 w-fit h-full shadow-md text-gray-800"
    >
      Connect
    </NavButton>
    //      // className="hidden xl:inline-flex bg-primary px-4 py-2 rounded text-sm font-semibold text-white shadow-sm hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary md:h-10 sm:h-8"

  );
};

export default ConnectWalletButton;
