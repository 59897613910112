import { useQuery } from "@tanstack/react-query";
import request from "../../../Api/Api.js"; // Ensure this path matches where your request utility is located

export function useDatabaseTables(selectedChain) {
  return useQuery({
    queryKey: ["databaseTables", selectedChain],
    queryFn: () => {
      const chains = selectedChain ? [selectedChain] : ["ethereum", "bitcoin"];
      const promises = chains.map((chain) =>
        request.get(`/executeGetTable?database=${chain}`).then((response) => {
          return response.data.map((table) => ({
            ...table,
            name: `${chain}.${table.name}`,
            chain,
          }));
        })
      );
      return Promise.all(promises)
        .then((results) => results.flat())
        .catch((err) => {
          console.error("Error fetching database tables:", err);
          throw err;
        });
    },
    keepPreviousData: true,
  });
}
