import { Card } from "../../Ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../Ui/table";
import PortfolioPieChart from "./PortfolioPieChart.js";
import { useQuery } from "@tanstack/react-query";
import request from "../../../Api/Api.js";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import CardLoading from "../CardLoading";
import { removeZeros, smallNumber } from "../Utilities/LongSmallNums.js";

export default function PieChartContainer({
  tokenValueLoading = true,
  totalValue = 0,
  error,
  tokenValueData,
}) {
  const topTokens = tokenValueData?.data
    .sort(
      (a, b) =>
        parseFloat(b.token_holdings_in_usd) -
        parseFloat(a.token_holdings_in_usd)
    )
    .slice(0, 5);

  // getting icons
  const TokenIcon = ({ tokenAddress }) => {
    const {
      data: iconData,
      isLoading,
      isError,
    } = useQuery({
      queryKey: ["getCryptoIcon", tokenAddress],
      queryFn: () =>
        request
          .get(`/getCryptoIcon?address=${tokenAddress}`)
          .then((res) => res.data),
      enabled: !!tokenAddress, // Only fetch icon if the token address is provided
    });
    if (isLoading || isError || !iconData) {
      return (
        <CheckCircleIcon className="h-4 w-4 lg:h-6 lg:w-6 my-2 text-primary" />
      );
    }
    return (
      <img
        src={`data:image/png;base64,${iconData}`}
        alt="Token Icon"
        className="h-4 w-4 lg:h-6 lg:w-6 my-2"
      />
    );
  };

  return (
    <div>
      <Card>
        <div className="grid grid-cols-1 sm:grid-cols-2 sm:divide-x-2 divide-onSurfaceVariant my-2 gap-2">
          <div className="h-full flex flex-col pl-2 w-full">
            <div className="text-lg lg:text-xl font-semibold text-center pb-3 text-gray-700">
              Top 5 Tokens
            </div>
            {error ? (
              <div className="h-96 font-monda text-5xl flex flex-col gap-4 text-center mt-6 text-red-400">
                error
                <p className="text-gray-700 text-3xl">
                  Can&apos;t retreive value from wallet address
                </p>
                <p className="text-gray-700 text-3xl">Refresh and try again</p>
              </div>
            ) : tokenValueLoading ? (
              // Display the loading component for the entire table section

              <CardLoading className="flex-grow h-96" /> // This will now expand to fill the flex container
            ) : (
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Icon</TableHead>
                    <TableHead>Name</TableHead>
                    <TableHead>Amount</TableHead>
                    <TableHead>Price</TableHead>
                    <TableHead>Value</TableHead>
                    <TableHead>Percentage</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {topTokens && topTokens.length > 0 ? (
                    topTokens.map((token, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <TokenIcon tokenAddress={token.token_address} />
                        </TableCell>
                        <TableCell>{token.tokenDetails.name}</TableCell>
                        <TableCell className="font-monda">
                          {token.adjusted_token_balance < 0 ? (
                            <span>
                              0.0
                              <sub>
                                {smallNumber(
                                  Number(token.adjusted_token_balance)
                                )}
                              </sub>
                              {removeZeros(
                                Number(token.adjusted_token_balance)
                              )}
                            </span>
                          ) : (
                            token.adjusted_token_balance.toLocaleString(
                              "en-US",
                              {
                                maximumFractionDigits: 2,
                              }
                            )
                          )}
                        </TableCell>
                        <TableCell className="font-monda">
                          {smallNumber(
                            Number(
                              token.tokenPriceInEth * token.latest_eth_price
                            )
                          ) > 1 ? (
                            <span>
                              $0.0
                              <sub>
                                {smallNumber(
                                  Number(
                                    token.tokenPriceInEth *
                                      token.latest_eth_price
                                  )
                                )}
                              </sub>
                              {removeZeros(
                                Number(
                                  token.tokenPriceInEth * token.latest_eth_price
                                )
                              )}
                            </span>
                          ) : (
                            <span>
                              $
                              {(
                                token.tokenPriceInEth * token.latest_eth_price
                              ).toFixed(3)}
                            </span>
                          )}
                        </TableCell>
                        <TableCell className="font-monda">
                          ${token.token_holdings_in_usd.toFixed(2)}
                        </TableCell>
                        <TableCell className="font-monda">
                          {(
                            (token.token_holdings_in_usd / totalValue) *
                            100
                          ).toFixed(2) + "%"}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <td colSpan="6">No data available</td>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
          </div>
          <div className="h-full flex flex-col">
            <div className="text-lg lg:text-xl font-semibold text-center pb-3 text-gray-700">
              Overview
            </div>
            {error ? (
              <div className="h-96 font-monda text-5xl flex flex-col gap-4 text-center mt-6 text-red-400">
                error
                <p className="text-gray-700 text-3xl">
                  Can&apos;t retreive values from this wallet address
                </p>
                <p className="text-gray-700 text-3xl">Refresh and try again</p>
              </div>
            ) : tokenValueLoading ? (
              // Display the loading component for the entire table section
              <div className="flex h-full px-2 ">
                <CardLoading className="h-full flex-grow" />
              </div>
            ) : (
              <PortfolioPieChart
                data={tokenValueData}
                totalValue={totalValue}
              />
            )}
          </div>
        </div>
      </Card>
    </div>
  );
}
