import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import Alerts from "./Alerts";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../Services/userContext";
import { useContext } from "react";
import AlertCardLoading from "./AlertCardLoading";

export default function Index() {
  const navigate = useNavigate();
  const { validateUser } = useContext(UserContext);
  return (
    <div className="min-h-[75vh]">
      {localStorage.getItem("user") === null ? (
        <div className=" min-h-[50vh] flex flex-col justify-center items-center w-full mx-auto">
          <div className="flex items-center gap-8 py-4">
            <div className="rounded-full bg-surfaceVariant p-4">
              <ExclamationCircleIcon className="w-16 h-16 text-onSurfaceVariant" />
            </div>
            <div>
              <div className="text-5xl md:leading-6 text-gray-600 ">
                Not logged in!
              </div>
            </div>
          </div>
          <div className=" text-gray-400 text-2xl gap-6 flex flex-col items-center">
            Login to see your alerts
            <button
              className="bg-surfaceVariant px-4 py-2 rounded-lg text-black hover:bg-primary hover:text-white"
              onClick={() => navigate("/signin")}
            >
              Login
            </button>
          </div>
        </div>
      ) : validateUser.isLoading ? (
        <AlertCardLoading />
      ) : (
        <Alerts />
      )}
    </div>
  );
}
