import { useQuery } from "@tanstack/react-query";
import request from "../Api/Api.js";
import { useState } from "react";
export default function useValidateUserQuery() {
  const [errorCode, setErrorCode] = useState(null);
  function getToken() {
    if (localStorage.getItem("user") != null) {
      return JSON.parse(localStorage.getItem("user")).accessToken;
    } else {
      return "";
    }
  }
  const { isError, isLoading, isFetching, data, refetch } = useQuery({
    queryKey: ["validateUser"],
    queryFn: async () => {
      const data = await request
        .get("/user/validate", {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        })
        .catch(function (error) {
          localStorage.removeItem("user");
          setErrorCode(error.response.status);
          return null;
        });
      return data;
    },
  });
  return {
    isError: isError,
    data: data,
    isLoading: isLoading,
    isFetching: isFetching,
    errorCode: errorCode,
    refetch: refetch,
  };
}
