import { useContext } from "react";
import Error from "../../Error.js";
import AlertCard from "./AlertCard.js";
import AlertCardLoading from "./AlertCardLoading.js";
import Header from "./Header.js";
import NoAlerts from "./NoAlerts.js";
import UseNotificationQuery from "./UseNotificationQuery.js";
import { UserContext } from "../../Services/userContext";

export default function Alerts() {
  const { isLoading, isError, data } = UseNotificationQuery();
  const { userData } = useContext(UserContext);
  if (isError || (!data && !isLoading)) {
    return (
      <div className="relative min-h-[75vh] max-w-7xl mx-auto py-4 px-8">
        <Error />
      </div>
    );
  }
  return (
    <div className="relative min-h-[75vh] max-w-7xl mx-auto py-4 px-8">
      {isLoading ? (
        <div>
          <AlertCardLoading />
        </div>
      ) : (
        <div className=" min-h-[75vh] w-full">
          <Header
            currAlerts={data?.data?.length}
            totalAlerts={userData?.alerts}
          />
          {data?.data?.length === 0 ? (
            <NoAlerts />
          ) : (
            <div className="w-full flex flex-col grow gap-6 items-center pt-2">
              {data?.data?.map((notification, idx) => (
                <AlertCard
                  key={idx}
                  name={notification?.alertName}
                  blockchain={notification.blockchain}
                  chart={notification.chart}
                  value={notification.value}
                  condition={notification.condition}
                  resolution={notification.resolution}
                  source={notification.dataSource}
                  freq={notification.frequency}
                  id={notification._id}
                />
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
