/// Created by James Launder
// contains the query state that hold all the data for the charts
import { useSearchParams, useNavigate } from "react-router-dom";
import { useState, useEffect, useRef, useContext } from "react";
import ChartFooter from "./ChartFooter";
import ChartHeader from "./ChartHeader";
import ChartMain from "./ChartMain";
import chartPic from "../../Assets/26-01-24-AvgGas.png";
import UpgradeHeader from "./UpgradeHeader";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import UseNavQuery from "./UseNavQuery.js";
import useChartsQuery from "./useChartsQuery";
import { UserContext } from "../../Services/userContext.js";
import { useGraphState } from "../../utils/useGraphState.js";

function MainSection(props) {
  const navigate = useNavigate();
  const handle = useFullScreenHandle();
  const image = useRef();
  const [searchParams] = useSearchParams();
  const [graphData] = useState([]);
  const [showHeader, setShowHeader] = useState(true);
  const [removeChart, setRemoveChart] = useState(null);
  const { navIsLoading, navIsFetching } = UseNavQuery();
  const { results, error } = useChartsQuery();
  const { userData, validateUser } = useContext(UserContext);
  const graphState = useGraphState((state) => state.graphState);
  function header() {
    if (localStorage.getItem("user") === null) {
      setShowHeader(<UpgradeHeader />);
      return;
    }
    if (userData?.tier < 1) {
      setShowHeader(<UpgradeHeader />);
      return;
    }

    setShowHeader(<div></div>);
  }

  useEffect(() => {
    header();
    // console.log("header effect called");
    // updateQuery();
  }, [
    searchParams.get("blockchain"),
    searchParams.get("name"),
    searchParams.get("resolution"),
    searchParams.get("source"),
    userData,
  ]);

  return (
    <div className="w-full h-fit overflow-auto relative z-0 pb-10">
      {error === 404 || (results[0].isError && !results[1]?.isError) ? (
        <main className="grid min-h-[70vh] place-items-center py-24 px-6 sm:py-32 lg:px-8 font-roboto">
          <div className="text-center">
            <p className="text-2xl font-semibold text-primary">404</p>
            <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              Graph not found
            </h1>
            <p className="mt-6 text-base leading-7 text-gray-600">
              Sorry, we couldn’t find the page you’re looking for.
            </p>
            <div className="mt-6 flex items-center justify-center gap-x-6">
              <button
                onClick={() => {
                  navigate("/");
                }}
                className="rounded-lg bg-primaryContainer px-3 py-2 text-sm font-semibold text-black shadow-sm hover:text-white hover:bg-primary"
              >
                Go back home
              </button>
            </div>
          </div>
        </main>
      ) : (
        <div>
          <div>{showHeader}</div>
          <FullScreen handle={handle}>
            <div ref={image}>
              <div>
                {navIsLoading || navIsFetching ? (
                  <div className="h-[70] w-full grid content-center font-roboto animate-pulse">
                    <div className="flex flex-row justify-between pt-2 mx-8 px-2 border-b border-secondary pb-2">
                      {/* Left div */}
                      <div className="flex flex-row gap-4 place-items-center">
                        <div className=" place-items-center pr-4 pl-14 py-5 rounded-xl bg-gray-300"></div>
                        <div className="flex flex-row place-items-center divide-x divide-secondary bg-gray-300 rounded-xl px-2">
                          <div className="group  rounded-lg p-2 py-5 pl-[92px]"></div>
                        </div>
                      </div>

                      <span className="relative inline-flex rounded-xl bg-gray-300 items-center  ">
                        <div className="group flex flex-row place-content-center p-2 hover:bg-primary hover:text-white pl-2 rounded-xl w-24 py-2.5"></div>
                        <div className="group flex flex-row place-content-center p-2 hover:bg-primary hover:text-white pl-2 rounded-xl w-24 py-2.5"></div>
                        <div className="group flex flex-row place-content-center p-2 hover:bg-primary hover:text-white pl-2 rounded-xl w-24 py-2.5"></div>
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col relative z-10">
                    <div className="flex justify-center absolute inset-0 mt-0 pt-2 text-2xl font-bold">
                      {graphState.name}
                    </div>
                    <div className="z-30 bg-white secret-div">
                      <ChartHeader
                        divRef={image}
                        name={graphState.name}
                        handle={handle}
                      />
                    </div>
                  </div>
                )}
              </div>
              {error === "No Token" ? (
                <div className="relative">
                  <img
                    src={chartPic}
                    className="blur-sm min-h-[70vh]"
                    height={"100%"}
                    width={"100%"}
                    alt="..."
                  />
                  <div className="absolute text-black top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 backdrop-blur-sm p-4">
                    <h1 className="text-5xl mb-4">Login to access!</h1>
                    <span className="mt-4">
                      <button
                        className="bg-surfaceVariant border border-primaryContainer shadow-md rounded-lg hover:bg-primary hover:text-white p-4 text-3xl font-semibold w-full"
                        onClick={() => {
                          navigate("/signin");
                        }}
                      >
                        Login
                      </button>
                    </span>
                  </div>
                </div>
              ) : (
                <div className="relative z-0">
                  {navIsLoading || validateUser.isLoading ? (
                    <div className="flex justify-between mx-8 py-2 animate-pulse">
                      <div className="flex flex-auto gap-2 items-center">
                        <div className="pr-4 pl-14 py-4 rounded-xl bg-gray-300"></div>
                      </div>

                      <div>
                        <span className="grid grid-flow-col w-full space-x-1 rounded-xl bg-gray-300">
                          <div className="w-12 rounded-xl py-4"></div>
                          <div className="w-12 rounded-xl py-4"></div>
                          <div className="w-12 rounded-xl py-4"></div>
                          <div className="w-12 rounded-xl py-4"></div>
                          <div className="hidden md:block w-12 rounded-xl py-4"></div>
                        </span>
                      </div>
                    </div>
                  ) : (
                    <ChartMain
                      graphData={graphData}
                      graphDataV2={results}
                      // graphDataV2={[]}
                      remove={removeChart}
                      setRemove={setRemoveChart}
                      sidebar={props.sidebar}
                      showError={props.showError}
                      setShowError={props.setShowError}
                      errorMessage={props.errorMessage}
                      setErrorMessage={props.setErrorMessage}
                      graphError={error}
                    />
                  )}
                </div>
              )}
            </div>
          </FullScreen>
          <ChartFooter />
        </div>
      )}
    </div>
  );
}

export default MainSection;
