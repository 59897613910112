import { InformationCircleIcon } from "@heroicons/react/24/outline";
import EthLogo from "../../../Assets/eth.svg";
import { useState } from "react";
import classNames from "../../../utils/ClassNames";

export default function NftCard({ nft, setLoadedImages, loadedImages }) {
  const [flip, setFlip] = useState(false);

  const convertIPFSToHTTP = (url) => {
    if (url.startsWith("ipfs://")) {
      return url.replace("ipfs://", "https://ipfs.io/ipfs/");
    } else if (url.startsWith("https://")) {
      return url;
    } else {
      return EthLogo;
    }
  };
  const convertIPFSToHTTPCheck = (url) => {
    if (!url) {
      return false;
    } else if (url.startsWith("https://")) {
      return true;
    } else if (url.startsWith("ipfs://")) {
      return true;
    } else {
      return false;
    }
  };
  const handleImageLoad = (tokenId) => {
    setLoadedImages((prevLoadedImages) => ({
      ...prevLoadedImages,
      [tokenId]: true,
    }));
  };
  const imageUrl = convertIPFSToHTTP(nft?.metadata?.image || "");
  const hasAttributes =
    Array.isArray(nft?.metadata.attributes) &&
    nft?.metadata.attributes.length > 0;

  if (!nft) {
    return <div></div>;
  }
  return (
    <button
      //   className="relative z-0 [perspective:1000px] h-80 group rounded-md overflow-hidden border border-onSurfaceVariant bg-white shadow-md"
      className={classNames(
        flip ? "none" : "[transform:rotateY(180deg)]",
        "transition-all duration-700 [transform-style:preserve-3d] w-fit relative z-0 [perspective:1000px] group bg-white rounded-md overflow-hidden shadow-md pb-2"
      )}
      onClick={() => setFlip(!flip)}
    >
      <div
        className={classNames(
          flip ? "[transform:rotateY(360deg)]" : "[transform:rotateY(180deg)]",
          "[transform-style:preserve-3d] transition-all duration-700 w-full h-full"
        )}
      >
        {flip ? (
          <div className="w-[340px] h-full p-4 overflow-y-scroll">
            <div className="flex flex-col">
              <h3 className="text-xl font-semibold mb-2">
                {nft.metadata.name}
              </h3>
              <p className="text-gray-600 text-sm">
                {nft.metadata.description}
              </p>
              {/* Additional content */}
              {hasAttributes && (
                <div className="p-4">
                  <h4 className="font-semibold">Attributes</h4>
                  <ul>
                    {nft.metadata.attributes.map((attribute, attrIndex) => (
                      <li key={attrIndex} className="text-sm text-gray-600">
                        <strong>{attribute.trait_type}:</strong>{" "}
                        {attribute.value}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="flex flex-col h-fit sm:w-[320px] md:w-[360px] lg:w-[340px] 2xl:w-[320px] 3xl:w-[340px]">
            <div className="sm:h-[320px] md:h-[360px] lg:h-[340px] 2x:h-[320px] 3xl:h-[340px] flex flex-col justify-center items-center">
              <div className=" h-full w-full rounded-md overflow-hidden flex justify-center">
                <img
                  src={imageUrl ?? ""}
                  alt={`NFT ${nft?.tokenId}`}
                  className={classNames(
                    "h-full w-full object-contain transition rounded-md",
                    !loadedImages[nft?.tokenId] ? "hidden" : "",
                    convertIPFSToHTTPCheck(nft?.metadata?.image || "")
                      ? "group-hover:scale-125"
                      : ""
                  )}
                  onLoad={() => handleImageLoad(nft?.tokenId)}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = EthLogo; // Display a fallback image
                  }}
                />
              </div>
            </div>
            <div className="row-span-1 p-2 text-center flex flex-col justify-evenly items-center">
              <div className="row-span-1 flex justify-center items-center text-gray-900 overflow-scroll-x">
                <div className="group text-2xl font-semibold p-2 py-2 truncate ... text-center flex justify-center items-center  w-[340px] px-4">
                  <InformationCircleIcon className="h-6 w-6 mr-2 mb-0.5 group-hover:text-primary" />
                  {nft.metadata.name}
                </div>
              </div>
              <div className="flex flex-col mb-2">
                <span className="font-semibold text-xl text-gray-700 mb-1">
                  Floor price:
                </span>

                <span className="flex justify-center items-center text-center gap-2 font-monda w-full text-3xl text-gray-900 ">
                  {(nft.most_recent_price / 1e18).toLocaleString("en-US", {
                    maximumFractionDigits:
                      nft.most_recent_price / 1e18 > 0.9 ? 2 : 3,
                  })}{" "}
                  ETH
                  {/* <img src={EthLogo} alt="ETH" className="h-[50%]" /> */}
                </span>
              </div>
              <div className="flex flex-wrap items-center justify-center text-center space-x-2">
                <span className="font-semibold text-xl text-gray-700">
                  Most recent purchase:
                </span>
                <span className="flex justify-center  items-center text-center gap-1 font-monda w-full text-2xl text-gray-900">
                  {nft.trade_timestamp.split(" ")[0]}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </button>
  );
}
