// Created by James Launder
// similar to other small chart with some tweaks to make it work in the news format
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useState } from "react";

function NewsChart(props) {
  //   const { theme } = useContext(ThemeContext);
  var newStyle = document.createElement("style");
  newStyle.appendChild(
    document.createTextNode(
      "\
@font-face {\
  font-family: 'Monda';\
  font-style: sans-serif;\
  font-weight: 400;\
  src: url('https://fonts.googleapis.com/css2?family=Monda&display=swap')\
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;\
}\
"
    )
  );

  document.head.appendChild(newStyle);
  const [options] = useState({
    exporting: {
      enabled: false,
      enableImages: true,
    },
    chart: {
      type: "area",
      backgroundColor: "transparent",
    },
    title: {
      text: "",
    },
    xAxis: {
      visible: false,
      type: "datetime",
    },
    yAxis: {
      visible: false,
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        name: props.name,
        data: props.data.graphData[0]?.values,
        color: "#FA9173",
        backgroundColor: "transparent",
        animation: false,
        lineWidth: 1,
        shadow: false,
        states: {
          hover: {
            lineWidth: 1,
          },
        },
        marker: {
          radius: 1,
          states: {
            hover: {
              radius: 2,
            },
          },
        },
        fillOpacity: 0.25,
      },
    ],
    credits: {
      enabled: false,
    },
  });

  return (
    <div className="">
      <HighchartsReact
        highcharts={Highcharts}
        containerProps={{
          style: {
            height: props.height,
          },
        }}
        options={options}
      />
    </div>
  );
}
export default NewsChart;
