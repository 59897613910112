import React from "react";
import {
  TableCellsIcon as TableIcon,
  CubeIcon,
} from "@heroicons/react/24/outline";

const Categories = ({ handleCategorySelected, materializedViewsData }) => {
  const categories = [
    {
      name: "Materialized Views",
      icon: TableIcon,
      onClick: () =>
        handleCategorySelected("Materialized Views", materializedViewsData),
    },
    {
      name: "Blockchain Raw Data",
      icon: CubeIcon,
      onClick: () => handleCategorySelected("Blockchain Raw Data"),
    },
  ];

  return (
    <div className="border-t pt-4 space-y-4">
      {categories.map((category) => (
        <button
          key={category.name}
          className="flex items-center space-x-2 cursor-pointer p-2 rounded-md hover:bg-gray-100"
          onClick={category.onClick}
        >
          <category.icon className="h-6 w-6 text-gray-500" />
          <span className="text-sm font-medium">{category.name}</span>
        </button>
      ))}
    </div>
  );
};

export default Categories;
