// Created by James Launder
// Contains the data for different options
// functions to load data from URL
// also has structure for surrouding chart components e.g. menus, zoom...

import Chart from "./Chart";
import ChartOptions from "./ChartOptions";
import ChartZoom from "./ChartZoom";
import { useContext, useEffect, useRef, useState } from "react";
import chartPic from "../../Assets/26-01-24-AvgGas.png";
import moment from "moment";
import newLogoV2 from "../../Assets/WevrLogoPrimary.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import CompareButton from "./CompareButton";
import AdditionalOptions from "./AdditionalOptions";
import { UserContext } from "../../Services/userContext";
import MobileAdditionalOptions from "./MobileAdditionalOptions";
import MobileChartOptions from "./MobileChartOptions";
import UseNavQuery from "./UseNavQuery.js";
import { useStoreQuery } from "./useStoreQuery";
import { useGraphState } from "../../utils/useGraphState.js";

function ChartMain(props) {
  const { user, validateUser } = useContext(UserContext);
  const queryState = useStoreQuery((state) => state.queryUrl);
  const newQuery = useStoreQuery((state) => state.addQuery);
  const removeMultiQuery = useStoreQuery((state) => state.removeMultiQuery);
  const removeAll = useStoreQuery((state) => state.removeAll);
  const { graphState, setResetCmp } = useGraphState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [zoom, setZoom] = useState(3);
  const [minExtreme, setMinExtreme] = useState();
  const [maxExtreme, setMaxExtreme] = useState();
  const [minZoom, setMinZoom] = useState(
    moment().subtract(6, "months").valueOf()
  );
  const firstUrl = useRef(true);
  const [open, setOpen] = useState(false);
  const [maxZoom, setMaxZoom] = useState(Date.now());
  const [additionalCharts, setAdditionalCharts] = useState([]);
  const [updateChart, setUpdateChart] = useState(null);
  const navigate = useNavigate();
  const { navIsLoading, name: name } = UseNavQuery();
  const colours = [
    {
      name: "Green",
      hex: "rgba(76,175,80, 0.75)",
    },
    {
      name: "Blue",
      hex: "rgba(41, 98, 255, 0.75)",
    },
    {
      name: "Red",
      hex: "rgba(242, 54, 69, 0.75)",
    },
    {
      name: "Purple",
      hex: "rgba(156, 39, 176, 0.75)",
    },
    {
      name: "Yellow",
      hex: "rgba(255, 235, 59, 0.75)",
    },
  ];
  const style = [
    {
      name: "Line",
      data: "line",
    },
    {
      name: "Bar",
      data: "column",
    },
  ];
  const scales = [
    {
      name: "Linear",
      data: "linear",
    },
    {
      name: "Log",
      data: "logarithmic",
    },
  ];
  // removes the additional charts when navigating to a new chart
  function removeAllAdd() {
    setAdditionalCharts([]);
    props.setRemove(null);
    removeAll();
    searchParams.delete("cmp");
    setSearchParams(searchParams);
  }
  useEffect(() => {
    if (searchParams.getAll("cmp").length > 0 && graphState.resetCmp) {
      removeAllAdd();
      setResetCmp(false);
    }
  }, [
    graphState.resetCmp,
    searchParams.get("name"),
    searchParams.get("blockchain"),
  ]);

  // logic for loading in additional charts from the url on the first load
  function loadURLCmp() {
    if (!navIsLoading) {
      // check that the query state is out of sync
      if (
        queryState.length < searchParams.getAll("cmp").length &&
        firstUrl.current
      ) {
        firstUrl.current = false;
        var tmpAdditional = [];
        var index = 0;
        var toRemove = [];
        var skip = false; // skip is used to skip charts with bad settings
        // for all the additional charts extract each one
        searchParams.getAll("cmp").forEach((el) => {
          var data = el.split("_");
          var colorIndex = colours.findIndex((obj) => obj.name === data[3]);
          var styleIndex = style.findIndex((obj) => obj.name === data[4]);
          var scaleIndex = scales.findIndex((obj) => obj.name === data[5]);
          index++;
          // ensure no errors in the url
          if (colorIndex === -1 || styleIndex === -1 || scaleIndex === -1) {
            toRemove.push(index);
            skip = true;
            props.setShowError(true);
            props.setErrorMessage(
              "Incorrect settings! A chart has been removed"
            );
          } else {
            // len 6 is no source listed
            if (data.length === 6) {
              tmpAdditional.filter((flt) => {
                if (flt.id === data[1] + data[0] + null + data[2]) {
                  toRemove.push(index);
                  skip = true;
                }
              });
              if (!skip) {
                tmpAdditional.push({
                  id: data[1] + data[0] + null + data[2],
                  blockchain: data[0],
                  name: data[1],
                  resolution: data[2],
                  color: colours[colorIndex],
                  style: style[styleIndex],
                  scale: scales[scaleIndex],
                  source: null,
                });
              }
            } else {
              tmpAdditional.filter((flt) => {
                if (flt.id === data[1] + data[0] + data[6] + data[2]) {
                  toRemove.push(index);
                  skip = true;
                }
              });
              if (!skip) {
                tmpAdditional.push({
                  id: data[1] + data[0] + data[6] + data[2],
                  blockchain: data[0],
                  name: data[1],
                  resolution: data[2],
                  color: colours[colorIndex],
                  style: style[styleIndex],
                  scale: scales[scaleIndex],
                  source: data[6],
                });
              }
            }
          }
          skip = false;
        });
        var tmpQueryUrl = Array.from(searchParams.entries());
        // using the skipped values remove them from the URL
        // this can be im proved by finding start index instead of hard coding it.
        toRemove.forEach((id) => {
          tmpQueryUrl.splice(id + 6, 1);
        });
        //  use the user data stored to validate how many charts they can have
        if (
          JSON.parse(localStorage.getItem("user")).charts < tmpAdditional.length
        ) {
          // amount added is invalid
          props.setShowError(true);
          props.setErrorMessage("Attempted to add too many charts");
          var amtInvalid =
            tmpAdditional.length -
            JSON.parse(localStorage.getItem("user")).charts;
          tmpAdditional.splice(tmpAdditional.length - amtInvalid, amtInvalid);

          tmpQueryUrl.splice(tmpQueryUrl.length - amtInvalid, amtInvalid);
        }
        // amount added is valid
        setSearchParams(tmpQueryUrl);
        tmpAdditional.forEach((el) => {
          setAdditionalCharts((prevState) => [...prevState, el]);
          newQuery(el.blockchain, el.resolution, el.name, el.source);
        });
      }
    }
  }

  // ensure that the local value is not tampered with
  function validateAdditionalCharts() {
    if (user.charts < additionalCharts.length) {
      // amount added is invalid
      var tmpQueryUrl = Array.from(searchParams.entries());
      var amtInvalid = additionalCharts.length - user.charts;
      tmpQueryUrl.splice(tmpQueryUrl.length - amtInvalid, amtInvalid);
      setSearchParams(tmpQueryUrl);
      setAdditionalCharts((prevState) => {
        const newState = [...prevState];
        newState.splice(additionalCharts.length - amtInvalid, amtInvalid);
        return newState;
      });
      removeMultiQuery(queryState.length - amtInvalid - 1, amtInvalid);
    }
  }
  useEffect(() => {
    if (searchParams.getAll("cmp").length > 0) {
      loadURLCmp();
    }
  }, []);
  useEffect(() => {
    if (additionalCharts.length > 0) {
      validateAdditionalCharts();
    }
  }, [user]);

  return (
    <div className="mb-4 w-full relative">
      {/* Chart options dropdown, Zoom buttons and chart */}
      {navIsLoading ||
      props.graphDataV2[0]?.isLoading ||
      props.graphDataV2[1]?.isLoading ||
      validateUser.isLoading ? (
        <div className="flex justify-between mx-8 py-2 animate-pulse">
          <div className="flex flex-auto gap-2 items-center">
            <div className="pr-4 pl-14 py-4 rounded-xl bg-gray-300"></div>
          </div>

          <div>
            <span className="grid grid-flow-col w-full space-x-1 rounded-xl bg-gray-300">
              <div className="w-12 rounded-xl py-4"></div>
              <div className="w-12 rounded-xl py-4"></div>
              <div className="w-12 rounded-xl py-4"></div>
              <div className="w-12 rounded-xl py-4"></div>
              <div className="hidden md:block w-12 rounded-xl py-4"></div>
            </span>
          </div>
        </div>
      ) : (
        <div className="flex justify-between mx-8 py-2 secret-div gap-2">
          <div className="grid grid-flow-col gap-2 items-center">
            <div className="hidden md:block">
              <ChartOptions
                chartOpts={props.chartOpts}
                setChartOpts={props.setChartOpts}
              />
            </div>
            <div className="block md:hidden">
              <MobileChartOptions
                chartOpts={props.chartOpts}
                setChartOpts={props.setChartOpts}
              />
            </div>
            <CompareButton
              open={open}
              setOpen={setOpen}
              additionalCharts={additionalCharts}
              setAdditionalCharts={setAdditionalCharts}
              colour={colours}
              style={style}
              scale={scales}
            />
            {/* <div className=" grid grid-flow-col bg-blue-500 overflow-auto w-full h-[40px]"> */}{" "}
            {additionalCharts ? (
              <div className="overflow-x-auto">
                <div className="hidden md:grid grid-flow-col overflow-auto w-full gap-2">
                  {additionalCharts.map((data, index) => (
                    <AdditionalOptions
                      key={data.name + data.blockchain}
                      index={index}
                      data={data}
                      setRemove={props.setRemove}
                      setAdditionalCharts={setAdditionalCharts}
                      additionalCharts={additionalCharts}
                      updateChart={updateChart}
                      setUpdateChart={setUpdateChart}
                      graphData={props.graphDataV2}
                      colour={colours}
                      style={style}
                      scale={scales}
                    />
                  ))}
                </div>
                <div className="md:hidden grid grid-flow-col overflow-auto w-full gap-2">
                  {additionalCharts.map((data, index) => (
                    <MobileAdditionalOptions
                      key={data.name + data.blockchain}
                      index={index}
                      data={data}
                      setRemove={props.setRemove}
                      setAdditionalCharts={setAdditionalCharts}
                      additionalCharts={additionalCharts}
                      updateChart={updateChart}
                      setUpdateChart={setUpdateChart}
                      graphData={props.graphDataV2}
                      colour={colours}
                      style={style}
                      scale={scales}
                    />
                  ))}
                </div>
              </div>
            ) : (
              <div></div>
            )}
            {/* </div> */}
          </div>

          <div>
            <ChartZoom
              set={setZoom}
              min={minExtreme}
              max={maxExtreme}
              setMinZoom={setMinZoom}
              setMaxZoom={setMaxZoom}
              minZoom={minZoom}
              maxZoom={maxZoom}
            />
          </div>
        </div>
      )}

      <div className="mx-8">
        {/* replace with props.dataLoading */}
        {/*{props.dataLoading || props.priceFetching || props.priceLoading ? (*/}
        {props.graphDataV2[0]?.isFetching ||
        props.graphDataV2[1]?.isFetching ? (
          <div className="min-h-[80vh] bg-gray-200 rounded-xl animate-pulse flex items-center justify-center">
            <img
              src={newLogoV2}
              alt="..."
              className="max-h-16 md:max-h-40 pl-14"
            />
          </div>
        ) : props.graphDataV2[0]?.data === "Unauthorized" ? (
          <div className="relative">
            <img
              src={chartPic}
              className="blur-sm min-h-[70vh]"
              height={"100%"}
              width={"100%"}
              alt="..."
            />
            <div className="absolute text-black top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 backdrop-blur-sm p-4">
              <h1 className="text-5xl mb-4">Upgrade to access</h1>
              <span className="mt-4">
                <button
                  className="bg-surfaceVariant border border-primaryContainer shadow-md rounded-lg hover:bg-primary hover:text-white p-4 text-3xl font-semibold w-full"
                  onClick={() => {
                    navigate("/pricing");
                  }}
                >
                  Upgrade
                </button>
              </span>
            </div>
          </div>
        ) : (
          <Chart
            data={props.graphData}
            dataV2={props.graphDataV2}
            remove={props.remove}
            setRemove={props.setRemove}
            additionalCharts={additionalCharts}
            name={name}
            sidebar={props.sidebar}
            zoom={zoom}
            min={setMinExtreme}
            max={setMaxExtreme}
            minZoom={minZoom}
            maxZoom={maxZoom}
            setMinZoom={setMinZoom}
            setMaxZoom={setMaxZoom}
            priceData={props.priceData}
            updateChart={updateChart}
            setUpdateChart={setUpdateChart}
          />
        )}
      </div>
    </div>
  );
}

export default ChartMain;
