import React, { useState, useEffect } from "react";
import { Button } from "../../Ui/button";
import usePremadeQueries from "../API/PremadeQueries";
import { useSavedQueries } from "../API/UseSavedQueries.js";
import SaveQueryDialog from "../Sidebar/SaveQueryDialog.js";
import { useCreateSavedQuery } from "../API/UseCreateSavedQuery.js";
import { useUpdateSavedQuery } from "../API/UseUpdateSavedQuery.js";
import { useDeleteSavedQuery } from "../API/useDeleteSavedQuery.js";
import ChainSelect from "./ChainSelect";
import Tables from "./Tables";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import LoadingSpinner from "../../Ui/loadingSpinner.js";
import { useQueryClient } from "@tanstack/react-query";
import { useDatabaseTables } from "../API/GetDatabaseTable";
import { useMaterializedViews } from "../API/UseMaterializedViews";

import {
  FolderIcon,
  PlusIcon,
  StarIcon,
  BookmarkIcon,
  Square3Stack3DIcon,
} from "@heroicons/react/24/outline";
import SavedQueries from "./SavedQueries";
import PremadeQueries from "./PremadeQueries";
import Categories from "./Categories";
import SearchComponent from "./SearchComponent.js";

const Sidebar = ({
  onAddColumn,
  queryText,
  chartConfig,
  setChartConfig,
  onLoadChartSettings,
  premadeQueries,
  isLoadingPremadeQueries,
  isLoadingPremadeQueriesError,
  selectedChain,
  setSelectedChain,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);
  const [selectedTable, setSelectedTable] = useState(null);
  const [viewCategory, setViewCategory] = useState(false);

  const [isAddingQuery, setIsAddingQuery] = useState(false);
  const [isEditingQuery, setIsEditingQuery] = useState(false);
  const [queryName, setQueryName] = useState("");
  const [queryDescription, setQueryDescription] = useState("");
  const [selectedSavedQuery, setSelectedSavedQuery] = useState(null);
  const [materializedViewsData, setMaterializedViewsData] = useState([]);
  const {
    data: tablesData,
    isLoading: isLoadingTables,
    isError: isLoadingTablesError,
  } = useDatabaseTables(selectedChain && viewCategory ? selectedChain : "");
  const { data: materializedViewsTable } = useMaterializedViews();

  const queryClient = useQueryClient();
  const {
    data: userSavedQueries,
    isLoading: isLoadingUserSavedQueries,
    isError: isUserSavedQueriesError,
    error: SavedQueryError,
  } = useSavedQueries();

  const {
    mutate: createSavedQuery,
    isLoading: isCreatingSavedQuery,
    isError: isCreateSavedQueryError,
  } = useCreateSavedQuery();

  const {
    mutate: updateSavedQuery,
    isLoading: isUpdatingSavedQuery,
    isError: isUpdateSavedQueryError,
  } = useUpdateSavedQuery();

  const {
    mutate: deleteSavedQuery,
    isLoading: isDeletingSavedQuery,
    isError: isDeleteSavedQueryError,
  } = useDeleteSavedQuery();

  const handleSaveQuery = (showChartSettings) => {
    const formattedChartSettings = showChartSettings
      ? {
          chartType: chartConfig.chartType,
          xAxisVariable: chartConfig.xAxisVariable,
          title: chartConfig.title,
          yAxes: chartConfig.series.map((serie) => ({
            variable: serie.name,
            color: serie.color,
          })),
        }
      : {};
    const newQuery = {
      name: queryName,
      description: queryDescription,
      query: queryText,
      ...(showChartSettings && { chartSettings: formattedChartSettings }),
    };

    createSavedQuery(newQuery, {
      onSuccess: () => {
        queryClient.invalidateQueries("savedQueries");
        setIsAddingQuery(false);
        setQueryName("");
        setQueryDescription("");
        handleCloseDialog();
      },
    });
  };

  const handleEditQuery = (query) => {
    setSelectedSavedQuery(query);
    setQueryName(query.name);
    setQueryDescription(query.description);
    setChartConfig(query.chartSettings);
    setOpenDialog(true);
  };

  const handleUpdateQuery = (showChartSettings) => {
    const updatedQuery = {
      name: queryName,
      description: queryDescription,
      query: queryText,
      ...(showChartSettings && { chartSettings: chartConfig }),
    };

    updateSavedQuery(
      {
        queryId: selectedSavedQuery._id,
        updatedQuery: updatedQuery,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("savedQueries");
          setIsEditingQuery(false);
          setSelectedSavedQuery(null);
          setQueryName("");
          setQueryDescription("");
          setOpenDialog(false);
        },
      }
    );
  };

  const handleDeleteQuery = (queryId) => {
    deleteSavedQuery(queryId, {
      onSuccess: () => {
        queryClient.invalidateQueries("getSavedQueries");
      },
    });
  };

  const [selectedCategory, setSelectedCategory] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const handleCategorySelected = (category, data) => {
    if (category === "Materialized Views") {
      setSelectedCategory(category);
      setViewCategory(true);
      setSelectedTable(null);
      setMaterializedViewsData(data);
    } else {
      setSelectedCategory(category);
      setViewCategory(true);
      setSelectedTable(null);
    }
  };

  const handleBackToCategories = () => {
    setViewCategory(false);
    setSelectedTable(null);
    setSelectedCategory("");
  };

  const handleColumnSelected = (columnName) => {
    onAddColumn(columnName);
  };

  const allData = [
    ...(materializedViewsTable || []).map((item) => ({
      ...item,
      type: "Materialized View",
    })),
    ...(userSavedQueries || []).map((item) => ({
      ...item,
      type: "Saved Query",
    })),
    ...(premadeQueries || []).map((item) => ({
      ...item,
      type: "Premade Query",
    })),
    ...(tablesData || []).map((item) => ({ ...item, type: "Table" })),
  ];

  const onSearchResultSelected = (result) => {
    switch (result.type) {
      case "Table":
      case "Materialized View":
        setSelectedTable(result);
        break;
      case "Saved Query":
      case "Premade Query":
        onAddColumn(result.query);
        break;
      default:
        console.error("Unknown result type", result);
    }
  };

  const shouldShowComponents = searchQuery === "";

  return (
    <div className="flex flex-col h-full ">
      <div className="w-48 sm:w-56 md:w-60 lg:w-64 border-r p-4 space-y-6  overflow-y-auto">
        <div className="flex items-center space-x-2">
          <FolderIcon className="h-6 w-6 text-gray-500" />
          {!viewCategory && !selectedTable && (
            <ChainSelect
              selectedChain={selectedChain}
              setSelectedChain={setSelectedChain}
            />
          )}
        </div>

        {!viewCategory && !selectedTable ? (
          <>
            <SearchComponent
              allData={allData}
              onSearchResultSelected={onSearchResultSelected}
              onAddColumn={onAddColumn}
              selectedTable={selectedTable}
              setSelectedTable={setSelectedTable}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
            {shouldShowComponents && (
              <>
                <div className="space-y-4">
                  <div className="flex items-center space-x-2">
                    <BookmarkIcon className="h-5 w-5 text-gray-500" />
                    <h3 className="text-lg font-semibold">Saved Queries</h3>
                  </div>
                  {isLoadingUserSavedQueries ? (
                    <div className="flex items-center justify-center space-x-2">
                      <LoadingSpinner />
                      <span>Loading saved queries...</span>
                    </div>
                  ) : SavedQueryError &&
                    SavedQueryError.response?.status === 401 ? (
                    <div className="flex items-center space-x-2">
                      <ExclamationTriangleIcon className="h-5 w-5" />
                      <span>Please log in!</span>
                    </div>
                  ) : isUserSavedQueriesError ? (
                    <div className="flex items-center space-x-2 text-red-500">
                      <ExclamationTriangleIcon className="h-5 w-5" />
                      <span>
                        Oops! An error occurred while loading saved queries.
                        Please try again later.
                      </span>
                    </div>
                  ) : (
                    <SavedQueries
                      userSavedQueries={userSavedQueries}
                      isLoadingUserSavedQueries={isLoadingUserSavedQueries}
                      onAddColumn={onAddColumn}
                      handleEditQuery={handleEditQuery}
                      handleDeleteQuery={handleDeleteQuery}
                      setOpenDialog={setOpenDialog}
                      onLoadChartSettings={onLoadChartSettings}
                    />
                  )}
                </div>

                <div className="space-y-4">
                  <div className="flex items-center space-x-2">
                    <StarIcon className="h-5 w-5 text-gray-500" />
                    <h3 className="text-lg font-semibold">Premade Queries</h3>
                  </div>
                  {isLoadingPremadeQueries ? (
                    <div className="flex items-center justify-center space-x-2">
                      <LoadingSpinner />
                      <span>Loading premade queries...</span>
                    </div>
                  ) : isLoadingPremadeQueriesError ? (
                    <div className="flex items-center space-x-2 text-red-500">
                      <ExclamationTriangleIcon className="h-5 w-5" />
                      <span>
                        Oops! An error occurred while loading premade queries.
                        Please try again later.
                      </span>
                    </div>
                  ) : (
                    <PremadeQueries
                      premadeQueries={premadeQueries}
                      isLoadingPremadeQueries={isLoadingPremadeQueries}
                      onAddColumn={onAddColumn}
                    />
                  )}
                </div>

                <div className="space-y-4">
                  <div className="flex items-center space-x-2">
                    <Square3Stack3DIcon className="h-5 w-5 text-gray-500" />
                    <h3 className="text-lg font-semibold">Categories</h3>
                  </div>
                  <Categories
                    handleCategorySelected={handleCategorySelected}
                    materializedViewsData={materializedViewsTable}
                  />
                </div>
              </>
            )}
          </>
        ) : (
          <>
            {isLoadingTables ? (
              <div className="flex items-center justify-center space-x-2">
                <LoadingSpinner />
                <span>Loading tables...</span>
              </div>
            ) : isLoadingTablesError ? (
              <div className="flex items-center space-x-2 text-red-500">
                <ExclamationTriangleIcon className="h-5 w-5" />
                <span>
                  Oops! An error occurred while loading tables. Please try again
                  later.
                </span>
              </div>
            ) : (
              <Tables
                selectedCategory={selectedCategory}
                selectedTable={selectedTable}
                tablesData={
                  selectedCategory === "Materialized Views"
                    ? materializedViewsData
                    : tablesData
                }
                handleTableSelected={setSelectedTable}
                handleBackToCategories={handleBackToCategories}
                handleColumnSelected={handleColumnSelected}
              />
            )}
          </>
        )}

        <SaveQueryDialog
          open={openDialog}
          setOpen={setOpenDialog}
          queryName={queryName}
          setQueryName={setQueryName}
          queryDescription={queryDescription}
          setQueryDescription={setQueryDescription}
          handleSaveQuery={handleSaveQuery}
          isCreating={isCreatingSavedQuery}
          isError={isCreateSavedQueryError}
          isEdit={selectedSavedQuery !== null}
          handleUpdateQuery={handleUpdateQuery}
          isUpdating={isUpdatingSavedQuery}
          isUpdateError={isUpdateSavedQueryError}
          handleDeleteQuery={handleDeleteQuery}
          isDeleting={isDeletingSavedQuery}
          isDeleteError={isDeleteSavedQueryError}
          selectedQueryId={selectedSavedQuery?._id}
          chartConfig={chartConfig}
          setChartConfig={setChartConfig}
        />
      </div>
    </div>
  );
};

export default Sidebar;
