export default function Privacy() {
  const privacy = new URL(
    "../../Assets/PrivacyPolicy.pdf",
    import.meta.url
  ).toString();
  return (
    <div className="w-full h-[70vh] font-roboto">
      <iframe src={privacy} title="PrivacyPolicy" width="100%" height="100%" />
    </div>
  );
}
