import React, { forwardRef, Fragment } from "react";
import { DataTable } from "../Ui/data-table";
import ChartComponent from "./chartComponent.js";
import VisualizationConfigModal from "./VisualizationConfigModal";
import { Card } from "../Ui/card.js";
import { exportToCSV } from "./Export.js";
import exportImage from "../../Services/exportImage.js";
import {
  TableCellsIcon as TableIcon,
  ChartBarIcon,
  ArrowDownTrayIcon,
  PencilSquareIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/react/24/solid";
import { Popover, Transition } from "@headlessui/react";

const BottomTable = forwardRef(
  (
    {
      activeTab,
      queryData,
      handleTabSwitch,
      chartConfig,
      isOpen,
      handleCloseModal,
      handleOpenModal,
      handleOnSubmit,
      allKeys,
      columns,
      isMinimized,
    },
    ref
  ) => (
    <Card
      className={`flex flex-col ${
        isMinimized ? "" : ""
      } transition-all duration-300 overflow-hidden p-4`}
    >
      <div className="mb-4 flex justify-between">
        <div className="flex">
          <span className="hidden md:inline-flex rounded-md shadow-lg bg-surfaceVariant">
            <button
              onClick={() => handleTabSwitch("table")}
              className={`${
                activeTab === "table"
                  ? "bg-primary text-white"
                  : "text-gray-700 hover:bg-primary hover:text-white"
              } px-4 py-2 text-sm font-medium focus:outline-none rounded-l-md flex items-center`}
            >
              <TableIcon className="h-5 w-5 mr-2" aria-hidden="true" />
              Table
            </button>
            <button
              onClick={() => handleTabSwitch("chart")}
              disabled={!chartConfig}
              className={`${
                activeTab === "chart"
                  ? "bg-primary text-white"
                  : "text-gray-700 hover:bg-primary hover:text-white"
              } ${
                !chartConfig ? "opacity-50 cursor-not-allowed" : ""
              } px-4 py-2 text-sm font-medium focus:outline-none flex items-center`}
            >
              <ChartBarIcon className="h-5 w-5 mr-2" aria-hidden="true" />
              Graph
            </button>
            {activeTab === "table" ? (
              <button
                onClick={() =>
                  exportToCSV(queryData.data, "exportedQueryData.csv")
                }
                className="text-gray-700 hover:bg-primary hover:text-white px-4 py-2 text-sm font-medium focus:outline-none flex items-center"
              >
                <ArrowDownTrayIcon
                  className="h-5 w-5 mr-2"
                  aria-hidden="true"
                />
                Export
              </button>
            ) : (
              <button
                onClick={() => exportImage(ref, "png")}
                className="text-gray-700 hover:bg-primary hover:text-white px-4 py-2 text-sm font-medium focus:outline-none flex items-center"
              >
                <ArrowDownTrayIcon
                  className="h-5 w-5 mr-2"
                  aria-hidden="true"
                />
                Export
              </button>
            )}
            <button
              onClick={handleOpenModal}
              className="text-gray-700 hover:bg-primary hover:text-white px-4 py-2 text-sm font-medium focus:outline-none rounded-r-md flex items-center"
            >
              <PencilSquareIcon className="h-5 w-5 mr-2" aria-hidden="true" />
              {chartConfig ? "Edit" : "Add"}
            </button>
          </span>
          <div className="md:hidden">
            <Popover as="div" className="">
              {({ open }) => (
                <>
                  <div>
                    <Popover.Button
                      className={`${
                        open ? "bg-primary text-white" : ""
                      } group flex flex-row p-2 place-content-center bg-surfaceVariant hover:bg-primary shadow-md hover:text-white border border-primaryContainer hover:border-primary rounded-lg col-span-1`}
                    >
                      <EllipsisHorizontalIcon
                        className={`${
                          open ? " text-white" : ""
                        } h-5 w-5 self-center text-gray-700 group-hover:text-white`}
                      />
                    </Popover.Button>
                  </div>

                  {open && (
                    <Transition
                      as={Fragment}
                      enter="transition-opacity ease-out duration-75"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Popover.Panel className="absolute border-2 border-primaryContainer bg-white rounded-xl z-30 w-fit right-0">
                        <div className="flex flex-col divide-y p-2">
                          <button
                            onClick={() => handleTabSwitch("table")}
                            className="flex justify-center items-center gap-2 p-2 hover:bg-primary hover:text-white rounded-md"
                          >
                            <TableIcon className="h-5 w-5" aria-hidden="true" />
                            Table
                          </button>
                          <button
                            onClick={() => handleTabSwitch("chart")}
                            disabled={!chartConfig}
                            className={`flex justify-center items-center gap-2 p-2 hover:bg-primary hover:text-white rounded-md ${
                              !chartConfig
                                ? "opacity-50 cursor-not-allowed"
                                : ""
                            }`}
                          >
                            <ChartBarIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                            Graph
                          </button>
                          {activeTab === "table" ? (
                            <button
                              onClick={() =>
                                exportToCSV(
                                  queryData.data,
                                  "exportedQueryData.csv"
                                )
                              }
                              className="flex justify-center items-center gap-2 p-2 hover:bg-primary hover:text-white rounded-md"
                            >
                              <ArrowDownTrayIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                              Export
                            </button>
                          ) : (
                            <button
                              onClick={() => exportImage(ref, "png")}
                              className="flex justify-center items-center gap-2 p-2 hover:bg-primary hover:text-white rounded-md"
                            >
                              <ArrowDownTrayIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                              Export
                            </button>
                          )}
                          <button
                            onClick={handleOpenModal}
                            className="flex justify-center items-center gap-2 p-2 hover:bg-primary hover:text-white rounded-md"
                          >
                            <PencilSquareIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                            {chartConfig ? "Edit" : "Add"}
                          </button>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  )}
                </>
              )}
            </Popover>
          </div>
        </div>
      </div>
      <div className="flex-grow overflow-auto">
        {activeTab === "table" ? (
          <div className="">
            <h3 className="text-lg font-semibold mb-4">Query Result:</h3>
            <DataTable
              pageSize={10}
              isRounded={true}
              columns={columns}
              data={queryData.data}
            />
          </div>
        ) : activeTab === "chart" && chartConfig ? (
          <div className="" ref={ref}>
            <ChartComponent chartConfig={chartConfig} />
          </div>
        ) : null}
      </div>
      <VisualizationConfigModal
        isOpen={isOpen}
        onClose={handleCloseModal}
        onSubmit={handleOnSubmit}
        allKeys={allKeys}
      />
    </Card>
  )
);

BottomTable.displayName = "BottomTable";

export default BottomTable;
