import SentimentBar from "./SentimentBar.js";
import dayjs from "dayjs";

export default function DashboardNewsCard(props) {
  function calculateText(value) {
    if (value == -5) {
      return "Terrible";
    } else if (value <= -2) {
      return "Poor";
    } else if (value < 2) {
      return "Neutral";
    } else if (value < 5) {
      return "Good";
    } else {
      return "Excellent";
    }
  }
  return (
    <a
      key={props.data.title}
      href={props.data.link}
      target="_blank"
      rel="noopener noreferrer"
      className="grid grid-rows-7 h-fit bg-surface p-4 rounded-md gap-1 border border-surfaceVariant shadow-sm"
    >
      {/* Title and author/date */}
      <div className="row-span-2 flex flex-col flex-1 justify-between pb-1">
        <div className="flex items-center text-md xl:text-xs font-semibold text-gray-900">
          {props.data.title}
        </div>
        <div className="flex justify-between pt-[0.5]">
          <div className="text-md xl:text-xs text-gray-600">
            {props.data.author}
          </div>

          <div className="text-md xl:text-xs text-gray-600">
            {dayjs(props.data.published * 1000).format("MM/DD/YYYY")}
          </div>
        </div>
      </div>
      {/* image */}
      {/* <div className="relative mx-auto rounded-lg overflow-hidden"> */}
      <div className="row-span-4 flex justify-center my-auto relative mx-auto rounded-lg overflow-hidden">
        <img
          src={
            props.data?.media_content != undefined
              ? props.data?.media_content[0]["url"]
              : ""
          }
          alt=""
          className="object-fill h-52 xl:h-36"
        />
      </div>
      {/* Sentiment */}
      <div className="row-span-1 flex flex-col justify-center gap-1">
        <div className="flex gap-1 text-gray-600">
          Sentiment:{" "}
          <div className="text-gray-600">
            {calculateText(props.data.Sentiment)}
          </div>
        </div>

        <SentimentBar value={props.data.Sentiment} />
      </div>
    </a>
  );
}
