// created by James Launder
// main holder for all the dashboard files

import { useNavigate } from "react-router";
import MainDashboard from "./MainDashboard";
import VertNavDashboard from "./VertNavDashboard";

function Dashboard() {
  const navigate = useNavigate();
  return (
    <div className="w-fit">
      <div className="flex flex-row relative z-0">
        <div className="hidden md:block">
          <VertNavDashboard />
        </div>

        <MainDashboard />
        <div className="absolute inset-0 flex justify-center md:items-center z-10 backdrop-blur-sm bg-white/30 ">
          <div className="h-fit mt-10 md:mt-0 bg-surfaceVariant rounded-md border border-onSurfaceVariant p-8 font-robot font-light text-center text-xl md:text-7xl">
            Dashboard Coming Soon
            <div className="p-4 flex gap-8 pb-4 w-full justify-center md:text-4xl mt-4">
              <button
                className="px-3 py-2 font-roboto font-light bg-primaryContainer rounded-lg hover:bg-primary hover:text-white"
                onClick={() => {
                  navigate("/Explore?blockchain=Ethereum");
                }}
              >
                Explore
              </button>
              <button
                className="px-3 py-2 font-roboto font-light bg-primaryContainer rounded-lg hover:bg-primary hover:text-white"
                onClick={() => {
                  navigate("/Discover");
                }}
              >
                Discover
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
