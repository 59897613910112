import { Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import { useSearchParams } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function BlockchainDropdown({ blockchainData, onBlockchainChange }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selected, setSelected] = useState();

  useEffect(() => {
    if (blockchainData) {
      const selectedBlockchain = searchParams.get("blockchain");
      const defaultBlockchain = blockchainData[0];
      const obj = blockchainData.find(
        (o) => o.name.toLowerCase() === selectedBlockchain
      );
      setSelected(obj || defaultBlockchain);
    }
  }, [blockchainData, searchParams]);

  const handleBlockchainSelect = (blockchain) => {
    setSelected(blockchain);
    setSearchParams({ blockchain: blockchain.name.toLowerCase() });
    if (onBlockchainChange) {
      onBlockchainChange(blockchain);
    }
  };

  return (
    <Listbox value={selected} onChange={handleBlockchainSelect}>
      {({ open }) => (
        <>
          <div className="relative w-full">
            <Listbox.Button className="relative w-full rounded-lg border border-primary bg-white py-2 pl-3 pr-10 text-left">
              <span className="flex items-center">
                {selected && (
                  <img
                    src={process.env.API + "/" + selected.icon}
                    alt=""
                    className="h-6 w-6 flex-shrink-0 rounded-full"
                  />
                )}
                <span className="ml-3 block truncate font-semibold text-lg">
                  {selected ? selected.name : "Select a blockchain"}
                </span>
              </span>
              <span className="absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <ChevronDownIcon
                  className="h-6 w-6 text-primary"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-30 mt-1 max-h-56 w-full overflow-auto rounded-lg bg-white shadow-lg border border-primary">
                {blockchainData.map((blockchain) => (
                  <Listbox.Option
                    key={blockchain._id}
                    className={({ active }) =>
                      classNames(
                        active ? "text-white bg-primary" : "text-gray-900",
                        "relative py-2 pl-3 pr-9 rounded-md cursor-pointer"
                      )
                    }
                    value={blockchain}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <img
                            src={process.env.API + "/" + blockchain.icon}
                            alt=""
                            className="h-6 w-6 flex-shrink-0 rounded-full"
                          />
                          <span
                            className={classNames(
                              selected ? "" : "font-normal",
                              "ml-3 block truncate"
                            )}
                          >
                            {blockchain.name}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-primary",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}

export default BlockchainDropdown;
