import React from "react";
import { Button } from "../../Ui/button";
import { ArrowLeftIcon, TableCellsIcon } from "@heroicons/react/24/outline";

const Tables = ({
  selectedCategory,
  selectedTable,
  tablesData,
  handleTableSelected,
  handleBackToCategories,
  handleColumnSelected,
}) => {
  const findTableColumns = (tableName) => {
    return tablesData?.find((table) => table.name === tableName)?.columns || [];
  };

  if (selectedTable) {
    return (
      <div className="h-4/5 flex flex-col">
        <Button
          onClick={handleBackToCategories}
          variant="ghost"
          className="mb-4"
        >
          <ArrowLeftIcon className="h-4 w-4 mr-2" />
          Back to Search Results{" "}
        </Button>
        <div className="flex-grow overflow-y-auto space-y-4">
          <h3 className="text-lg font-semibold">
            {selectedTable.name} - {selectedTable.chain}
          </h3>
          <div className="space-y-2">
            {selectedTable.columns.map((column, index) => (
              <button
                key={index}
                className="flex items-center space-x-2 cursor-pointer px-2 py-1 rounded hover:bg-gray-100"
                onClick={() => handleColumnSelected(column.column_name)}
              >
                <TableCellsIcon className="h-5 w-5 text-gray-500" />
                <span className="text-sm">
                  {column.column_name}: {column.column_type}
                </span>
              </button>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Button onClick={handleBackToCategories} variant="ghost" className="mb-4">
        <ArrowLeftIcon className="h-4 w-4 mr-2" />
        Back to Tables
      </Button>
      <div className="space-y-4">
        <h3 className="text-lg font-semibold">{selectedCategory}</h3>
        <div className="space-y-2">
          {tablesData?.map((table) => (
            <div
              key={table.name}
              className="flex items-center space-x-2 cursor-pointer px-2 py-1 rounded hover:bg-gray-100"
              onClick={() => handleTableSelected(table)}
            >
              <TableCellsIcon className="h-5 w-5 text-gray-500" />
              <span className="text-sm">{table.name}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Tables;
