import { Card, CardContent, CardHeader, CardTitle } from "../../Ui/card";
import CardLoading from "../CardLoading";

export default function HeaderCard({
  loading,
  value,
  error,
  name,
  prefix = "",
  suffix,
  decimals,
}) {
  return (
    <Card className="col-span-1">
      <CardHeader>
        <CardTitle className="break-words">{name}</CardTitle>
      </CardHeader>
      <CardContent className="font-monda text-gray-700">
        {error ? (
          <div className="text-red-400">error</div>
        ) : loading ? (
          <CardLoading className="flex-grow" />
        ) : (
          `${prefix}${Number(value).toLocaleString("en-US", {
            maximumFractionDigits: decimals,
          })} ${suffix}`
        )}
      </CardContent>
    </Card>
  );
}
