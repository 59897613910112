import { Outlet, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import NavBar from "./Charting/NavBar.js";
import { useSidebarState } from "../utils/useSidebarState.js";
import useWindowDimensions from "../Hooks/useWindowDimensions.js";
import ChartSidebarV2 from "./Ui/ChartSidebarV2.js";
import TableSidebar from "./TablePage/TableSidebar.js";

function Explore() {
  const { width } = useWindowDimensions();
  const setSidebar = useSidebarState((state) => state.setSidebar);
  const location = useLocation();

  useEffect(() => {
    if (width <= 768) {
      setSidebar(false);
    } else {
      setSidebar(true);
    }
  }, [width, setSidebar]);

  const [exploreType, setExploreType] = useState(() =>
    location.pathname.includes("Tables")
  );

  useEffect(() => {
    setExploreType(location.pathname.includes("Tables"));
  }, [location.pathname]);

  return (
    <div className="flex flex-col relative z-0 h-full max-w-full mx-auto">
      <nav className="z-10">
        <NavBar />
      </nav>
      <div className="flex flex-row w-full min-h-screen">
        <div className="absolute z-20 md:z-0 md:relative border-r-2 border-secondary">
          {exploreType ? (
            <TableSidebar key="table" />
          ) : (
            <ChartSidebarV2 key="chart" />
          )}
        </div>
        <main className="flex-grow">
          <Outlet />
        </main>
      </div>
    </div>
  );
}

export default Explore;
