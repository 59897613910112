// Created By James Launder
// landing page for charting contains basic info
import SimpleChart from "./SimpleChart.js";
import newLogoV2 from "../../Assets/WevrLogoPrimary.svg";
import SmallChartContainer from "./SmallChartContainer.js";
import SentimentBar from "./SentimentBar.js";
import { useNavigate } from "react-router-dom";
import UseDashboardQuery from "./UseDashboardQuery.js";
import DashboardNewsCard from "./DashboardNewsCard.js";

// loops through and finds the average sentiment
function overallSentiment(data) {
  var overall = 0;
  data.forEach(function (value) {
    overall = overall + value.Sentiment;
  });
  return overall / data.length;
}

function ChartingDashBoard(props) {
  const navigate = useNavigate();
  const { isLoading, isError, isFetching, data } = UseDashboardQuery();
  // all dashboard data query

  if (isLoading || isFetching) {
    return (
      <div className="w-full p-4 bg-surface">
        <div className=" grid grid-cols-1 lg:grid-cols-12 mb-4">
          <div className="grid col-span-8 p-4  mr-4 bg-gray-200 animate-pulse rounded-md border border-onSurfaceVariant ">
            <div className="min-h-[55vh] bg-gray-200 rounded-xl animate-pulse flex items-center justify-center">
              <img src={newLogoV2} alt="..." className="h-20 md:h-28 pl-14" />
            </div>
          </div>

          <div className="grid place-content-around bg-gray-200 animate-pulse p-4 col-span-4 rounded-md border border-onSurfaceVariant"></div>
        </div>
        <div className="w-full bg-gray-200 rounded-md animate-pulse border border-onSurfaceVariant flex justify-around py-4  min-h-[25vh] mt-4"></div>
      </div>
    );
  }
  if (isError) {
    return (
      <main className="grid w-full place-items-center py-24 px-6 sm:py-32 lg:px-8 font-roboto bg-surface">
        <div className="text-center">
          <p className="text-2xl font-semibold text-primary">404</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Error
          </h1>
          <p className="mt-6 text-base leading-7 text-gray-600">
            Sorry, we couldn’t find the page you’re looking for.
          </p>
          <div className="mt-6 flex items-center justify-center gap-x-6">
            <button
              onClick={() => {
                navigate("/");
              }}
              className="rounded-lg bg-primaryContainer px-3 py-2 text-sm font-semibold text-black shadow-sm hover:text-white hover:bg-primary"
            >
              Go back home
            </button>
          </div>
        </div>
      </main>
    );
  }
  return (
    <div className="p-4 w-full bg-surface">
      <div className=" grid grid-cols-1 gap-4 xl:gap-0 xl:grid-cols-12 mb-4">
        <div className="flex flex-col p-1 xl:col-span-7 2xl:col-span-8 lg:mr-4 rounded-md border border-surfaceVariant bg-white shadow-md">
          <div className=" bg-white rounded-md py-2 px-1 pr-4 divide-y relative h-full">
            <div className="flex justify-center font-roboto text-lg sm:text-2xl font-semibold py-4">
              {data.data.mainChart.mainGraphNames.name}
            </div>
            {/* unfixable resizing bug on chart i legit dont even know why */}

            <div className="flex grow xl:relative xl:h-4/5">
              <SimpleChart
                data={data.data.mainChart.mainGraphData}
                name={data.data.mainChart.mainGraphNames.name}
                sidebar={props.sidebar}
              />
            </div>

            <div className="text-sm sm:text-lg p-1">
              {data.data.mainChart.mainGraphNames.description}
            </div>
          </div>
        </div>
        {/* flex */}
        {/* news */}
        <div className="flex-col p-4 space-y-4 xl:col-span-5 2xl:col-span-4 rounded-md border border-surfaceVariant shadow-md">
          <div className="bg-white rounded-md border-b border-surfaceVariant">
            <div className="flex items-center p-2 gap-6 pr-4">
              <div>Overall Sentiment:</div>

              <SentimentBar value={overallSentiment(data.data.news)} />
            </div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {data.data?.news?.map((data) => (
              <DashboardNewsCard data={data} key={data.link} />
            ))}
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 items-center md:grid-cols-2 xl:grid-cols-4 border border-surfaceVariant p-2 rounded-md shadow-md bg-white">
        <div className="justify-center flex flex-col items-center px-2 border-b border-surfaceVariant xl:border-r">
          <SmallChartContainer
            graphName={data.data.smallCharts.smallChart1.smallGraphNames1.name}
            smallChartData={data.data.smallCharts.smallChart1.smallGraphData1}
            timePeriod={"14d"}
            graphHeight={"10vh"}
          />
        </div>

        <div className="justify-center flex flex-col items-center px-2 border-b border-surfaceVariant xl:border-r">
          <SmallChartContainer
            graphName={data.data.smallCharts.smallChart2.smallGraphNames2.name}
            smallChartData={data.data.smallCharts.smallChart2.smallGraphData2}
            timePeriod={"14d"}
            graphHeight={"10vh"}
          />
        </div>
        <div className="justify-center flex flex-col items-center px-2 border-b border-surfaceVariant md:border-none">
          <SmallChartContainer
            graphName={data.data.smallCharts.smallChart3.smallGraphNames3.name}
            smallChartData={data.data.smallCharts.smallChart3.smallGraphData3}
            timePeriod={"14d"}
            graphHeight={"10vh"}
          />
        </div>
        <div className="justify-center flex flex-col items-center px-2 xl:border-l xl:border-surfaceVariant">
          <SmallChartContainer
            graphName={data.data.smallCharts.smallChart4.smallGraphNames4.name}
            smallChartData={data.data.smallCharts.smallChart4.smallGraphData4}
            timePeriod={"14d"}
            graphHeight={"10vh"}
          />
        </div>
      </div>
    </div>
  );
}

export default ChartingDashBoard;
