// Created By James Launder inspired by tailwindUI
// Modal form for mobile additional options

import { Fragment, useState, useEffect, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ArrowPathIcon, ChartBarIcon } from "@heroicons/react/24/outline";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import ColourDropdown from "./ColourDropdown";
import { useSearchParams } from "react-router-dom";
import { useStoreQuery } from "./useStoreQuery";

export default function MobileAdditionalOptions(props) {
  const DELIMITTER = "_";
  const [open, setOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const firstRender = useRef(true);
  const [selectedColour, setSelectedColour] = useState(props.data.color);
  const queryState = useStoreQuery((state) => state.queryUrl);
  const removeQuery = useStoreQuery((state) => state.removeQuery);

  function deleteParamsEntry(params) {
    const key = "cmp";
    var value = "";
    if (props.data.source === null) {
      value =
        props.data.blockchain +
        DELIMITTER +
        props.data.name +
        DELIMITTER +
        props.data.resolution +
        DELIMITTER +
        props.data.color.name +
        DELIMITTER +
        props.data.style.name +
        DELIMITTER +
        props.data.scale.name;
      const newEntries = Array.from(params.entries()).filter(
        ([k, v]) => !(k === key && v === value)
      );
      setSearchParams(newEntries);
    } else {
      value =
        props.data.blockchain +
        DELIMITTER +
        props.data.name +
        DELIMITTER +
        props.data.resolution +
        DELIMITTER +
        props.data.color.name +
        DELIMITTER +
        props.data.style.name +
        DELIMITTER +
        props.data.scale.name +
        DELIMITTER +
        props.data.source;
      const newEntries = Array.from(params.entries()).filter(
        ([k, v]) => !(k === key && v === value)
      );
      setSearchParams(newEntries);
    }
  }

  function updateSearchParams(params, newValue, keyChange) {
    const key = "cmp";
    var value = "";
    var updated = "";
    // create the new thing here!!!
    if (props.data.source === null) {
      switch (
        keyChange // certain key set value correctly
      ) {
        case "colour":
          updated =
            props.data.blockchain +
            DELIMITTER +
            props.data.name +
            DELIMITTER +
            props.data.resolution +
            DELIMITTER +
            newValue +
            DELIMITTER +
            props.data.style.name +
            DELIMITTER +
            props.data.scale.name;
          break;
        case "style":
          updated =
            props.data.blockchain +
            DELIMITTER +
            props.data.name +
            DELIMITTER +
            props.data.resolution +
            DELIMITTER +
            props.data.color.name +
            DELIMITTER +
            newValue +
            DELIMITTER +
            props.data.scale.name;
          break;
        case "scale":
          updated =
            props.data.blockchain +
            DELIMITTER +
            props.data.name +
            DELIMITTER +
            props.data.resolution +
            DELIMITTER +
            props.data.color.name +
            DELIMITTER +
            props.data.style.name +
            DELIMITTER +
            newValue;
          break;
      }
    } else {
      switch (
        keyChange // certain key set value correctly
      ) {
        case "colour":
          updated =
            props.data.blockchain +
            DELIMITTER +
            props.data.name +
            DELIMITTER +
            props.data.resolution +
            DELIMITTER +
            newValue +
            DELIMITTER +
            props.data.style.name +
            DELIMITTER +
            props.data.scale.name +
            DELIMITTER +
            props.data.source;
          break;
        case "style":
          updated =
            props.data.blockchain +
            DELIMITTER +
            props.data.name +
            DELIMITTER +
            props.data.resolution +
            DELIMITTER +
            props.data.color.name +
            DELIMITTER +
            newValue +
            DELIMITTER +
            props.data.scale.name +
            DELIMITTER +
            props.data.source;
          break;
        case "scale":
          updated =
            props.data.blockchain +
            DELIMITTER +
            props.data.name +
            DELIMITTER +
            props.data.resolution +
            DELIMITTER +
            props.data.color.name +
            DELIMITTER +
            props.data.style.name +
            DELIMITTER +
            newValue +
            DELIMITTER +
            props.data.source;
          break;
      }
    }
    if (props.data.source === null) {
      value =
        props.data.blockchain +
        DELIMITTER +
        props.data.name +
        DELIMITTER +
        props.data.resolution +
        DELIMITTER +
        props.data.color.name +
        DELIMITTER +
        props.data.style.name +
        DELIMITTER +
        props.data.scale.name;
      var newEntries = Array.from(params.entries()).filter(
        ([k, v]) => !(k === key && v === value)
      );
      newEntries.push(["cmp", updated]);
      setSearchParams(newEntries);
    } else {
      value =
        props.data.blockchain +
        DELIMITTER +
        props.data.name +
        DELIMITTER +
        props.data.resolution +
        DELIMITTER +
        props.data.color.name +
        DELIMITTER +
        props.data.style.name +
        DELIMITTER +
        props.data.scale.name;
      const newEntries = Array.from(params.entries()).filter(
        ([k, v]) => !(k === key && v === value)
      );
      newEntries.push(["cmp", updated]);

      setSearchParams(newEntries);
    }
  }
  const updateColour = (updated, name, blockchain, source, resolution) => {
    props.setAdditionalCharts((prevState) => {
      const newState = [...prevState];
      newState[props.index].color = updated;
      return newState;
    });
    props.setUpdateChart({
      key: "color",
      name: name,
      blockchain: blockchain,
      update: updated,
      source: source,
      resolution: resolution,
    });
  };
  const updateStyle = (updated, name, blockchain, source, resolution) => {
    updateSearchParams(searchParams, updated.name, "style");
    props.setAdditionalCharts((prevState) => {
      const newState = [...prevState];
      newState[props.index].style = updated;
      return newState;
    });
    props.setUpdateChart({
      key: "style",
      name: name,
      blockchain: blockchain,
      update: updated,
      source: source,
      resolution: resolution,
    });
  };
  const updateScale = (updated, name, blockchain, source, resolution) => {
    updateSearchParams(searchParams, updated.name, "scale");
    props.setAdditionalCharts((prevState) => {
      const newState = [...prevState];
      newState[props.index].scale = updated;
      return newState;
    });
    props.setUpdateChart({
      key: "scale",
      name: name,
      blockchain: blockchain,
      update: updated,
      source: source,
      resolution: resolution,
    });
  };
  // removes the chart from url, internal state and querystate
  const deleteChart = () => {
    var queryIndex = -1;
    if (props.data.source === null) {
      queryIndex = queryState.findIndex(
        (obj) =>
          obj.key ===
          props.data.blockchain + props.data.resolution + props.data.name
      );

      deleteParamsEntry(searchParams);
    } else {
      queryIndex = queryState.findIndex(
        (obj) =>
          obj.key ===
          props.data.blockchain +
            props.data.resolution +
            props.data.name +
            props.data.source
      );
      deleteParamsEntry(searchParams);
    }

    props.setRemove({
      name: props.data.name,
      blockchain: props.data.blockchain,
      source: props.data.source,
      resolution: props.data.resolution,
    });
    props.setAdditionalCharts((prevState) => {
      const newState = [...prevState];
      newState.splice(props.index, 1);
      return newState;
    });
    removeQuery(queryIndex);
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    updateSearchParams(searchParams, selectedColour.name, "colour");

    updateColour(
      selectedColour,
      props.data.name,
      props.data.blockchain,
      props.data.source,
      props.data.resolution
    );
  }, [selectedColour]);
  return (
    <div className="font-roboto ">
      {props.graphData[props.index + 2]?.isLoading ||
      props.graphData[props.index + 2]?.isFetching ? (
        <div>
          <button
            style={{
              border: "2px solid " + props.data.color.hex,
            }}
            className="group flex flex-row md:bg-primaryContainer px-3 py-2 hover:bg-primary hover:text-white rounded-lg"
          >
            <div className="md:hidden flex">
              <ArrowPathIcon
                className="h-6 w-6 font-bold text-secondary self-center group-hover:text-white animate-spin"
                aria-hidden="true"
              />
            </div>
          </button>
        </div>
      ) : (
        <div>
          {props.graphData[props.index + 2]?.isError ? (
            <div>
              <button
                style={{
                  border: "2px solid " + props.data.color.hex,
                }}
                onClick={() => {
                  deleteChart();
                }}
                className="group flex flex-row md:bg-primaryContainer px-3 py-2 hover:bg-primary hover:text-white rounded-lg"
              >
                <div className="md:hidden flex">
                  <ExclamationCircleIcon
                    className="h-6 w-6 font-bold text-red-400 self-center group-hover:text-white"
                    aria-hidden="true"
                  />
                </div>
              </button>
            </div>
          ) : (
            <div>
              <button
                style={{
                  border: "2px solid " + props.data.color.hex,
                }}
                onClick={() => {
                  setOpen(true);
                }}
                className="group flex flex-row md:bg-primaryContainer px-3 py-2 hover:bg-primary hover:text-white rounded-lg"
              >
                <div className="md:hidden flex">
                  <ChartBarIcon
                    className="h-6 w-6 font-bold text-secondary self-center group-hover:text-white"
                    aria-hidden="true"
                  />
                </div>
              </button>
              <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={setOpen}>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                  </Transition.Child>

                  <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-[75%] items-end justify-center p-4 text-center sm:items-center sm:p-0">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      >
                        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6 ">
                          <div className="px-2 py-2 flex gap-2 justify-between items-center">
                            <div className="flex gap-2 py-1.5">
                              <h1 className="text-gray-900 min-w-[78px]">
                                Blockchain
                              </h1>
                              <div>{props.data.blockchain}</div>
                            </div>
                          </div>
                          <div className="px-2 py-2 flex gap-2 justify-between items-center">
                            <div className="flex gap-2 py-1.5">
                              <h1 className="text-gray-900 min-w-[78px]">
                                Name
                              </h1>
                              <div>{props.data.name}</div>
                            </div>
                          </div>
                          <div className="px-2 py-2 flex gap-2 items-center">
                            <div className="flex gap-2 py-1.5">
                              <h1 className="text-gray-900 min-w-[78px]">
                                Resolution
                              </h1>
                              <div>{props.data.resolution}</div>
                            </div>
                          </div>
                          <div className="px-2 py-2 flex gap-2 items-center">
                            <h1 className="text-gray-900 pt-1 min-w-[78px]">
                              Colour
                            </h1>
                            <div className="w-full mb-1">
                              <ColourDropdown
                                title={""}
                                data={props.colour}
                                selected={selectedColour}
                                setSelected={setSelectedColour}
                              />
                            </div>
                          </div>

                          <div className="px-2 py-3 flex gap-2 items-center">
                            <h1 className="text-gray-900 min-w-[78px]">
                              Style
                            </h1>
                            <span className="grid grid-flow-col rounded-lg bg-white text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 w-full ">
                              {props.style.map((data) => (
                                <button
                                  type="button"
                                  key={data.name + " combo"}
                                  onClick={() => {
                                    //   // params("resolution", data);
                                    updateStyle(
                                      data,
                                      props.data.name,
                                      props.data.blockchain,
                                      props.data.source,
                                      props.data.resolution
                                    );
                                    // setSelectedStyle(data);
                                  }}
                                  className={`${
                                    props.data.style.name === data.name
                                      ? "bg-primary text-white text-bold"
                                      : "text-gray-900 hover:bg-primary/30 hover:text-gray-900"
                                  } rounded-lg py-1.5 text-gray-900 `}
                                >
                                  {data.name}
                                </button>
                              ))}
                            </span>
                          </div>
                          <div className="px-2 py-3 flex gap-2 items-center">
                            <h1 className="text-gray-900 min-w-[78px]">
                              Scale
                            </h1>

                            <span className="grid grid-flow-col rounded-lg bg-white text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 w-full">
                              {props.scale.map((data) => (
                                <button
                                  type="button"
                                  key={data.name + " combo"}
                                  onClick={() => {
                                    //   // params("resolution", data);
                                    updateScale(
                                      data,
                                      props.data.name,
                                      props.data.blockchain,
                                      props.data.source,
                                      props.data.resolution
                                    );
                                  }}
                                  className={`${
                                    props.data.scale.name === data.name
                                      ? "bg-primary text-white text-bold"
                                      : "text-gray-900 hover:bg-primary/30 hover:text-gray-900"
                                  } rounded-lg py-1.5 text-gray-900 `}
                                >
                                  {data.name}
                                </button>
                              ))}
                            </span>
                          </div>
                          <div className="px-2 py-2 flex gap-2 items-center mt-5 sm:mt-6">
                            <div className="flex gap-2 justify-between w-full">
                              <button
                                className="py-2 px-3 bg-red-400 hover:bg-red-500 text-white rounded-lg"
                                onClick={() => {
                                  deleteChart();
                                }}
                              >
                                Remove
                              </button>
                              <button
                                className="py-2 px-3 bg-primaryContainer  rounded-lg"
                                onClick={() => {
                                  setOpen(false);
                                }}
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition.Root>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
