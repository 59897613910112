import { useState } from "react";
import { Card, CardContent } from "../../Ui/card";
import CardLoading from "../CardLoading";
import ProfitChart from "./ProfitChart";
import { Tabs, TabsList, TabsTrigger } from "../../Ui/tabs";
import classNames from "../../../utils/ClassNames";

const activeClassName = "border-b-2 border-primary text-white bg-primary";
const notActiveClassName = "text-black hover:text-primary";
export default function ProfitChartContainer({
  balanceLoading,
  balanceData,
  error,
}) {
  const [showCumulative, setShowCumulative] = useState(true);
  const formattedBalanceData =
    balanceData?.map((entry) => ({
      x: new Date(entry.time).getTime(), // Convert time to timestamp
      y: showCumulative
        ? entry.cumulative_balance_in_eth
        : entry.balance_change_in_eth,
    })) || [];
  return (
    <div>
      <Card>
        <div className="flex justify-center items-center py-3">
          <Tabs defaultValue="tab1">
            <TabsList className="text-sm font-monda">
              <TabsTrigger
                onClick={() => {
                  setShowCumulative(false);
                }}
                className={classNames(
                  !showCumulative ? activeClassName : notActiveClassName,
                  "text-sm md:text-base"
                )}
              >
                Balance Change
              </TabsTrigger>
              <TabsTrigger
                onClick={() => {
                  setShowCumulative(true);
                }}
                className={classNames(
                  showCumulative ? activeClassName : notActiveClassName,
                  "text-sm md:text-base"
                )}
              >
                Cumulative Balance
              </TabsTrigger>
            </TabsList>
          </Tabs>
        </div>

        <CardContent>
          {error ? (
            <div className="h-64 font-monda text-5xl flex flex-col gap-4 text-center mt-6 text-red-400">
              error
              <p className="text-gray-700 text-3xl">
                Can&apos;t retrieve values from this wallet address
              </p>
              <p className="text-gray-700 text-3xl">Refresh and try again</p>
            </div>
          ) : balanceLoading ? (
            <CardLoading className="flex-grow h-64" />
          ) : (
            <ProfitChart data={formattedBalanceData} />
          )}
        </CardContent>
      </Card>
    </div>
  );
}
