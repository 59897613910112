import { Fragment, useState } from "react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "../../Ui/Command";
import UseGetUserFootprints from "../API/UseGetUserFootprints";
import { useNavigate } from "react-router-dom";
import GoToAddress from "../Utilities/GoToAddress";
import { CheckIcon, PencilIcon, XMarkIcon } from "@heroicons/react/24/outline";
import classNames from "../../../utils/ClassNames";
import { Dialog, Transition } from "@headlessui/react";

export default function FootprintsDialog({
  open,
  setOpen,
  dialog,
  setEdit,
  setOpenDialog,
}) {
  //   const [open, setOpen] = React.useState(false)
  const [value, setValue] = useState(false);
  const navigate = useNavigate();

  const { isLoading, isError, data, error } = UseGetUserFootprints();

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden  bg-white rounded-lg pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <Command className="bg-white px-4 pb-4 pt-4">
                  <div className=" flex gap-4 w-full">
                    <CommandInput
                      placeholder="Search footprints..."
                      className="w-full"
                    />
                    <button
                      type="button"
                      className="rounded-md text-gray-400 hover:text-gray-500 p-1"
                      onClick={() => {
                        // setErrorMsg("");
                        // clearClose();
                        setOpen(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon
                        className="h-6 w-6 group-hover:text-white "
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                  <CommandEmpty>No footprints found.</CommandEmpty>

                  <CommandGroup>
                    {isError ? (
                      error?.response?.status === 401 ? (
                        <CommandItem>Login/Sign up!</CommandItem>
                      ) : (
                        <CommandItem>Error Fetching!</CommandItem>
                      )
                    ) : isLoading ? (
                      <CommandItem>Loading...</CommandItem>
                    ) : data?.data?.length === 0 ? (
                      "No Footprints found"
                    ) : (
                      data?.data?.map((item) => (
                        <div key={item.name} className="flex">
                          <CommandItem key={item.name} value={item.name}>
                            <button
                              onSelect={(currentValue) => {
                                setValue(
                                  currentValue === value ? "" : currentValue
                                );
                                setOpen(false);
                              }}
                              onClick={() => {
                                GoToAddress(item.addresses.address, navigate);
                              }}
                              className="flex flex-grow hover:text-primary transition-all border-r"
                            >
                              <CheckIcon
                                className={classNames(
                                  "mr-2 h-4 w-4",
                                  value === item.name
                                    ? "opacity-100"
                                    : "opacity-0"
                                )}
                              />
                              {item.name}
                            </button>
                            <button
                              onClick={() => {
                                setOpen(false);
                                setOpenDialog(true);
                                dialog({
                                  _id: item._id,
                                  name: item.name,
                                  address: item.addresses.address,
                                });
                                setEdit(true);
                              }}
                            >
                              <PencilIcon className="ml-2 h-4 w-4 text-gray-700 hover:text-primary transition-all" />
                            </button>
                          </CommandItem>
                        </div>
                      ))
                    )}
                    <CommandItem>
                      <button
                        className="border-t-2 border-dashed border-gray-200 pt-3 font-semibold w-full"
                        onClick={() => {
                          setOpen(false);

                          setOpenDialog(true);
                          dialog({
                            _id: null,
                            name: "",
                            address: "",
                          });
                          setEdit(false);
                        }}
                      >
                        Add +
                      </button>
                    </CommandItem>
                  </CommandGroup>
                </Command>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
