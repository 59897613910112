import { useMutation, useQueryClient } from "@tanstack/react-query";
import request from "../../../Api/Api.js";
import { useState } from "react";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";

export default function UseDeleteUserFootprint(id) {
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  function getToken() {
    if (localStorage.getItem("user") != null) {
      return JSON.parse(localStorage.getItem("user")).accessToken;
    } else {
      return "";
    }
  }
  const mutation = useMutation({
    mutationFn: async () => {
      setLoading(true);
      setError(false);
      setMessage("");
      setSuccess(false);
      return await request.delete("/footprint/" + id, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
    },
    mutationKey: ["removeFootprint " + id],
    onSuccess: async (id) => {
      setLoading(false);
      setSuccess(true);
      await queryClient.cancelQueries({ queryKey: ["getFootprints"] });
      const prev = queryClient.getQueryData(["getFootprints"]);
      queryClient.setQueryData(["getFootprints"], (old) => {
        let update = old.data.filter((item) => item._id != id);
        [...update];
      });

      return { prev };
    },
    onError: (error, id, context) => {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      setMessage(
        <div className="text-red-500 font-semibold flex gap-1 self-center">
          <ExclamationCircleIcon className="h-6 w-6 self-center mt-[1px]" />
          {resMessage}
        </div>
      );
      setError(true);
      setLoading(false);
      queryClient.setQueryData(["getFootprints"], context.prev);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["getFootprints"] });
    },
  });

  return {
    mutation: mutation,
    loading: loading,
    error: error,
    success: success,
    message: message,
  };
}
