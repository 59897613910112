// GetAllTableStores.js
import { useQuery } from "@tanstack/react-query";
import request from "../../../Api/Api.js";

export function useAllTableStores() {
  return useQuery({
    queryKey: ["allTableStores"],
    queryFn: async () => {
      const blockchains = ["ethereum", "bitcoin"]; // Add all your blockchains here
      const promises = blockchains.map((blockchain) =>
        request.get(`/tableStores/${blockchain}`).then((response) => ({
          blockchain,
          data: response.data.tableStores,
        }))
      );
      const results = await Promise.all(promises);
      return results.reduce((acc, result) => {
        acc[result.blockchain] = result.data;
        return acc;
      }, {});
    },
  });
}
