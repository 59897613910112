import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ArrowPathIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import UseAddUserFootprint from "../API/UseAddUserFootprint";
import UseUpdateUserFootprint from "../API/UseUpdateUserFootprint";
import UseDeleteUserFootprint from "../API/UseDeleteUserFootprint";
import classNames from "../../../utils/ClassNames";

export default function AddDialog({
  open,
  setOpen,
  dataId,
  dataName,
  dataAddress,
  edit,
}) {
  const [newName, setName] = useState(dataName ?? "");
  const [newAddress, setAddress] = useState(dataAddress ?? "");
  const [message, setMessage] = useState("");
  const {
    mutation: mutation,
    loading: loading,
    error: error,
    success: success,
    message: errMessage,
  } = UseAddUserFootprint(!edit);
  const {
    mutation: mutationEdit,
    loading: loadingEdit,
    error: errorEdit,
    success: successEdit,
    message: errMessageEdit,
  } = UseUpdateUserFootprint(dataId);
  const {
    mutation: mutationDelete,
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
    message: errMessageDelete,
  } = UseDeleteUserFootprint(dataId);
  const submit = () => {
    if (edit) {
      mutationEdit.mutate({
        name: newName,
        address: newAddress,
      });
    } else {
      mutation.mutate({
        name: newName,
        address: newAddress,
      });
    }
  };
  useEffect(() => {
    setAddress(dataAddress);
  }, [dataAddress]);
  useEffect(() => {
    setName(dataName);
  }, [dataName]);
  useEffect(() => {
    if (success || successEdit || successDelete) {
      setOpen(false);
    }
  }, [success, successEdit, successDelete]);

  useEffect(() => {
    setMessage(errMessage || errMessageEdit || errMessageDelete);
  }, [error, errorEdit, errorDelete]);
  useEffect(() => {
    if (!open) {
      setMessage("");
      setName("");
      setAddress("");
    }
  }, [open]);
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden  bg-white rounded-lg pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block ">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                    onClick={() => {
                      // setErrorMsg("");
                      // clearClose();
                      setOpen(false);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="bg-white px-8 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start ">
                    <div className="mt-3 text-center sm:mt-2 sm:text-left  w-full ">
                      <div className="flex flex-row items-center">
                        <div className="flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-orange-100 sm:mx-0 sm:h-10 sm:w-10">
                          <PlusIcon
                            className="h-6 w-6 text-primary"
                            aria-hidden="true"
                          />
                        </div>
                        <Dialog.Title
                          as="h3"
                          className="ml-4 text-base font-semibold leading-6 text-gray-900"
                        >
                          Add new footprint
                        </Dialog.Title>
                      </div>
                      <div className="mt-4 pt-4 w-full border-t pb-2 block text-md font-medium leading-6 text-gray-900">
                        Name
                      </div>
                      <div>
                        <input
                          type="text"
                          name="footprint-name"
                          placeholder="Name"
                          className={`block border mt-1 border-onSurfaceVariant w-full py-2 pl-3 pr-9 rounded-md focus:border-primary focus:outline-none`}
                          onInput={(e) => setName(e.target.value)}
                          value={newName}
                        />
                      </div>
                      <div className="mt-4 w-full border-t pt-4 pb-2 block text-md font-medium leading-6 text-gray-900">
                        Address
                      </div>
                      <div>
                        <input
                          type="text"
                          name="footprint-Address"
                          placeholder="Address"
                          className={`block border mt-1 border-onSurfaceVariant w-full py-2 pl-3 pr-9 rounded-md focus:border-primary focus:outline-none`}
                          value={newAddress}
                          onInput={(e) => setAddress(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50  w-full flex  justify-between items-center p-6 mt-4">
                  <div className="flex flex-grow">
                    {error || errorEdit || errorDelete ? (
                      <div>{message}</div>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className=" sm:flex sm:flex-row-reverse h-full">
                    <button
                      type="submit"
                      className={classNames(
                        loading || loadingEdit || loadingDelete
                          ? "bg-gray-300 text-gray-400"
                          : "hover:text-white hover:bg-primary bg-primaryContainer",
                        "inline-flex gap-4 w-full justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm sm:ml-3 sm:w-auto"
                      )}
                      onClick={() => {
                        submit();
                      }}
                      disabled={loading || loadingEdit || loadingDelete}
                    >
                      {loading || loadingEdit ? (
                        <ArrowPathIcon className="h-5 w-5 animate-spin" />
                      ) : (
                        <div className="hidden"></div>
                      )}
                      {edit ? "Update" : "Add"}
                    </button>
                    <button
                      type="submit"
                      className={classNames(
                        edit
                          ? loadingDelete
                            ? "bg-gray-300 text-gray-400"
                            : "bg-red-400 hover:bg-red-300 text-white"
                          : "bg-white hover:bg-gray-50 text-gray-900",
                        "mt-3 inline-flex gap-4 w-full justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm  sm:mt-0 sm:w-auto"
                      )}
                      onClick={() => {
                        {
                          edit ? mutationDelete.mutate(dataId) : setOpen(false);
                        }
                        // setOpen(false);
                        // clearClose();
                      }}
                      disabled={loadingDelete}
                    >
                      {loadingDelete ? (
                        <ArrowPathIcon className="h-5 w-5 animate-spin" />
                      ) : (
                        <div className="hidden"></div>
                      )}
                      {edit ? "Delete" : "Cancel"}
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
