import { useMutation, useQueryClient } from "@tanstack/react-query";
import request from "../../../Api/Api.js";
import { useState } from "react";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";

export default function UseAddUserFootprint(optimistic = false) {
  function getToken() {
    if (localStorage.getItem("user") != null) {
      return JSON.parse(localStorage.getItem("user")).accessToken;
    } else {
      return "";
    }
  }

  //   const openSuccess = useStoreNotificationPopup((state) => state.open);
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const mutation = useMutation({
    mutationFn: async ({ name, address }) => {
      setLoading(true);
      setError(false);
      setMessage("");
      setSuccess(false);
      return await request.post(
        "/footprint",
        {
          name: name,
          address: address,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
    },
    mutationKey: ["addFootprint"],
    onSuccess: async (data) => {
      // setSuccessful(true);
      setLoading(false);
      setSuccess(true);
      if (optimistic) {
        await queryClient.cancelQueries({ queryKey: ["getFootprints"] });
        const prev = queryClient.getQueryData(["getFootprints"]);
        queryClient.setQueryData(["getFootprints"], (old) => [
          ...old.data,
          data,
        ]);
        return { prev };
      } else {
        // openSuccess("Successfully added alert!");
      }
    },
    onError: (error, id, context) => {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      setMessage(
        <div className="text-red-500 font-semibold flex gap-1 self-center">
          <ExclamationCircleIcon className="h-6 w-6 self-center mt-[1px]" />
          {resMessage}
        </div>
      );
      setError(true);
      setLoading(false);
      if (optimistic) {
        queryClient.setQueryData(["getFootprints"], context.prev);
      }
      console.error(resMessage);
    },
    onSettled: () => {
      setLoading(false);
      if (optimistic) {
        queryClient.invalidateQueries({ queryKey: ["getFootprints"] });
      }
    },
  });

  return {
    mutation: mutation,
    loading: loading,
    error: error,
    success: success,
    message: message,
  };
}
