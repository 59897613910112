// Created by James Launder
// background component used by many pages

import TwistComponentPrimary from "./Homepage/TwistComponentPrimary";

function Background() {
  return (
    <div className="relative">
      <TwistComponentPrimary className="absolute -z-10 rotate-[-20deg] top-[-250px] sm:top-[-400px] md:top-[-400px] lg:top-[-450px] xl:top-[-500px] 2xl:top-[-650px] left-[-450px] sm:left-[-250px] md:left-[-450px] lg:left-[-500px] xl:left-[-800px] 2xl:left-[-850px] h-[800px] w-[800px] lg:h-[900px] lg:w-[900px] xl:h-[1100px] xl:w-[1100px] 2xl:h-[1300px] 2xl:w-[1300px] blur-sm block" />
      <TwistComponentPrimary className="absolute -z-10 rotate-[-120deg] top-[-250px] sm:top-[-300px] lg:top-[-350px] xl:top-[-500px] 2xl:top-[-600px] left-[50px] sm:left-[250px] md:left-[200px] lg:left-[250px] xl:left-[120px] 2xl:left-[120px] h-[800px] w-[800px] md:h-[800px] md:w-[800px] lg:h-[900px] lg:w-[900px] xl:h-[1100px] xl:w-[1100px] 2xl:h-[1300px] 2xl:w-[1300px] blur-sm block" />
    </div>
  );
}

export default Background;
