import { useQuery } from "@tanstack/react-query";
import request from "../../Api/Api.js";
import { useSearchParams } from "react-router-dom";

export default function UseDashboardQuery() {
  const [searchParams] = useSearchParams();

  const { isLoading, isError, isFetching, data } = useQuery({
    queryKey: [searchParams.get("blockchain"), "dashboard"],
    queryFn: async () => {
      const data = await request
        .get("/staticDash/" + searchParams.get("blockchain"))
        .catch(function (error) {
          return error.response;
        });
      return data;
    },
    staleTime: 1000,
  });

  return { isLoading, isError, isFetching, data };
}
