import * as React from "react";
import { cn } from "../../utils/utils";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/react/24/solid";
const getButtonVariantClass = (variant, size) => {
  let baseClass =
    "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50";
  let variantClass = "";
  let sizeClass = "";

  switch (variant) {
    case "destructive":
      variantClass =
        "bg-destructive text-destructive-foreground hover:bg-destructive/90";
      break;
    case "outline":
      variantClass =
        "border border-input bg-background hover:bg-accent hover:text-accent-foreground";
      break;
    case "secondary":
      variantClass =
        "bg-secondary text-secondary-foreground hover:bg-secondary/80";
      break;
    case "ghost":
      variantClass = "hover:bg-accent hover:text-accent-foreground";
      break;
    case "link":
      variantClass = "text-primary underline-offset-4 hover:underline";
      break;
    default:
      variantClass = "bg-primary text-primary-foreground hover:bg-primary/90";
  }

  switch (size) {
    case "sm":
      sizeClass = "h-9 rounded-md px-3";
      break;
    case "lg":
      sizeClass = "h-11 rounded-md px-8";
      break;
    case "icon":
      sizeClass = "h-10 w-10";
      break;
    default:
      sizeClass = "h-10 px-4 py-2";
  }

  return `${baseClass} ${variantClass} ${sizeClass}`;
};
const Pagination = ({ className, ...props }) => (
  <nav
    role="navigation"
    aria-label="pagination"
    className={cn("mx-auto flex w-full justify-center", className)}
    {...props}
  />
);

const PaginationContent = React.forwardRef(({ className, ...props }, ref) => (
  <ul
    ref={ref}
    className={cn("flex flex-row items-center gap-1", className)}
    {...props}
  />
));
PaginationContent.displayName = "PaginationContent";

const PaginationItem = React.forwardRef(({ className, ...props }, ref) => (
  <li ref={ref} className={cn("list-none", className)} {...props} />
));
PaginationItem.displayName = "PaginationItem";

const PaginationLink = ({
  className,
  isActive,
  size = "icon",
  href,
  ...props
}) => {
  const Component = href ? "a" : "button";
  return (
    <PaginationItem>
      <Component
        aria-current={isActive && href ? "page" : undefined}
        className={cn(
          getButtonVariantClass(isActive ? "outline" : "ghost", size),
          className
        )}
        href={href}
        {...props}
      />
    </PaginationItem>
  );
};
PaginationLink.displayName = "PaginationLink";

const PaginationPrevious = ({ className, ...props }) => (
  <PaginationLink
    aria-label="Go to previous page"
    size="default"
    className={cn("gap-1 pl-2.5", className)}
    {...props}
  >
    <ChevronLeftIcon className="h-4 w-4" />
    <span>Previous</span>
  </PaginationLink>
);

const PaginationNext = ({ className, ...props }) => (
  <PaginationLink
    aria-label="Go to next page"
    size="default"
    className={cn("gap-1 pr-2.5", className)}
    {...props}
  >
    <span>Next</span>
    <ChevronRightIcon className="h-4 w-4" />
  </PaginationLink>
);

const PaginationEllipsis = ({ className, ...props }) => (
  <span
    aria-hidden
    className={cn("flex h-9 w-9 items-center justify-center", className)}
    {...props}
  >
    <EllipsisHorizontalIcon className="h-4 w-4" />
    <span className="sr-only">More pages</span>
  </span>
);
export default Pagination;
export {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
};
