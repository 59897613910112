import ConnectWalletButton from "../connectWallet";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Tabs, TabsList, TabsTrigger } from "../../Ui/tabs";
import MobileNavBar from "../NavBar/MobileNavBar";
import classNames from "../../../utils/ClassNames";
import { NavButton } from "../../Ui/NavButton";
import NavBarCombo from "../NavBar/NavBarCombo";
import AddDialog from "./AddDialog";
import useWindowDimensions from "../../../Hooks/useWindowDimensions";
import {
  ListBulletIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import SearchDialog from "./SearchDialog";
import FootprintsDialog from "./FootprintsDialog";
import { useToast } from "../../Ui/use-toast";

const activeClassName = "border-b-2 border-primary text-white bg-primary";
const notActiveClassName = "text-black hover:text-primary";
function Navbar() {
  const [addressInput, setAddressInput] = useState("");
  const [dialogData, setDialogData] = useState({
    _id: null,
    name: "",
    address: "",
  });
  const [focused, setFocused] = useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);
  const [edit, setEdit] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openMobileSearch, setOpenMobileSearch] = useState(false);
  const [openMobileFootprints, setOpenMobileFootprints] = useState(false);
  const { toast } = useToast();

  const navigate = useNavigate();
  const location = useLocation();
  const { width } = useWindowDimensions();
  // Function to handle tab changes
  const handleTabChange = (tabRoute) => {
    let currentAddress = addressInput;

    // If the addressInput is empty, try getting the address from the URL
    if (!currentAddress) {
      currentAddress = new URLSearchParams(location.search).get("address");
    }

    // Alert the user if no address is available
    if (!currentAddress) {
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Please enter address!",
      });
      return;
    }

    // Navigate to the new tab with the available address
    navigate(`/Footprint/${tabRoute}?address=${currentAddress}`);
  };
  const handleAddressInput = (event) => {
    setAddressInput(event.target.value);
  };

  const handleFormSubmit = () => {
    // Remove the leading and trailing slashes to ensure consistent splitting
    if (!addressInput.trim()) {
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Please enter address!",
      });
      return; // Stop the function execution if no address is entered
    }
    const trimmedPath = location.pathname.replace(/^\/|\/$/g, "");
    const pathSegments = trimmedPath.split("/");

    // The tab is the second segment after 'Footprint'
    let currentTab =
      pathSegments.length > 1 ? pathSegments[1].toLowerCase() : "portfolio";

    // If currentTab is 'footprint', set it to 'portfolio'
    if (currentTab === "footprint") {
      currentTab = "portfolio";
    }

    const newPath = `/Footprint/${currentTab}?address=${addressInput || ""}`;
    navigate(newPath);
  };

  const currentTab = location.pathname.split("/").pop();

  //tabs for mobile
  const tabOptions = [
    { label: "Portfolio", route: "portfolio" },
    { label: "Transactions", route: "transactions" },
    { label: "NFT Footprint", route: "nfts" },
    { label: "Visualiser", route: "networkgraph" },
    { label: "Analytics", route: "analytics" },
  ];
  const [selectedTab, setSelectedTab] = useState(tabOptions[0]); // Assuming the first tab is the default

  return (
    <div>
      <div className="w-full border-b border-secondary">
        <div className="min-h-16 px-3 py-3 md:grid grid-cols-1 flex flex-row md:grid-cols-3 md:justify-items-stretch">
          <div className="flex w-full col-span-1 sm:w-fit xl:mr-10 2xl:mr-8">
            {width < 1120 ? (
              <div className="w-full sm:w-fit flex gap-2">
                <NavButton
                  type="button"
                  className="h-full rounded-md border border-onSurfaceVariant bg-white hover:bg-primary group p-2 shadow-md mr-2"
                  onClick={() => setOpenMobileFootprints(true)}
                >
                  <ListBulletIcon className="h-5 w-5 text-onSurfaceVariant group-hover:text-white" />
                </NavButton>
                <NavButton
                  type="button"
                  className="h-full rounded-md border border-onSurfaceVariant bg-white hover:bg-primary group p-2 shadow-md"
                  onClick={() => setOpenMobileSearch(true)}
                >
                  <MagnifyingGlassIcon className="h-5 w-5 text-onSurfaceVariant group-hover:text-white" />
                </NavButton>
                <MobileNavBar
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                  handleTabChange={handleTabChange}
                  tabOptions={tabOptions}
                />
              </div>
            ) : (
              <>
                <NavBarCombo
                  focused={focused}
                  setOpenDialog={setOpenDialog}
                  dialog={setDialogData}
                  edit={edit}
                  setEdit={setEdit}
                />
                <input
                  id="addressInput"
                  type="text"
                  placeholder="Add Address... 0x.."
                  value={addressInput}
                  onChange={handleAddressInput}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  className={classNames(
                    "h-full ml-2 xl:text-base 2xl:text-lg text-sm border px-2 xl:py-2 2xl:py-2.5 sm:w-20 xl:w-32 2xl:w-40 focus:outline-0 focus:w-full duration-500 border-onSurfaceVariant shadow-lg rounded-l-md"
                  )}
                />
                <NavButton
                  type="button"
                  variant="semibold"
                  onClick={() => handleFormSubmit()}
                  className="h-full rounded-r-md border-t border-b border-r border-onSurfaceVariant bg-white hover:bg-primary group p-3 shadow-md"
                >
                  Go
                </NavButton>
              </>
            )}
          </div>
          <div className="hidden sm:col-span-1 w-full flex-grow sm:flex justify-center">
            <Tabs
              defaultValue="tab1"
              className="sm:flex sm:px-1 hidden md:flex gap-x-4 px-4 items-center justify-center text-center"
            >
              <TabsList className="">
                <TabsTrigger
                  onClick={() => handleTabChange("portfolio")}
                  className={classNames(
                    currentTab === "portfolio"
                      ? activeClassName
                      : notActiveClassName
                  )}
                >
                  Portfolio
                </TabsTrigger>
                <TabsTrigger
                  onClick={() => handleTabChange("transactions")}
                  className={
                    currentTab === "transactions"
                      ? activeClassName
                      : notActiveClassName
                  }
                >
                  Transactions
                </TabsTrigger>
                <TabsTrigger
                  onClick={() => handleTabChange("nfts")}
                  className={
                    currentTab === "nfts" ? activeClassName : notActiveClassName
                  }
                >
                  NFT Footprint
                </TabsTrigger>
                <TabsTrigger
                  onClick={() => handleTabChange("networkgraph")}
                  className={
                    currentTab === "networkgraph"
                      ? activeClassName
                      : notActiveClassName
                  }
                >
                  Visualiser
                </TabsTrigger>
                <TabsTrigger
                  onClick={() => handleTabChange("analytics")}
                  className={
                    currentTab === "analytics"
                      ? activeClassName
                      : notActiveClassName
                  }
                >
                  Analytics
                </TabsTrigger>
              </TabsList>
            </Tabs>
          </div>

          <div className="justify-end md:flex hidden">
            <ConnectWalletButton onAddressConnect={setAddressInput} />
          </div>
        </div>
      </div>
      <AddDialog
        open={openDialog}
        setOpen={setOpenDialog}
        dataId={dialogData._id}
        dataName={dialogData.name}
        dataAddress={dialogData.address}
        edit={edit}
      />
      <SearchDialog open={openMobileSearch} setOpen={setOpenMobileSearch} />
      <FootprintsDialog
        open={openMobileFootprints}
        setOpen={setOpenMobileFootprints}
        setOpenDialog={setOpenDialog}
        dialog={setDialogData}
        edit={edit}
        setEdit={setEdit}
      />
    </div>
  );
}

export default Navbar;
