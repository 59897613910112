// src/API/Query.js
import { useMutation } from "@tanstack/react-query";
import request from "../../../Api/Api.js";
import { useToast } from "../../Ui/use-toast";

function getToken() {
  if (localStorage.getItem("user") != null) {
    return JSON.parse(localStorage.getItem("user")).accessToken;
  } else {
    return "";
  }
}

const useCustomQueryMutation = ({ setTimer, setTimerActive, setShowTimer }) => {
  const { toast } = useToast();

  return useMutation({
    mutationFn: (query) =>
      request.post(
        "/executeCustomQuery",
        { customQuery: query },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      ),
    onMutate: () => {
      // Start the timer
      setTimer(0); // Reset timer whenever a new mutation starts
      setTimerActive(true); // Activate timer
      setShowTimer(true); // Show the timer as soon as the query starts
    },
    onSuccess: () => {
      setTimerActive(false); // Deactivate timer once query is settled (either onSuccess or onError)
    },
    onError: (error) => {
      // Actions on error
      console.error("Query error:", error);
      setTimerActive(false); // Stop timer even on error

      if (error.response) {
        if (error.response.status === 400) {
          toast({
            variant: "destructive",
            title: "Bad Request",
            description: "The query is invalid. Please check your syntax.",
          });
        } else if (error.response.status === 401) {
          toast({
            variant: "destructive",
            title: "Unauthorized",
            description: "You are not authorized to perform this action.",
          });
        } else {
          toast({
            variant: "destructive",
            title: "Error!",
            description: error.response.data?.error || "An error occurred",
          });
        }
      } else {
        toast({
          variant: "destructive",
          title: "Error!",
          description: "An error occurred",
        });
      }
    },
  });
};

export default useCustomQueryMutation;
