import { XMarkIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";

export default function CouponBanner({ enabled, setEnabled }) {
  const navigate = useNavigate();
  if (!enabled) {
    return <></>;
  }
  return (
    <div className="w-full h-16 sm:h-8 bg-primary text-white flex justify-center">
      {/* desktop */}
      <button
        className="font-monda text-white text-sm sm:text-base px-8 sm:block hidden"
        onClick={() => {
          navigate("/Pricing");
        }}
      >
        FOOTPRINT LAUNCH SALE 50% off your first payment for all tiers!
      </button>
      {/* Mobile */}
      <button
        className="font-monda text-white text-sm sm:text-base px-8 sm:hidden block"
        onClick={() => {
          navigate("/Pricing");
        }}
      >
        FOOTPRINT LAUNCH SALE 50% off
      </button>
      <button
        className="absolute sm:top-0 top-4 right-4 p-1"
        onClick={() => setEnabled(false)}
      >
        <XMarkIcon className="h-6 w-6 text-white group-hover:text-surfaceVariant" />
      </button>
    </div>
  );
}
