import { Card, CardTitle, CardHeader, CardContent } from "../../Ui/card.js";
import {
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableCell,
  TableBody,
} from "../../Ui/table.js";
import CardLoading from "../CardLoading.js";
import {
  Pagination,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "../../Ui/pagination.js";
import { useState } from "react";
import { removeZeros, smallNumber } from "../Utilities/LongSmallNums.js";

export default function TokenTable({
  tokenValueLoading,
  tokenValueError,
  tokenValueData,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = tokenValueData?.data?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalItems = tokenValueData?.data?.length || 0;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const pageNumbers = [];
  for (
    let i = 1;
    i <= Math.ceil(tokenValueData?.data?.length / itemsPerPage);
    i++
  ) {
    pageNumbers.push(i);
  }
  return (
    <Card className="w-full flex flex-col min-h-[400px] md:min-h-[500px]">
      <CardHeader>
        <CardTitle className="text-center">Total Token Value</CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col flex-grow overflow-auto">
        {tokenValueLoading ? (
          <CardLoading className="flex-grow" />
        ) : tokenValueError ? (
          <div className="h-96 font-monda text-5xl flex flex-col gap-4 text-center mt-6 text-red-400">
            error
            <p className="text-gray-700 text-3xl">
              Can&apos;t retreive value from wallet address
            </p>
            <p className="text-gray-700 text-3xl">Refresh and try again</p>
          </div>
        ) : (
          <>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Token Address</TableHead>
                  <TableHead>Token Name</TableHead>
                  <TableHead>Symbol</TableHead>
                  <TableHead>Decimals</TableHead>
                  <TableHead>Total Supply</TableHead>
                  <TableHead>Adjusted Token Balance</TableHead>
                  <TableHead>Token Price</TableHead>
                  <TableHead>Total Value in ETH</TableHead>
                  <TableHead>Total Value in USD</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {currentItems?.map((token, index) => (
                  <TableRow key={index}>
                    {/* Table cells */}
                    <TableCell>{token.token_address}</TableCell>{" "}
                    {/* Direct access */}
                    <TableCell>{token.tokenDetails.name}</TableCell>{" "}
                    {/* Accessed from tokenDetails */}
                    <TableCell>{token.tokenDetails.symbol}</TableCell>{" "}
                    {/* Accessed from tokenDetails */}
                    <TableCell>{token.tokenDetails.decimals}</TableCell>{" "}
                    {/* Accessed from tokenDetails */}
                    <TableCell>{token.tokenDetails.totalSupply}</TableCell>{" "}
                    {/* Accessed from tokenDetails */}
                    <TableCell className="font-monda">
                      {token.adjusted_token_balance < 0 ? (
                        <span>
                          0.0
                          <sub>
                            {smallNumber(Number(token.adjusted_token_balance))}
                          </sub>
                          {removeZeros(Number(token.adjusted_token_balance))}
                        </span>
                      ) : (
                        token.adjusted_token_balance.toLocaleString("en-US", {
                          maximumFractionDigits: 2,
                        })
                      )}
                    </TableCell>
                    {/* Fixed to 2 decimal places */}
                    <TableCell className="font-monda">
                      {smallNumber(
                        Number(token.tokenPriceInEth * token.latest_eth_price)
                      ) > 1 ? (
                        <span>
                          $0.0
                          <sub>
                            {smallNumber(
                              Number(
                                token.tokenPriceInEth * token.latest_eth_price
                              )
                            )}
                          </sub>
                          {removeZeros(
                            Number(
                              token.tokenPriceInEth * token.latest_eth_price
                            )
                          )}
                        </span>
                      ) : (
                        <span>
                          $
                          {(
                            token.tokenPriceInEth * token.latest_eth_price
                          ).toFixed(3)}
                        </span>
                      )}
                    </TableCell>
                    {/* Fixed to 2 decimal places */}
                    <TableCell>
                      {token.token_holdings_in_eth.toFixed(2)}
                    </TableCell>{" "}
                    {/* Fixed to 2 decimal places */}
                    <TableCell>
                      ${token.token_holdings_in_usd.toFixed(2)}
                    </TableCell>{" "}
                    {/* Fixed to 2 decimal places */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        )}
        <Pagination className="pt-4">
          <PaginationPrevious
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage <= 1}
          />
          {pageNumbers.map((number) => (
            <PaginationLink
              key={number}
              onClick={() => setCurrentPage(number)}
              isActive={currentPage === number}
            >
              {number}
            </PaginationLink>
          ))}
          <PaginationNext
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage >= totalPages}
          />
        </Pagination>
      </CardContent>
    </Card>
  );
}
