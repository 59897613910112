// GetEthTableStores.js
import { useQuery } from "@tanstack/react-query";
import request from "../../../Api/Api.js";
import { useSearchParams } from "react-router-dom";

export function useTableStores() {
  const [searchParams] = useSearchParams();

  const {
    isLoading,
    isError,
    isFetching,
    data: tablesData,
  } = useQuery({
    queryKey: ["tableStores", searchParams.get("blockchain")],
    queryFn: async () => {
      const data = await request
        .get(`/tableStores/${searchParams.get("blockchain")}`)
        .catch(function (error) {
          return error.response;
        });
      return data;
    },
    staleTime: Infinity,
  });

  return { isLoading, isError, isFetching, tablesData };
}
