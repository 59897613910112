// created by james launder
// Compare button with all the options to add a new chart

import { Fragment, useEffect, useRef, useState, useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ChartBarIcon,
  XMarkIcon,
  PlusIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import { useSearchParams } from "react-router-dom";
import CompareCombo from "./CompareCombo.js";
import ColourDropdown from "./ColourDropdown.js";
import useCompareQuery from "./useCompareQuery.js";
import { useStoreQuery } from "./useStoreQuery.js";
import { UserContext } from "../../Services/userContext";

function CompareButton(props) {
  const DELIMITTER = "_";
  const { userData } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedResolution, setSelectedResolution] = useState("Day");
  const [selectedSource, setSelectedSource] = useState(null);
  // const [checkEnabled, setCheckEnabled] = useState(false);
  const isErrorMain = useRef(false);
  const isErrorAdd = useRef(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [valid, setValid] = useState(false);
  const [selectedColour, setSelectedColour] = useState(props.colour[0]);
  const cancelButtonRef = useRef(null);
  const [selectedBlockchain, setSelectedBlockchain] = useState({
    name: searchParams.get("blockchain"),
  });
  const [selectedChart, setSelectedChart] = useState(null);
  const [selectedStyle, setSelectedStyle] = useState(props.style[0]);
  const [selectedScale, setSelectedScale] = useState(props.scale[0]);
  const {
    blockchains: data,
    blockchainsLoading: isLoading,
    charts: charts,
    chartsLoading: chartsLoading,
    chartsFetching: chartsFetching,
  } = useCompareQuery(selectedBlockchain.name);
  const newQuery = useStoreQuery((state) => state.addQuery);
  const chartsLen = useStoreQuery((state) => state.queryUrl);
  // when opened validate that the user can add a new chart
  const checkCharts = () => {
    if (chartsLen.length >= userData?.charts) {
      setValid(false);
      setErrorMsg(
        <div className="text-red-500 font-semibold flex gap-1 self-center">
          <ExclamationCircleIcon className="h-6 w-6 self-center mt-[1px]" />
          Maximum Charts Reached!
        </div>
      );
    } else {
      setValid(true);
      setErrorMsg("");
    }
  };
  useEffect(() => {
    checkCharts();
  }, [chartsLen]);
  useEffect(() => {
    setSelectedChart(null);
  }, [selectedBlockchain]);
  useEffect(() => {
    setSelectedSource(null);
  }, [selectedChart]);

  // called when adding a chart
  const handleSubmit = () => {
    if (selectedBlockchain === null || selectedChart === null) {
      // error checking before submitting
      setErrorMsg(
        <div className="text-red-500 font-semibold flex gap-1 self-center">
          <ExclamationCircleIcon className="h-6 w-6 self-center mt-[1px]" />
          Missing Chart!
        </div>
      );
    } else {
      if (Array.isArray(selectedChart.sources) && selectedSource === null) {
        setErrorMsg(
          <div className="text-red-500 font-semibold flex gap-1 self-center">
            <ExclamationCircleIcon className="h-6 w-6 self-center mt-[1px]" />
            Missing Source!
          </div>
        );
      } else {
        if (
          selectedBlockchain.name === searchParams.get("blockchain") &&
          selectedChart.name === searchParams.get("name") &&
          selectedResolution === searchParams.get("resolution")
        ) {
          if (selectedSource != null) {
            if (selectedSource.name === searchParams.get("source")) {
              isErrorMain.current = true;
              setErrorMsg(
                <div className="text-red-500 font-semibold flex gap-1 self-center">
                  <ExclamationCircleIcon className="h-6 w-6 self-center mt-[1px]" />
                  Same As Original Chart!
                </div>
              );
            } else {
              setErrorMsg("");

              isErrorMain.current = false;
            }
          } else {
            setErrorMsg(
              <div className="text-red-500 font-semibold flex gap-1 self-center">
                <ExclamationCircleIcon className="h-6 w-6 self-center mt-[1px]" />
                Same As Original Chart!
              </div>
            );

            isErrorMain.current = true;
          }
        } else {
          setErrorMsg("");

          isErrorMain.current = false;
        }
        if (selectedSource === null) {
          if (
            props.additionalCharts.some(
              (chart) =>
                chart.id ===
                selectedChart.name +
                  selectedBlockchain.name +
                  null +
                  selectedResolution
            )
          ) {
            setErrorMsg(
              <div className="text-red-500 font-semibold flex gap-1 self-center">
                <ExclamationCircleIcon className="h-6 w-6 self-center mt-[1px]" />
                Chart Already Added!
              </div>
            );

            isErrorAdd.current = true;
          } else {
            if (!isErrorMain.current) {
              setErrorMsg("");
            }

            isErrorAdd.current = false;
          }
        } else {
          if (
            props.additionalCharts.some(
              (chart) =>
                chart.id ===
                selectedChart.name +
                  selectedBlockchain.name +
                  selectedSource.name +
                  selectedResolution
            )
          ) {
            setErrorMsg(
              <div className="text-red-500 font-semibold flex gap-1 self-center">
                <ExclamationCircleIcon className="h-6 w-6 self-center mt-[1px]" />
                Chart Already Added!
              </div>
            );

            isErrorAdd.current = true;
          } else {
            if (!isErrorMain.current) {
              setErrorMsg("");
            }

            isErrorAdd.current = false;
          }
        }
        // post error validation
        if (!isErrorAdd.current && !isErrorMain.current) {
          var newChart;
          if (selectedSource === null) {
            newQuery(
              selectedBlockchain.name,
              selectedResolution,
              selectedChart.name,
              null
            );
            newChart = {
              id:
                selectedChart.name +
                selectedBlockchain.name +
                null +
                selectedResolution,
              blockchain: selectedBlockchain.name,
              name: selectedChart.name,
              resolution: selectedResolution,
              color: selectedColour,
              style: selectedStyle,
              scale: selectedScale,
              source: null,
            };
            searchParams.append(
              "cmp",
              selectedBlockchain.name +
                DELIMITTER +
                selectedChart.name +
                DELIMITTER +
                selectedResolution +
                DELIMITTER +
                selectedColour.name +
                DELIMITTER +
                selectedStyle.name +
                DELIMITTER +
                selectedScale.name
            );
            setSearchParams(searchParams);
          } else {
            newQuery(
              selectedBlockchain.name,
              selectedResolution,
              selectedChart.name,
              selectedSource.name
            );
            newChart = {
              id:
                selectedChart.name +
                selectedBlockchain.name +
                selectedSource.name +
                selectedResolution,
              blockchain: selectedBlockchain.name,
              name: selectedChart.name,
              resolution: selectedResolution,
              color: selectedColour,
              style: selectedStyle,
              scale: selectedScale,
              source: selectedSource.name,
            };
            searchParams.append(
              "cmp",
              selectedBlockchain.name +
                DELIMITTER +
                selectedChart.name +
                DELIMITTER +
                selectedResolution +
                DELIMITTER +
                selectedColour.name +
                DELIMITTER +
                selectedStyle.name +
                DELIMITTER +
                selectedScale.name +
                DELIMITTER +
                selectedSource.name
            );
            setSearchParams(searchParams);
          }
          setSelectedChart(null);
          setSelectedSource(null);
          props.setAdditionalCharts((prevState) => [...prevState, newChart]);
          setOpen(false);
        }
      }
    }
  };
  return (
    <div className="font-roboto ">
      <button
        onClick={() => {
          setOpen(true);
        }}
        className="group flex flex-row border border-primaryContainer bg-surfaceVariant px-3 py-2 hover:bg-primary hover:text-white rounded-lg shadow-md"
      >
        <div className="hidden md:flex">
          <div>Add</div>
          <PlusIcon
            className="h-5 w-5 font-bold text-onSurfaceVariant self-center ml-2 group-hover:text-white"
            aria-hidden="true"
          />
        </div>
        <div className="md:hidden flex">
          <PlusIcon
            className="h-6 w-6 font-bold text-onSurfaceVariant self-center group-hover:text-white"
            aria-hidden="true"
          />
        </div>
      </button>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md">
                  {/* <form onSubmit={handleSubmit}> */}
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                      onClick={() => {
                        setErrorMsg("");
                        setOpen(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start ">
                      <div className="mt-3 text-center sm:mt-2 sm:text-left  w-full ">
                        <div className="flex flex-row items-center">
                          <div className="flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-orange-100 sm:mx-0 sm:h-10 sm:w-10">
                            <ChartBarIcon
                              className="h-6 w-6 text-primary"
                              aria-hidden="true"
                            />
                          </div>
                          <Dialog.Title
                            as="h3"
                            className="ml-4 text-base font-semibold leading-6 text-gray-900"
                          >
                            Add new chart data
                          </Dialog.Title>
                        </div>

                        <div className="mt-4">
                          <div className="flex flex-col">
                            {isLoading ? (
                              <div>Loading...</div>
                            ) : (
                              <CompareCombo
                                title={"Blockchain"}
                                data={data?.data?.blockchain}
                                selected={selectedBlockchain}
                                setSelected={setSelectedBlockchain}
                              />
                            )}
                          </div>
                          <div className="flex flex-col mt-4">
                            {selectedBlockchain.name === "None" ? (
                              <div>
                                <div className="block text-sm font-medium leading-6 text-gray-900">
                                  Charts
                                </div>
                                <div className="truncate">
                                  Select A Chart...
                                </div>
                              </div>
                            ) : (
                              <div>
                                {chartsFetching || chartsLoading ? (
                                  <div>
                                    <div className="block text-sm font-medium leading-6 text-gray-900">
                                      Charts
                                    </div>
                                    <div className="truncate">Loading...</div>
                                  </div>
                                ) : (
                                  <CompareCombo
                                    title={"Charts"}
                                    data={charts?.data?.graphNames}
                                    selected={selectedChart}
                                    setSelected={setSelectedChart}
                                  />
                                )}
                              </div>
                            )}
                          </div>
                          <div className="flex flex-col mt-4">
                            {selectedChart === null ? (
                              <div>
                                <div className="block text-sm font-medium leading-6 text-gray-900">
                                  Source
                                </div>
                                <div className="truncate">
                                  Select A Chart...
                                </div>
                              </div>
                            ) : (
                              <div>
                                {Array.isArray(selectedChart.sources) ? (
                                  <CompareCombo
                                    title={"Source"}
                                    data={selectedChart.sources}
                                    selected={selectedSource}
                                    setSelected={setSelectedSource}
                                  />
                                ) : (
                                  <div>
                                    <div className="block text-sm font-medium leading-6 text-gray-900">
                                      Source
                                    </div>
                                    <div className="truncate">
                                      {selectedChart.dataSource}
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                          <div className="mt-4">
                            <ColourDropdown
                              title={"Colour"}
                              data={props.colour}
                              selected={selectedColour}
                              setSelected={setSelectedColour}
                            />
                          </div>
                          <div className="mt-4">
                            <>
                              <h1 className="block text-sm font-medium leading-6 text-gray-900">
                                Resolution
                              </h1>
                              {selectedChart === null ? (
                                <div className="mt-2 h-[40px]">
                                  Select A Chart...
                                </div>
                              ) : (
                                <div>
                                  <span className="grid grid-flow-col rounded-lg bg-white text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 mt-2">
                                    {selectedChart.resolution.map((data) => (
                                      <button
                                        type="button"
                                        key={data + " combo"}
                                        onClick={() => {
                                          //   // params("resolution", data);
                                          setSelectedResolution(data);
                                        }}
                                        className={`${
                                          selectedResolution === data
                                            ? "bg-primary text-white text-bold"
                                            : "text-gray-900 hover:bg-primary/30 hover:text-gray-900"
                                        } rounded-lg py-1.5 text-gray-900 `}
                                      >
                                        {data}
                                      </button>
                                    ))}
                                  </span>
                                </div>
                              )}
                            </>
                          </div>
                          <div className="mt-4">
                            <>
                              <h1 className="block text-sm font-medium leading-6 text-gray-900">
                                Style
                              </h1>
                              {selectedChart === null ? (
                                <div className="mt-2 h-[40px]">
                                  Select A Chart...
                                </div>
                              ) : (
                                <div>
                                  <span className="grid grid-flow-col rounded-lg bg-white text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 mt-2">
                                    {props.style.map((data) => (
                                      <button
                                        type="button"
                                        key={data.name + " combo"}
                                        onClick={() => {
                                          setSelectedStyle(data);
                                        }}
                                        className={`${
                                          selectedStyle.name === data.name
                                            ? "bg-primary text-white text-bold"
                                            : "text-gray-900 hover:bg-primary/30 hover:text-gray-900"
                                        } rounded-lg py-1.5 text-gray-900 `}
                                      >
                                        {data.name}
                                      </button>
                                    ))}
                                  </span>
                                </div>
                              )}
                            </>
                          </div>
                          <div className="mt-4">
                            <>
                              <h1 className="block text-sm font-medium leading-6 text-gray-900">
                                Scale
                              </h1>
                              {selectedChart === null ? (
                                <div className="mt-2 h-[40px]">
                                  Select A Chart...
                                </div>
                              ) : (
                                <div>
                                  <span className="grid grid-flow-col rounded-lg bg-white text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 mt-2">
                                    {props.scale.map((data) => (
                                      <button
                                        type="button"
                                        key={data.name + " combo"}
                                        onClick={() => {
                                          setSelectedScale(data);
                                        }}
                                        className={`${
                                          selectedScale.name === data.name
                                            ? "bg-primary text-white text-bold"
                                            : "text-gray-900 hover:bg-primary/30 hover:text-gray-900"
                                        } rounded-lg py-1.5 text-gray-900 `}
                                      >
                                        {data.name}
                                      </button>
                                    ))}
                                  </span>
                                </div>
                              )}
                            </>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:justify-between sm:px-6">
                    <div>{errorMsg}</div>
                    <div>
                      {valid ? (
                        <button
                          type="submit"
                          className="inline-flex w-full justify-center rounded-md bg-primaryContainer px-3 py-2 text-sm hover:text-white shadow-sm hover:bg-primary sm:ml-3 sm:w-auto"
                          onClick={() => {
                            handleSubmit();
                          }}
                        >
                          Add
                        </button>
                      ) : (
                        <button
                          disabled
                          className="cursor-not-allowed inline-flex w-full justify-center rounded-md bg-primaryContainer px-3 py-2 text-sm shadow-sm sm:ml-3 sm:w-auto"
                        >
                          Add
                        </button>
                      )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
export default CompareButton;
