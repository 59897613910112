import React, { useState, useEffect, useRef } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

// Required Highcharts modules
require("highcharts/indicators/indicators")(Highcharts);
require("highcharts/indicators/ema")(Highcharts);
import watermarkIcon from "../../Assets/WevrWatermark.svg";
import watermarkIconSmall from "../../Assets/WevrLogoSecondary.svg";
var largeWatermark = watermarkIcon;
var smallWatermark = watermarkIconSmall;

const convertHexToRGBA = (hexCode, opacity) => {
  let hex = hexCode.replace("#", "");

  if (hex.length === 3) {
    hex = [...hex].map((x) => x + x).join("");
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

const configurePieChart = (chartConfig) => {
  return {
    series: chartConfig.series.map((s) => ({
      type: "pie",
      name: s.name,
      data: s.data.map((d) => ({
        //this part different to normal charts
        name: d.name,
        y: d.y,
        color: convertHexToRGBA(s.color, 0.5),
      })),
    })),
  };
};

//specific chart related settings ~ not related to data ingestion just styles
const chartConfigurations = {
  line: {
    chart: { type: "line" },
    plotOptions: {
      series: {
        turboThreshold: 0, // Applies to all series-based charts except pie
      },
    },
    tooltip: { enabled: true },
    xAxis: { visible: true },
    yAxis: { visible: true },
  },
  column: {
    chart: { type: "column" },
    plotOptions: {
      series: {
        turboThreshold: 0, // Here as well
      },
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    tooltip: { enabled: true },
    xAxis: { visible: true },
    yAxis: { visible: true },
  },
  spline: {
    chart: { type: "spline" },
    plotOptions: {
      series: {
        turboThreshold: 0, // And here
      },
    },
    tooltip: { enabled: true },
    xAxis: { visible: true },
    yAxis: { visible: true },
  },
  area: {
    chart: { type: "area" },
    plotOptions: {
      series: {
        turboThreshold: 0, // Also here
      },
      area: {
        lineWidth: 1,
        states: {
          hover: { lineWidth: 1 },
        },
        threshold: null,
      },
    },
    tooltip: { enabled: true },
    xAxis: { visible: true },
    yAxis: { visible: true },
  },
  scatter: {
    chart: { type: "scatter" },
    plotOptions: {
      series: {
        turboThreshold: 0, // Applies to scatter as well
      },
      scatter: {
        marker: {
          radius: 5,
          states: {
            hover: {
              enabled: true,
              lineColor: "rgb(100,100,100)",
            },
          },
        },
        tooltip: {
          headerFormat: "<b>{series.name}</b><br>",
          pointFormat: "{point.x}, {point.y}",
        },
      },
    },
    tooltip: { enabled: true },
    xAxis: { visible: true },
    yAxis: { visible: true },
    navigator: { enabled: true },
    rangeSelector: { enabled: true },
  },
  pie: {
    chart: { type: "pie" },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.percentage:.1f} %",
        },
      },
    },
    tooltip: { enabled: false },
    xAxis: { visible: false },
    yAxis: { visible: false },
    navigator: { enabled: false },
    rangeSelector: { enabled: false },
    scrollbar: { enabled: false },
  },
};

const ChartComponent = ({ chartConfig }) => {
  const chartRef = useRef(null);

  const [chartOptions, setChartOptions] = useState({
    exporting: {
      enabled: false,
      enableImages: true,
    },
    legend: {
      enabled: true,
      itemStyle: {
        // color: text,
        fontWeight: "bold",
      },
    },
    chart: {
      type: chartConfig.chartType || "line",
      zoomType: "x",
      style: {
        fontFamily: "Monda",
      },
      events: {
        load: function () {
          const chart = this; // 'this' refers to the Highcharts chart object
          let width_image = 627.98;
          let height_image = 127;
          let textX = chart.plotLeft + chart.plotWidth * 0.5;
          let textY = chart.plotTop + chart.plotHeight * 0.5;
          let position_x = textX - width_image / 2;
          let position_y = textY - height_image / 2;

          // Store the watermark reference in the chart object for easy access
          chart.watermark = chart.renderer
            .image(
              largeWatermark,
              position_x,
              position_y,
              width_image,
              height_image
            )
            .add();
        },
        redraw: function () {
          const chart = this;
          let plotWidth = chart.plotWidth;
          let threshold = 800;
          let watermark = chart.watermark; // Access the watermark stored in the chart object

          if (plotWidth < threshold && watermark.href !== smallWatermark) {
            let width_image_sm = 152;
            let height_image_sm = 100;
            let textX_sm = chart.plotLeft + chart.plotWidth * 0.5;
            let textY_sm = chart.plotTop + chart.plotHeight * 0.5;
            let position_x_sm = textX_sm - width_image_sm / 2;
            let position_y_sm = textY_sm - height_image_sm / 2;

            watermark.attr({
              href: smallWatermark,
              x: position_x_sm,
              y: position_y_sm,
              width: width_image_sm,
              height: height_image_sm,
            });
          } else if (
            plotWidth >= threshold &&
            watermark.href !== largeWatermark
          ) {
            let width_image = 627.98;
            let height_image = 127;
            let textX = chart.plotLeft + chart.plotWidth * 0.5;
            let textY = chart.plotTop + chart.plotHeight * 0.5;
            let position_x = textX - width_image / 2;
            let position_y = textY - height_image / 2;

            watermark.attr({
              href: largeWatermark,
              x: position_x,
              y: position_y,
              width: width_image,
              height: height_image,
            });
          }
        },
      },
    },
    scrollbar: {
      enabled: false,
    },
    title: {
      text: "Loading...", // A temporary title before the dynamic title is applied
    },
    xAxis: {
      type: "datetime", // Common setting if your charts primarily use datetime x-axis
      title: {
        text: "Date", // Default x-axis title
      },
    },
    yAxis: {
      title: {
        text: "Value", // Default y-axis title
      },
    },
    series: [
      {
        name: "Loading data...", // Placeholder series name
        data: [], // Empty data array as a placeholder
      },
    ],
    // Adding the plotOptions with the turboThreshold setting
    plotOptions: {
      series: {
        turboThreshold: 0, // Set to a larger threshold, it is by default to 1000
      },
    },
    credits: {
      enabled: false,
    },
    navigator: {
      maskFill: "rgba(250, 145, 115, 0.35)",
    },
    rangeSelector: {
      enabled: false,
    },
    accessibility: {
      enabled: false,
    },
    // Any other global options or fallback configurations
  });

  // Helper function to merge chart type specific configurations
  const mergeTypeSpecificConfigurations = (baseOptions, chartType) => {
    const typeSpecificSettings = chartConfigurations[chartType] || {};
    return {
      ...baseOptions,
      ...typeSpecificSettings, //overwrites
    };
  };
  // if nothing is specified have the loading state which would
  useEffect(() => {
    if (chartConfig) {
      let baseOptions = {
        chart: {
          type: chartConfig.chartType || "line",
        },
        title: {
          text: chartConfig.title || "Custom Chart Title",
        },
        xAxis: {
          type: "datetime",
          title: {
            text: chartConfig.xAxisVariable || "",
          },
        },
        yAxis: [],
        series: [],
        plotOptions: {
          series: {
            turboThreshold: 5000,
          },
        },
      };

      if (chartConfig.chartType === "pie") {
        baseOptions = mergeTypeSpecificConfigurations(baseOptions, "pie");
        if (chartConfig.series) {
          Object.assign(baseOptions, configurePieChart(chartConfig));
        }
      } else {
        baseOptions = mergeTypeSpecificConfigurations(
          baseOptions,
          chartConfig.chartType
        );
        if (chartConfig.series) {
          baseOptions.yAxis = chartConfig.series.map((s, index) => ({
            id: `yAxis-${index}`,
            title: { text: s.name },
            opposite: index % 2 !== 0,
          }));

          baseOptions.series = chartConfig.series.map((s, index) => ({
            ...s,
            yAxis: `yAxis-${index}`,
            type: chartConfig.chartType,
            color: convertHexToRGBA(s.color, 0.66),
          }));
        }
      }

      setChartOptions(baseOptions);

      setTimeout(() => {
        if (chartRef.current && chartRef.current.chart) {
          chartRef.current.chart.zoomOut();
        }
      }, 100);
    }
  }, [chartConfig]);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      constructorType={"stockChart"}
      options={chartOptions}
      containerProps={{ style: { height: "100%" } }}
      ref={chartRef}
    />
  );
};

export default ChartComponent;
