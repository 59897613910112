// Created by James Launder
// Zoom functionality also contains the calendar

import { useEffect, useState, useRef } from "react";
import { CalendarIcon } from "@heroicons/react/24/solid";
import { Popover } from "@headlessui/react";
import dayjs from "dayjs";
import { DateRange } from "react-date-range";
// uses internal states to communicate
// can be improved with better calendar and better management of current zoom.
function ChartZoom(props) {
  let defaultStartDate = new Date();
  defaultStartDate.setMonth(defaultStartDate.getMonth() - 6);
  const [state, setState] = useState([
    {
      startDate: defaultStartDate,
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [selected, setSelected] = useState("6m");
  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    props.setMinZoom(state[0].startDate.getTime());
    props.setMaxZoom(state[0].endDate.getTime());
  }, [state]);

  // this was used to make the calendar stay synced with the highcharts but it causes bulk re renders as poorly designed by Launder...
  // can tidy states up and make it one to sync accross but cbs rn got more important tickets to do
  // useEffect(() => {
  //   if (isFirstRender2.current) {
  //     isFirstRender2.current = false;
  //     return;
  //   }
  //   // console.log(props.minZoom);
  //   // console.log(props.maxZoom);
  //   let tmpMin = new Date(props.minZoom);
  //   let tmpMax = new Date(props.maxZoom);
  //   setState([
  //     {
  //       startDate: tmpMin,
  //       endDate: tmpMax,
  //       key: "selection",
  //     },
  //   ]);
  // }, [props.minZoom, props.maxZoom]);

  return (
    <div>
      {/* // larger size date */}
      <span className="hidden md:grid grid-flow-col w-full space-x-1 rounded-xl bg-surfaceVariant border border-primaryContainer shadow-md">
        <button
          type="button"
          className={`${
            selected === "1m"
              ? "bg-primary text-white text-bold"
              : "text-black hover:bg-primary/30 "
          } w-12 rounded-xl py-2.5`}
          onClick={() => {
            setSelected("1m");
            props.setMinZoom(dayjs().subtract(1, "months").valueOf());
            props.setMaxZoom(Date.now());
          }}
        >
          1m
        </button>
        <button
          type="button"
          className={`${
            selected === "3m"
              ? "bg-primary text-white text-bold"
              : "text-black hover:bg-primary/30"
          } w-12 rounded-xl py-2.5`}
          onClick={() => {
            setSelected("3m");
            props.setMinZoom(dayjs().subtract(3, "months").valueOf());
            props.setMaxZoom(Date.now());
          }}
        >
          3m
        </button>
        <button
          type="button"
          className={`${
            selected === "6m"
              ? "bg-primary text-white text-bold"
              : "text-black hover:bg-primary/30"
          } w-12 rounded-xl py-2.5`}
          onClick={() => {
            setSelected("6m");
            props.setMinZoom(dayjs().subtract(6, "months").valueOf());
            props.setMaxZoom(Date.now());
          }}
        >
          6m
        </button>
        <button
          type="button"
          className={`${
            selected === "YTD"
              ? "bg-primary text-white text-bold"
              : "text-black hover:bg-primary/30"
          } w-12 rounded-xl py-2.5`}
          onClick={() => {
            setSelected("YTD");
            props.setMinZoom(dayjs().startOf("year").valueOf());
            props.setMaxZoom(Date.now());
          }}
        >
          YTD
        </button>
        <button
          type="button"
          className={`${
            selected === "1y"
              ? "bg-primary text-white text-bold"
              : "text-black hover:bg-primary/30"
          } w-12 rounded-xl py-2.5`}
          onClick={() => {
            setSelected("1y");
            props.setMinZoom(dayjs().subtract(1, "years").valueOf());
            props.setMaxZoom(Date.now());
          }}
        >
          1y
        </button>
        <Popover className="relative ">
          <Popover.Button
            type="button"
            onClick={() => {
              setSelected(99);
            }}
            className={`${
              selected == 99
                ? "bg-primary text-white text-bold"
                : "text-black hover:bg-primary/30"
            } relative group flex place-content-center flex-row p-2 h-full items-center rounded-xl w-12`}
          >
            <CalendarIcon
              className={`${
                selected == 99 ? "text-white" : "text-black"
              }  h-5 w-5 `}
            />
          </Popover.Button>

          <Popover.Panel className="absolute z-10 -left-[270] w-screen max-w-sm px-1 py-2">
            <div>
              {/* <Calendar
                initialFocus
                mode="range"
                defaultMonth={date?.from}
                selected={date}
                onSelect={setDate}
                numberOfMonths={2}
                className="bg-white border rounded-md"
              /> */}
              <DateRange
                editableDateInputs={true}
                onChange={(item) => setState([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={state}
              />
              {/* <RangePicker
              open={true}
              getPopupContainer={(trigger) => {
                return trigger;
              }}
              onChange={setNewTime}
              defaultValue={[moment(props.min), moment(props.max)]}
              value={[moment(props.minZoom), moment(props.maxZoom)]}
              showToday="true"
            /> */}
            </div>
          </Popover.Panel>
        </Popover>
      </span>
      <div>
        <Popover as="div" className="inline-block md:hidden text-left">
          {({ open }) => (
            <>
              <div>
                <Popover.Button
                  className={`${
                    open
                      ? "bg-primary text-white"
                      : " bg-surfaceVariant shadow-md"
                  } group flex place-content-center flex-row px-3 py-2 hover:bg-primary hover:text-white rounded-lg border border-primaryContainer focus:outline-none`}
                >
                  <div className="grid text-center">
                    <div className="h-6 text-center text-xl">{selected}</div>

                    {/* <ChevronDownIcon
                      className={`${
                        open ? " text-white" : ""
                      } h-6 w-6 text-secondary group-hover:text-white mt-1`}
                      aria-hidden="true"
                    /> */}
                  </div>
                </Popover.Button>
              </div>
              {open && (
                <Popover.Panel
                  static
                  className="absolute mt-1 z-10 w-[70px] divide-y divide-secondary rounded-lg bg-white border border-primaryContainer shadow-sm"
                >
                  <div className="flex flex-col relative w-full">
                    <button
                      type="button"
                      className={`${
                        selected === "1m"
                          ? "bg-primary text-white text-bold"
                          : "text-black hover:bg-primary/30 "
                      } rounded-lg py-2.5`}
                      onClick={() => {
                        setSelected("1m");
                        props.setMinZoom(
                          dayjs().subtract(1, "months").valueOf()
                        );
                        props.setMaxZoom(Date.now());
                      }}
                    >
                      1m
                    </button>
                    <button
                      type="button"
                      className={`${
                        selected === "3m"
                          ? "bg-primary text-white text-bold"
                          : "text-black hover:bg-primary/30"
                      } rounded-lg py-2.5`}
                      onClick={() => {
                        setSelected("3m");
                        props.setMinZoom(
                          dayjs().subtract(3, "months").valueOf()
                        );
                        props.setMaxZoom(Date.now());
                      }}
                    >
                      3m
                    </button>
                    <button
                      type="button"
                      className={`${
                        selected === "6m"
                          ? "bg-primary text-white text-bold"
                          : "text-black hover:bg-primary/30"
                      } rounded-lg py-2.5`}
                      onClick={() => {
                        setSelected("6m");
                        props.setMinZoom(
                          dayjs().subtract(6, "months").valueOf()
                        );
                        props.setMaxZoom(Date.now());
                      }}
                    >
                      6m
                    </button>
                    <button
                      type="button"
                      className={`${
                        selected === "YTD"
                          ? "bg-primary text-white text-bold"
                          : "text-black hover:bg-primary/30"
                      } rounded-lg py-2.5`}
                      onClick={() => {
                        setSelected("YTD");
                        props.setMinZoom(dayjs().startOf("year").valueOf());
                        props.setMaxZoom(Date.now());
                      }}
                    >
                      YTD
                    </button>
                    <button
                      type="button"
                      className={`${
                        selected === "1y"
                          ? "bg-primary text-white text-bold"
                          : "text-black hover:bg-primary/30"
                      } rounded-lg py-2.5`}
                      onClick={() => {
                        setSelected("1y");
                        props.setMinZoom(
                          dayjs().subtract(1, "years").valueOf()
                        );
                        props.setMaxZoom(Date.now());
                      }}
                    >
                      1y
                    </button>
                  </div>
                </Popover.Panel>
              )}
            </>
          )}
        </Popover>
      </div>
    </div>
  );
}

export default ChartZoom;
