import { useQuery } from "@tanstack/react-query";
import request from "../../../Api/Api.js";

function getToken() {
  if (localStorage.getItem("user") != null) {
    return JSON.parse(localStorage.getItem("user")).accessToken;
  } else {
    return "";
  }
}

export function UseEthBalance() {
  const address = new URLSearchParams(location.search).get("address");

  const { isLoading, isError, data, error } = useQuery({
    queryKey: ["getEthCurrent", address], // Updated queryKey to reflect the data being fetched
    queryFn: async () => {
      const data = await request.get(`/getEthCurrent?address=${address}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });

      return data.data;
    },
  });

  return { isLoading, isError, data, error };
}

export function UseTotalTokenValue() {
  const address = new URLSearchParams(location.search).get("address");

  const { isLoading, isError, data, error } = useQuery({
    queryKey: ["totalTokenValue", address],
    queryFn: async () => {
      const data = await request.get(`/totalTokenValue?address=${address}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return data;
    },
  });

  return { isLoading, isError, data, error };
}

export function UseAccBal() {
  const address = new URLSearchParams(location.search).get("address");

  const { isLoading, isError, data, error } = useQuery({
    queryKey: ["accountBalanceOverTime", address],
    queryFn: async () => {
      const data = await request.get(`/accountBalanceTS?address=${address}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return data.data;
    },
  });

  return { isLoading, isError, data, error };
}
