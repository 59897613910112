import React, { useState } from "react";

const ImageWithFallback = ({
  src,
  alt,
  fallbackComponent: FallbackComponent,
  className,
}) => {
  const [imgError, setImgError] = useState(false);

  return (
    <>
      {imgError ? (
        <FallbackComponent className={className} />
      ) : (
        <img
          className={className}
          src={src}
          alt={alt}
          onError={() => setImgError(true)}
        />
      )}
    </>
  );
};

export default ImageWithFallback;
