export default function AlertCardLoading() {
  return (
    <div>
      <div className="w-full flex-row justify-between flex my-2 border-b border-gray-600 pb-4 gap-16 animate-pulse">
        <div className="p-4 rounded-full bg-gray-300 w-12 h-12"></div>
        <div className="flex-grow px-20 flex flex-col gap-2 bg-gray-300 rounded-lg"></div>
        <div className=" items-center justify-end md:flex hidden">
          <div className="bg-gray-300 h-full w-16 rounded-lg"></div>
        </div>
      </div>

      <div className="flex flex-col gap-6 py-6">
        <div className="w-full flex flex-col gap-2 p-2 border border-gray-600 rounded-lg animate-pulse">
          <div className="w-full flex justify-between border-b py-2 text-gray-600 font-semibold leading-6 text-lg">
            <div className="flex-grow grid grid-flow-col items-center gap-8">
              <div className="text-center bg-gray-300 rounded-lg h-8 flex justify-center px-16"></div>
              <div className="text-center bg-gray-300 rounded-lg h-8 flex justify-center px-16"></div>
              <div className="text-center bg-gray-300 rounded-lg h-8 md:flex hidden justify-center px-16"></div>
            </div>
            <div className="px-6 md:grid hidden grid-flow-col gap-6">
              <button className="rounded-lg border p-2 h-8 w-8 bg-gray-300"></button>
              <button className="rounded-lg border p-2 h-8 w-8 bg-gray-300"></button>
            </div>
          </div>
          <div className="w-full border-b py-6 flex justify-center items-center gap-4">
            <div className="flex gap-4 bg-primary/25 items-center justify-center rounded-lg text-4xl p-4 w-1/2 h-16">
              <div></div>
              <div className="italic"></div>
            </div>
            <div className="border-l px-4 text-3xl py-2 text-gray-600 flex flex-col items-center gap-2 w-1/2 h-16 bg-gray-300 rounded-lg "></div>
          </div>
          <div className="w-full grid grid-flow-col divide-x py-2 text-gray-600 font-semibold leading-6 text-lg gap-8">
            <div className="text-center bg-gray-300 rounded-lg h-8 flex justify-center px-16"></div>
            <div className="text-center bg-gray-300 rounded-lg h-8 flex justify-center px-16"></div>
            <div className="text-center bg-gray-300 rounded-lg h-8 md:flex hidden justify-center px-16"></div>
          </div>
        </div>
        <div className="w-full flex flex-col gap-2 p-2 border border-gray-600 rounded-lg animate-pulse">
          <div className="w-full flex justify-between border-b py-2 text-gray-600 font-semibold leading-6 text-lg">
            <div className="flex-grow grid grid-flow-col items-center gap-8">
              <div className="text-center bg-gray-300 rounded-lg h-8 flex justify-center px-16"></div>
              <div className="text-center bg-gray-300 rounded-lg h-8 flex justify-center px-16"></div>
              <div className="text-center bg-gray-300 rounded-lg h-8 md:flex hidden justify-center px-16"></div>
            </div>
            <div className="px-6 md:grid hidden grid-flow-col gap-6">
              <button className="rounded-lg border p-2 h-8 w-8 bg-gray-300"></button>
              <button className="rounded-lg border p-2 h-8 w-8 bg-gray-300"></button>
            </div>
          </div>
          <div className="w-full border-b py-6 flex justify-center items-center gap-4">
            <div className="flex gap-4 bg-primary/25 items-center justify-center rounded-lg text-4xl p-4 w-1/2 h-16">
              <div></div>
              <div className="italic"></div>
            </div>
            <div className="border-l px-4 text-3xl py-2 text-gray-600 flex flex-col items-center gap-2 w-1/2 h-16 bg-gray-300 rounded-lg "></div>
          </div>
          <div className="w-full grid grid-flow-col divide-x py-2 text-gray-600 font-semibold leading-6 text-lg gap-8">
            <div className="text-center bg-gray-300 rounded-lg h-8 flex justify-center px-16"></div>
            <div className="text-center bg-gray-300 rounded-lg h-8 flex justify-center px-16"></div>
            <div className="text-center bg-gray-300 rounded-lg h-8 md:flex hidden justify-center px-16"></div>
          </div>
        </div>
        <div className="w-full flex flex-col gap-2 p-2 border border-gray-600 rounded-lg animate-pulse">
          <div className="w-full flex justify-between border-b py-2 text-gray-600 font-semibold leading-6 text-lg">
            <div className="flex-grow grid grid-flow-col items-center gap-8">
              <div className="text-center bg-gray-300 rounded-lg h-8 flex justify-center px-16"></div>
              <div className="text-center bg-gray-300 rounded-lg h-8 flex justify-center px-16"></div>
              <div className="text-center bg-gray-300 rounded-lg h-8 md:flex hidden justify-center px-16"></div>
            </div>
            <div className="px-6 md:grid hidden grid-flow-col gap-6">
              <button className="rounded-lg border p-2 h-8 w-8 bg-gray-300"></button>
              <button className="rounded-lg border p-2 h-8 w-8 bg-gray-300"></button>
            </div>
          </div>
          <div className="w-full border-b py-6 flex justify-center items-center gap-4">
            <div className="flex gap-4 bg-primary/25 items-center justify-center rounded-lg text-4xl p-4 w-1/2 h-16">
              <div></div>
              <div className="italic"></div>
            </div>
            <div className="border-l px-4 text-3xl py-2 text-gray-600 flex flex-col items-center gap-2 w-1/2 h-16 bg-gray-300 rounded-lg "></div>
          </div>
          <div className="w-full grid grid-flow-col divide-x py-2 text-gray-600 font-semibold leading-6 text-lg gap-8">
            <div className="text-center bg-gray-300 rounded-lg h-8 flex justify-center px-16"></div>
            <div className="text-center bg-gray-300 rounded-lg h-8 flex justify-center px-16"></div>
            <div className="text-center bg-gray-300 rounded-lg h-8 md:flex hidden justify-center px-16"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
