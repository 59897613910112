// created by james launder
// contains the dropdown for selecting a blockchain
// also has data for the ticker

import { Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import {
  ChartBarIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  TableCellsIcon,
} from "@heroicons/react/20/solid";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import request from "../../Api/Api.js";
import {
  ArrowLeftOnRectangleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import watermarkIcon from "../../Assets/WevrLogoSecondary.svg";
import { useSidebarState } from "../../utils/useSidebarState.js";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function NavBar() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [selected, setSelected] = useState();
  const sidebar = useSidebarState((state) => state.state);
  const setSidebar = useSidebarState((state) => state.setSidebar);
  // used to change from green to red
  const colour = (first, last) => {
    if (first > last) {
      return false;
    }
    return true;
  };

  // check if price is in the string
  const isPrice = (string) => {
    if (string.includes("Price")) {
      return true;
    }
    return false;
  };

  // gets all the blockchains in the database
  const { isLoading, isError, data } = useQuery({
    queryKey: ["blockchains-nav"],
    queryFn: () => request.get("/blockchains"),
  });

  // gets all the ticker data for that blockchain
  const {
    isLoading: tickerLoading,
    isError: tickerError,
    data: tickerData,
  } = useQuery({
    queryKey: ["ticker-" + searchParams.get("blockchain")],
    queryFn: () => request.get("/ticker/" + searchParams.get("blockchain")),
  });

  useEffect(() => {
    if (!isLoading) {
      let obj = data.data.blockchain.find(
        (o) => o.name === searchParams.get("blockchain")
      );

      setSelected(obj);
    }
  }, [data, searchParams.get("blockchain")]);
  const chartTableNav = () => {
    const path = location.pathname.split("/");
    return path.includes("Tables");
  };

  if (isLoading || selected === undefined) {
    return (
      <div className="h-16 bg-surface w-full flex flex-row divide-x-2 divide-secondary font-roboto border-b border-secondar text-roboto">
        <div
          className="w-[238px]
      px-1 grid grid-flow-col place-items-center justify-between  "
        >
          <div className="w-[209px] z-10">
            <div className="relative">
              {" "}
              <div className="relative w-full rounded-lg border border-primary bg-white py-2 pl-3 pr-10 text-left">
                {" "}
                Loading
              </div>
            </div>
          </div>
        </div>
        <div className="px-4 grid grid-flow-col justify-start place-items-center h-full w-full">
          <div className="flex flex-row h-4/5 gap-4 text-lg border-r-2 px-4 border-secondary place-items-center">
            <div>Price</div>
            <div className="font-monda">$100,000</div>
            <div>
              <ChevronUpIcon className="h-6 w-6 text-green-500" />
            </div>
            <div className="font-monda">24%</div>
          </div>
          <div className="grid grid-flow-col h-4/5 w-full gap-4 text-lg border-r-2 px-4 border-secondary place-items-center">
            <div>Price</div>
            <div className="font-monda">$100,000</div>
            <div>
              <ChevronUpIcon className="h-6 w-6 text-green-500" />
            </div>
            <div className="font-monda">24%</div>
          </div>
          <div className="grid grid-flow-col h-4/5 w-full gap-4 text-lg border-r-2 px-4 border-secondary place-items-center">
            <div>Price</div>
            <div className="font-monda">$100,000</div>
            <div>
              <ChevronUpIcon className="h-6 w-6 text-green-500" />
            </div>
            <div className="font-monda">24%</div>
          </div>
          <div className="grid grid-flow-col h-4/5 w-full gap-4 text-lg border-r-2 px-4 border-secondary place-items-center">
            <div>Price</div>
            <div className="font-monda">$100,000</div>
            <div>
              <ChevronUpIcon className="h-6 w-6 text-green-500" />
            </div>
            <div className="font-monda">24%</div>
          </div>
        </div>
      </div>
    );
  }
  if (isError) {
    return <div>Error</div>;
  }
  return (
    <div className="md:h-16 bg-surface w-full flex flex-col md:flex-row  font-roboto text-roboto">
      <div
        className="md:w-[250px]
        px-[0.9rem] grid grid-flow-col place-items-center md:justify-between md:py-0 py-2 "
      >
        <div className="md:w-[219px] z-10 relative flex grow w-full">
          <button
            onClick={() => {
              setSidebar(!sidebar);
              // console.log(props.sidebar);
            }}
            className="bg-surfaceVariant rounded-xl mr-2 p-2 border-2 group border-primaryContainer hover:bg-primary hover:text-white transition duration-0 md:hidden flex justify-center items-center"
          >
            <ArrowLeftOnRectangleIcon
              className={`${
                sidebar
                  ? "transition  duration-75"
                  : "rotate-180 transition duration-75"
              }h-6 w-6 group-hover:text-white text-onSurfaceVariant`}
            />
          </button>
          <Listbox value={selected} onChange={setSelected}>
            {({ open }) => (
              <>
                <div className="relative w-full">
                  <Listbox.Button className="relative w-full rounded-lg border border-primary bg-white py-2 pl-3 pr-10 text-left">
                    <span className="flex items-center">
                      <img
                        src={process.env.API + "/" + selected.icon}
                        alt=""
                        className="h-6 w-6 flex-shrink-0 rounded-full"
                      />
                      <span className="ml-3 block truncate font-semibold text-lg">
                        {selected.name}
                      </span>
                    </span>
                    <span className="absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                      <ChevronDownIcon
                        className="h-6 w-6 text-primary"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-30 mt-1 max-h-56 w-full overflow-auto rounded-lg bg-white shadow-lg border border-primary ">
                      {data.data.blockchain?.map((data, index) => (
                        <button className="w-full divide-x" key={index}>
                          <Listbox.Option
                            onClick={() => {
                              navigate({
                                pathname: chartTableNav()
                                  ? "/Explore/Tables"
                                  : "/Explore",
                                search: data.search,
                              });
                            }}
                            key={data._id}
                            className={({ active }) =>
                              classNames(
                                active
                                  ? "text-white bg-primary"
                                  : "text-gray-900",
                                "relative py-2 pl-3 pr-9 rounded-md"
                              )
                            }
                            value={data}
                          >
                            {({ selected, active }) => (
                              <>
                                <div className="flex items-center">
                                  <img
                                    src={process.env.API + "/" + data.icon}
                                    alt=""
                                    className="h-6 w-6 flex-shrink-0 rounded-full"
                                  />
                                  <span
                                    className={classNames(
                                      selected ? "" : "font-normal",
                                      "ml-3 block truncate"
                                    )}
                                  >
                                    {data.name}
                                  </span>
                                </div>

                                {selected ? (
                                  <span
                                    className={classNames(
                                      active ? "text-white" : "text-primary",
                                      "absolute inset-y-0 right-0 flex items-center pr-4"
                                    )}
                                  >
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        </button>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        </div>
      </div>
      <div className=" ml-2 flex items-center text-center ">
        <div className="rounded-lg   text-gray-900 bg-white border border-primary flex">
          <button
            className={`${
              !chartTableNav() ? "bg-primary text-white font-semibold" : ""
            } flex rounded-l-md pl-2 items-center py-2.5 px-2 w-full h-full`}
            onClick={() => {
              navigate({
                pathname: "/Explore",
                search: "?blockchain=" + searchParams.get("blockchain"),
              });
            }}
          >
            <ChartBarIcon className="w-4 h-4 mr-1" />
            Charts
          </button>
          <button
            className={`${
              chartTableNav()
                ? "bg-primary text-white font-semibold"
                : "hover:bg-primary/30"
            } flex rounded-r-md pr-2 items-center py-2.5 px-2 w-full h-full`}
            onClick={() => {
              navigate({
                pathname: "/Explore/Tables",
                search: "?blockchain=" + searchParams.get("blockchain"),
              });
            }}
          >
            <TableCellsIcon className="w-4 h-4 mr-1" />
            Tables
          </button>
        </div>
      </div>
      <div className="w-full overflow-auto">
        {tickerLoading ? (
          <div className="h-full animate-pulse w-full justify-center flex ">
            <img
              src={watermarkIcon}
              alt="..."
              className="h-6 pb-1 md:h-14 md:w-14 self-center"
            />
          </div>
        ) : (
          <div className="h-full">
            {tickerError ? (
              <div className="h-full w-full justify-center flex ">
                <ExclamationCircleIcon
                  alt="..."
                  className="h-14 w-14 self-center text-red-500"
                />
              </div>
            ) : (
              <div className="px-4 grid grid-flow-col justify-start place-items-center max-w-full md:py-0 pb-2 h-full">
                {tickerData.data.map((data) => (
                  <div
                    key={data.name}
                    className=" flex flex-row h-4/5 gap-2 first:border-l-2 border-r-2 px-4 border-secondary place-items-center text-sm xl:text-lg"
                  >
                    <div className="font-roboto text-center whitespace-nowrap overflow-visible text-gray-700">
                      {data.name}
                    </div>
                    {/* {value.data.data.count.toLocaleString("en-US", {
                            maximumFractionDigits: 2,
                          })} */}
                    <div className="font-monda text-center flex text-gray-700">
                      <div
                        className={`${
                          isPrice(data.name) ? "block" : "hidden"
                        } `}
                      >
                        $
                      </div>
                      {data.data[0][1].toLocaleString("en-US", {
                        maximumFractionDigits: 2,
                      })}
                    </div>
                    <div>
                      <ChevronUpIcon
                        className={`${
                          colour(data.data[1][1], data.data[0][1])
                            ? "text-green-500"
                            : "text-red-500 rotate-180"
                        } h-4 w-4 md:h-6 md:w-6`}
                      />
                    </div>
                    <div
                      className={`${
                        colour(data.data[1][1], data.data[0][1])
                          ? "text-green-500"
                          : "text-red-500"
                      } font-monda font-bold align-baseline  md:text-sm`}
                    >
                      {(
                        ((data.data[0][1] - data.data[1][1]) /
                          data.data[0][1]) *
                        100
                      ).toFixed(1)}
                      %
                    </div>
                    {/* <div className="font-monda text-center">
                  {data.data[0][1] - data.data[1][1]}%
                </div> */}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default NavBar;
