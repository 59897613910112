// useQueryStates.js
import { useState } from "react";
//useMutation,not needed
import { useQueryClient } from "@tanstack/react-query";
import { useCreateSavedQuery } from "./API/UseCreateSavedQuery";
import { useUpdateSavedQuery } from "./API/UseUpdateSavedQuery";
import { useDeleteSavedQuery } from "./API/useDeleteSavedQuery";
import { useToast } from "../Ui/use-toast";

const useQueryStates = () => {
  const { toast } = useToast();

  const [openDialog, setOpenDialog] = useState(false);
  const [queryName, setQueryName] = useState("");
  const [queryDescription, setQueryDescription] = useState("");
  const [selectedSavedQuery, setSelectedSavedQuery] = useState(null);

  const queryClient = useQueryClient();

  const {
    mutate: createSavedQuery,
    isLoading: isCreatingSavedQuery,
    isError: isCreateSavedQueryError,
  } = useCreateSavedQuery();

  const {
    mutate: updateSavedQuery,
    isLoading: isUpdatingSavedQuery,
    isError: isUpdateSavedQueryError,
  } = useUpdateSavedQuery();

  const {
    mutate: deleteSavedQuery,
    isLoading: isDeletingSavedQuery,
    isError: isDeleteSavedQueryError,
  } = useDeleteSavedQuery();

  const handleOpenDialog = () => {
    setOpenDialog(true);
    setSelectedSavedQuery(null); // Reset selectedSavedQuery when opening the dialog
  };

  const handleSaveQuery = (showChartSettings, queryText, chartConfig) => {
    let formattedChartSettings = {};

    if (showChartSettings) {
      if (!chartConfig || !chartConfig.series) {
        toast({
          title: "Error",
          description:
            "Cannot save a chart style without first creating a chart!",
          variant: "destructive",
        });
        return;
      }

      formattedChartSettings = {
        chartType: chartConfig.chartType,
        xAxisVariable: chartConfig.xAxisVariable,
        title: chartConfig.title,
        yAxes: chartConfig.series.map((serie) => ({
          variable: serie.name,
          color: serie.color,
        })),
      };
    }

    const newQuery = {
      name: queryName,
      description: queryDescription,
      query: queryText,
      ...(showChartSettings && { chartSettings: formattedChartSettings }),
    };

    createSavedQuery(newQuery, {
      onSuccess: () => {
        queryClient.invalidateQueries("savedQueries");
        setQueryName("");
        setQueryDescription("");
        setOpenDialog(false);
      },
      onError: (error) => {
        console.error("Error saving query:", error);
      },
    });
  };
  const handleUpdateQuery = (showChartSettings, queryText, chartConfig) => {
    const updatedQuery = {
      name: queryName,
      description: queryDescription,
      query: queryText,
      ...(showChartSettings && { chartSettings: chartConfig }),
    };

    updateSavedQuery(
      {
        queryId: selectedSavedQuery._id,
        updatedQuery: updatedQuery,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("savedQueries");
          setQueryName("");
          setQueryDescription("");
          setOpenDialog(false);
        },
      }
    );
  };

  const handleDeleteQuery = (queryId) => {
    deleteSavedQuery(queryId, {
      onSuccess: () => {
        queryClient.invalidateQueries("getSavedQueries");
      },
    });
  };

  return {
    openDialog,
    setOpenDialog,
    queryName,
    setQueryName,
    queryDescription,
    setQueryDescription,
    selectedSavedQuery,
    setSelectedSavedQuery,
    isCreatingSavedQuery,
    isCreateSavedQueryError,
    isUpdatingSavedQuery,
    isUpdateSavedQueryError,
    isDeletingSavedQuery,
    isDeleteSavedQueryError,
    handleOpenDialog,
    handleSaveQuery,
    handleUpdateQuery,
    handleDeleteQuery,
  };
};

export default useQueryStates;
