// created by james launder
// used to show the sentiment, changes based on screen size
/* eslint-disable no-unused-vars */
import "./ProgressBar.css";

const ProgressBar = (props) => {
  let {
    size = 150,
    progress = 0,
    trackWidth = 10,
    trackColor = `#ddd`,
    indicatorWidth = 10,
    indicatorColor = `#07c`,
    indicatorCap = `round`,
    label = `Sentiement`,
    secondLabel = "Neutral",
    labelColor = `#333`,
    spinnerMode = false,
    spinnerSpeed = 1,
  } = props;
  function calculateNewValue(value) {
    return (value + 5) * 10;
  }

  function calculateText(value) {
    if (value == -5) {
      return "Terrible";
    } else if (value <= -2) {
      return "Poor";
    } else if (value < 2) {
      return "Neutral";
    } else if (value < 5) {
      return "Good";
    } else {
      return "Excellent";
    }
  }
  function calculateColor(value) {
    if (value == -5) {
      return "#ef4444";
    } else if (value <= -2) {
      return "#f97316";
    } else if (value < 2) {
      return "#facc15";
    } else if (value < 5) {
      return "#a3e635";
    } else {
      return "#22c55e";
    }
  }
  var center = size / 2,
    radius =
      center - (trackWidth > indicatorWidth ? trackWidth : indicatorWidth),
    dashArray = 2 * Math.PI * radius,
    dashOffset = dashArray * ((100 - calculateNewValue(progress)) / 100);

  let hideLabel = size < 100 || !label.length || spinnerMode ? true : false;

  return (
    <>
      <div className="svg-pi-wrapper" style={{ width: size, height: size }}>
        <svg className="svg-pi" style={{ width: size, height: size }}>
          <circle
            className="svg-pi-track"
            cx={center}
            cy={center}
            fill="transparent"
            r={radius}
            stroke={trackColor}
            strokeWidth={trackWidth}
          />
          <circle
            className={`svg-pi-indicator ${
              spinnerMode ? "svg-pi-indicator--spinner" : ""
            }`}
            style={{ animationDuration: spinnerSpeed * 1000 }}
            cx={center}
            cy={center}
            fill="transparent"
            r={radius}
            stroke={calculateColor(progress)}
            strokeWidth={indicatorWidth}
            strokeDasharray={dashArray}
            strokeDashoffset={dashOffset}
            strokeLinecap={indicatorCap}
          />
        </svg>

        {!hideLabel && (
          <div className="svg-pi-label" style={{ color: labelColor }}>
            <span className="svg-pi-label__loading">
              {calculateNewValue(progress)}%
            </span>

            {!spinnerMode && (
              <span className="svg-pi-label__progress">
                {calculateText(progress)}
              </span>
            )}
            {/* <span className="svg-pi-label__loading">{secondLabel}</span> */}
          </div>
        )}
      </div>
    </>
  );
};

export default ProgressBar;
