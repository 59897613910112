import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/outline";

import classNames from "../../../utils/ClassNames";
import { NavButton } from "../../Ui/NavButton";
import { useNavigate } from "react-router-dom";

export default function SearchDialog({ open, setOpen }) {
  const [addressInput, setAddressInput] = useState("");
  const navigate = useNavigate();
  const handleAddressInput = (event) => {
    setAddressInput(event.target.value);
  };
  const submit = () => {
    if (!addressInput.trim()) {
      alert("Please enter an address.");
      return; // Stop the function execution if no address is entered
    }
    const trimmedPath = location.pathname.replace(/^\/|\/$/g, "");
    const pathSegments = trimmedPath.split("/");

    // The tab is the second segment after 'Footprint'
    let currentTab =
      pathSegments.length > 1 ? pathSegments[1].toLowerCase() : "portfolio";

    // If currentTab is 'footprint', set it to 'portfolio'
    if (currentTab === "footprint") {
      currentTab = "portfolio";
    }

    const newPath = `/Footprint/${currentTab}?address=${addressInput || ""}`;
    navigate(newPath);
    setOpen(false);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden  bg-white rounded-lg pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block ">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                    onClick={() => {
                      // setErrorMsg("");
                      // clearClose();
                      setOpen(false);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon
                      className="h-6 w-6 group-hover:text-white "
                      aria-hidden="true"
                    />
                  </button>
                </div>
                <div className="bg-white px-8 pb-4 pt-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start ">
                    <div className="mt-2 text-center sm:mt-2 sm:text-left  w-full ">
                      <div className="flex flex-row items-center">
                        <div className="flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-orange-100 sm:mx-0 sm:h-10 sm:w-10">
                          <MagnifyingGlassIcon
                            className="h-6 w-6 text-primary"
                            aria-hidden="true"
                          />
                        </div>
                        <Dialog.Title
                          as="h3"
                          className="ml-4 text-base font-semibold leading-6 text-gray-900"
                        >
                          Search address
                        </Dialog.Title>
                      </div>
                      <div className="flex flex-row mt-2  pt-4 pb-4">
                        <input
                          id="addressInput"
                          type="text"
                          placeholder="Add Address... 0x.."
                          value={addressInput}
                          onChange={handleAddressInput}
                          className={classNames(
                            "h-full text-base border px-2 py-2 w-full flex-grow focus:outline-0  duration-500 border-onSurfaceVariant shadow-lg rounded-l-md"
                          )}
                        />
                        <NavButton
                          type="button"
                          variant="semibold"
                          onClick={() => {
                            submit();
                          }}
                          className="h-full rounded-r-md border-t border-b border-r border-onSurfaceVariant bg-white hover:bg-primary group p-3 shadow-md"
                        >
                          Go
                        </NavButton>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
