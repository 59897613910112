import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  BellAlertIcon,
  ChevronDoubleLeftIcon,
  PencilSquareIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import useCompareQuery from "./useCompareQuery";
import CompareCombo from "./CompareCombo";
import UseMutationNotification from "./UseMutationNotification";
import UseMutationEditNotification from "../Alerts/UseMutationEditNotification";
import {
  ArrowPathIcon,
  ChevronDoubleRightIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import classNames from "../../utils/ClassNames";
import UseCurrentValueQuery from "../Alerts/UseCurrentValueQuery";
import { UserContext } from "../../Services/userContext";
import { Tooltip } from "react-tooltip";

// TODO:
// add validation to the form
// put in states for the query
// deal with the email, telegram re work
// multiple sources
// add in text at the bottom like glassnode

export default function NotificationDialog({
  open,
  setOpen,
  optimistic = false,
  blockchain = null,
  chartName = null,
  resolution = "Day",
  sourceProp = null,
  conditionProp = "Greater than",
  frequencyProp = "Once",
  valueProp = 0,
  channelProp = true,
  alertNameProp = "",
  edit = false,
  id = null,
}) {
  const { userData } = useContext(UserContext);
  const [collapse, setCollapse] = useState(true);
  const [selectedBlockchain, setSelectedBlockchain] = useState({
    name: blockchain,
  });
  const [selectedChart, setSelectedChart] = useState(chartName);
  const [selectedSource, setSelectedSource] = useState(sourceProp);
  const [selectedResolution, setSelectedResolution] = useState(resolution);
  const [selectedCondition, setSelectedCondition] = useState({
    name: conditionProp,
  });
  const [selectedFrequency, setSelectedFrequency] = useState({
    name: frequencyProp,
  });
  const [value, setValue] = useState(valueProp);
  const [email, setEmail] = useState(channelProp);
  const [alertName, setAlertName] = useState(alertNameProp);
  const [message, setMessage] = useState("");

  function handleChangeEmail(e) {
    let isChecked = e.target.checked;
    if (isChecked) {
      setEmail(true);
    } else {
      setEmail(false);
    }
    // do whatever you want with isChecked value
  }

  const {
    mutation: mutation,
    loading: loading,
    error: error,
    success: success,
    message: errMessage,
  } = UseMutationNotification(optimistic);
  const {
    mutation: mutateEdit,
    loading: loadingEdit,
    error: errorEdit,
    success: successEdit,
    message: errMessageEdit,
  } = UseMutationEditNotification(id);

  const {
    blockchains: blockchains,
    blockchainsLoading: isLoading,
    charts: charts,
    chartsLoading: chartsLoading,
    chartsFetching: chartsFetching,
  } = useCompareQuery(selectedBlockchain?.name);
  const {
    isLoading: currLoading,
    isError: currError,
    data: currData,
  } = UseCurrentValueQuery(
    selectedBlockchain?.name,
    selectedResolution,
    selectedChart?.name,
    selectedSource
  );

  const cancelButtonRef = useRef(null);
  let valid = true;
  let conditions = [
    {
      name: "Greater than",
      iconLocal: (
        <ChevronDoubleRightIcon className="h-6 w-6 flex-shrink-0 rounded-full text-primary group-hover:text-white" />
      ),
    },
    {
      name: "Less than",
      iconLocal: (
        <ChevronDoubleLeftIcon className="h-6 w-6 flex-shrink-0 rounded-full text-primary group-hover:text-white" />
      ),
    },
  ];
  let frequency = [
    {
      name: "Once",
    },
    {
      name: "Daily",
    },
    {
      name: "Hours",
    },
  ];

  const submit = () => {
    setMessage("");
    if (edit) {
      detectChanges();
    } else {
      if (
        !selectedBlockchain ||
        !selectedChart ||
        !selectedResolution ||
        !selectedChart?.dataSource ||
        !selectedCondition ||
        value === null ||
        !selectedFrequency
      ) {
        if (Array.isArray(selectedChart?.sources)) {
          setMessage(
            <div className="text-red-500 font-semibold flex gap-1 self-center">
              <ExclamationCircleIcon className="h-6 w-6 self-center mt-[1px]" />
              Missing Source!
            </div>
          );
        } else {
          setMessage(
            <div className="text-red-500 font-semibold flex gap-1 self-center">
              <ExclamationCircleIcon className="h-6 w-6 self-center mt-[1px]" />
              Missing Values!
            </div>
          );
        }

        return;
      } else {
        var sourceSelect = null;
        if (Array.isArray(selectedChart?.sources)) {
          sourceSelect = selectedSource.name;
        } else {
          sourceSelect = selectedChart.dataSource;
        }
        mutation.mutate({
          blockchain: selectedBlockchain,
          chart: selectedChart,
          resolution: selectedResolution,
          source: sourceSelect,
          sources: selectedSource,
          condition: selectedCondition,
          val: value,
          freq: selectedFrequency,
          email: email,
          name: alertName,
        });
      }
    }
  };
  useEffect(() => {
    if (
      !chartsLoading &&
      !(
        selectedBlockchain.name === "None" || selectedBlockchain.name === null
      ) &&
      edit &&
      chartName != null
    ) {
      let editChartName = charts?.data?.graphNames.filter(
        (chart) => chart.name === chartName
      );
      if (editChartName) {
        setSelectedChart(editChartName[0]);
      } else {
        return;
      }
    }
  }, [chartsLoading]);

  // changes made
  function detectChanges() {
    if (
      chartName != selectedChart?.name ||
      blockchain != selectedBlockchain.name ||
      resolution != selectedResolution ||
      conditionProp != selectedCondition.name ||
      frequencyProp != selectedFrequency.name ||
      valueProp != value ||
      alertNameProp != alertName
    ) {
      mutateEdit.mutate({
        blockchain: selectedBlockchain,
        chart: selectedChart,
        resolution: selectedResolution,
        source: selectedChart.dataSource,
        sources: selectedSource,
        condition: selectedCondition,
        val: value,
        freq: selectedFrequency,
        email: email,
        name: alertName,
      });
    }
  }

  function handleCurrentValue() {
    if (
      selectedBlockchain?.name === null ||
      selectedChart === null ||
      // selectedChart?.name === undefined ||
      selectedResolution === null ||
      currData?.data?.graphData === undefined
    ) {
      return "Waiting...";
    }
    if (currError) {
      return "Error";
    }
    if (currLoading) {
      return "Loading...";
    }

    return (
      currData?.data?.graphData[0]?.data[1].toLocaleString("en-US", {
        maximumFractionDigits: 2,
      }) ?? "No data"
    );
  }

  function checkTier(res) {
    switch (userData.tier) {
      case 0:
        if (res === "Day") {
          return false;
        }
        return true;
      case 1:
        if (res === "Block" || res === "Min") {
          return true;
        }
        return false;
      default:
        return false;
    }
  }

  useEffect(() => {
    if (success || successEdit) {
      setOpen(false);
    }
    if (success) {
      setSelectedChart(null);
      setSelectedSource(null);
      setSelectedResolution("Day");
      setSelectedCondition({ name: "Greater than" });
      setSelectedFrequency({ name: "Once" });
      setAlertName("");
      setValue(0);
    }
  }, [success, successEdit]);

  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md">
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                      onClick={() => {
                        // setErrorMsg("");
                        setOpen(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start ">
                      <div className="mt-3 text-center sm:mt-2 sm:text-left  w-full ">
                        <div className="flex flex-row items-center">
                          <div className="flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-orange-100 sm:mx-0 sm:h-10 sm:w-10">
                            <BellAlertIcon
                              className="h-6 w-6 text-primary"
                              aria-hidden="true"
                            />
                          </div>
                          <Dialog.Title
                            as="h3"
                            className="ml-4 text-base font-semibold leading-6 text-gray-900"
                          >
                            Add new alert
                          </Dialog.Title>
                        </div>

                        <div className="relative mt-4 bg-gray-100 p-2 rounded-lg">
                          <div className="absolute right-0 top-0 hidden pr-2 pt-2 sm:block">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                              onClick={() =>
                                setCollapse((collapse) => !collapse)
                              }
                            >
                              <span className="sr-only">Close</span>
                              <PencilSquareIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                          <div className="flex flex-col">
                            {isLoading ? (
                              <div>Loading...</div>
                            ) : (
                              <CompareCombo
                                title={"Blockchain"}
                                data={blockchains?.data?.blockchain}
                                selected={selectedBlockchain}
                                setSelected={setSelectedBlockchain}
                              />
                            )}
                          </div>
                          <div className="flex flex-col mt-4">
                            {selectedBlockchain.name === "None" ||
                            selectedBlockchain.name === null ? (
                              <div>
                                <div className="block text-sm font-medium leading-6 text-gray-900">
                                  Charts
                                </div>
                                <div className="truncate">
                                  Select A Chart...
                                </div>
                              </div>
                            ) : (
                              <div>
                                {chartsFetching || chartsLoading ? (
                                  <div>
                                    <div className="block text-sm font-medium leading-6 text-gray-900">
                                      Charts
                                    </div>
                                    <div className="truncate">Loading...</div>
                                  </div>
                                ) : (
                                  <CompareCombo
                                    title={"Charts"}
                                    data={charts?.data?.graphNames}
                                    selected={selectedChart}
                                    setSelected={setSelectedChart}
                                  />
                                )}
                              </div>
                            )}
                          </div>
                          {/* Hideable div to fit screen better */}
                          <Transition
                            show={collapse}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity duration-150"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            {/* <div className={collapse ? `hidden` : `block`}> */}
                            <div className="flex flex-col mt-4">
                              {selectedChart === null ? (
                                <div>
                                  <div className="block text-sm font-medium leading-6 text-gray-900">
                                    Source
                                  </div>
                                  <div className="truncate">
                                    Select A Chart...
                                  </div>
                                </div>
                              ) : (
                                <div>
                                  {Array.isArray(selectedChart.sources) ? (
                                    <CompareCombo
                                      title={"Source"}
                                      data={selectedChart.sources}
                                      selected={selectedSource}
                                      setSelected={setSelectedSource}
                                    />
                                  ) : (
                                    <div>
                                      <div className="block text-sm font-medium leading-6 text-gray-900">
                                        Source
                                      </div>
                                      <div className="truncate">
                                        {selectedChart.dataSource}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                            <div className="mt-4">
                              <>
                                <h1 className="block text-sm font-medium leading-6 text-gray-900">
                                  Resolution
                                </h1>
                                {selectedChart === null ? (
                                  <div className="mt-2 h-[40px]">
                                    Select A Chart...
                                  </div>
                                ) : (
                                  <div>
                                    <span className="grid grid-flow-col rounded-lg bg-white text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 mt-2">
                                      {selectedChart.resolution?.map((data) => (
                                        <>
                                          <button
                                            type="button"
                                            key={data + " combo"}
                                            onClick={() => {
                                              //   // params("resolution", data);
                                              setSelectedResolution(data);
                                            }}
                                            disabled={checkTier(data)}
                                            className={classNames(
                                              checkTier(data)
                                                ? "text-gray-300"
                                                : selectedResolution === data &&
                                                  !checkTier(data)
                                                ? "bg-primary text-white text-bold"
                                                : "text-gray-900 hover:bg-primary/30 hover:text-gray-900",

                                              "rounded-lg py-1.5 text-gray-900",
                                              "res-select-" + data
                                            )}
                                          >
                                            {data}
                                          </button>
                                          {checkTier(data) ? (
                                            <Tooltip
                                              anchorSelect={
                                                ".res-select-" + data
                                              }
                                              place="top"
                                            >
                                              Upgrade!
                                            </Tooltip>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      ))}
                                    </span>
                                  </div>
                                )}
                              </>
                            </div>
                            {/* </div> */}
                          </Transition>
                        </div>
                        <div className="mt-4 w-full border-b pb-4 block text-md font-medium leading-6 text-gray-900">
                          Options
                        </div>
                        <div className="mt-4">
                          <CompareCombo
                            title={"Condition"}
                            data={conditions}
                            selected={selectedCondition}
                            setSelected={setSelectedCondition}
                          />
                        </div>
                        <div className="mt-4 block text-sm font-medium leading-6 text-gray-900">
                          <div className="flex gap-2 justify-between items-center">
                            Value{" "}
                            <div className="block text-sm font-medium leading-6 text-gray-700 bg-gray-100 py-1 px-2 rounded-lg">
                              Current: {handleCurrentValue()}
                            </div>
                          </div>

                          <input
                            type="text"
                            name="value"
                            placeholder="Value"
                            className={`block border mt-1 border-onSurfaceVariant w-full py-2 pl-3 pr-9 rounded-lg focus:border-primary focus:outline-none`}
                            value={value}
                            onInput={(e) => setValue(e.target.value)}
                          />
                        </div>
                        <div className="mt-4">
                          <CompareCombo
                            title={"Alert Frequency"}
                            data={frequency}
                            selected={selectedFrequency}
                            setSelected={setSelectedFrequency}
                          />
                        </div>
                        <fieldset className="mt-4 block text-sm font-medium leading-6 text-gray-900">
                          Channel
                          <legend className="sr-only">Channel</legend>
                          <div className="relative flex items-start">
                            <div className="flex h-6 items-center">
                              <input
                                id="email"
                                aria-describedby="email-description"
                                name="email"
                                type="checkbox"
                                defaultChecked
                                disabled
                                className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                                onChange={(e) => handleChangeEmail(e)}
                              />
                            </div>
                            <div className="ml-3 text-sm leading-6">
                              <label
                                htmlFor="email"
                                className="font-medium text-gray-900"
                              >
                                Email
                              </label>{" "}
                              <span
                                id="comments-description"
                                className="text-gray-500"
                              >
                                <span className="sr-only">Email</span>
                              </span>
                            </div>
                          </div>
                        </fieldset>
                        <div className="mt-4 block text-sm font-medium leading-6 text-gray-900">
                          Alert Name
                          <input
                            type="text"
                            name="alert-name"
                            placeholder="Name"
                            className={`block border mt-1 border-onSurfaceVariant w-full py-2 pl-3 pr-9 rounded-lg focus:border-primary focus:outline-none`}
                            value={alertName}
                            onInput={(e) => setAlertName(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:justify-between sm:px-6">
                    {error || errorEdit ? (
                      <div>
                        {errMessage}
                        {errMessageEdit}
                      </div>
                    ) : (
                      <div>{message}</div>
                    )}

                    <div>
                      {valid ? (
                        <button
                          type="submit"
                          className={classNames(
                            "inline-flex w-full justify-center rounded-md  px-3 py-2 text-sm  shadow-sm  sm:ml-3 sm:w-auto gap-4",
                            loading || loadingEdit
                              ? "bg-gray-300 text-gray-400"
                              : "hover:text-white hover:bg-primary bg-primaryContainer"
                          )}
                          disabled={loading || loadingEdit}
                          onClick={() => {
                            // handleSubmit();
                            submit();
                          }}
                        >
                          {loading || loadingEdit ? (
                            <ArrowPathIcon className="h-5 w-5 animate-spin" />
                          ) : (
                            <div className="hidden"></div>
                          )}
                          {edit ? "Save" : "Add"}
                        </button>
                      ) : (
                        <button
                          disabled
                          className="cursor-not-allowed inline-flex w-full justify-center rounded-md bg-primaryContainer px-3 py-2 text-sm shadow-sm sm:ml-3 sm:w-auto"
                        >
                          Add
                        </button>
                      )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
