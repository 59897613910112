import { Outlet } from "react-router-dom";
import Navbar from "./NavBar/Navbar.js";
import { useState } from "react";

function Portfolio() {
  const [open, setOpen] = useState(true);
  return (
    <div className="flex flex-col relative z-0 h-full w-full mx-auto">
      {/* header bar */}
      <nav className="z-10">
        <Navbar sidebar={open} setSidebar={setOpen} />
      </nav>

      <div className="flex flex-row w-full min-h-screen bg-surface">
        <main className="h-full w-full z-0 pb-6">
          <Outlet />
        </main>
      </div>
    </div>
  );
}

export default Portfolio;
