import React from "react";
import { Skeleton } from "../Ui/skeleton";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../Ui/table";

const TableSkeleton = ({ columns, rows, isGrid }) => {
  if (!columns || columns.length === 0) {
    return null; // Return null if columns array is not available or empty
  }

  return (
    <div className="">
      <Table className="border rounded-md">
        <TableHeader>
          <TableRow>
            {columns.map((column, index) => (
              <TableHead key={index} className="text-xs font-semibold px-2">
                <div className="flex items-center justify-between">
                  <span
                    className={`${isGrid ? "truncate" : ""}`}
                    title={column.header}
                  >
                    {isGrid && column.header.length > 10
                      ? `${column.header.slice(0, 10)}...`
                      : column.header}
                  </span>
                  <Skeleton className="h-4 w-6" />
                </div>
              </TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {[...Array(rows)].map((_, rowIndex) => (
            <TableRow key={rowIndex}>
              {columns.map((column, colIndex) => (
                <TableCell key={colIndex} className="text-xs px-4 h-14">
                  <Skeleton className="h-4 w-full" />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default TableSkeleton;
