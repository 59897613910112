// Created By James Launder inspired by tailwindUI
// Modal form for mobile options

import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition, Popover } from "@headlessui/react";
import {
  ChevronRightIcon,
  ArrowSmallRightIcon,
  Cog6ToothIcon,
} from "@heroicons/react/24/solid";
import { useSearchParams } from "react-router-dom";
import UseNavQuery from "./UseNavQuery";

export default function MobileChartOptions(props) {
  const [open, setOpen] = useState(false);
  const {
    name: name,
    resolution: resolution,
    dataSource: dataSource,
    sources: sources,
  } = UseNavQuery();
  const [selectedExchange, setSelectedExchange] = useState("FTX");
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedResolution, setSelectedResolution] = useState(
    searchParams.get("resolution")
  );
  const [emaInput, setEmaInput] = useState("");
  const [selectedEma, setSelectedEma] = useState(searchParams.get("ema"));
  const [smaInput, setSmaInput] = useState("");
  const [selectedSma, setSelectedSma] = useState(searchParams.get("sma"));
  const [chartOpts, setChartOpts] = useState({
    chartOptions: [
      {
        yAxis: "Linear",
        style: "Line",
        name: name,
      },
      { yAxis: "Linear", style: "Line", name: "Price" },
    ],
  });
  const [EMA] = useState({
    None: [
      {
        disabled: "disabled",
      },
    ],
    "7d": [],
    "14d": [],
    "30d": [],
  });
  const [SMA] = useState({
    None: [
      {
        disabled: "disabled",
      },
    ],
    "7d": [],
    "14d": [],
    "30d": [],
  });
  const params = (name, value) => {
    searchParams.set(name, value);
    setSearchParams(searchParams);
  };
  // on load
  useEffect(() => {
    if (localStorage.getItem("user") != null) {
      if (JSON.parse(localStorage.getItem("user")).tier == 0) {
        setSelectedResolution("Daily");
      }
    }
    if (sources) {
      // i made changes here
      setSelectedExchange(sources[0]?.name);
    }
  }, []);
  useEffect(() => {
    updateName(0, name);
  }, [name]);
  useEffect(() => {
    setSelectedEma(searchParams.get("ema"));
    setSelectedSma(searchParams.get("sma"));
    setSelectedResolution(searchParams.get("resolution"));
    setSelectedExchange(searchParams.get("source"));
  }, [searchParams]);

  const handleEmaInput = (event) => {
    setEmaInput(event.target.value);
    setSelectedEma(event.target.value);
    if (!event.target.value) {
      setSelectedEma(searchParams.get("ema"));
    }
  };
  const handleSmaInput = (event) => {
    setSmaInput(event.target.value);
    setSelectedSma(event.target.value);
    if (!event.target.value) {
      setSelectedSma(searchParams.get("sma"));
    }
  };
  const updateStyle = (name, value) => {
    let updatedList = chartOpts.chartOptions.map((item) => {
      if (item.name === name) {
        return { ...item, style: value };
      }
      return item;
    });
    setChartOpts({ chartOptions: updatedList });
  };

  const updateYaxis = (name, value) => {
    let updatedList = chartOpts.chartOptions.map((item) => {
      if (item.name === name) {
        return { ...item, yAxis: value };
      }
      return item;
    });
    setChartOpts({ chartOptions: updatedList });
  };
  const updateName = (itemIndex, value) => {
    let updatedList = chartOpts.chartOptions.map((item, index) => {
      if (index === itemIndex) {
        return { ...item, name: value };
      }
      return item;
    });
    setChartOpts({ chartOptions: updatedList });
  };

  return (
    <div className="font-roboto ">
      <button
        onClick={() => {
          setOpen(true);
          // console.log("clicked");
        }}
        className="group flex flex-row  px-3 py-2 rounded-lg border-primaryContainer border-2 hover:bg-primary bg-surfaceVariant shadow-md"
      >
        <div className="md:hidden flex">
          <Cog6ToothIcon
            className="h-6 w-6 font-bold text-onSurfaceVariant self-center group-hover:text-white"
            aria-hidden="true"
          />
        </div>
      </button>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-[75%] items-end justify-center text-center sm:items-center p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full">
                  <div className="p-2 flex items-center">
                    <>
                      <span className="pr-4 text-gray-700">Data Source</span>
                      {/*  > md popover */}
                      {Array.isArray(sources) ? (
                        <div>
                          {/* < md pop over */}
                          <Popover
                            as="div"
                            className="inline-block md:hidden text-left"
                          >
                            {({ open }) => (
                              <>
                                <div>
                                  <Popover.Button className="group inline-flex justify-center rounded-lg w-32 bg-white ring-1 ring-inset ring-gray-300 hover:bg-primary hover:text-white px-4 py-2 text-center">
                                    <div className="mb-1">
                                      {selectedExchange}
                                    </div>

                                    <ChevronRightIcon
                                      className="h-5 w-5 ml-2 self-center text-secondary group-hover:text-white"
                                      aria-hidden="true"
                                    />
                                  </Popover.Button>
                                </div>
                                {open && (
                                  <Popover.Panel
                                    static
                                    className="absolute  ml-4 mt-1 z-10 divide-y divide-secondary rounded-lg bg-white ring-1 ring-inset ring-gray-300"
                                  >
                                    <div className="px-1 py-1">
                                      {props.graphSources.map(
                                        (exchange, index) => (
                                          <div key={index}>
                                            <ul className="divide-y divide-secondary px-1 py-2 hover:bg-primary hover:text-white rounded-lg">
                                              <button
                                                className="w-full text-left"
                                                onClick={() => {
                                                  setSelectedExchange(
                                                    exchange.name
                                                  );
                                                  params(
                                                    "source",
                                                    exchange.name
                                                  );
                                                }}
                                              >
                                                {exchange.name}
                                              </button>
                                            </ul>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </Popover.Panel>
                                )}
                              </>
                            )}
                          </Popover>
                        </div>
                      ) : (
                        <div className="font-robot  ">{dataSource}</div>
                      )}
                    </>
                  </div>
                  <div className="px-2 py-2 min-w-[256px]">
                    <>
                      <h1 className="pb-1 text-gray-700">Resolution</h1>

                      {resolution ? (
                        <span className="grid grid-flow-col rounded-lg bg-white text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 w-full">
                          {resolution.map((data) => (
                            <button
                              key={data}
                              onClick={() => {
                                params("resolution", data);
                              }}
                              className={`${
                                selectedResolution === data
                                  ? "bg-primary text-white text-bold"
                                  : "text-gray-900 hover:bg-primary/30 hover:text-gray-900"
                              } rounded-lg py-2.5 text-gray-900`}
                            >
                              {data}
                            </button>
                          ))}
                        </span>
                      ) : (
                        <div>
                          <span className="grid grid-flow-col rounded-lg bg-white text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 w-full"></span>
                        </div>
                      )}
                    </>
                  </div>
                  <div className="px-2 py-2 mr-1">
                    <h1 className="pb-1 text-gray-700">SMA</h1>
                    <div className="flex flex-auto rounded-lg bg-white text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 w-full">
                      <span className="grid grid-flow-col grow rounded-lg">
                        {Object.keys(SMA).map((category) => (
                          <button
                            key={category}
                            onClick={() => {
                              params("sma", category);
                            }}
                            className={`${
                              selectedSma === category
                                ? "bg-primary text-white text-bold"
                                : "text-gray-900 hover:bg-primary/30 hover:text-gray-900"
                            } rounded-lg py-2.5 text-gray-900`}
                          >
                            {category}
                          </button>
                        ))}
                      </span>
                      <span className="hidden md:flex place-content-end rounded-lg ">
                        <input
                          type="text"
                          className={`${
                            smaInput ? "bg-primary text-white" : ""
                          } w-16 rounded-l-lg border pl-2 border-gray-300`}
                          placeholder="Days"
                          onChange={handleSmaInput}
                          value={smaInput}
                        />
                        <button
                          className=" text-primary w-8 text-center rounded-r-lg hover:bg-primary hover:text-white"
                          onClick={() => {
                            // console.log(smaInput);
                            if (!smaInput) {
                              params("sma", "None");
                            } else {
                              params("sma", smaInput);
                            }
                          }}
                        >
                          <ArrowSmallRightIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                        </button>
                      </span>
                    </div>
                  </div>
                  <div className="px-2 py-2 mr-1">
                    <h1 className="pb-1 text-gray-700">EMA</h1>
                    <div className="flex flex-auto rounded-lg bg-white text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 w-full">
                      <span className="grid grid-flow-col grow rounded-lg">
                        {Object.keys(EMA).map((category) => (
                          <button
                            key={category}
                            onClick={() => {
                              params("ema", category);
                            }}
                            className={`${
                              selectedEma === category
                                ? "bg-primary text-white text-bold"
                                : "text-black hover:bg-primary/30 hover:text-black"
                            } w-full rounded-lg py-2.5 text-black`}
                          >
                            {category}
                          </button>
                        ))}
                      </span>
                      <span className="hidden md:flex place-content-end rounded-lg ">
                        <input
                          type="text"
                          className={`${
                            emaInput ? "bg-primary text-white" : ""
                          } w-16 rounded-l-lg border pl-2 border-gray-300`}
                          // className="w-16 h-full rounded-l-lg border-r-2 pl-2 border-secondary"
                          placeholder="Days"
                          onChange={handleEmaInput}
                          value={emaInput}
                        />
                        <button
                          className="text-primary w-8 text-center rounded-r-lg hover:bg-primary hover:text-white"
                          onClick={() => {
                            if (!emaInput) {
                              params("ema", "None");
                            } else {
                              params("ema", emaInput);
                            }
                          }}
                        >
                          <ArrowSmallRightIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                        </button>
                      </span>
                    </div>
                  </div>

                  <div className="px-2 py-2">
                    {chartOpts.chartOptions.map((value, index) => (
                      <div key={index}>
                        <h1 key={value.name} className="pb-1 text-gray-700">
                          {value.name}
                        </h1>
                        <span className="grid grid-flow-col gap-x-2">
                          <span className="grid grid-flow-col rounded-lg bg-white text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 w-full">
                            <button
                              onClick={() => {
                                // params("scale", "Linear");
                                // setLinear("Linear");
                                // let updateValue = { yAxis: "Test" };
                                if (index === 0) {
                                  params("scale", "Linear");
                                } else {
                                  params("scale" + index, "Linear");
                                }
                                updateYaxis(value.name, "Linear");
                                // props.setChartOpts((prevState) => ({
                                //   ...prevState,
                                //   ...updateValue,
                                // }));
                                // console.log(props.chartOpts);
                              }}
                              className={`${
                                value.yAxis === "Linear"
                                  ? "bg-primary text-white text-bold"
                                  : "text-gray-900 hover:bg-primary/30 hover:text-gray-900"
                              } rounded-lg py-2.5 text-gray-900 `}
                            >
                              Linear
                            </button>
                            <button
                              onClick={() => {
                                // params("scale", "Log");
                                // setLinear("Log");
                                if (index === 0) {
                                  params("scale", "Log");
                                } else {
                                  params("scale" + index, "Log");
                                }
                                updateYaxis(value.name, "Log");
                              }}
                              className={`${
                                value.yAxis === "Log"
                                  ? "bg-primary text-white text-bold"
                                  : "text-gray-900 hover:bg-primary/30 hover:text-gray-900"
                              } rounded-lg py-2.5 text-gray-900 `}
                            >
                              Log
                            </button>
                          </span>
                          <span className="grid grid-flow-col rounded-lg bg-white text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 w-full">
                            <button
                              onClick={() => {
                                // params("type", "Line");
                                updateStyle(value.name, "Line");
                                if (index === 0) {
                                  params("type", "Line");
                                } else {
                                  params("type" + index, "Line");
                                }
                                // setType("Line");
                              }}
                              className={`${
                                value.style === "Line"
                                  ? "bg-primary text-white text-bold"
                                  : "text-gray-900 hover:bg-primary/30 hover:text-gray-900"
                              } rounded-lg py-2.5 text-gray-900 `}
                            >
                              Line
                            </button>
                            <button
                              onClick={() => {
                                // params("type", "Bar");
                                updateStyle(value.name, "Bar");
                                if (index === 0) {
                                  params("type", "Bar");
                                } else {
                                  params("type" + index, "Bar");
                                }
                                // setType("Bar");
                              }}
                              className={`${
                                value.style === "Bar"
                                  ? "bg-primary text-white text-bold"
                                  : "text-gray-900 hover:bg-primary/30 hover:text-gray-900"
                              } rounded-lg py-2.5 text-gray-900 `}
                            >
                              Bar
                            </button>
                          </span>
                        </span>
                      </div>
                    ))}
                  </div>
                  <div className="px-2 py-2 justify-end flex">
                    <button
                      className="px-3 py-2 rounded-lg bg-primaryContainer"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
