// Created by james launder
// used to take in the current password, validate it and then change to a new password

/* eslint-disable jsx-a11y/label-has-associated-control */
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import request from "../../Api/Api";
import { useToast } from "../Ui/use-toast";
import { ArrowPathIcon } from "@heroicons/react/24/outline";

const schema = yup
  .object({
    currPassword: yup
      .string()
      .required("Password is mandatory")
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        "Password must contain at least 8 characters, one uppercase, one number and one special case character"
      ),
    password: yup
      .string()
      .required("Please enter your password")
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        "Password must contain at least 8 characters, one uppercase, one number and one special case character"
      ),
    confirmPwd: yup
      .string()
      .required("Please confirm your password")
      .oneOf([yup.ref("password")], "Password doesn't match"),
  })
  .required();
export default function ChangePass(props) {
  const [successful, setSuccessful] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const { toast } = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const mutation = useMutation({
    mutationFn: async ({ password, currPassword }) => {
      setLoading(true);

      return await request.put(
        "/user/changepassword",
        {
          email: JSON.parse(localStorage.getItem("user")).email,
          password: currPassword,
          newPassword: password,
        },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("user")).accessToken
            }`,
          },
        }
      );
    },

    onSuccess: () => {
      setSuccessful(true);
      setLoading(false);
      toast({
        variant: "success",
        title: "Success!",
        description: "Successfully updated password",
      });
      props.setOpen(false);

      setMessage("Password Reset");
    },
    onError: (error) => {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      setMessage(resMessage);
      setSuccessful(false);
      setLoading(false);
    },
  });
  const onSubmit = (data) => {
    mutation.mutate({
      currPassword: data.currPassword,
      password: data.password,
    });
  };

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={props.setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <form className="pt-4" onSubmit={handleSubmit(onSubmit)}>
                  <div className="flex flex-col">
                    <label className="block text-md font-roboto font-light">
                      Current Password:
                    </label>
                    <input
                      name="currPassword"
                      type="password"
                      placeholder="Current Password"
                      className={`block border border-onSurfaceVariant w-full p-3 rounded-lg mb-4 form-control focus:border-primary focus:outline-none ${
                        errors.password ? "is-invalid" : ""
                      }`}
                      {...register("currPassword")}
                    />
                    <div className="invalid-feedback">
                      {errors.currPassword?.message}
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <label className="block text-md font-roboto font-light">
                      New Password:
                    </label>
                    <input
                      name="password"
                      type="password"
                      placeholder="Password"
                      className={`block border border-onSurfaceVariant w-full p-3 rounded-lg mb-4 form-control focus:border-primary focus:outline-none ${
                        errors.password ? "is-invalid" : ""
                      }`}
                      {...register("password")}
                    />
                    <div className="invalid-feedback">
                      {errors.password?.message}
                    </div>
                  </div>
                  <div className="flex flex-col form-group">
                    <label className="block text-md font-roboto font-light">
                      Confirm New Password:
                    </label>
                    <input
                      name="confirmPwd"
                      type="password"
                      placeholder="Confirm Password"
                      className={`block border border-onSurfaceVariant w-full p-3 rounded-lg mb-4 form-control focus:border-primary focus:outline-none ${
                        errors.confirmPwd ? "is-invalid" : ""
                      }`}
                      {...register("confirmPwd")}
                    />
                    <div className="invalid-feedback">
                      {errors.confirmPwd?.message}
                    </div>
                  </div>
                  {message && (
                    <div className="form-group">
                      <div
                        className={
                          successful
                            ? "alert alert-success"
                            : "alert alert-danger"
                        }
                        role="alert"
                      >
                        {message}
                      </div>
                    </div>
                  )}
                  <div className="py-4 grid grid-cols-3">
                    <button
                      type="submit"
                      className="bg-surfaceVariant group hover:bg-primary hover:text-white text-gray-900 mt-3 inline-flex gap-4 w-full justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm  sm:mt-0 sm:w-auto"
                      disabled={loading}
                    >
                      {loading ? (
                        <ArrowPathIcon className="h-5 w-5 animate-spin group-hover:text-white" />
                      ) : (
                        <></>
                      )}
                      Reset
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
