// SavedQueries.js
import React from "react";
import { PencilIcon, DocumentTextIcon } from "@heroicons/react/24/solid";

const SavedQueries = ({
  userSavedQueries,
  isLoadingUserSavedQueries,
  onAddColumn,
  handleEditQuery,
  setOpenDialog,
  onLoadChartSettings,
}) => {
  const handleEdit = (query) => {
    handleEditQuery(query);
    setOpenDialog(true);
  };

  const onSelectQuery = (query) => {
    onAddColumn(query.query); // Existing functionality
    onLoadChartSettings(query.chartSettings); // New functionality to load chart settings
  };
  return (
    <div className="border-t pt-4">
      {isLoadingUserSavedQueries ? (
        <div className="text-sm text-gray-500">Loading saved queries...</div>
      ) : userSavedQueries && userSavedQueries.length > 0 ? (
        <div className="max-h-48 overflow-y-auto">
          {userSavedQueries.map((query) => (
            <div
              key={query._id}
              className="flex items-center justify-between py-2"
            >
              <div className="flex items-center space-x-2">
                <DocumentTextIcon className="h-5 w-5 text-gray-500" />
                <span
                  className="text-sm font-medium text-gray-700 hover:text-gray-900 cursor-pointer"
                  onClick={() => onSelectQuery(query)}
                >
                  {query.name}
                </span>
              </div>
              <PencilIcon
                className="h-4 w-4 text-gray-500 hover:text-gray-700 cursor-pointer"
                onClick={() => handleEdit(query)} // Call the handleEdit function
              />
            </div>
          ))}
        </div>
      ) : (
        <div className="text-sm text-gray-500">No saved queries found.</div>
      )}
    </div>
  );
};

export default SavedQueries;
