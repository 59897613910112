// created by James Launder
// simple chart that removes alot of the options from the main chart.

import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import { useEffect, useState, useRef } from "react";
import watermarkIcon from "../../Assets/WevrLogoSecondary.svg";
import { useSearchParams } from "react-router-dom";

function SimpleChart(props) {
  //   const { theme } = useContext(ThemeContext);
  var watermark = "";
  const [searchParams] = useSearchParams();
  var newStyle = document.createElement("style");
  newStyle.appendChild(
    document.createTextNode(
      "\
@font-face {\
  font-family: 'Monda';\
  font-style: sans-serif;\
  font-weight: 400;\
  src: url('https://fonts.googleapis.com/css2?family=Monda&display=swap')\
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;\
}\
"
    )
  );

  document.head.appendChild(newStyle);
  const dataSource = [
    {
      id: "first",
      name: props.name,
      data: props.data[0]?.values,
      color: "#FA9173",
      yAxis: 0,
      dataGrouping: {
        approximation: "average",
        enabled: true,
        forced: true,
        units: [["day", [1]]],
      },
    },
  ];
  const [chartOptions, setChartOptions] = useState({
    exporting: {
      enabled: false,
      enableImages: true,
    },
    legend: {
      enabled: true,
      itemStyle: {
        // color: text,
        fontWeight: "bold",
      },
    },
    chart: {
      type: "spline",
      zoomType: "x",
      backgroundColor: "transparent",
      renderTo: this,
      style: {
        fontFamily: "Monda",
      },
      events: {
        load: function () {
          var height_image = 100;
          var width_image = 152;
          // var height_image = 48;
          // var width_image = 20;
          var textX = this.plotLeft + this.plotWidth * 0.5;
          var textY = this.plotTop + this.plotHeight * 0.5;
          var position_x = textX - width_image / 2;
          var position_y = textY - height_image / 2;
          watermark = this.renderer
            .image(watermarkIcon, position_x, position_y, 152, 100)
            .add();
        },
        redraw: function () {
          var height_image = 100;
          var width_image = 152;
          // var height_image = 48;
          // var width_image = 20;
          var textX = this.plotLeft + this.plotWidth * 0.5;
          var textY = this.plotTop + this.plotHeight * 0.5;
          var position_x = textX - width_image / 2;
          var position_y = textY - height_image / 2;
          watermark.attr({
            x: position_x,
            y: position_y,
          });
        },
      },
    },
    scrollbar: {
      enabled: false,
    },
    xAxis: {
      labels: {
        style: {
          fontSize: "13px",
        },
      },
    },
    yAxis: {
      opposite: false,
      labels: {
        style: {
          fontSize: "13px",
        },
      },
    },
    series: dataSource,
    credits: {
      enabled: false,
    },
    navigator: {
      maskFill: "rgba(250, 145, 115, 0.35)",
    },
    rangeSelector: {
      enabled: false,
    },

    tooltip: {
      useHTML: true,
      formatter: function () {
        return this.points.reduce(function (s, point) {
          return (
            s +
            "<br/> <div>" +
            '<span style="color:' +
            point.color +
            '"> <b>' +
            point.series.name +
            ": " +
            "</b> </span>" +
            "<span>" +
            point.y +
            "</span>"
          );
        }, "<b>" +
          moment.unix(this.x / 1000).format("ddd, DD, HH:mm:ss") +
          "</b>");
      },
      valueDecimals: 0,
      borderColor: "#FA9173",
      borderRadius: 8,
      borderWidth: 1,
    },
    accessibility: {
      enabled: false,
    },
  });

  const chartRef = useRef(null);

  useEffect(() => {
    setChartOptions({
      series: dataSource,
    });
    window.dispatchEvent(new Event("resize"));
  }, []);

  useEffect(() => {
    window.dispatchEvent(new Event("resize"));
  }, [props.sidebar]);

  useEffect(() => {
    var updated = {
      id: "first",
      name: props.name,
      data: props.data[0]?.values,
      color: "#FA9173",
      yAxis: 0,
      dataGrouping: {
        approximation: "average",
        enabled: true,
        forced: true,
        units: [["day", [1]]],
      },
    };

    dataSource.splice(0, 1, updated);
    setChartOptions({
      series: dataSource,
    });
  }, [searchParams.get("blockchain")]);
  return (
    <div className="relative w-full z-0">
      {/* <div className="z-0 absolute top-1/2 left-1/2 transform md:-translate-x-1/2 -translate-x-1/3 md:-translate-y-3/4 -translate-y-3/4">
        <img
          src={watermarkIcon}
          alt=""
          className="objext-scale-down m-auto h-1/2 xl:h-36 block"
        />
      </div> */}
      <div className="z-10 h-full w-full">
        <HighchartsReact
          containerProps={{
            style: {
              width: "100%",
              height: "100%",
            },
          }}
          highcharts={Highcharts}
          constructorType={"stockChart"}
          options={chartOptions}
          ref={chartRef}
        />
      </div>
    </div>
  );
}
// className="h-1/3 md:h-1/2 xl:h-36"
export default SimpleChart;
