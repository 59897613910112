import React, { useEffect, useState } from "react";
import { Card, CardTitle, CardHeader, CardContent } from "../Ui/card.js";
import { useLocation, useNavigate } from "react-router-dom";
import CardLoading from "../Portfolio/CardLoading.js";
import {
  ArrowUpIcon,
  ArrowDownIcon,
  CheckIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { Tabs, TabsList, TabsTrigger } from "../Ui/tabs.js";
import classNames from "../../utils/ClassNames.js";
import { UsePercentile, UseTransactions } from "./API/AnalyticsQuerires.js";
import { Button } from "../Ui/button.js";

const activeClassName = "border-b-2 border-primary text-white bg-primary";
const notActiveClassName = "text-black hover:text-primary";
function DisplayAnalytics() {
  const [percentile, setPercentile] = useState("50%");
  const [blockchain, setBlockchain] = useState("Ethereum");
  const location = useLocation();
  const address = new URLSearchParams(location.search).get("address");
  const navigate = useNavigate(); // Use the useNavigate hook
  useEffect(() => {
    if (localStorage.getItem("user") === null) {
      navigate("/Footprint");
    }
  }, []);

  if (!address) {
    return <div>Please enter an address.</div>;
  }

  const {
    isLoading: tickerLoading,
    isError: tickerError,
    data: txData,
  } = UseTransactions();

  const {
    isLoading,
    isError: percentileError,
    data: metricsData,
  } = UsePercentile(percentile, blockchain);

  const handleChangePercentile = (newPercentile) =>
    setPercentile(newPercentile);
  // const handleChangeBlockchain = (newBlockchain) =>
  //   setBlockchain(newBlockchain);

  // const metrics = metricsData?.metrics?.Ethereum || {};

  const transactions = txData?.data || [];
  transactions.sort((a, b) => b.block_number - a.block_number);

  const weiToEther = (weiValue) => parseFloat(weiValue) / Math.pow(10, 18);

  const totalETHReceived = transactions
    .filter((tx) => tx.to_address.toLowerCase() === address.toLowerCase())
    .reduce((sum, tx) => sum + weiToEther(tx.value), 0);

  const totalETHSent = transactions
    .filter((tx) => tx.from_address.toLowerCase() === address.toLowerCase())
    .reduce((sum, tx) => sum + weiToEther(tx.value), 0);

  const largestTransactionReceived = Math.max(
    ...transactions
      .filter((tx) => tx.to_address.toLowerCase() === address.toLowerCase())
      .map((tx) => weiToEther(tx.value))
  );

  const largestTransactionSent = Math.max(
    ...transactions
      .filter((tx) => tx.from_address.toLowerCase() === address.toLowerCase())
      .map((tx) => weiToEther(tx.value))
  );
  const numberOfTransactions = transactions.length;

  const averageTransactionValue =
    numberOfTransactions > 0
      ? (totalETHReceived + totalETHSent) / numberOfTransactions
      : 0;

  const transactionDates = transactions.map(
    (tx) => tx.block_timestamp.split(" ")[0]
  );
  const mostActiveDayCalc = transactionDates
    .sort(
      (a, b) =>
        transactionDates.filter((date) => date === a).length -
        transactionDates.filter((date) => date === b).length
    )
    .pop();

  const getMostFrequentTransactionPartner = (
    transactions,
    currentAccountAddress
  ) => {
    if (!transactions || transactions.length === 0) {
      return null; // Return null or any appropriate value indicating no transactions or data not loaded
    }

    const partnerCounts = transactions.reduce((counts, tx) => {
      const partnerAddress =
        tx.from_address.toLowerCase() === currentAccountAddress.toLowerCase()
          ? tx.to_address.toLowerCase()
          : tx.from_address.toLowerCase();
      counts[partnerAddress] = (counts[partnerAddress] || 0) + 1;
      return counts;
    }, {});

    if (Object.keys(partnerCounts).length === 0) {
      return null; // Return null if no partner counts found (though this should be covered by the first check)
    }

    // Reduce the entries to find the most frequent partner
    return Object.entries(partnerCounts).reduce((a, b) =>
      a[1] > b[1] ? a : b
    )[0];
  };
  const mostFrequentPartner = getMostFrequentTransactionPartner(
    transactions,
    address
  );
  const getLongestIdlePeriod = (transactions) => {
    const sortedTransactions = transactions.sort(
      (a, b) => new Date(a.block_timestamp) - new Date(b.block_timestamp)
    );
    let longestIdlePeriod = 0;
    for (let i = 1; i < sortedTransactions.length; i++) {
      const currentIdlePeriod =
        new Date(sortedTransactions[i].block_timestamp) -
        new Date(sortedTransactions[i - 1].block_timestamp);
      if (currentIdlePeriod > longestIdlePeriod) {
        longestIdlePeriod = currentIdlePeriod;
      }
    }
    return longestIdlePeriod; // This is in milliseconds
  };
  const longestIdlePeriod = getLongestIdlePeriod(transactions);

  let totalGasUsed = 0;
  let totalGasPrice = 0;

  transactions.forEach((tx) => {
    totalGasUsed += tx.gas;
    totalGasPrice += tx.gas_price;
  });

  const averageGasPrice = totalGasPrice / transactions.length;
 
  const transactionTypeCounts = transactions.reduce((acc, tx) => {
    acc[tx.transaction_type] = (acc[tx.transaction_type] || 0) + 1;
    return acc;
  }, {});

  let mostCommonTransactionType = "N/A"; // Default value in case there are no transactions

  if (Object.keys(transactionTypeCounts).length > 0) {
    mostCommonTransactionType = Object.keys(transactionTypeCounts).reduce(
      (a, b) => (transactionTypeCounts[a] > transactionTypeCounts[b] ? a : b)
    );
  }

  const hourlyTransactionCounts = transactions.reduce((acc, tx) => {
    const hour = new Date(tx.block_timestamp).getHours();
    acc[hour] = (acc[hour] || 0) + 1;
    return acc;
  }, {});

  let peakTransactionHour = "N/A"; // Default value in case there are no transactions

  if (Object.keys(hourlyTransactionCounts).length > 0) {
    peakTransactionHour = Object.keys(hourlyTransactionCounts).reduce((a, b) =>
      hourlyTransactionCounts[a] > hourlyTransactionCounts[b] ? a : b
    );
  }
  const dayOfWeekCounts = transactions.reduce((acc, tx) => {
    const dayOfWeek = new Date(tx.block_timestamp).getDay(); // 0 (Sunday) to 6 (Saturday)
    acc[dayOfWeek] = (acc[dayOfWeek] || 0) + 1;
    return acc;
  }, {});

  // Initialize the most common day of the week as 'N/A'
  let mostCommonDayOfWeek = "N/A";

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  // Check if we have any transactions counted
  if (Object.keys(dayOfWeekCounts).length > 0) {
    // Find the day index with the maximum count
    const maxDayIndex = Object.keys(dayOfWeekCounts).reduce((a, b) =>
      dayOfWeekCounts[a] > dayOfWeekCounts[b] ? a : b
    );

    // Convert the index to the corresponding day name
    mostCommonDayOfWeek = days[parseInt(maxDayIndex)];
  }

  /// percentage stuff

  function calculateComparisonIndicator(totalValue, quantileValue) {
    let comparisonIndicator = {
      icon: null, // Default to no icon
      color: "text-gray-500", // Default color
      formattedPercentageDiff: "",
    };

    if (totalValue !== undefined && quantileValue > 0) {
      const percentageDifference =
        ((totalValue - quantileValue) / quantileValue) * 100;
      comparisonIndicator.color =
        percentageDifference < 0 ? "text-red-500" : "text-green-500";
      comparisonIndicator.icon =
        percentageDifference < 0 ? (
          <ArrowDownIcon className="w-5 h-5" />
        ) : (
          <ArrowUpIcon className="w-5 h-5" />
        );
      comparisonIndicator.formattedPercentageDiff = `${Math.abs(
        percentageDifference
      ).toFixed(2)}%`;
    } else if (quantileValue === 0) {
      comparisonIndicator.formattedPercentageDiff = "∞"; // Handle special case where quantileValue is 0
      comparisonIndicator.icon = (
        <ArrowUpIcon className="w-5 h-5 text-green-500" />
      );
    }

    return comparisonIndicator;
  }

  function generateComparisonIndicator(userValue, baselineValue) {
    const comparisonIndicator = {
      icon: null, // Default to no icon
      color: "text-gray-500", // Default color
      formattedUserValue: userValue ? userValue.toString() : "N/A",
      formattedBaselineValue: baselineValue ? baselineValue.toString() : "N/A",
      match: userValue == baselineValue, // Directly store match result
    };

    if (comparisonIndicator.match) {
      comparisonIndicator.color = "text-green-500"; // Green for matching values
      comparisonIndicator.icon = <CheckIcon className="w-5 h-5" />; // Use a checkmark icon for match
    } else {
      comparisonIndicator.color = "text-red-500"; // Red for non-matching values
      comparisonIndicator.icon = <XMarkIcon className="w-5 h-5" />; // Use an X icon for non-match
    }

    return comparisonIndicator;
  }

  let comparisonIndicatorETHReceived = {
    icon: null,
    color: "text-gray-500",
    formattedPercentageDiff: "",
  };
  let comparisonIndicatorETHSent = {
    icon: null,
    color: "text-gray-500",
    formattedPercentageDiff: "",
  };

  let comparisonTotalIndicatorETHReceived = {
    icon: null,
    color: "text-gray-500",
    formattedPercentageDiff: "",
  };
  let comparisonTotalIndicatorETHSent = {
    icon: null,
    color: "text-gray-500",
    formattedPercentageDiff: "",
  };
  let comparisonIndicatorAverageTransactionValue = {
    icon: null,
    color: "text-gray-500",
    formattedPercentageDiff: "",
  };
  let comparisonIndicatorAverageGasUsed = {
    icon: null,
    color: "text-gray-500",
    formattedPercentageDiff: "",
  };
  let comparisonIndicatorAverageGasPrice = {
    icon: null,
    color: "text-gray-500",
    formattedPercentageDiff: "",
  };
  let comparisonTotalTransactions = {
    icon: null,
    color: "text-gray-500",
    formattedPercentageDiff: "",
  };

  let comparisonTransactionType = {
    icon: null,
    color: "text-gray-500",
    formattedPercentageDiff: "",
  };
  let comparisonMostActiveDay = {
    icon: null,
    color: "text-gray-500",
    formattedPercentageDiff: "",
  };
  let comparisonMostCommonDay = {
    icon: null,
    color: "text-gray-500",
    formattedPercentageDiff: "",
  };
  let comparisonMostCommonHour = {
    icon: null,
    color: "text-gray-500",
    formattedPercentageDiff: "",
  };
  const allDataLoaded = !tickerLoading && !isLoading;
  if (allDataLoaded) {
    const metrics = metricsData?.data?.[0]?.metrics?.Ethereum;
    const {
      medianTransactionValue,
      medianGasUsed,
      medianGasPrice,
      quantileEthReceived, // Now correctly extracting this value
      quantileEthSent,
      quantileTotalEthReceived,
      quantileTotalEthSent,
      quantileTransactions,
      quantileGasPrice,
      transactionType,
      mostCommonDay,
      mostCommonHour,
      mostActiveDay,
    } = metrics;

    // Assuming totalETHReceived and totalETHSent are defined and available here
    comparisonIndicatorETHReceived = calculateComparisonIndicator(
      totalETHReceived,
      quantileEthReceived
    );
    comparisonIndicatorETHSent = calculateComparisonIndicator(
      totalETHSent,
      quantileEthSent
    );
    comparisonIndicatorAverageTransactionValue = calculateComparisonIndicator(
      averageTransactionValue,
      medianTransactionValue
    );

    comparisonIndicatorAverageGasUsed = calculateComparisonIndicator(
      totalGasUsed,
      medianGasUsed
    );

    comparisonIndicatorAverageGasPrice = calculateComparisonIndicator(
      averageGasPrice,
      quantileGasPrice
    );
    comparisonTotalIndicatorETHReceived = calculateComparisonIndicator(
      totalETHReceived,
      quantileTotalEthReceived
    );
    comparisonTotalIndicatorETHSent = calculateComparisonIndicator(
      totalETHSent,
      quantileTotalEthSent
    );
    comparisonTotalTransactions = calculateComparisonIndicator(
      numberOfTransactions,
      quantileTransactions
    );
    comparisonTransactionType = generateComparisonIndicator(
      mostCommonTransactionType,
      transactionType
    );
    comparisonMostActiveDay = generateComparisonIndicator(
      mostActiveDayCalc,
      mostActiveDay
    );

    comparisonMostCommonDay = generateComparisonIndicator(
      mostCommonDayOfWeek,
      mostCommonDay
    );
    comparisonMostCommonHour = generateComparisonIndicator(
      peakTransactionHour,
      mostCommonHour
    );
  }

  //date formatter
  function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-CA", {
      // 'en-CA' format yields 'YYYY-MM-DD'
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  }

  if (tickerError || percentileError) {
    return (
      <div className="h-96 font-monda text-5xl flex flex-col justify-center items-center gap-4 text-center mt-6 text-red-400">
        error
        <p className="text-gray-700 text-3xl">
          Can&apos;t retrieve values from this wallet address
        </p>
        <p className="text-gray-700 text-3xl">Refresh and try again</p>
        <Button
          className="w-fit text-white mt-2"
          onClick={() => navigate("/Footprint")}
        >
          Return to Footprint
        </Button>
      </div>
    );
  }
  return (
    <div className="w-full flex flex-col gap-4 font-roboto py-4 px-6">
      {/* Header with values */}
      <div className="grid grid-cols-3 gap-4 items-end">
        <Card className="col-span-1 lg:block hidden h-fit">
          <CardHeader>
            <CardTitle>Total ETH Received</CardTitle>
          </CardHeader>
          <CardContent>
            {tickerLoading ? (
              <CardLoading additionalClasses="flex-grow" />
            ) : (
              <div className="flex items-center space-x-2">
                <span>{`${totalETHReceived.toFixed(3)} ETH`}</span>
                {/* Ensure we only show the comparison if totalETHReceived is defined and the icon exists */}
                {totalETHReceived !== undefined &&
                  comparisonTotalIndicatorETHReceived.icon && (
                    <span
                      className={`flex items-center ${comparisonTotalIndicatorETHReceived.color}`}
                    >
                      {comparisonTotalIndicatorETHReceived.icon}

                      {
                        comparisonTotalIndicatorETHReceived.formattedPercentageDiff
                      }
                    </span>
                  )}
              </div>
            )}
          </CardContent>
        </Card>
        <div className="text-center p-2 col-span-3 lg:col-span-1 flex flex-col items-center w-full justify-center">
          <h1 className="text-2xl font-bold text-gray-800 my-4">
            Key Metric Percentile Comparisons
          </h1>
          <Tabs className="sm:px-3 flex gap-x-4 text-md lg:text-lg px-4 items-center justify-center text-center">
            <TabsList>
              <TabsTrigger
                onClick={() => {
                  handleChangePercentile("1%");
                }}
                className={classNames(
                  percentile === "1%" ? activeClassName : notActiveClassName
                )}
              >
                1%
              </TabsTrigger>
              <TabsTrigger
                onClick={() => {
                  handleChangePercentile("25%");
                }}
                className={classNames(
                  percentile === "25%" ? activeClassName : notActiveClassName
                )}
              >
                25%
              </TabsTrigger>
              <TabsTrigger
                onClick={() => {
                  handleChangePercentile("50%");
                }}
                className={classNames(
                  percentile === "50%" ? activeClassName : notActiveClassName
                )}
              >
                50%
              </TabsTrigger>
              <TabsTrigger
                onClick={() => {
                  handleChangePercentile("75%");
                }}
                className={classNames(
                  percentile === "75%" ? activeClassName : notActiveClassName
                )}
              >
                75%
              </TabsTrigger>
              <TabsTrigger
                onClick={() => {
                  handleChangePercentile("99%");
                }}
                className={classNames(
                  percentile === "99%" ? activeClassName : notActiveClassName
                )}
              >
                99%
              </TabsTrigger>
            </TabsList>
          </Tabs>
        </div>
        <Card className="col-span-1 lg:block hidden">
          <CardHeader>
            <CardTitle>Total ETH Sent</CardTitle>
          </CardHeader>
          <CardContent>
            {tickerLoading ? (
              <CardLoading additionalClasses="flex-grow" />
            ) : (
              <div className="flex items-center space-x-2">
                <span>{`${totalETHSent.toFixed(3)} ETH`}</span>
                {totalETHSent !== undefined &&
                  comparisonTotalIndicatorETHSent.icon && (
                    <span
                      className={`flex items-center ${comparisonTotalIndicatorETHSent.color}`}
                    >
                      {comparisonTotalIndicatorETHSent.icon}

                      {comparisonTotalIndicatorETHSent.formattedPercentageDiff}
                    </span>
                  )}
              </div>
            )}
          </CardContent>
        </Card>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4">
        {/* Total Value Card */}
        <Card className="col-span-1 lg:hidden block">
          <CardHeader>
            <CardTitle>Total ETH Received</CardTitle>
          </CardHeader>
          <CardContent>
            {tickerLoading ? (
              <CardLoading additionalClasses="flex-grow" />
            ) : (
              <div className="flex items-center space-x-2">
                <span>{`${totalETHReceived.toFixed(3)} ETH`}</span>
                {/* Ensure we only show the comparison if totalETHReceived is defined and the icon exists */}
                {totalETHReceived !== undefined &&
                  comparisonTotalIndicatorETHReceived.icon && (
                    <span
                      className={`flex items-center ${comparisonTotalIndicatorETHReceived.color}`}
                    >
                      {comparisonTotalIndicatorETHReceived.icon}

                      {
                        comparisonTotalIndicatorETHReceived.formattedPercentageDiff
                      }
                    </span>
                  )}
              </div>
            )}
          </CardContent>
        </Card>

        <Card className="col-span-1 lg:hidden block">
          <CardHeader>
            <CardTitle>Total ETH Sent</CardTitle>
          </CardHeader>
          <CardContent>
            {tickerLoading ? (
              <CardLoading additionalClasses="flex-grow" />
            ) : (
              <div className="flex items-center space-x-2">
                <span>{`${totalETHSent.toFixed(3)} ETH`}</span>
                {totalETHSent !== undefined &&
                  comparisonTotalIndicatorETHSent.icon && (
                    <span
                      className={`flex items-center ${comparisonTotalIndicatorETHSent.color}`}
                    >
                      {comparisonTotalIndicatorETHSent.icon}

                      {comparisonTotalIndicatorETHSent.formattedPercentageDiff}
                    </span>
                  )}
              </div>
            )}
          </CardContent>
        </Card>
        <Card className="col-span-1">
          <CardHeader>
            <CardTitle>Number of Transactions</CardTitle>
          </CardHeader>
          <CardContent>
            {tickerLoading ? (
              <CardLoading additionalClasses="flex-grow" />
            ) : (
              <div className="flex items-center space-x-2">
                <span>{numberOfTransactions}</span>
                {numberOfTransactions !== undefined &&
                  comparisonTotalTransactions.icon && (
                    <span
                      className={`flex items-center ${comparisonTotalTransactions.color}`}
                    >
                      {comparisonTotalTransactions.icon}

                      {comparisonTotalTransactions.formattedPercentageDiff}
                    </span>
                  )}
              </div>
            )}
          </CardContent>
        </Card>

        <Card className="col-span-1">
          <CardHeader>
            <CardTitle>Average Transaction Value</CardTitle>
          </CardHeader>
          <CardContent>
            {tickerLoading ? (
              <CardLoading additionalClasses="flex-grow" />
            ) : (
              <div className="flex items-center space-x-2">
                <span>{`${averageTransactionValue.toFixed(3)} ETH`}</span>
                {/* Ensure we only show the comparison if averageTransactionValue is defined */}
                {averageTransactionValue !== undefined &&
                  comparisonIndicatorAverageTransactionValue.icon && (
                    <span
                      className={`flex items-center ${comparisonIndicatorAverageTransactionValue.color}`}
                    >
                      {comparisonIndicatorAverageTransactionValue.icon}

                      {
                        comparisonIndicatorAverageTransactionValue.formattedPercentageDiff
                      }
                    </span>
                  )}
              </div>
            )}
          </CardContent>
        </Card>

        <Card className="col-span-1">
          <CardHeader>
            <CardTitle>Largest Transaction Received</CardTitle>
          </CardHeader>
          <CardContent>
            {tickerLoading ? (
              <CardLoading additionalClasses="flex-grow" />
            ) : (
              <div className="flex items-center space-x-2">
                <span>{`${largestTransactionReceived.toFixed(3)} ETH`}</span>
                {/* Ensure we only show the comparison if totalETHReceived is defined and the icon exists */}
                {largestTransactionReceived !== undefined &&
                  comparisonIndicatorETHReceived.icon && (
                    <span
                      className={`flex items-center ${comparisonIndicatorETHReceived.color}`}
                    >
                      {comparisonIndicatorETHReceived.icon}

                      {comparisonIndicatorETHReceived.formattedPercentageDiff}
                    </span>
                  )}
              </div>
            )}
          </CardContent>
        </Card>

        <Card className="col-span-1">
          <CardHeader>
            <CardTitle>Largest Transaction Sent</CardTitle>
          </CardHeader>
          <CardContent>
            {tickerLoading ? (
              <CardLoading additionalClasses="flex-grow" />
            ) : (
              <div className="flex items-center space-x-2">
                <span>{`${largestTransactionSent.toFixed(3)} ETH`}</span>
                {/* Ensure we only show the comparison if totalETHReceived is defined and the icon exists */}
                {largestTransactionSent !== undefined &&
                  comparisonIndicatorETHSent.icon && (
                    <span
                      className={`flex items-center ${comparisonIndicatorETHSent.color}`}
                    >
                      {comparisonIndicatorETHSent.icon}

                      {comparisonIndicatorETHSent.formattedPercentageDiff}
                    </span>
                  )}
              </div>
            )}
          </CardContent>
        </Card>

        <Card className="col-span-1">
          <CardHeader>
            <CardTitle>Most Active Day</CardTitle>
          </CardHeader>
          <CardContent>
            {tickerLoading ? (
              <CardLoading additionalClasses="flex-grow" />
            ) : (
              <div className="flex items-center space-x-2">
                <span>{mostActiveDayCalc}</span>
                {/* Ensure we only show the comparison if totalETHReceived is defined and the icon exists */}
                {mostActiveDayCalc !== undefined &&
                  comparisonMostActiveDay.icon && (
                    <span
                      className={`flex items-center ${comparisonMostActiveDay.color}`}
                    >
                      {comparisonMostActiveDay.icon}
                      {formatDate(
                        comparisonMostActiveDay.formattedBaselineValue
                      )}
                    </span>
                  )}
              </div>
            )}
          </CardContent>
        </Card>

        <Card className="col-span-1">
          <CardHeader>
            <CardTitle>Total Gas Used</CardTitle>
          </CardHeader>
          <CardContent>
            {tickerLoading ? (
              <CardLoading additionalClasses="flex-grow" />
            ) : (
              <div className="flex items-center space-x-2">
                <span>{`${totalGasUsed.toLocaleString()} units`}</span>
                {/* Ensure we only show the comparison if averageTransactionValue is defined */}
                {totalGasUsed !== undefined &&
                  comparisonIndicatorAverageGasUsed.icon && (
                    <span
                      className={`flex items-center ${comparisonIndicatorAverageGasUsed.color}`}
                    >
                      {comparisonIndicatorAverageGasUsed.icon}

                      {
                        comparisonIndicatorAverageGasUsed.formattedPercentageDiff
                      }
                    </span>
                  )}
              </div>
            )}
          </CardContent>
        </Card>

        <Card className="col-span-1 overflow-auto">
          <CardHeader>
            <CardTitle>Average Gas Price</CardTitle>
          </CardHeader>
          <CardContent>
            {tickerLoading ? (
              <CardLoading additionalClasses="flex-grow" />
            ) : (
              <div className="flex items-center space-x-2">
                <span>{` ${averageGasPrice.toFixed(2)} Wei`}</span>
                {/* Ensure we only show the comparison if averageTransactionValue is defined */}
                {averageGasPrice !== undefined &&
                  comparisonIndicatorAverageGasPrice.icon && (
                    <span
                      className={`flex items-center   ${comparisonIndicatorAverageGasPrice.color}`}
                    >
                      {comparisonIndicatorAverageGasPrice.icon}

                      {
                        comparisonIndicatorAverageGasPrice.formattedPercentageDiff
                      }
                    </span>
                  )}
              </div>
            )}
          </CardContent>
        </Card>

        <Card className="col-span-1">
          <CardHeader>
            <CardTitle>Most Common Transaction Type</CardTitle>
          </CardHeader>
          <CardContent>
            {tickerLoading ? (
              <CardLoading additionalClasses="flex-grow" />
            ) : (
              <div className="flex items-center space-x-2">
                <span>{mostCommonTransactionType}</span>
                {/* Ensure we only show the comparison if totalETHReceived is defined and the icon exists */}
                {mostCommonTransactionType !== undefined &&
                  comparisonTransactionType.icon && (
                    <span
                      className={`flex items-center ${comparisonTransactionType.color}`}
                    >
                      {comparisonTransactionType.icon}
                      {comparisonTransactionType.formattedBaselineValue}
                    </span>
                  )}
              </div>
            )}
          </CardContent>
        </Card>

        <Card className="col-span-1">
          <CardHeader>
            <CardTitle>Peak Transaction Hour</CardTitle>
          </CardHeader>
          <CardContent>
            {tickerLoading ? (
              <CardLoading additionalClasses="flex-grow" />
            ) : (
              <div className="flex items-center space-x-2">
                <span>{`${peakTransactionHour}:00 - ${
                  parseInt(peakTransactionHour) + 1
                }:00`}</span>
                {/* Ensure we only show the comparison if totalETHReceived is defined and the icon exists */}
                {comparisonMostCommonHour !== undefined &&
                  comparisonMostCommonHour.icon && (
                    <span
                      className={`flex items-center ${comparisonMostCommonHour.color}`}
                    >
                      {comparisonMostCommonHour.icon}
                      {comparisonMostCommonHour.formattedBaselineValue}
                    </span>
                  )}
              </div>
            )}
          </CardContent>
        </Card>

        <Card className="col-span-1">
          <CardHeader>
            <CardTitle>Most Common Trading Day</CardTitle>
          </CardHeader>
          <CardContent>
            {tickerLoading ? (
              <CardLoading additionalClasses="flex-grow" />
            ) : (
              <div className="flex items-center space-x-2">
                <span>{mostCommonDayOfWeek}</span>
                {/* Ensure we only show the comparison if totalETHReceived is defined and the icon exists */}
                {comparisonMostCommonDay !== undefined &&
                  comparisonMostCommonDay.icon && (
                    <span
                      className={`flex items-center ${comparisonMostCommonDay.color}`}
                    >
                      {comparisonMostCommonDay.icon}
                      {comparisonMostCommonDay.formattedBaselineValue}
                    </span>
                  )}
              </div>
            )}
          </CardContent>
        </Card>
        <Card className="col-span-1">
          <CardHeader>
            <CardTitle>Most Frequent Transaction Partner</CardTitle>
          </CardHeader>
          <CardContent className="break-words">
            {tickerLoading ? (
              <CardLoading additionalClasses="flex-grow" />
            ) : (
              <span>
                Address:{" "}
                <a
                  href={`https://etherscan.io/address/${mostFrequentPartner}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-primary hover:text-primary"
                >
                  {mostFrequentPartner}
                </a>
              </span>
            )}
          </CardContent>
        </Card>

        <Card className="col-span-1">
          <CardHeader>
            <CardTitle>Longest Idle Period</CardTitle>
          </CardHeader>
          <CardContent>
            {tickerLoading ? (
              <CardLoading additionalClasses="flex-grow" />
            ) : (
              `${(longestIdlePeriod / (1000 * 60 * 60 * 24)).toFixed(2)} days`
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
}

export default DisplayAnalytics;
