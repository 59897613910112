import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CardLoading from "../CardLoading.js";
import {
  Pagination,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "../../Ui/pagination.js";
import { Card, CardTitle, CardHeader, CardContent } from "../../Ui/card.js";
import useNFTQuery from "../API/useNFTQuery.js";
import NftCard from "./NftCard.js";
import { Button } from "../../Ui/button.js";

function DisplayNFT() {
  const location = useLocation();
  const address = new URLSearchParams(location.search).get("address");
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("user") === null) {
      navigate("/Footprint");
    }
  }, [navigate]);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;

  const [loadedImages, setLoadedImages] = useState({});
  const { isLoading, isError, data: nftData } = useNFTQuery();

  const uniqueNfts = new Map();

  const filteredNftData = nftData?.filter((nft) => {
    if (uniqueNfts.has(nft.tokenId)) {
      return false;
    } else {
      uniqueNfts.set(nft.tokenId, true);
      return true;
    }
  });

  if (!address) {
    return <div>Please enter an address to view NFT holdings.</div>;
  }

  let totalValueInWei = BigInt(0);
  let liquidityScores = [];
  let nftCount = 0;

  filteredNftData?.forEach((nft) => {
    const priceInWei = BigInt(nft.most_recent_price);
    totalValueInWei += priceInWei;
    nftCount += 1;

    const tradeTime = new Date(nft.trade_timestamp).getTime();
    const now = new Date().getTime();
    const hoursDiff = (now - tradeTime) / (1000 * 60 * 60);

    if (hoursDiff <= 1) {
      liquidityScores.push("Amazing");
    } else if (hoursDiff <= 24) {
      liquidityScores.push("Good");
    } else if (hoursDiff <= 168) {
      liquidityScores.push("Poor");
    } else {
      liquidityScores.push("Bad");
    }
  });

  const totalValueInEther = Number(totalValueInWei) / 1e18;
  const formattedTotalValue = totalValueInEther.toFixed(4);
  const averagePriceInEther = nftCount > 0 ? totalValueInEther / nftCount : 0;
  const formattedAveragePrice = averagePriceInEther.toFixed(4);

  const liquidityCount = liquidityScores.reduce((acc, score) => {
    acc[score] = (acc[score] || 0) + 1;
    return acc;
  }, {});
  let mostCommonLiquidity = "N/A";
  if (Object.keys(liquidityCount).length > 0) {
    mostCommonLiquidity = Object.keys(liquidityCount).reduce((a, b) =>
      liquidityCount[a] > liquidityCount[b] ? a : b
    );
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredNftData?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalItems = filteredNftData?.length || 0;
  const totalPages = Math.max(1, Math.ceil(totalItems / itemsPerPage));

  const renderPageNumbers = () => {
    let pages = [];
    const maxVisiblePages = 5;
    let startPage, endPage;

    if (totalPages <= maxVisiblePages) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 3) {
        startPage = 1;
        endPage = 5;
      } else if (currentPage + 2 >= totalPages) {
        startPage = totalPages - 4;
        endPage = totalPages;
      } else {
        startPage = currentPage - 2;
        endPage = currentPage + 2;
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <PaginationLink
          key={i}
          onClick={() => setCurrentPage(i)}
          isActive={currentPage === i}
        >
          {i}
        </PaginationLink>
      );
    }

    return pages;
  };

  if (isError) {
    return (
      <div className="w-full flex flex-col gap-4 font-roboto p-2 px-4">
        <div className="h-64 font-monda text-5xl flex flex-col gap-4 text-center items-center mt-6 text-red-400">
          error
          <p className="text-gray-700 text-3xl">
            Can not retrieve values from this wallet address
          </p>
          <p className="text-gray-700 text-3xl">Refresh and try again</p>
          <Button
            className="w-fit text-white mt-2"
            onClick={() => navigate("/Footprint")}
          >
            Return to Footprint
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="w-full font-roboto p-2 px-4 ">
        {/* Header with values */}
        <div className="grid grid-cols-3 gap-4 mb-4 ">
          {/* Total Value Card */}
          <Card className="col-span-1">
            <CardHeader>
              <CardTitle className="break-words">Total Value</CardTitle>
            </CardHeader>
            <CardContent>
              {isLoading ? (
                <CardLoading additionalClasses="flex-grow" />
              ) : (
                Number(formattedTotalValue).toFixed(3)
              )}
            </CardContent>
          </Card>

          {/* Average Price Card */}
          <Card className="col-span-1">
            <CardHeader>
              <CardTitle className="break-words">Average Price</CardTitle>
            </CardHeader>
            <CardContent>
              {isLoading ? (
                <CardLoading additionalClasses="flex-grow" />
              ) : (
                formattedAveragePrice
              )}
            </CardContent>
          </Card>

          {/* Average Liquidity Card */}
          <Card className="col-span-1">
            <CardHeader>
              <CardTitle className="break-words">Average Liquidity</CardTitle>
            </CardHeader>
            <CardContent>
              {isLoading ? (
                <CardLoading additionalClasses="flex-grow" />
              ) : (
                mostCommonLiquidity
              )}
            </CardContent>
          </Card>
        </div>
        {isLoading ? (
          <div className="min-h-[400px] md:min-h-[800px] flex flex-col">
            <CardLoading className="flex flex-col flex-grow col-span-full" />
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-5 gap-4 gap-y-6 justify-items-center">
            {currentItems?.map((nft, index) => (
              <NftCard
                key={index}
                nft={nft}
                setLoadedImages={setLoadedImages}
                loadedImages={loadedImages}
              />
            ))}
          </div>
        )}

        <div className="flex justify-center pt-4">
          <Pagination>
            <PaginationPrevious
              onClick={() => setCurrentPage((prev) => Math.max(1, prev - 1))}
              disabled={currentPage === 1}
            />
            {renderPageNumbers()}
            <PaginationNext
              onClick={() =>
                setCurrentPage((prev) => Math.min(totalPages, prev + 1))
              }
              disabled={currentPage === totalPages}
            />
          </Pagination>
        </div>
      </div>
    </div>
  );
}

export default DisplayNFT;
