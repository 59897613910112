import { useQuery } from "@tanstack/react-query";
import request from "../../../Api/Api.js";

function getToken() {
  if (localStorage.getItem("user") != null) {
    return JSON.parse(localStorage.getItem("user")).accessToken;
  } else {
    return "";
  }
}

export function useTableData(blockchain, table, compare, filters) {
  return useQuery({
    queryKey: [
      "getPostgresTableData",
      table?.name,
      table?.interval,
      compare,
      filters,
    ],
    queryFn: () => {
      if (!blockchain || !table?.name || !table?.interval)
        return Promise.resolve(null);
      const url = `/getPostgresTableData/${blockchain}/${encodeURIComponent(
        table.name
      )}/${table.interval}`;
      const params = {
        compare: compare ? "true" : "false",
        filters: JSON.stringify(filters),
      };
      const headers = {
        Authorization: `Bearer ${getToken()}`, // Include the token in the headers
      };
      return request
        .get(url, { params, headers })
        .then((response) => response.data);
    },
    enabled: !!blockchain && !!table?.name && !!table?.interval,
    keepPreviousData: true, // Add this option

    onError: (error) => {
      if (error.response?.status === 404) {
        // Return an empty data object if the table is not found for the selected blockchain
        return Promise.resolve({ graphData: [], comparisonData: [] });
      }
      // Rethrow other errors
      throw error;
    },
  });
}
