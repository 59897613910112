// created by James Launder
// simple ui to fill a bar based on percentage
// also changes colour based on fill

const Bar = ({ value, max }) => {
  // var newValue;
  function calculateNewValue(value, max) {
    return (value / max) * 100;
  }

  return (
    <div className="h-4 w-full bg-gray-300 rounded-xl">
      <div
        style={{ width: `${calculateNewValue(value, max)}%` }}
        className={`h-full rounded-xl bg-primary`}
      ></div>
    </div>
  );
};

export default Bar;
