// LoginPrompt.js
import React from "react";
import { useNavigate } from "react-router-dom";

const LoginPrompt = () => {
  const navigate = useNavigate();

  return (
    <div className="absolute text-black top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 backdrop-blur-sm p-4">
      <h1 className="text-5xl mb-4">Login to access!</h1>
      <span className="mt-4">
        <button
          className="bg-surfaceVariant border border-primaryContainer shadow-md rounded-lg hover:bg-primary hover:text-white p-4 text-3xl font-semibold w-full"
          onClick={() => {
            navigate("/signin");
          }}
        >
          Login
        </button>
      </span>
    </div>
  );
};

export default LoginPrompt;
