import {
  ChevronDownIcon,
  CheckIcon,
  PencilIcon,
} from "@heroicons/react/24/solid";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "../../Ui/Command";
import { Popover, PopoverContent, PopoverTrigger } from "../../Ui/Popover";

import { cn } from "../../../utils/utils";
import { useState } from "react";
import classNames from "../../../utils/ClassNames";
import UseGetUserFootprints from "../API/UseGetUserFootprints";
import { useNavigate } from "react-router-dom";
import { NavButton } from "../../Ui/NavButton";
import GoToAddress from "../Utilities/GoToAddress";

export default function NavBarCombo({
  focused,
  setOpenDialog,
  dialog,
  setEdit,
}) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(false);
  const navigate = useNavigate();

  const { isLoading, isError, data, error } = UseGetUserFootprints();

  // make it so that it has just button to popp down sync value with the input box, add go button, kinda jank but could work
  return (
    <Popover
      open={open}
      onOpenChange={setOpen}
      //   className="transition-all rounded-r-md border-t border-b border-r border-onSurfaceVariant bg-white overflow-hidden"
    >
      <PopoverTrigger asChild>
        <div
          className={classNames(
            focused ? "w-[75px]" : "w-[150px] xl:w-[200px] 2xl:w-[250px]",
            "duration-500 h-full overflow-hidden truncate shadow-md"
          )}
        >
          <NavButton
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className={classNames(
              focused ? "p-1" : "",
              "w-full duration-500 justify-between rounded-md border-onSurfaceVariant bg-white hover:bg-primary group  h-full "
            )}
          >
            <div
              className={classNames(
                focused ? "w-[0px] opacity-0" : "w-full opacity-100",
                "duration-500"
              )}
            >
              {isError
                ? error?.response?.status === 401
                  ? "Login/Sign up!"
                  : "Error Fetching!"
                : isLoading
                ? "Loading..."
                : value
                ? data?.data.find((item) => item.name === value)?.name
                : "Preset/Saved"}
            </div>

            <ChevronDownIcon
              className={classNames(
                focused ? "ml-0" : "ml-2",
                "h-4 w-4 xl:h-6 xl:w-6 2xl:h-8 2xl:w-8 group-hover:text-white text-onSurfaceVariant"
              )}
            />
          </NavButton>
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] 2xl:w-[250px]">
        <Command>
          <CommandInput placeholder="Search footprints..." />
          <CommandEmpty>No footprints found.</CommandEmpty>
          <CommandGroup>
            {isError ? (
              error?.response?.status === 401 ? (
                <CommandItem>Login/Sign up!</CommandItem>
              ) : (
                <CommandItem>Error Fetching!</CommandItem>
              )
            ) : isLoading ? (
              <CommandItem>Loading...</CommandItem>
            ) : data?.data?.length === 0 ? (
              "No Footprints found"
            ) : (
              data?.data?.map((item) => (
                <div key={item.name} className="flex">
                  <CommandItem key={item.name} value={item.name}>
                    <button
                      onSelect={(currentValue) => {
                        setValue(currentValue === value ? "" : currentValue);
                        setOpen(false);
                      }}
                      onClick={() => {
                        GoToAddress(item.addresses.address, navigate);
                      }}
                      className="flex flex-grow hover:text-primary transition-all border-r"
                    >
                      <CheckIcon
                        className={cn(
                          "mr-2 h-4 w-4",
                          value === item.name ? "opacity-100" : "opacity-0"
                        )}
                      />
                      {item.name}
                    </button>
                    <button
                      onClick={() => {
                        setOpenDialog(true);
                        dialog({
                          _id: item._id,
                          name: item.name,
                          address: item.addresses.address,
                        });
                        setEdit(true);
                      }}
                    >
                      <PencilIcon className="ml-2 h-4 w-4 text-gray-700 hover:text-primary transition-all" />
                    </button>
                  </CommandItem>
                </div>
              ))
            )}
            <CommandItem>
              <button
                className="border-t-2 border-dashed border-gray-200 pt-3 font-semibold w-full"
                onClick={() => {
                  setOpenDialog(true);
                  dialog({
                    _id: null,
                    name: "",
                    address: "",
                  });
                  setEdit(false);
                }}
              >
                Add +
              </button>
            </CommandItem>
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
