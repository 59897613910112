import { useQueries } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import request from "../../Api/Api.js";
import { useStoreQuery } from "./useStoreQuery.js";
import { useState } from "react";
export default function useChartsQuery() {
  const queryUrl = useStoreQuery((state) => state.queryUrl);
  const [searchParams] = useSearchParams();
  // var errorStatus = null;
  const [errorStatus, setErrorStatus] = useState(false);
  // console.log("usecharts Called!");
  function getToken() {
    if (localStorage.getItem("user") != null) {
      return JSON.parse(localStorage.getItem("user")).accessToken;
    } else {
      return "";
    }
  }
  async function query(url) {
    return await request
      .get(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        setErrorStatus(false);
        return response;
      })
      .catch(function (error) {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log(resMessage);
        // console.log(error.response);
        setErrorStatus(resMessage);
        return resMessage;
      });
  }

  var string = searchParams.get("source")
    ? "/" +
      searchParams.get("blockchain") +
      "/" +
      searchParams.get("resolution") +
      "/" +
      searchParams.get("name") +
      "/" +
      searchParams.get("source")
    : "/" +
      searchParams.get("blockchain") +
      "/" +
      searchParams.get("resolution") +
      "/" +
      searchParams.get("name");
  var key = searchParams.get("source")
    ? [
        searchParams.get("blockchain"),
        searchParams.get("resolution"),
        searchParams.get("name"),
        searchParams.get("source"),
      ]
    : [
        searchParams.get("blockchain"),
        searchParams.get("resolution"),
        searchParams.get("name"),
      ];

  const queryStateV2 = [
    {
      key: key,
      url: string,
    },
    {
      key: [
        "price",
        searchParams.get("blockchain"),
        searchParams.get("resolution"),
      ],
      url:
        "/" +
        searchParams.get("blockchain") +
        "/Price/" +
        searchParams.get("resolution"),
    },
  ];
  // console.log(queryStateV2.concat(queryUrl));
  const allQueries = queryStateV2.concat(queryUrl);
  // console.log(allQueries);
  const results = useQueries({
    queries: allQueries.map((value) => {
      // console.log("called");
      return {
        queryKey: value.key,
        queryFn: () => query(value.url),
      };
    }),
  });
  return { results: results, error: errorStatus };
}
