// PremadeQueries.js
import React from "react";
import { DocumentTextIcon } from "@heroicons/react/24/outline";

const PremadeQueries = ({
  premadeQueries,
  isLoadingPremadeQueries,
  onAddColumn,
}) => {
  return (
    <div className="border-t pt-4 space-y-2">
      {isLoadingPremadeQueries ? (
        <div className="text-sm text-gray-500">Loading premade queries...</div>
      ) : (
        premadeQueries?.map((query, index) => (
          <button
            key={index}
            className="flex items-center space-x-2 cursor-pointer px-2 py-1 rounded hover:bg-gray-100"
            onClick={() => onAddColumn(query.query)}
          >
            <DocumentTextIcon className="h-5 w-5 text-gray-500" />
            <span className="text-sm font-medium">{query.name}</span>
          </button>
        ))
      )}
    </div>
  );
};

export default PremadeQueries;
