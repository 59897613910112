import React, { useState, useEffect } from "react";
import { Input } from "../../Ui/input";
import { Button } from "../../Ui/button";
import {
  ArrowLeftIcon,
  ChevronRightIcon,
  TableCellsIcon,
} from "@heroicons/react/24/outline";

const SearchComponent = ({
  allData,
  onSearchResultSelected,
  onAddColumn,
  selectedTable,
  setSelectedTable,
  searchQuery,
  setSearchQuery,
}) => {
  const [filteredResults, setFilteredResults] = useState([]);

  useEffect(() => {
    if (!searchQuery) {
      setFilteredResults([]);
    } else {
      const queryLower = searchQuery.toLowerCase();
      const filtered = allData.filter((data) =>
        data.name.toLowerCase().includes(queryLower)
      );
      setFilteredResults(filtered);
    }
  }, [searchQuery, allData]);

  const handleInputChange = (value) => {
    setSearchQuery(value);
  };

  return (
    <div>
      <Input
        placeholder="Search..."
        value={searchQuery}
        onChange={(e) => handleInputChange(e.target.value)}
        className="mb-4"
      />
      {selectedTable ? (
        <div>
          <Button
            onClick={() => setSelectedTable(null)}
            variant="ghost"
            className="mb-4"
          >
            <ArrowLeftIcon className="h-4 w-4 mr-2" />
            Back to Search Results
          </Button>
          <div className="space-y-4">
            <h3 className="text-lg font-semibold">{selectedTable.name}</h3>
            <div className="space-y-2">
              {selectedTable.columns.map((column, index) => (
                <button
                  key={index}
                  className="flex items-center space-x-2 cursor-pointer px-2 py-1 rounded hover:bg-gray-100"
                  onClick={() => onAddColumn(column.column_name)}
                >
                  <TableCellsIcon className="h-5 w-5 text-gray-500" />
                  <span className="text-sm">
                    {column.column_name}: {column.column_type}
                  </span>
                </button>
              ))}
            </div>
          </div>
        </div>
      ) : (
        filteredResults.length > 0 && (
          <div className="border-t pt-4 space-y-2">
            {filteredResults.map((result) => (
              <button
                key={result.name}
                className="flex justify-between items-center cursor-pointer px-2 py-1 rounded hover:bg-gray-100"
                onClick={() => onSearchResultSelected(result)}
              >
                <span className="text-sm font-medium">{result.name}</span>
                {result.type === "Table" && (
                  <ChevronRightIcon
                    className="h-5 w-5 text-gray-500"
                    onClick={(e) => {
                      e.stopPropagation();
                      onAddColumn(result.name);
                    }}
                  />
                )}
              </button>
            ))}
          </div>
        )
      )}
    </div>
  );
};

export default SearchComponent;
