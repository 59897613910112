import { useQuery } from "@tanstack/react-query";
import request from "../../../Api/Api.js";
export default function UseNetworkQuery(minValue, startDate, endDate) {
  const address = new URLSearchParams(location.search).get("address");
  function getToken() {
    if (localStorage.getItem("user") != null) {
      return JSON.parse(localStorage.getItem("user")).accessToken;
    } else {
      return "";
    }
  }
  const { isLoading, data, isError, error } = useQuery({
    queryKey: ["networkAddresses", address, minValue, startDate, endDate],
    queryFn: async () => {
      const data = await request.get(
        `/networkAddresses?address=${address}&minValue=${minValue}&startDate=${startDate}&endDate=${endDate}`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return data.data;
    },
  });
  return { isLoading, isError, data, error };
}
