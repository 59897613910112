import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CardLoading from "./CardLoading.js";
import { Card, CardTitle, CardHeader, CardContent } from "../Ui/card.js";
import UseTransactionQuery from "./API/UseTransactionQuery.js";
import { Button } from "../Ui/button.js";
import { DataTable } from "../Ui/data-table"; // Import the DataTable component

function DisplayTransactions() {
  const navigate = useNavigate();
  const location = useLocation();
  const address = new URLSearchParams(location.search).get("address");

  useEffect(() => {
    if (localStorage.getItem("user") === null) {
      navigate("/Footprint");
    }
  }, [navigate]);

  if (!address) {
    return <div>Please enter an address.</div>;
  }

  const {
    isLoading: tickerLoading,
    isError: tickerError,
    data: txData,
    error: tickerErrorDetails,
  } = UseTransactionQuery();

  const transactions = txData?.data || [];
  transactions.sort((a, b) => b.block_number - a.block_number);

  const calculateTransactionFee = (gas, gasPrice) => {
    const feeInWei = BigInt(gas) * BigInt(gasPrice);
    return Number(feeInWei) / 1e18;
  };

  const convertWeiToEth = (weiValue) => {
    return Number(weiValue) / 1e18;
  };

  const calculateTotalGasUsedInEth = (transactions) => {
    const totalGasUsedInWei = transactions.reduce((acc, tx) => {
      const gasUsedInWei = BigInt(tx.gas) * BigInt(tx.gas_price);
      return acc + gasUsedInWei;
    }, BigInt(0));
    return convertWeiToEth(totalGasUsedInWei);
  };

  const totalGasUsedInEth = calculateTotalGasUsedInEth(transactions);

  const calculateUniqueCounterParties = (transactions, address) => {
    const allAddresses = transactions.flatMap((tx) => [
      tx.to_address,
      tx.from_address,
    ]);
    const filteredAddresses = allAddresses.filter((addr) => addr !== address);
    return new Set(filteredAddresses).size;
  };

  const uniqueCounterParties = calculateUniqueCounterParties(
    transactions,
    address
  );
  const totalTransactionCount = transactions.length;
  const findLargestTrade = (transactions) => {
    return transactions.reduce(
      (max, tx) => (BigInt(tx.value) > BigInt(max.value) ? tx : max),
      transactions[0]
    );
  };

  const largestTrade =
    transactions.length > 0 ? findLargestTrade(transactions) : null;
  const largestTradeValueInEth = largestTrade
    ? convertWeiToEth(largestTrade.value)
    : 0;

  const columns = [
    {
      header: "Hash",
      accessorKey: "hash",
      cell: ({ row }) => (
        <a
          href={`https://etherscan.io/tx/${row.original.hash}`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-primary"
        >
          {row.original.hash}
        </a>
      ),
      width: 200,
    },
    {
      header: "Block Number",
      accessorKey: "block_number",
      width: 100,
    },
    {
      header: "From Address",
      accessorKey: "from_address",
      width: 200,
    },
    {
      header: "To Address",
      accessorKey: "to_address",
      width: 200,
    },
    {
      header: "Value (ETH)",
      accessorKey: "value",
      cell: ({ row }) => convertWeiToEth(row.original.value).toFixed(6),
      width: 100,
    },
    {
      header: "Gas",
      accessorKey: "gas",
      width: 100,
    },
    {
      header: "Transaction Fee (ETH)",
      accessorKey: "transaction_fee",
      cell: ({ row }) =>
        calculateTransactionFee(
          row.original.gas,
          row.original.gas_price
        ).toFixed(6),
      width: 150,
    },
  ];

  if (tickerError) {
    return (
      <div className="w-full flex flex-col gap-4 font-roboto p-2 px-4">
        <div className="h-64 font-monda text-5xl flex flex-col gap-4 text-center items-center mt-6 text-red-400">
          error
          <p className="text-gray-700 text-3xl">
            Can&apos;t retrieve values from this wallet address
          </p>
          <p className="text-gray-700 text-3xl">Refresh and try again</p>
          <Button
            className="w-fit text-white mt-2"
            onClick={() => navigate("/Footprint")}
          >
            Return to Footprint
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col gap-4 font-roboto p-2 px-4">
      {/* Header with values */}
      <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {/* Total Value Card */}
        <Card className="col-span-1">
          <CardHeader>
            <CardTitle className="break-words">Total Transactions</CardTitle>
          </CardHeader>
          <CardContent>
            {tickerLoading ? <CardLoading /> : totalTransactionCount}
          </CardContent>
        </Card>

        {/* Total Gas Used Card */}
        <Card className="col-span-1">
          <CardHeader>
            <CardTitle className="break-words">Total Gas Used</CardTitle>
          </CardHeader>
          <CardContent>
            {tickerLoading ? (
              <CardLoading />
            ) : (
              `${totalGasUsedInEth?.toFixed(3)} ETH`
            )}
          </CardContent>
        </Card>

        {/* Unique Counter-Parties Card */}
        <Card className="col-span-1">
          <CardHeader>
            <CardTitle className="break-words">
              Unique Counter-Parties
            </CardTitle>
          </CardHeader>
          <CardContent>
            {tickerLoading ? <CardLoading /> : uniqueCounterParties}
          </CardContent>
        </Card>

        {/* Largest Trade Card */}
        <Card className="col-span-1">
          <CardHeader>
            <CardTitle className="break-words">Largest Trade</CardTitle>
          </CardHeader>
          <CardContent>
            {tickerLoading ? (
              <CardLoading />
            ) : (
              `${largestTradeValueInEth.toFixed(4)} ETH`
            )}
          </CardContent>
        </Card>
      </div>

      {/* Bottom table */}
      <div className="text-center w-full">
        <Card className="w-full min-h-[400px] md:min-h-[800px] flex flex-col">
          <CardHeader>
            <CardTitle>Transaction Record</CardTitle>
          </CardHeader>
          <CardContent className="flex flex-col flex-grow overflow-x-auto">
            {tickerLoading ? (
              <CardLoading className="flex-grow" />
            ) : tickerErrorDetails ? (
              <div>Error: {tickerErrorDetails.message}</div>
            ) : (
              <DataTable
                columns={columns}
                data={transactions}
                pageSize={20}
                isRounded={true}
                roundToDecimal={6}
              />
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
}

export default DisplayTransactions;
