// created by James Launder
// simple ui to fill a bar based on percentage
// also changes colour based on fill

const SentimentBar = ({ value }) => {
  // var newValue;
  function calculateNewValue(value) {
    return (value + 5) * 10;
  }
  function calculateColor(value) {
    if (value == -5) {
      return "bg-red-500";
    } else if (value <= -2) {
      return "bg-orange-500";
    } else if (value < 2) {
      return "bg-yellow-500";
    } else if (value < 5) {
      return "bg-lime-500";
    } else {
      return "bg-green-500";
    }
  }
  return (
    <div className="h-2 w-full bg-gray-300 rounded-xl">
      <div
        style={{ width: `${calculateNewValue(value)}%` }}
        className={`h-full rounded-xl ${calculateColor(value)}`}
      ></div>
    </div>
  );
};

export default SentimentBar;
