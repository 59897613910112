export default function TermsAndConditions() {
  const terms = new URL(
    "../../Assets/WevrTerms&Conditions.pdf",
    import.meta.url
  ).toString();
  return (
    <div className="w-full h-[70vh] font-roboto">
      <iframe
        src={terms}
        title="TermsAndConditions"
        width="100%"
        height="100%"
      />
    </div>
  );
}
