import React, { useState } from "react";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/24/solid";
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TableFooter,
} from "../Ui/table";
import {
  Pagination,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "../Ui/pagination.js";

export function DataTable({
  columns,
  data,
  pageSize = 10,
  isRounded = false,
  roundToDecimal = 4,
}) {
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize });
  const [sorting, setSorting] = useState([]);

  const table = useReactTable({
    data,
    columns,
    state: {
      pagination,
      sorting,
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const currentPage = table.getState().pagination.pageIndex + 1;
  const totalPages = table.getPageCount();
  const setCurrentPage = (pageNumber) => {
    setPagination((prev) => ({
      ...prev,
      pageIndex: pageNumber - 1,
    }));
  };

  const formatValue = (value) => {
    if (typeof value === "number" && isRounded) {
      return value.toFixed(roundToDecimal);
    }
    return value;
  };

  return (
    <div
      className={`h-full ${
        isRounded
          ? "rounded-md border"
          : " rounded-b-md border-l border-r border-b  bg-[#F8F9FC]"
      }  `}
    >
      <Table>
        <TableHeader className="">
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id} className="h-10">
              {headerGroup.headers.map((header) => {
                const sortingIsEnabled = header.column.getCanSort();
                return (
                  <TableHead
                    key={header.id}
                    onClick={
                      sortingIsEnabled
                        ? header.column.getToggleSortingHandler()
                        : undefined
                    }
                    style={{
                      cursor: sortingIsEnabled ? "pointer" : "auto",
                      minWidth: header.column.columnDef.width,
                      maxWidth: header.column.columnDef.width,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      fontSize: "larger",
                    }}
                    className="text-xs font-semibold px-2"
                  >
                    <div className="flex items-center justify-between">
                      {header.isPlaceholder ? null : (
                        <>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {sortingIsEnabled ? (
                            header.column.getIsSorted() ? (
                              header.column.getIsSorted() === "desc" ? (
                                <ChevronDownIcon className="w-6 h-6 text-primary" />
                              ) : (
                                <ChevronUpIcon className="w-6 h-6 text-primary" />
                              )
                            ) : (
                              <ChevronUpIcon className="w-6 h-6 text-gray-400" />
                            )
                          ) : null}
                        </>
                      )}
                    </div>
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows.map((row) => (
            <TableRow key={row.id} className={`text-xs`}>
              {row.getVisibleCells().map((cell) => (
                <TableCell
                  key={cell.id}
                  className="text-xs px-4 h-14"
                  style={{
                    minWidth: cell.column.columnDef.width,
                    maxWidth: cell.column.columnDef.width,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    fontSize: "larger",
                  }}
                >
                  {formatValue(
                    flexRender(cell.column.columnDef.cell, cell.getContext())
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
        <TableFooter clas>
          <TableRow>
            <TableCell colSpan={columns.length} className="text-center">
              <Pagination>
                <PaginationPrevious
                  onClick={() =>
                    setPagination((prev) => ({
                      ...prev,
                      pageIndex: Math.max(0, prev.pageIndex - 1),
                    }))
                  }
                  disabled={!table.getCanPreviousPage()}
                />
                <PaginationLink
                  onClick={() => setCurrentPage(1)}
                  isActive={currentPage === 1}
                >
                  1
                </PaginationLink>
                {currentPage <= 3
                  ? [...Array(Math.min(3, totalPages - 1))].map((_, i) => {
                      const pageNumber = i + 2;
                      if (pageNumber < totalPages) {
                        return (
                          <PaginationLink
                            key={pageNumber}
                            onClick={() => setCurrentPage(pageNumber)}
                            isActive={currentPage === pageNumber}
                          >
                            {pageNumber}
                          </PaginationLink>
                        );
                      }
                      return null;
                    })
                  : currentPage >= totalPages - 2
                  ? [...Array(3)].map((_, i) => {
                      const pageNumber = totalPages - 3 + i;
                      if (pageNumber > 1) {
                        return (
                          <PaginationLink
                            key={pageNumber}
                            onClick={() => setCurrentPage(pageNumber)}
                            isActive={currentPage === pageNumber}
                          >
                            {pageNumber}
                          </PaginationLink>
                        );
                      }
                      return null;
                    })
                  : [...Array(3)].map((_, i) => {
                      const pageNumber = currentPage + i - 1;
                      return (
                        <PaginationLink
                          key={pageNumber}
                          onClick={() => setCurrentPage(pageNumber)}
                          isActive={currentPage === pageNumber}
                        >
                          {pageNumber}
                        </PaginationLink>
                      );
                    })}
                {totalPages > 1 && (
                  <PaginationLink
                    onClick={() => setCurrentPage(totalPages)}
                    isActive={currentPage === totalPages}
                  >
                    {totalPages}
                  </PaginationLink>
                )}
                <PaginationNext
                  onClick={() =>
                    setPagination((prev) => ({
                      ...prev,
                      pageIndex: prev.pageIndex + 1,
                    }))
                  }
                  disabled={!table.getCanNextPage()}
                />
              </Pagination>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </div>
  );
}
