import { Button } from "../Ui/button";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import TwistComponentPrimary from "../Homepage/TwistComponentPrimary.js";
import { NavButton } from "../Ui/NavButton.js";
import { useToast } from "../Ui/use-toast";

function DisplayIndex() {
  const [addressInput, setAddressInput] = useState("");
  const { toast } = useToast();

  const navigate = useNavigate();

  // Function to handle address input changes and navigate
  const handleAddressInput = (event) => {
    setAddressInput(event.target.value);
  };

  // Function to handle form submission (same as on portfolio js)
  const handleFormSubmit = () => {
    // Check if the address input is empty
    if (!addressInput.trim()) {
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Please enter address!",
      });
      return; // Stop the function execution if no address is entered
    }

    const currentTab = "portfolio"; // hardcoded in portfolio
    const newPath = `/Footprint/${currentTab}?address=${addressInput}`;
    navigate(newPath);
  };
  return (
    <div className="h-fit py-10 grid place-items-center relative z-0 w-full overflow-hidden">
      <div className="relative -z-30 ">
        <TwistComponentPrimary className="absolute -z-50 rotate-[-20deg] top-[-250px] sm:top-[-250px] md:top-[-250px] lg:top-[-300px] xl:top-[-450px] 2xl:top-[-500px] left-[-450px] sm:left-[-450px] md:left-[-700px] lg:left-[-800px] xl:left-[-900px] 2xl:left-[-1000px] h-[800px] w-[800px] lg:h-[900px] lg:w-[900px] xl:h-[1100px] xl:w-[1100px] 2xl:h-[1300px] 2xl:w-[1300px] blur-sm block" />
        <TwistComponentPrimary className="absolute -z-50 rotate-[-120deg] top-[-250px] sm:top-[-300px] md:top-[-350px] lg:top-[-350px] xl:top-[-500px] 2xl:top-[-600px] left-[50px] sm:left-[0px] md:left-[30px] lg:left-[50px] xl:left-[100px] 2xl:left-[0px] h-[800px] w-[800px] md:h-[800px] md:w-[800px] lg:h-[900px] lg:w-[900px] xl:h-[1100px] xl:w-[1100px] 2xl:h-[1300px] 2xl:w-[1300px] blur-sm block" />
      </div>
      <div className="flex flex-col grow  lg:gap-4 max-w-6xl h-[75vh] justify-center px-4">
        <div className="bg-white border border-primaryContainer shadow-md rounded-2xl p-10 md:p-12 w-full flex flex-col gap-3 lg:gap-6">
          <h1 className="text-6xl md:text-6xl lg:text-7xl xl:text-8xl font-monda text-center text-primary mb-2">
            Footprint
          </h1>
          <p className="hidden sm:block text-lg md:text-xl lg:text-2xl text-center text-gray-600">
            Track anyones web3 address, revealing NFT, DEFI and transfer
            information
          </p>
          {localStorage.getItem("user") === null ? (
            <div className="flex flex-col items-center justify-center gap-4 py-2">
              <div className="hidden sm:block text-gray-700 text-lg md:text-xl lg:text-2xl mb-4">
                Sign up/Log in to check it out
              </div>
              <NavButton
                onClick={() => navigate("/signin")}
                className="rounded-md border border-onSurfaceVariant bg-white hover:bg-primary hover:text-white px-2 py-2.5 text-lg font-semibold w-fit h-fit shadow-md text-gray-800"
              >
                Sign up/Log in
              </NavButton>
            </div>
          ) : (
            <>
              <p className="py-2 sm:py-0 text-lg sm:text-md md:text-lg lg:text-xl xl:text-2xl text-center text-gray-600">
                Try it out below!
              </p>

              <div className="w-full flex items-center md:py-4 lg:py-8 h-fit">
                <input
                  id="addressInput"
                  type="text"
                  placeholder="Track any wallet address "
                  value={addressInput}
                  onChange={handleAddressInput}
                  className="block border border-onSurfaceVariant w-full p-2  sm:p-3 rounded-md form-control focus:border-primary focus:outline-none"
                />
                <Button
                  type="submit"
                  className="rounded-md border  bg-surfaceVariant hover:bg-primary group mx-2 p-3 h-fit"
                  onClick={handleFormSubmit}
                >
                  <ArrowRightIcon className="h-6 w-6 sm:h-8 sm:w-8 text-onSurfaceVariant group-hover:text-white" />
                </Button>
              </div>
            </>
          )}

          {/* <div className="flex flex-col w-full text-center">
            Or check out our premade ....
            <div>Binance</div>
            <div>Binance</div>
            <div>Binance</div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
export default DisplayIndex;
