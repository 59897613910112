// created by james launder
// placeholder stuff for the dashboard
import placeholderPicture from "../../Assets/placeholderChart.png";
function MainDashboard() {
  return (
    <div className="font-roboto p-4">
      <div className="flex md:justify-between py-4 mb-2  ">
        <button className="px-3 py-2 font-light bg-primaryContainer rounded-lg">
          Settings
        </button>
        <div className="flex flex-row gap-2 items-center">
          <div className="font-bold text-xl md:pr-4 md:text-4xl">
            Dashboard Title
          </div>
          <button className="px-3 py-2 font-light bg-primaryContainer rounded-lg hidden md:block">
            Save
          </button>
        </div>
        <button className="px-3 py-2 font-light bg-primaryContainer rounded-lg hidden md:block">
          + add
        </button>
      </div>
      <div className="flex flex-col gap-4 ">
        <div className="flex flex-col gap-4 md:flex-row">
          <div className=" h-fit w-fit p-4  md:mr-4 bg-surfaceVariant rounded-md border border-onSurfaceVariant">
            {/* unfixable resizing bug on chart i legit dont even know why */}
            <img
              className="object-fill h-[600px] w-[1200px]"
              src={placeholderPicture}
              alt=""
            />
          </div>
          <div className="grid grid-row gap-4 content-between">
            <div className=" h-fit w-fit p-4   bg-surfaceVariant rounded-md border border-onSurfaceVariant">
              <img
                className="h-[270px] w-[800px]"
                src={placeholderPicture}
                alt=""
              />
            </div>
            <div className=" h-fit w-fit p-4   bg-surfaceVariant rounded-md border border-onSurfaceVariant">
              <img
                className="object-fill h-[270px] w-[800px]"
                src={placeholderPicture}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4 md:flex-row">
          <div className="flex flex-col justify-evenly w-fit bg-surfaceVariant p-4 rounded-md border border-onSurfaceVariant">
            <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 justify-items-center content-center gap-4">
              <button
                target="_blank"
                rel="noopener noreferrer"
                className="flex flex-col justify-evenly bg-white p-4  rounded-md h-[300px] ="
              >
                <div className="pb-1">
                  <div className="flex items-center min-h-20 text-xs font-semibold">
                    test
                  </div>
                  <div className="flex justify-between pt-[0.5]">
                    <div className="text-xs">test</div>

                    <div className="text-xs">test</div>
                  </div>
                </div>
                <div className="p-2">
                  <img
                    src={placeholderPicture}
                    alt=""
                    className="object-scale-down rounded-md mx-4 h-48 w-48"
                  />
                </div>
                <div className="py-1 ">Sentiment:</div>
              </button>
              <button
                target="_blank"
                rel="noopener noreferrer"
                className="flex flex-col justify-evenly bg-white p-4  rounded-md h-[300px] ="
              >
                <div className="pb-1">
                  <div className="flex items-center min-h-20 text-xs font-semibold">
                    test
                  </div>
                  <div className="flex justify-between pt-[0.5]">
                    <div className="text-xs">test</div>

                    <div className="text-xs">test</div>
                  </div>
                </div>
                <div className="p-2">
                  <img
                    src={placeholderPicture}
                    alt=""
                    className="object-scale-down rounded-md mx-4 h-48 w-48"
                  />
                </div>
                <div className="py-1 ">Sentiment:</div>
              </button>
              <button
                target="_blank"
                rel="noopener noreferrer"
                className="flex flex-col justify-evenly bg-white p-4  rounded-md h-[300px] ="
              >
                <div className="pb-1">
                  <div className="flex items-center min-h-20 text-xs font-semibold">
                    test
                  </div>
                  <div className="flex justify-between pt-[0.5]">
                    <div className="text-xs">test</div>

                    <div className="text-xs">test</div>
                  </div>
                </div>
                <div className="p-2">
                  <img
                    src={placeholderPicture}
                    alt=""
                    className="object-scale-down rounded-md mx-4 h-48 w-48"
                  />
                </div>
                <div className="py-1 ">Sentiment:</div>
              </button>
              <button
                target="_blank"
                rel="noopener noreferrer"
                className="flex flex-col justify-evenly bg-white p-4  rounded-md h-[300px] ="
              >
                <div className="pb-1">
                  <div className="flex items-center min-h-20 text-xs font-semibold">
                    test
                  </div>
                  <div className="flex justify-between pt-[0.5]">
                    <div className="text-xs">test</div>

                    <div className="text-xs">test</div>
                  </div>
                </div>
                <div className="p-2">
                  <img
                    src={placeholderPicture}
                    alt=""
                    className="object-scale-down rounded-md mx-4 h-48 w-48"
                  />
                </div>
                <div className="py-1 ">Sentiment:</div>
              </button>
            </div>
          </div>
          <div className="flex flex-col w-full md:w-[25vw] md:ml-4 bg-surfaceVariant p-4 col-span-4 rounded-md border border-onSurfaceVariant">
            <div className="w-full bg-white break-normal p-2">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. In urna
              erat, tincidunt bibendum nisi non, pretium egestas dolor. Etiam
              quis libero convallis, blandit tellus dignissim, pretium nisi.
              Aliquam erat volutpat. Nulla ultrices feugiat nunc id
              sollicitudin. Donec et gravida mi, sed molestie tellus. Nulla
              porta ornare leo at venenatis. Sed eget erat mollis, vehicula
              lacus ut, finibus dui. Fusce consectetur ante sed elementum
              feugiat. Suspendisse elementum porta posuere. Nulla non aliquet
              dolor, et ullamcorper justo. Aenean vulputate ipsum sed elit
              lacinia vulputate. Sed at mi sed ante consectetur malesuada non ut
              lectus.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainDashboard;
