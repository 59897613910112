export default function MasterAgreement() {
  const master = new URL(
    "../../Assets/WevrMasterAgreement.pdf",
    import.meta.url
  ).toString();
  return (
    <div className="w-full h-[70vh] font-roboto">
      <iframe src={master} title="MasterAgreement" width="100%" height="100%" />
    </div>
  );
}
