// DescriptionSection.js
import React from "react";

const DescriptionSection = ({ description, sources, dataSource, category }) => {
  return (
    <div className="flex flex-col sm:flex-row items-center sm:gap-4 bg-[#F8F9FC] text-black p-4 rounded-lg relative shadow-sm">
      <div
        className="w-1 bg-primary rounded-full absolute left-4"
        style={{ height: "50%", top: "25%" }}
      />

      <div className="flex-1 pl-8 sm:pl-12">
        <h2 className="text-xl font-semibold mb-2">Description</h2>
        <p className="text-sm text-gray-600 mb-4">{description}</p>
      </div>
    </div>
  );
};

export default DescriptionSection;
