import { useQuery } from "@tanstack/react-query";
import request from "../../Api/Api.js";
export default function useUserInfoQuery() {
  function getToken() {
    if (localStorage.getItem("user") != null) {
      return JSON.parse(localStorage.getItem("user")).accessToken;
    } else {
      return "";
    }
  }
  const { isError, isLoading, isFetching, data, refetch } = useQuery({
    queryKey: ["userInfo"],
    queryFn: async () => {
      const data = await request
        .get("/user", {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        })
        .catch(function (error) {
          return error;
        });
      return data;
    },
    staleTime: 500,
  });
  return {
    isError: isError,
    data: data,
    isLoading: isLoading,
    isFetching: isFetching,
    refetch: refetch,
  };
}
