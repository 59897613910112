// Created By James Launder
// Chart Options Dropdown menu

import { useState, Fragment, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Transition, Popover } from "@headlessui/react";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  ArrowSmallRightIcon,
  Cog6ToothIcon,
} from "@heroicons/react/24/solid";
import { usePopper } from "react-popper";
import UseNavQuery from "./UseNavQuery";

// works differently to additional options as this relies on the URL params to communicate the data instaed of internal states.
function ChartOptions(props) {
  const [selectedExchange, setSelectedExchange] = useState("FTX");
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedResolution, setSelectedResolution] = useState(
    searchParams.get("resolution")
  );
  const [emaInput, setEmaInput] = useState("");
  const [selectedEma, setSelectedEma] = useState(searchParams.get("ema"));
  const [smaInput, setSmaInput] = useState("");
  const [selectedSma, setSelectedSma] = useState(searchParams.get("sma"));
  const {
    name: name,
    resolution: resolution,
    dataSource: dataSource,
    sources: sources,
  } = UseNavQuery();
  const [chartOpts, setChartOpts] = useState({
    chartOptions: [
      {
        yAxis: "Linear",
        style: "Line",
        name: name,
      },
      { yAxis: "Linear", style: "Line", name: "Price" },
    ],
  });

  let [referenceElement, setReferenceElement] = useState();
  let [popperElement, setPopperElement] = useState();
  let { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "right-start",
  });
  const [EMA] = useState({
    None: [
      {
        disabled: "disabled",
      },
    ],
    "7d": [],
    "14d": [],
    "30d": [],
  });
  const [SMA] = useState({
    None: [
      {
        disabled: "disabled",
      },
    ],
    "7d": [],
    "14d": [],
    "30d": [],
  });
  const params = (name, value) => {
    searchParams.set(name, value);
    setSearchParams(searchParams);
  };
  // on load
  useEffect(() => {
    if (localStorage.getItem("user") != null) {
      if (JSON.parse(localStorage.getItem("user")).tier == 0) {
        setSelectedResolution("Daily");
      }
    }
    if (sources) {
      // i made changes here
      setSelectedExchange(sources[0]?.name);
    }
  }, []);
  useEffect(() => {
    updateName(0, name);
  }, [name]);
  useEffect(() => {
    setSelectedEma(searchParams.get("ema"));
    setSelectedSma(searchParams.get("sma"));
    setSelectedResolution(searchParams.get("resolution"));
    setSelectedExchange(searchParams.get("source"));
  }, [searchParams]);

  const handleEmaInput = (event) => {
    setEmaInput(event.target.value);
    setSelectedEma(event.target.value);
    if (!event.target.value) {
      setSelectedEma(searchParams.get("ema"));
    }
  };
  const handleSmaInput = (event) => {
    setSmaInput(event.target.value);
    setSelectedSma(event.target.value);
    if (!event.target.value) {
      setSelectedSma(searchParams.get("sma"));
    }
  };
  const updateStyle = (name, value) => {
    let updatedList = chartOpts.chartOptions.map((item) => {
      if (item.name === name) {
        return { ...item, style: value };
      }
      return item;
    });
    setChartOpts({ chartOptions: updatedList });
  };

  const updateYaxis = (name, value) => {
    let updatedList = chartOpts.chartOptions.map((item) => {
      if (item.name === name) {
        return { ...item, yAxis: value };
      }
      return item;
    });
    setChartOpts({ chartOptions: updatedList });
  };
  const updateName = (itemIndex, value) => {
    let updatedList = chartOpts.chartOptions.map((item, index) => {
      if (index === itemIndex) {
        return { ...item, name: value };
      }
      return item;
    });
    setChartOpts({ chartOptions: updatedList });
  };

  return (
    <div className="font-roboto">
      <Popover as="div" className="inline-block text-left ">
        {({ open }) => (
          <>
            <div>
              <Popover.Button
                className={`${
                  open
                    ? "bg-primary text-white"
                    : " bg-white md:bg-surfaceVariant shadow-md"
                } group flex place-content-center flex-row px-3 py-2 hover:bg-primary hover:text-white rounded-lg border border-primaryContainer focus:outline-none`}
              >
                <div className="hidden md:flex">
                  {" "}
                  Options
                  <ChevronDownIcon
                    className={`${
                      open ? " text-white" : "text-secondary"
                    } h-5 w-5 ml-2 self-center text-secondary group-hover:text-white`}
                    // className="h-5 w-5 ml-2 self-center text-secondary group-hover:text-white"
                    aria-hidden="true"
                  />
                </div>
                <div className="flex md:hidden">
                  <Cog6ToothIcon
                    className={`${
                      open ? " text-white" : ""
                    } h-6 w-6 text-secondary group-hover:text-white`}
                  />
                </div>
              </Popover.Button>
            </div>
            {open && (
              <Transition
                as={Fragment}
                enter="transition-opacity ease-out duration-400"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Popover.Panel className="absolute p-2 mt-2 z-10 text-sm md:text-base md:w-96 divide-y divide-secondary rounded-lg bg-white border-2 border-primaryContainer bg-opacity-60 backdrop-blur shadow-lg">
                  <div className="p-2 flex items-center">
                    <>
                      <span className="pr-4 text-gray-700">Data Source</span>
                      {/*  > md popover */}
                      {Array.isArray(sources) ? (
                        <div>
                          <Popover
                            as="div"
                            className="hidden md:inline-block text-left"
                          >
                            {({ open }) => (
                              <>
                                <div>
                                  <Popover.Button
                                    ref={setReferenceElement}
                                    className="group inline-flex justify-center rounded-lg w-32 bg-white ring-1 ring-inset ring-gray-300 hover:bg-primary hover:text-white px-4 py-2"
                                  >
                                    {selectedExchange}
                                    <ChevronRightIcon
                                      className="h-5 w-5 ml-2 self-center text-secondary group-hover:text-white"
                                      aria-hidden="true"
                                    />
                                  </Popover.Button>
                                </div>
                                {open && (
                                  <Popover.Panel
                                    static
                                    className="absolute -right-1/2 ml-2 z-10 w-28 divide-y divide-secondary rounded-lg bg-white ring-1 ring-inset ring-gray-300"
                                    ref={setPopperElement}
                                    style={styles.popper}
                                    {...attributes.popper}
                                  >
                                    <div className="px-1 py-1">
                                      {sources.map((exchange, index) => (
                                        <div key={index}>
                                          <ul className="divide-y divide-secondary px-1 py-2 hover:bg-primary hover:text-white rounded-lg">
                                            <button
                                              className="w-full text-left"
                                              onClick={() => {
                                                setSelectedExchange(
                                                  exchange.name
                                                );
                                                params("source", exchange.name);
                                              }}
                                            >
                                              {exchange.name}
                                            </button>
                                          </ul>
                                        </div>
                                      ))}
                                    </div>
                                  </Popover.Panel>
                                )}
                              </>
                            )}
                          </Popover>
                          {/* < md pop over */}
                          <Popover
                            as="div"
                            className="inline-block md:hidden text-left"
                          >
                            {({ open }) => (
                              <>
                                <div>
                                  <Popover.Button className="group inline-flex justify-center rounded-lg bg-primaryContainer hover:bg-primary hover:text-white px-4 py-2">
                                    {selectedExchange}
                                    <ChevronRightIcon
                                      className="h-5 w-5 ml-2 self-center text-secondary group-hover:text-white"
                                      aria-hidden="true"
                                    />
                                  </Popover.Button>
                                </div>
                                {open && (
                                  <Popover.Panel
                                    static
                                    className="absolute  ml-4 mt-1 z-10 divide-y divide-secondary rounded-lg bg-primaryContainer"
                                  >
                                    <div className="px-1 py-1">
                                      {props.graphSources.map(
                                        (exchange, index) => (
                                          <div key={index}>
                                            <ul className="divide-y divide-secondary px-1 py-2 hover:bg-primary hover:text-white rounded-lg">
                                              <button
                                                className="w-full text-left"
                                                onClick={() => {
                                                  setSelectedExchange(
                                                    exchange.name
                                                  );
                                                  params(
                                                    "source",
                                                    exchange.name
                                                  );
                                                }}
                                              >
                                                {exchange.name}
                                              </button>
                                            </ul>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </Popover.Panel>
                                )}
                              </>
                            )}
                          </Popover>
                        </div>
                      ) : (
                        <div className="font-robot ">{dataSource}</div>
                      )}
                    </>
                  </div>
                  <div className="px-2 py-2">
                    <>
                      <h1 className="pb-1 text-gray-700">Resolution</h1>

                      <span className="grid grid-flow-col rounded-lg bg-white text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 w-full">
                        {resolution.map((data) => (
                          <button
                            key={data}
                            onClick={() => {
                              params("resolution", data);
                            }}
                            className={`${
                              selectedResolution === data
                                ? "bg-primary text-white text-bold"
                                : "text-gray-900 hover:bg-primary/30 hover:text-gray-900"
                            } rounded-lg py-2.5 text-gray-900`}
                          >
                            {data}
                          </button>
                        ))}
                      </span>
                    </>
                  </div>
                  <div className="px-2 py-2 mr-1">
                    <h1 className="pb-1 text-gray-700">SMA</h1>
                    <div className="flex flex-auto rounded-lg bg-white text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 w-full">
                      <span className="grid grid-flow-col grow rounded-lg">
                        {Object.keys(SMA).map((category) => (
                          <button
                            key={category}
                            onClick={() => {
                              params("sma", category);
                            }}
                            className={`${
                              selectedSma === category
                                ? "bg-primary text-white text-bold"
                                : "text-gray-900 hover:bg-primary/30 hover:text-gray-900"
                            } rounded-lg py-2.5 text-gray-900`}
                          >
                            {category}
                          </button>
                        ))}
                      </span>
                      <span className="hidden md:flex place-content-end rounded-lg ">
                        <input
                          type="text"
                          className={`${
                            smaInput ? "bg-primary text-white" : ""
                          } w-16 rounded-l-lg border pl-2 border-gray-300`}
                          placeholder="Days"
                          onChange={handleSmaInput}
                          value={smaInput}
                        />
                        <button
                          className=" text-primary w-8 text-center rounded-r-lg hover:bg-primary hover:text-white"
                          onClick={() => {
                            // console.log(smaInput);
                            if (!smaInput) {
                              params("sma", "None");
                            } else {
                              params("sma", smaInput);
                            }
                          }}
                        >
                          <ArrowSmallRightIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                        </button>
                      </span>
                    </div>
                  </div>
                  <div className="px-2 py-2 mr-1">
                    <h1 className="pb-1 text-gray-700">EMA</h1>
                    <div className="flex flex-auto rounded-lg bg-white text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 w-full">
                      <span className="grid grid-flow-col grow rounded-lg">
                        {Object.keys(EMA).map((category) => (
                          <button
                            key={category}
                            onClick={() => {
                              params("ema", category);
                            }}
                            className={`${
                              selectedEma === category
                                ? "bg-primary text-white text-bold"
                                : "text-black hover:bg-primary/30 hover:text-black"
                            } w-full rounded-lg py-2.5 text-black`}
                          >
                            {category}
                          </button>
                        ))}
                      </span>
                      <span className="hidden md:flex place-content-end rounded-lg ">
                        <input
                          type="text"
                          className={`${
                            emaInput ? "bg-primary text-white" : ""
                          } w-16 rounded-l-lg border pl-2 border-gray-300`}
                          // className="w-16 h-full rounded-l-lg border-r-2 pl-2 border-secondary"
                          placeholder="Days"
                          onChange={handleEmaInput}
                          value={emaInput}
                        />
                        <button
                          className="text-primary w-8 text-center rounded-r-lg hover:bg-primary hover:text-white"
                          onClick={() => {
                            if (!emaInput) {
                              params("ema", "None");
                            } else {
                              params("ema", emaInput);
                            }
                          }}
                        >
                          <ArrowSmallRightIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                        </button>
                      </span>
                    </div>
                  </div>
                  <div className="px-2 py-2">
                    {chartOpts.chartOptions.map((value, index) => (
                      <div key={index}>
                        <h1 key={value.name} className="pb-1 text-gray-700">
                          {value.name}
                        </h1>
                        <span className="grid grid-flow-col gap-x-2">
                          <span className="grid grid-flow-col rounded-lg bg-white text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 w-full">
                            <button
                              onClick={() => {
                                // params("scale", "Linear");
                                // setLinear("Linear");
                                // let updateValue = { yAxis: "Test" };
                                if (index === 0) {
                                  params("scale", "Linear");
                                } else {
                                  params("scale" + index, "Linear");
                                }
                                updateYaxis(value.name, "Linear");
                                // props.setChartOpts((prevState) => ({
                                //   ...prevState,
                                //   ...updateValue,
                                // }));
                                // console.log(props.chartOpts);
                              }}
                              className={`${
                                value.yAxis === "Linear"
                                  ? "bg-primary text-white text-bold"
                                  : "text-gray-900 hover:bg-primary/30 hover:text-gray-900"
                              } rounded-lg py-2.5 text-gray-900 `}
                            >
                              Linear
                            </button>
                            <button
                              onClick={() => {
                                // params("scale", "Log");
                                // setLinear("Log");
                                if (index === 0) {
                                  params("scale", "Log");
                                } else {
                                  params("scale" + index, "Log");
                                }
                                updateYaxis(value.name, "Log");
                              }}
                              className={`${
                                value.yAxis === "Log"
                                  ? "bg-primary text-white text-bold"
                                  : "text-gray-900 hover:bg-primary/30 hover:text-gray-900"
                              } rounded-lg py-2.5 text-gray-900 `}
                            >
                              Log
                            </button>
                          </span>
                          <span className="grid grid-flow-col rounded-lg bg-white text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 w-full">
                            <button
                              onClick={() => {
                                // params("type", "Line");
                                updateStyle(value.name, "Line");
                                if (index === 0) {
                                  params("type", "Line");
                                } else {
                                  params("type" + index, "Line");
                                }
                                // setType("Line");
                              }}
                              className={`${
                                value.style === "Line"
                                  ? "bg-primary text-white text-bold"
                                  : "text-gray-900 hover:bg-primary/30 hover:text-gray-900"
                              } rounded-lg py-2.5 text-gray-900 `}
                            >
                              Line
                            </button>
                            <button
                              onClick={() => {
                                // params("type", "Bar");
                                updateStyle(value.name, "Bar");
                                if (index === 0) {
                                  params("type", "Bar");
                                } else {
                                  params("type" + index, "Bar");
                                }
                                // setType("Bar");
                              }}
                              className={`${
                                value.style === "Bar"
                                  ? "bg-primary text-white text-bold"
                                  : "text-gray-900 hover:bg-primary/30 hover:text-gray-900"
                              } rounded-lg py-2.5 text-gray-900 `}
                            >
                              Bar
                            </button>
                          </span>
                        </span>
                      </div>
                    ))}
                  </div>
                </Popover.Panel>
              </Transition>
            )}
          </>
        )}
      </Popover>
    </div>
  );
}

export default ChartOptions;
