import { useQuery } from "@tanstack/react-query";
import request from "../../../Api/Api.js";

export default function useNFTQuery() {
  const address = new URLSearchParams(location.search).get("address");

  function getToken() {
    if (localStorage.getItem("user") != null) {
      return JSON.parse(localStorage.getItem("user")).accessToken;
    } else {
      return "";
    }
  }
  const { isLoading, isError, data, error } = useQuery({
    queryKey: ["nftData", address],
    queryFn: async () => {
      const data = await request.get(`/nftHoldings?address=${address}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });

      return data.data;
    },
  });

  return { isLoading, isError, data, error };
}
