import React, { useState, useEffect } from "react";
import { Checkbox } from "../Ui/checkbox";
import { Button } from "../Ui/button";
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogTitle,
  DialogDescription,
  DialogClose,
} from "../Ui/Dialog";
import { Bars3Icon } from "@heroicons/react/24/outline";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "../Ui/select";
import { exportToCSV } from "../QueryPage/Export";

const HorizontalBar = ({
  onFilterChange,
  onGranularityChange,
  selectedTable,
  queryData,
  isGrid,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [localInterval, setLocalInterval] = useState(
    selectedTable?.interval || "days"
  );
  const [selectedFilters, setSelectedFilters] = useState([]);

  const toggleDialog = () => setDialogOpen(!dialogOpen);

  const handleIntervalChange = (value) => {
    setLocalInterval(value);
    if (typeof onGranularityChange === "function") {
      onGranularityChange(value);
    }
  };

  const handleFilterChange = (filterName) => {
    // console.log(filterName);
    setSelectedFilters((prevFilters) => {
      // console.log(prevFilters);
      const newFilters = prevFilters.includes(filterName)
        ? prevFilters.filter((f) => f !== filterName)
        : [...prevFilters, filterName];
      // console.log(newFilters);

      const filterConditions = newFilters.map(
        (filter) => selectedTable.filters[filter]
      );
      // console.log(filterConditions);
      if (typeof onFilterChange === "function") {
        onFilterChange(filterConditions);
      }
      // return prevFilters;
      return newFilters;
    });
  };

  useEffect(() => {
    setLocalInterval(selectedTable?.interval || "days");
    if (selectedTable?.filters) {
      setSelectedFilters([]); // Reset filters when selectedTable changes
    }
  }, [selectedTable]);

  const renderFilters = () => {
    if (
      selectedTable?.filters &&
      Object.keys(selectedTable.filters).length > 0
    ) {
      return Object.keys(selectedTable.filters).map((filterName, index) => (
        <div key={index} className="flex items-center space-x-2">
          <Checkbox
            id={`filter-${index}`}
            checked={selectedFilters.includes(filterName)}
            onCheckedChange={() => handleFilterChange(filterName)}
          />
          <label className="text-sm font-medium" htmlFor={`filter-${index}`}>
            {filterName}
          </label>
        </div>
      ));
    } else {
      return <></>;
    }
  };

  return (
    <div className="relative">
      {!isGrid && (
        <div className="hidden sm:flex justify-between items-center py-2 px-4 rounded-t-md border bg-[#F8F9FC]">
          {/* Desktop Filters */}
          <div className="flex space-x-4">{renderFilters()}</div>
          <div className="flex space-x-4 items-center">
            <Select value={localInterval} onValueChange={handleIntervalChange}>
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="Select interval" />
              </SelectTrigger>
              <SelectContent>
                {selectedTable?.resolution?.map((interval) => (
                  <SelectItem key={interval} value={interval.toLowerCase()}>
                    {interval}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <Button
              onClick={() =>
                exportToCSV(queryData?.graphData, "exportedQueryData.csv")
              }
              className="text-white hover:bg-primary hover:text-white px-4 py-2 text-sm font-medium focus:outline-none flex items-center"
            >
              Export
            </Button>
          </div>
        </div>
      )}
      {/* Hamburger Menu */}
      <div
        className={`${
          isGrid ? "flex" : "flex sm:hidden"
        } justify-end items-center py-2 px-4 bg-gray-100 rounded-t-md border`}
      >
        <Dialog>
          <DialogTrigger as={Button} onClick={toggleDialog} className="text-sm">
            <Bars3Icon className="h-6 w-6" />
          </DialogTrigger>
          <DialogContent>
            <DialogTitle>Filter Options</DialogTitle>
            <DialogDescription>Adjust your preferences.</DialogDescription>
            <div className="flex flex-col space-y-4">
              {renderFilters()}
              <Select
                value={localInterval}
                onValueChange={handleIntervalChange}
              >
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Select interval" />
                </SelectTrigger>
                <SelectContent>
                  {selectedTable?.resolution?.map((interval) => (
                    <SelectItem key={interval} value={interval.toLowerCase()}>
                      {interval}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <Button
                onClick={() =>
                  exportToCSV(queryData?.graphData, "exportedQueryData.csv")
                }
                className="text-white bg-primary hover:text-gray-200 px-4 py-2 text-sm font-medium focus:outline-none flex items-center"
              >
                Export
              </Button>
              <DialogClose as={Button} className="text-sm">
                Close
              </DialogClose>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

export default HorizontalBar;
