import { create } from "zustand";
export const useStoreNotificationPopup = create((set) => ({
  popup: [false, ""],
  open: (message) =>
    set(() => ({
      popup: [true, message],
    })),
  close: () =>
    set(() => ({
      popup: [false, ""],
    })),
}));
