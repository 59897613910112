// created by james launder
// vertical nav bar & search function
// has problem  with scroll
import { useEffect, useState, useRef } from "react";
import { ArrowLeftIcon, ChevronDownIcon } from "@heroicons/react/24/solid";
import { Disclosure } from "@headlessui/react";
import { useSearchParams } from "react-router-dom";
import useWindowDimensions from "../../Hooks/useWindowDimensions.js";
import UseNavQuery from "../Charting/UseNavQuery.js";
import { useSidebarState } from "../../utils/useSidebarState.js";
import { useGraphState } from "../../utils/useGraphState.js";
import {
  SidebarHeader,
  SidebarItem,
  SidebarItems,
  SidebarMain,
} from "./Sidebar.js";

function ChartSidebarV2() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filteredList, setFilteredList] = useState([]);
  const [searchState, setSearchState] = useState(false);
  const { width } = useWindowDimensions();
  const { isLoading, isError, isFetching, queryData } = UseNavQuery();
  const { setGraphName, setResetCmp } = useGraphState();
  const sidebar = useSidebarState((state) => state.state);
  const setSidebar = useSidebarState((state) => state.setSidebar);
  const group = {};
  // groups each chart by its category
  const groupCategory = (item) => {
    item.forEach(({ category, icon, ...rest }) => {
      // console.log(icon);
      group[(category, icon)] = group[(category, icon)] || {
        category,
        icon,
        children: [],
      };
      group[(category, icon)].children.push(rest);
    });
    return Object.values(group);
  };

  // ensures that the params are up to date
  useEffect(() => {
    // setActiveName(searchParams.get("name"));
    if (searchParams.get("name")) {
      if (!searchParams.get("resolution")) {
        searchParams.set("resolution", "Day");
      }
      if (!searchParams.get("sma")) {
        searchParams.set("sma", "None");
      }
      if (!searchParams.get("ema")) {
        searchParams.set("ema", "None");
      }
      if (!searchParams.get("scale")) {
        searchParams.set("scale", "Linear");
      }
      if (!searchParams.get("type")) {
        searchParams.set("type", "Line");
      }
      setSearchParams(searchParams);
      setGraphName(searchParams.get("name"));
    }
  }, [searchParams.get("blockchain")]);

  // function to interact with the params
  const params = (value) => {
    setResetCmp(true);
    // console.log("ver nat: " + resetChartsState);
    // if (!resetChartsState) {
    //   resetChartsToggle;
    //   console.log("ver nat psot: " + resetChartsState);
    // }
    searchParams.set("name", value.name);
    if (!searchParams.get("resolution")) {
      searchParams.set("resolution", "Day");
    }
    if (!searchParams.get("sma")) {
      searchParams.set("sma", "None");
    }
    if (!searchParams.get("ema")) {
      searchParams.set("ema", "None");
    }
    if (!searchParams.get("scale")) {
      searchParams.set("scale", "Linear");
    }
    if (!searchParams.get("type")) {
      searchParams.set("type", "Line");
    }
    const firstLoadValues = queryData?.data?.graphNames.find(
      (key) => key.name === searchParams.get("name")
    );
    if (!firstLoadValues.resolution.includes(searchParams.get("resolution"))) {
      searchParams.set("resolution", "Day");
    }
    if (Array.isArray(firstLoadValues.sources)) {
      // searchParams.delete("source");
      searchParams.set("source", firstLoadValues.sources[0].name);
    } else {
      searchParams.delete("source");
    }
    setSearchParams(searchParams);
    setGraphName(value.name);
    // setActiveName(value);
  };
  // search function
  const filterBySearch = (event) => {
    const query = event.target.value;
    if (query) {
      setSearchState(true);
    } else {
      setSearchState(false);
    }
    var updatedList = queryData?.data?.graphNames;
    updatedList = updatedList.filter((item) => {
      return item.name.toLowerCase().startsWith(query.toLowerCase());
    });
    setFilteredList(updatedList);
  };

  const SearchItems = ({ items }) => (
    <ul className="mt-2">
      {items.map((key) => (
        <div key={key.name} className="w-full px-2">
          <li
            key={key.name}
            // className="group w-full flex items-center bg-white dark:bg-gray-600/40 border-gray-400 shadow-md rounded-md pl-11 pr-2 py-3 text-md font text-black dark:text-white hover:text-gray-900 hover:bg-gray-50"
          >
            <button
              key={key.name}
              onClick={() => {
                params(key.name);
                setSearchState(false);
              }}
              className={`${
                searchParams.get("name") === key.name
                  ? "bg-primary text-white"
                  : "hover:bg-primary/30 bg-primaryContainer"
              } group w-full flex justify-between rounded-lg p-2`}
            >
              <span>{key.name}</span>
            </button>
          </li>
        </div>
      ))}
    </ul>
  );
  if (isError) {
    return <div>Error</div>;
  }
  // isloading
  if (isLoading) {
    return (
      <div className="w-[0px] md:w-[248px] min-h-screen transition-all font-roboto bg-surface">
        <div className="flex flex-row px-2 mx-auto py-2 animate-pulse">
          <div className="mx-auto w-full rounded-xl p-2 space-y-2 mt-2">
            <div className="flex h-14 w-full place-items-center bg-gray-300 justify-between p-2 rounded-lg font-medium text-lg border border-primaryContainer"></div>
            <div className="flex h-14 w-full place-items-center bg-gray-300 justify-between p-2 rounded-lg font-medium text-lg border border-primaryContainer"></div>
            <div className="flex h-14 w-full place-items-center bg-gray-300 justify-between p-2 rounded-lg font-medium text-lg border border-primaryContainer"></div>
            <div className="flex h-14 w-full place-items-center bg-gray-300 justify-between p-2 rounded-lg font-medium text-lg border border-primaryContainer"></div>
            <div className="flex h-14 w-full place-items-center bg-gray-300 justify-between p-2 rounded-lg font-medium text-lg border border-primaryContainer"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <SidebarMain sidebar={sidebar}>
      <SidebarHeader
        sidebar={sidebar}
        setSidebar={setSidebar}
        onChange={filterBySearch}
      />
      <SidebarItems>
        {sidebar ? (
          searchState ? (
            <SearchItems items={filteredList} />
          ) : (
            groupCategory(queryData?.data?.graphNames)?.map((item) => (
              <SidebarItem
                siderbar={sidebar}
                setSidebar={setSidebar}
                item={item}
                onClick={params}
                width={width}
                searchParams={searchParams}
                key={item.category}
              />
            ))
          )
        ) : (
          groupCategory(queryData?.data?.graphNames)?.map((item) => (
            <div key={item.category}>
              <button
                onClick={() => setSidebar(!sidebar)}
                className="flex w-full bg-white  p-2 rounded-lg border-2 border-primaryContainer"
              >
                <div className="grid grid-flow-col">
                  <img
                    className="object-scale-down h-8 w-8"
                    src={process.env.API + "/" + item.icon}
                    alt=""
                  />
                  {/* <ClipboardIcon className="h-5 w-5 text-primary" /> */}
                </div>
              </button>
            </div>
          ))
        )}
      </SidebarItems>
    </SidebarMain>
  );
}

export default ChartSidebarV2;
