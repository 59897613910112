import React, { useState, useContext } from "react";
import { Transition } from "@headlessui/react";
import { useNavigate, NavLink } from "react-router-dom";
import { UserCircleIcon } from "@heroicons/react/24/solid";
import betaLogo from "../Assets/WevrLogoTextBeta.svg";
import wevrLogoPrimary from "../Assets/WevrLogoPrimary.svg"; // Import the logo
import { UserContext } from "../Services/userContext";
import { cn } from "../utils/utils";

import {
  NavigationMenu,
  NavigationMenuList,
  NavigationMenuItem,
  NavigationMenuTrigger,
  NavigationMenuContent,
  NavigationMenuLink,
  NavigationMenuViewport,
  navigationMenuTriggerStyle,
} from "./Ui/Nav-menu";

const exploreComponents = [
  {
    title: "Ethereum Charts",
    href: "/Explore?blockchain=Ethereum",
    description: "Ethereum charts contains a wide array of onchain charts.",
  },
  {
    title: "Ethereum Tables",
    href: "/Explore/Tables?blockchain=Ethereum",
    description: "Ethereum tables contains a vast variety of Crypto tables.",
  },
  {
    title: "Bitcoin Tables",
    href: "/Explore/Tables?blockchain=Bitcoin",
    description: "Bitcoin tables contains a vast variety of Crypto tables.",
  },
  {
    title: "Bitcoin Charts",
    href: "/Explore?blockchain=Bitcoin",
    description: "Bitcoin charts contains a wide array of onchain charts",
  },
];

const tablesLink = {
  title: "Tables",
  href: "/Explore/Tables?blockchain=Ethereum",
};

function Nav() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { validateUser } = useContext(UserContext);

  let activeClassNameSmall =
    "flex border-b-2 border-primary px-3 py-2 rounded-md text-sm font-medium items-center text-center text-primary";
  let notActiveClassNameSmall =
    "flex px-3 py-2 rounded-md text-sm font-medium text-black items-center text-center border-b-2 border-white hover:text-primary";
  let activeClassName =
    "flex border-b-2 border-primary h-16 px-3 py-2 items-center text-center text-primary";
  let notActiveClassName =
    "flex h-16 px-3 py-2 text-black items-center text-center border-b-2 border-white hover:text-primary";

  return (
    <div>
      <nav className="bg-white bg-clip-padding backdrop-filter backdrop-blur-xl z-50 shadow-md">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center h-full">
              <div className="flex-shrink-0">
                <NavLink to="/" end>
                  <img className="h-9" src={betaLogo} alt="Wevr" />
                </NavLink>
              </div>
              <div className="hidden lg:flex">
                <NavigationMenu>
                  <NavigationMenuList>
                    <NavigationMenuItem>
                      <NavigationMenuTrigger className={notActiveClassName}>
                        Explore
                      </NavigationMenuTrigger>
                      <NavigationMenuContent>
                        <ul className="grid gap-3 p-6 md:w-[400px] lg:w-[500px] lg:grid-cols-[.75fr_1fr] bg-white shadow-lg rounded-md z-50">
                          <li className="row-span-3 z-50">
                            <NavigationMenuLink asChild>
                              <NavLink
                                className="flex h-full w-full select-none flex-col justify-end rounded-md bg-gradient-to-b from-muted/50 to-muted p-6 no-underline outline-none focus:shadow-md relative"
                                to={exploreComponents[0].href}
                              >
                                <div className="mb-2 mt-4 text-lg font-semibold">
                                  {exploreComponents[0].title}
                                </div>
                                <p className="text-sm leading-tight text-muted-foreground">
                                  {exploreComponents[0].description}
                                </p>
                                <img
                                  src={wevrLogoPrimary}
                                  alt="Logo"
                                  className="h-6 w-6 absolute top-4 right-4"
                                />
                              </NavLink>
                            </NavigationMenuLink>
                          </li>
                          {exploreComponents.slice(1).map((component) => (
                            <ListItem
                              key={component.title}
                              title={component.title}
                              href={component.href}
                            >
                              {component.description}
                            </ListItem>
                          ))}
                        </ul>
                      </NavigationMenuContent>
                    </NavigationMenuItem>
                    <NavigationMenuItem>
                      <NavLink
                        to="/Discover"
                        className={({ isActive }) =>
                          isActive ? activeClassName : notActiveClassName
                        }
                      >
                        Discover
                      </NavLink>
                    </NavigationMenuItem>
                    <NavigationMenuItem>
                      <NavLink
                        to="/Footprint"
                        className={({ isActive }) =>
                          isActive ? activeClassName : notActiveClassName
                        }
                      >
                        Footprint
                      </NavLink>
                    </NavigationMenuItem>
                    <NavigationMenuItem>
                      <NavLink
                        to="/query"
                        className={({ isActive }) =>
                          isActive ? activeClassName : notActiveClassName
                        }
                      >
                        Query
                      </NavLink>
                    </NavigationMenuItem>
                    <NavigationMenuItem>
                      <NavLink
                        to="/Dashboard"
                        className={({ isActive }) =>
                          isActive ? activeClassName : notActiveClassName
                        }
                      >
                        Dashboard
                      </NavLink>
                    </NavigationMenuItem>
                    <NavigationMenuItem>
                      <NavLink
                        to="/Alerts"
                        className={({ isActive }) =>
                          isActive ? activeClassName : notActiveClassName
                        }
                      >
                        Alerts
                      </NavLink>
                    </NavigationMenuItem>
                    <NavigationMenuItem>
                      <NavLink
                        to="/Pricing"
                        className={({ isActive }) =>
                          isActive ? activeClassName : notActiveClassName
                        }
                      >
                        Pricing
                      </NavLink>
                    </NavigationMenuItem>
                  </NavigationMenuList>
                  <NavigationMenuViewport />
                </NavigationMenu>
              </div>
            </div>

            <div className="hidden lg:block place-content-end">
              {validateUser.isLoading ? (
                <div>
                  <svg
                    aria-hidden="true"
                    className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-primary"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>
              ) : localStorage.getItem("user") === null ? (
                <button
                  type="button"
                  className="inline-flex items-center px-3 py-2 font-semibold hover:text-white bg-surfaceVariant border border-primaryContainer shadow-md rounded-lg hover:bg-primary"
                  onClick={() => {
                    navigate("/signin");
                  }}
                >
                  Login
                </button>
              ) : (
                <div>
                  <button
                    className="group inline-flex items-center px-4 py-2"
                    onClick={() => {
                      navigate("/UserSettings");
                    }}
                  >
                    <UserCircleIcon
                      className="h-8 w-8 text-onSurfaceVariant rounded-2xl group-hover:text-primary"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              )}
            </div>
            <div className="-mr-2 flex lg:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className="bg-surfaceVariant border border-primaryContainer inline-flex items-center justify-center p-2 rounded-md text-primary hover:text-primaryContainer hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                {!isOpen ? (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                ) : (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>

        <Transition
          show={isOpen}
          enter="transition ease-out duration-100 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="lg:hidden z-50" id="mobile-menu">
            <div className="grid grid-cols-2 items-center font-roboto font-light">
              <NavLink
                to="/Explore?blockchain=Ethereum"
                className={({ isActive }) =>
                  isActive ? activeClassNameSmall : notActiveClassNameSmall
                }
              >
                Explore
              </NavLink>
              <NavLink
                to={tablesLink.href}
                className={({ isActive }) =>
                  isActive ? activeClassNameSmall : notActiveClassNameSmall
                }
              >
                {tablesLink.title}
              </NavLink>
              <NavLink
                to="/Discover"
                className={({ isActive }) =>
                  isActive ? activeClassNameSmall : notActiveClassNameSmall
                }
              >
                Discover
              </NavLink>
              <NavLink
                to="/Footprint"
                className={({ isActive }) =>
                  isActive ? activeClassNameSmall : notActiveClassNameSmall
                }
              >
                Footprint
              </NavLink>
              <NavLink
                to="/query"
                className={({ isActive }) =>
                  isActive ? activeClassNameSmall : notActiveClassNameSmall
                }
              >
                Query
              </NavLink>
              <NavLink
                to="/Dashboard"
                className={({ isActive }) =>
                  isActive ? activeClassNameSmall : notActiveClassNameSmall
                }
              >
                Dashboard
              </NavLink>
              <NavLink
                to="/Alerts"
                className={({ isActive }) =>
                  isActive ? activeClassNameSmall : notActiveClassNameSmall
                }
              >
                Alerts
              </NavLink>
              <NavLink
                to="/Pricing"
                className={({ isActive }) =>
                  isActive ? activeClassNameSmall : notActiveClassNameSmall
                }
              >
                Pricing
              </NavLink>
              {validateUser.isLoading ? (
                <div>
                  <svg
                    aria-hidden="true"
                    className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-primary"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>
              ) : localStorage.getItem("user") === null ? (
                <button
                  type="button"
                  className={notActiveClassNameSmall}
                  onClick={() => {
                    navigate("/signin");
                  }}
                >
                  Login
                </button>
              ) : (
                <div>
                  <button
                    className="group inline-flex items-center px-4 py-2"
                    onClick={() => {
                      navigate("/UserSettings");
                    }}
                  >
                    <UserCircleIcon
                      className="h-8 w-8 text-onSurfaceVariant rounded-2xl group-hover:text-primary"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              )}
            </div>
          </div>
        </Transition>
      </nav>
    </div>
  );
}

const ListItem = React.forwardRef(
  ({ className, title, href, children, ...props }) => {
    return (
      <li>
        <NavigationMenuLink asChild>
          <NavLink
            to={href}
            className={cn(
              "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
              className
            )}
            onClick={() => {}}
            {...props}
          >
            <div className="text-sm font-bold leading-none">{title}</div>
            <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
              {children}
            </p>
          </NavLink>
        </NavigationMenuLink>
      </li>
    );
  }
);
ListItem.displayName = "ListItem";

export default Nav;
