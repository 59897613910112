import { useQuery } from "@tanstack/react-query";
import request from "../../Api/Api.js";

export default function UseCurrentValueQuery(
  blockchain,
  res,
  id,
  source = null
) {
  // console.log(source);
  function getToken() {
    if (localStorage.getItem("user") != null) {
      return JSON.parse(localStorage.getItem("user")).accessToken;
    } else {
      return "";
    }
  }
  var string =
    source === null || source === "Node"
      ? "/" + blockchain + "/Current/" + res + "/" + id
      : typeof source === "object"
      ? "/" + blockchain + "/Current/" + res + "/" + id + "/" + source.name
      : "/" + blockchain + "/Current/" + res + "/" + id + "/" + source;
  const { isLoading, isError, isFetching, data, refetch } = useQuery({
    queryKey: ["current-value-" + string],
    queryFn: async () => {
      if (blockchain === null || id === undefined) {
        return;
      }
      const data = await request
        .get(string, {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        })
        .catch(function (error) {
          return error.response;
        });
      return data;
    },
    enabled:
      blockchain != null ||
      id != null ||
      id != undefined ||
      source != undefined,
    staleTime: 1000,
  });

  return { isLoading, isError, isFetching, data, refetch };
}
