import { ArrowLeftIcon, ClipboardIcon } from "@heroicons/react/24/solid";

// import { Disclosure } from "@headlessui/react";

function VertNavDashboard() {
  return (
    <div
      className={`${"w-[240px]"} min-h-screen px-1 transition-all font-roboto border-r-2 border-secondary pt-6`}
    >
      <div className="flex flex-row px-2 mx-auto py-2">
        <button
          onClick={() => {
            // console.log(props.sidebar);
          }}
          className="bg-primaryContainer rounded-xl mr-2 p-2 hover:bg-primary hover:text-white transition duration-0"
        >
          <ArrowLeftIcon className={`h-6 w-6 hover:text-white`} />
        </button>
        <div>
          <input
            type="text"
            name="search"
            id="search"
            placeholder="Search"
            className="w-[168px] px-4 h-12 rounded-xl border border-primaryContainer"
          />
        </div>
      </div>

      <div>
        <div>
          <div className="mx-auto w-full rounded-xl p-2 space-y-2 mt-2">
            <button className="flex w-full place-items-center bg-white justify-between p-2 rounded-lg font-medium text-lg border border-primaryContainer">
              <div className="grid grid-flow-col items-center">
                <ClipboardIcon className="object-scale-down h-6 w-6 mr-2 text-primary" />
                <div>My dashboard</div>
              </div>
            </button>
            <button className="flex w-full place-items-center bg-white justify-between p-2 rounded-lg font-medium text-lg border border-primaryContainer">
              <div className="grid grid-flow-col items-center">
                <ClipboardIcon className="object-scale-down h-6 w-6 mr-2 text-primary" />
                <div>My dashboard</div>
              </div>
            </button>
            <button className="flex w-full place-items-center bg-white justify-between p-2 rounded-lg font-medium text-lg border border-primaryContainer">
              <div className="grid grid-flow-col items-center">
                <ClipboardIcon className="object-scale-down h-6 w-6 mr-2 text-primary" />
                <div>My dashboard</div>
              </div>
            </button>
            <button className="flex w-full place-items-center bg-white justify-between p-2 rounded-lg font-medium text-lg border border-primaryContainer">
              <div className="grid grid-flow-col items-center">
                <ClipboardIcon className="object-scale-down h-6 w-6 mr-2 text-primary" />
                <div>My dashboard</div>
              </div>
            </button>
            <button className="flex w-full place-items-center bg-white justify-between p-2 rounded-lg font-medium text-lg border border-primaryContainer">
              <div className="grid grid-flow-col items-center">
                <ClipboardIcon className="object-scale-down h-6 w-6 mr-2 text-primary" />
                <div>My dashboard</div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VertNavDashboard;
