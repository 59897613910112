// Created By James Launder
// Used to send a link to the email allowing the user to reset their password

/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import request from "../../Api/Api";
import Background from "../Background";
import { ArrowPathIcon } from "@heroicons/react/24/outline";

const schema = yup
  .object({
    email: yup.string().email().required(),
  })
  .required();
function ForgotPass() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [successful, setSuccessful] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const mutation = useMutation({
    mutationFn: async ({ email }) => {
      setLoading(true);

      return await request.post("/forgot", {
        email: email,
      });
    },

    onSuccess: () => {
      setMessage("Reset link sent to email");
      setLoading(false);

      setSuccessful(true);
    },
    onError: (error) => {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // console.log(error);
      setMessage(resMessage);
      setSuccessful(false);
      setLoading(false);
    },
  });
  const onSubmit = (data) => {
    mutation.mutate({
      email: data.email,
    });
  };
  return (
    <div className="bg-surface w-full font-roboto relative z-0 overflow-hidden">
      <div className="min-h-[75vh] grid place-items-center py-2 font-roboto relative z-0 overflow-x-hidden">
        <div className="flex flex-col grow gap-4 max-w-6xl">
          <div className="relative z-10 top-[-110px] sm:top-[-40px] md:top-[-50px] lg:top-[-90px] xl:top-[-150px] 2xl:top-[-150px] sm:left-[-200px] md:left-[-130px]">
            <Background />
          </div>
          <div className="relative z-20 pt-4 px-4">
            <div className="font-roboto font-semibold text-4xl pb-2">
              Forgot Password
            </div>
          </div>
          <div className="relative z-20 bg-white rounded-md p-6 w-full lg:w-96 shadow-md">
            <form className="pt-4" onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-col">
                <label className="block text-md font-roboto font-light mb-1">
                  Email:
                </label>
                <input
                  type="text"
                  name="email"
                  placeholder="Email"
                  className={`block border border-onSurfaceVariant w-full p-3 rounded-md mb-4 form-control focus:border-primary focus:outline-none ${
                    errors.email ? "is-invalid" : ""
                  }`}
                  {...register("email")}
                />
                <div className="invalid-feedback">{errors.email?.message}</div>
              </div>

              {message && (
                <div className="form-group">
                  <div
                    className={
                      successful ? "alert alert-success" : "alert alert-danger"
                    }
                    role="alert"
                  >
                    {message}
                  </div>
                </div>
              )}
              <div className="py-4 grid grid-cols-3">
                <button
                  type="submit"
                  className="bg-surfaceVariant group hover:bg-primary hover:text-white text-gray-900 mt-3 inline-flex gap-4 w-full justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm  sm:mt-0 sm:w-auto"
                  disabled={loading}
                >
                  {loading ? (
                    <ArrowPathIcon className="h-5 w-5 animate-spin group-hover:text-white" />
                  ) : (
                    <></>
                  )}
                  Reset
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPass;
