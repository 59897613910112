import * as React from "react";
import { cn } from "../../utils/utils";

const Card = React.forwardRef(({ className, ...props }, ref) => (
  <div
    ref={ref}
    // Use clsx to concatenate the base classes with any additional classes passed through className
    className={cn(
      "rounded-md border  text-card-foreground shadow-md transform transition duration-300 bg-white",
      className // additional classes passed to the component
    )}
    {...props}
  />
));
Card.displayName = "Card";

const CardHeader = React.forwardRef(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "flex flex-col space-y-1.5 px-6 pt-4 pb-2", // base classes
      className // additional classes passed to the component
    )}
    {...props}
  />
));
CardHeader.displayName = "CardHeader";

const CardTitle = React.forwardRef(({ className, children, ...props }, ref) => {
  const defaultTitle = "Card Title";

  return (
    <h3
      ref={ref}
      className={cn(
        "font-semibold leading-none tracking-tight text-sm sm:text-base lg:text-lg text-gray-700", // base classes
        className // additional classes passed to the component
      )}
      {...props}
    >
      {children || defaultTitle}
    </h3>
  );
});
CardTitle.displayName = "CardTitle";

const CardDescription = React.forwardRef(({ className, ...props }, ref) => (
  <p
    ref={ref}
    className={cn(
      "text-sm text-muted-foreground", // base classes
      className // additional classes passed to the component
    )}
    {...props}
  />
));
CardDescription.displayName = "CardDescription";

const CardContent = React.forwardRef(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "px-6 py-4 pt-0 font-monda text-base sm:text-xl md:text-2xl text-gray-900", // base classes
      className // additional classes passed to the component
    )}
    {...props}
  />
));
CardContent.displayName = "CardContent";

const CardFooter = React.forwardRef(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "flex items-center p-6 pt-0 ", // base classes
      className // additional classes passed to the component
    )}
    {...props}
  />
));
CardFooter.displayName = "CardFooter";
export {
  Card,
  CardHeader,
  CardFooter,
  CardTitle,
  CardDescription,
  CardContent,
};
