import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition, FocusTrap } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";

function SaveQueryDialog({
  open,
  setOpen,
  queryName,
  setQueryName,
  queryDescription,
  setQueryDescription,
  handleSaveQuery,
  isCreating,
  isEdit, // Add this prop to indicate if it's an edit operation
  handleUpdateQuery,
  handleDeleteQuery,
  selectedQueryId,
  chartConfig,
}) {
  const [showChartSettings, setShowChartSettings] = useState(!!chartConfig);
  const [isEditing, setIsEditing] = useState(isEdit);

  // Effect to synchronize isEditing state with isEdit prop changes.
  useEffect(() => {
    setIsEditing(isEdit);
  }, [isEdit]); // Re-run this effect if isEdit prop changes.

  const clearForm = () => {
    setQueryName("");
    setQueryDescription("");

    //setIsEditing(false); // Reset edit mode state
  };

  const handleClose = () => {
    clearForm();
    setOpen(false);
  };

  const handleDelete = () => {
    if (selectedQueryId && handleDeleteQuery) {
      handleDeleteQuery(selectedQueryId);
      setOpen(false);
      clearForm(); // Make sure clearForm is called here to reset the state
    }
  };

  const handleSave = () => {
    handleSaveQuery(showChartSettings);
  };

  const handleUpdate = () => {
    handleUpdateQuery(showChartSettings);
  };

  const submitButtonStyles = (loading) =>
    loading
      ? "bg-gray-300 text-gray-400"
      : "hover:text-white hover:bg-primary bg-primaryContainer";

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <FocusTrap>
                  <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md text-gray-400 hover:text-gray-500"
                      onClick={handleClose}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        {isEditing ? "Edit Query" : "Save Query"}
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Fill in the details below to{" "}
                          {isEditing ? "update your" : "save a new"} query.
                        </p>
                      </div>
                      <div className="mt-4">
                        <label
                          htmlFor="queryName"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Query Name
                        </label>
                        <input
                          type="text"
                          name="queryName"
                          id="queryName"
                          value={queryName}
                          onChange={(e) => setQueryName(e.target.value)}
                          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                          tabIndex={0}
                        />
                      </div>
                      <div className="mt-4">
                        <label
                          htmlFor="queryDescription"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Description
                        </label>
                        <textarea
                          name="queryDescription"
                          id="queryDescription"
                          value={queryDescription}
                          onChange={(e) => setQueryDescription(e.target.value)}
                          rows={4}
                          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                          tabIndex={0}
                        ></textarea>
                      </div>
                      <div className="mt-4 flex items-center">
                        <label
                          htmlFor="showChartSettings"
                          className="text-sm font-medium text-gray-700 mr-2"
                        >
                          Save Chart Style
                        </label>
                        <div className="relative inline-block w-10 align-middle select-none">
                          <input
                            type="checkbox"
                            id="showChartSettings"
                            checked={showChartSettings}
                            onChange={() =>
                              setShowChartSettings(!showChartSettings)
                            }
                            className="sr-only"
                          />
                          <button
                            className={`block ${
                              showChartSettings ? "bg-primary" : "bg-gray-300"
                            } w-10 h-6 rounded-full cursor-pointer`}
                            onClick={() =>
                              setShowChartSettings(!showChartSettings)
                            }
                          >
                            <div
                              className={`dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition ${
                                showChartSettings
                                  ? "transform translate-x-full"
                                  : ""
                              }`}
                            ></div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    {isEditing && (
                      <button
                        type="button"
                        className="ml-3 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:w-auto sm:text-sm"
                        onClick={handleDelete}
                      >
                        Delete Query
                      </button>
                    )}
                    <button
                      type="button"
                      className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:ml-3 sm:w-auto sm:text-sm ${submitButtonStyles(
                        isCreating
                      )}`}
                      onClick={isEditing ? handleUpdate : handleSave}
                      disabled={isCreating}
                    >
                      {isEditing ? "Update Query" : "Save Query"}
                    </button>
                    <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={handleClose}
                      tabIndex={0}
                    >
                      Cancel
                    </button>
                  </div>
                </FocusTrap>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default SaveQueryDialog;
