// Created by James Launder
// popup that the user can use to change the email to a new one.

/* eslint-disable jsx-a11y/label-has-associated-control */
import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import request from "../../Api/Api";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { useToast } from "../Ui/use-toast";

const schema = yup
  .object({
    email: yup.string().email().required(),
  })
  .required();
export default function UpdateEmail(props) {
  const [successful, setSuccessful] = useState(false);
  const [code, setCode] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const { toast } = useToast();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const mutation = useMutation({
    mutationFn: async ({ email }) => {
      setLoading(true);

      return await request.put(
        "/user/updateemail",
        {
          email: email,
        },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("user")).accessToken
            }`,
          },
        }
      );
    },

    onSuccess: () => {
      setSuccessful(true);
      setMessage("Verify new email");
      setLoading(false);

      setCode(true);
    },
    onError: (error) => {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      setMessage(resMessage);
      setSuccessful(false);
      setLoading(false);
    },
  });
  const verifyMutation = useMutation({
    mutationFn: async ({ email, code }) => {
      setLoading(true);

      return await request.put(
        "/user/validateemail/" + code,
        {
          email: email,
        },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("user")).accessToken
            }`,
          },
        }
      );
    },

    onSuccess: () => {
      setSuccessful(true);
      setLoading(false);
      toast({
        variant: "success",
        title: "Success!",
        description: "Successfully updated email",
      });
      props.setOpen(false);
      setMessage("Email Updated");
      setCode(false);
    },
    onError: (error) => {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      setMessage(resMessage);
      setSuccessful(false);
      setLoading(false);
    },
  });
  const onSubmit = (data) => {
    if (code) {
      verifyMutation.mutate({
        email: data.email,
        code: data.code,
      });
    } else {
      mutation.mutate({
        email: data.email,
        code: data.code,
      });
    }
  };
  useEffect(() => {
    if (!open) {
      setCode(false);
    }
  }, [open]);
  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={props.setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <form className="pt-4" onSubmit={handleSubmit(onSubmit)}>
                  <div className="flex flex-col">
                    <label className="block text-md font-roboto font-light">
                      Email:
                    </label>
                    <input
                      type="text"
                      name="email"
                      placeholder="Email"
                      className={`block border border-onSurfaceVariant w-full p-3 rounded-lg mb-4 form-control focus:border-primary focus:outline-none ${
                        errors.email ? "is-invalid" : ""
                      }`}
                      {...register("email")}
                    />
                    <div className="invalid-feedback">
                      {errors.email?.message}
                    </div>
                  </div>
                  {code ? (
                    <div className="flex flex-col">
                      <label className="block text-md font-roboto font-light">
                        Code:
                      </label>
                      <input
                        type="text"
                        name="code"
                        placeholder="Code"
                        className={`block border border-onSurfaceVariant w-full p-3 rounded-lg mb-4 form-control focus:border-primary focus:outline-none ${
                          errors.code ? "is-invalid" : ""
                        }`}
                        {...register("code")}
                      />
                      <div className="invalid-feedback">
                        {errors.code?.message}
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}

                  {message && (
                    <div className="form-group">
                      <div
                        className={
                          successful
                            ? "alert alert-success"
                            : "alert alert-danger"
                        }
                        role="alert"
                      >
                        {message}
                      </div>
                    </div>
                  )}
                  <div className="py-4 grid grid-cols-3">
                    <button
                      type="submit"
                      className="bg-surfaceVariant group hover:bg-primary hover:text-white text-gray-900 mt-3 inline-flex gap-4 w-full justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm  sm:mt-0 sm:w-auto"
                      disabled={loading}
                    >
                      {loading ? (
                        <ArrowPathIcon className="h-5 w-5 animate-spin group-hover:text-white" />
                      ) : (
                        <></>
                      )}
                      Update
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
