import { create } from "zustand";

export const useGraphState = create((set) => ({
  graphState: { name: "", resetCmp: false, resetCharts: false },
  setGraphName: (input) =>
    set((state) => ({ graphState: { ...state.graphState, name: input } })),
  setResetCmp: (value) =>
    set((state) => ({ graphState: { ...state.graphState, resetCmp: value } })),
  setResetCharts: (value) =>
    set((state) => ({
      graphState: { ...state.graphState, resetCharts: value },
    })),
}));
