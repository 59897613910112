import React from "react";
import {
  ArrowsPointingOutIcon,
  ArrowsPointingInIcon,
} from "@heroicons/react/24/solid"; // Make sure to import the correct icons
import { Button } from "../../Ui/button";

const MinimizeButton = ({ isMinimized, toggle }) => {
  return (
    <Button variant="outline" onClick={toggle} className="mr-2">
      {isMinimized ? (
        <ArrowsPointingOutIcon className="h-5 w-5" />
      ) : (
        <ArrowsPointingInIcon className="h-5 w-5" />
      )}
    </Button>
  );
};

export default MinimizeButton;
