// created by james launder
// sign up form, relatively simple just has verification for each input

/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import request from "../../Api/Api.js";
import Background from "../Background.js";
import logo from "../../Assets/WevrLogoPrimary.svg";

const schema = yup
  .object({
    username: yup.string().required(),
    email: yup.string().email().required(),
    password: yup
      .string()
      .required("Please enter your password")
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        "Password must contain at least 8 characters, one uppercase, one number and one special case character"
      ),
    confirmPwd: yup
      .string()
      .required("Please confirm your password")
      .oneOf([yup.ref("password")], "Password doesn't match"),
    mail: yup.boolean(),
  })
  .required();

function SignUp() {
  let navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [successful, setSuccessful] = useState(false);
  const [message, setMessage] = useState("");
  const location = useLocation();
  const mutation = useMutation({
    mutationFn: async ({ username, email, password, mail }) => {
      return await request.post("/signup", {
        username: username,
        email: email,
        password: password,
        mail: mail,
      });
    },

    onSuccess: () => {
      setSuccessful(true);
      navigate("/signin", { state: location });
      window.location.reload();
    },
    onError: (error) => {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      setMessage(resMessage);
      setSuccessful(false);
    },
  });
  const onSubmit = (data) => {
    mutation.mutate({
      username: data.username,
      email: data.email,
      password: data.password,
      mail: data.mail,
    });
  };

  return (
    // <div className="min-h-[75vh] grid place-items-center relative z-0 overflow-x-hidden">
    // <div className="flex flex-col grow gap-4 max-w-6xl">
    <div className="relative">
      <div className="min-h-[75vh] grid place-items-center relative z-0 overflow-hidden">
        <div className="flex flex-col grow gap-4 max-w-6xl">
          <Background />
          <div className="px-4">
            <div className="pt-4 px-4 pb-4 flex flex-col">
              <img
                src={logo}
                alt="wevr.ai"
                className="h-12 md:h-16 self-center pb-2"
              />
              <div className="text-center text-gray-600 text-lg sm:text-2xl">
                Sign up to explore
              </div>
            </div>
            <div className=" bg-surface border border-primaryContainer shadow-md rounded-2xl  p-4 w-full">
              <form className="pt-4" onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-col">
                  <label className="block text-md font-roboto font-light">
                    Username
                  </label>
                  <input
                    type="text"
                    name="username"
                    placeholder="Username"
                    className={`block border border-onSurfaceVariant w-full p-3 rounded-lg mb-4 form-control focus:border-primary focus:outline-none ${
                      errors.username ? "is-invalid" : ""
                    }`}
                    {...register("username")}
                  />
                  <div className="invalid-feedback">
                    {errors.username?.message}
                  </div>
                </div>
                <div className="flex flex-col">
                  <label className="block text-md font-roboto font-light">
                    Email
                  </label>
                  <input
                    type="text"
                    name="email"
                    placeholder="Email"
                    className={`block border border-onSurfaceVariant w-full p-3 rounded-lg mb-4 form-control focus:border-primary focus:outline-none ${
                      errors.email ? "is-invalid" : ""
                    }`}
                    {...register("email")}
                  />
                  <div className="invalid-feedback">
                    {errors.email?.message}
                  </div>
                </div>
                <div className="flex flex-col">
                  <label className="block text-md font-roboto font-light">
                    Password
                  </label>
                  <input
                    name="password"
                    type="password"
                    placeholder="Password"
                    className={`block border border-onSurfaceVariant w-full p-3 rounded-lg mb-4 form-control focus:border-primary focus:outline-none ${
                      errors.password ? "is-invalid" : ""
                    }`}
                    {...register("password")}
                  />
                  <div className="invalid-feedback">
                    {errors.password?.message}
                  </div>
                </div>
                <div className="flex flex-col form-group">
                  <label className="block text-md font-roboto font-light">
                    Confirm Password
                  </label>
                  <input
                    name="confirmPwd"
                    type="password"
                    placeholder="Confirm Password"
                    className={`block border border-onSurfaceVariant w-full p-3 rounded-lg mb-4 form-control focus:border-primary focus:outline-none ${
                      errors.confirmPwd ? "is-invalid" : ""
                    }`}
                    {...register("confirmPwd")}
                  />
                  <div className="invalid-feedback">
                    {errors.confirmPwd?.message}
                  </div>
                </div>
                <div className="flex flex-row form-group items-center ml-1">
                  <input type="checkbox" name="mail" {...register("mail")} />
                  <label className="block text-sm font-roboto px-2 text-gray-600">
                    Subscribe to receive new and product updated. You can
                    unsubscribe at anytime.
                  </label>
                </div>
                {message && (
                  <div className="form-group">
                    <div
                      className={
                        successful
                          ? "alert alert-success"
                          : "alert alert-danger"
                      }
                      role="alert"
                    >
                      {message}
                    </div>
                  </div>
                )}
                <div className="py-4 flex flex-col sm:flex-row gap-2">
                  <button
                    type="submit"
                    className=" px-4 sm:px-6 py-2 min-w-[6rem] text-sm sm:text-md rounded-lg bg-surfaceVariant border border-primaryContainer shadow-md hover:text-white hover:bg-primary"
                    // disabled={loading}
                  >
                    Sign Up
                  </button>

                  <div className="px-2 flex items-center group text-gray-600">
                    <Link
                      to="/Legal"
                      className="text-sm sm:text-md group-hover:underline"
                    >
                      By signing up, you agree to the Master Agreements, Terms
                      of Service and Privacy Policy
                    </Link>
                  </div>
                </div>
              </form>
              <div>
                <Link to="/signIn" className="flex group">
                  <div className="text-gray-600">
                    Already have an account?&nbsp;
                  </div>
                  <div className="text-primary group-hover:underline">
                    Log in
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
