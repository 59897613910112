// created by James Launder
// provides a surround for the small chart w/ chart name and percentage change

import SmallChart from "./SmallChart";
function SmallChartContainer(props) {
  const colour = (first, last) => {
    if (first > last) {
      return false;
    }
    return true;
  };

  return (
    <div className="flex flex-col divide-y w-full">
      {/* Header */}
      <div className="text-center font-bold font-roboto px-3 py-2">
        {props.graphName}
      </div>
      <div className="">
        <SmallChart
          data={props.smallChartData}
          name={props.graphName}
          height={props.graphHeight}
          width={props.graphWidth}
        />
      </div>
      <div className="flex justify-around py-2">
        <span className="flex flex-row text-center align-baseline px-3 py-2">
          <div className="font-roboto align-baseline mb-[0.5] text-sm">
            {props.timePeriod}
            {": "}
          </div>
          <div
            className={`${
              colour(
                props.smallChartData[0]?.values.slice(-1)[0][1],
                props.smallChartData[0]?.values.slice(0)[0][1]
              )
                ? "text-green-600"
                : "text-red-600"
            } font-monda font-bold align-baseline text-sm`}
          >
            {(
              ((props.smallChartData[0]?.values.slice(0)[0][1] -
                props.smallChartData[0]?.values.slice(-1)[0][1]) /
                props.smallChartData[0]?.values.slice(0)[0][1]) *
              100
            ).toFixed(1)}
            %
          </div>
        </span>
        <span className="flex flex-row text-center align-baseline px-3 py-2">
          <div className="font-roboto  align-baseline mb-[0.5] text-sm">
            Last:{" "}
          </div>
          <div className="font-monda font-bold align-baseline text-sm">
            {props.smallChartData[0]?.values
              .slice(0)[0][1]
              .toLocaleString("en-US", {
                maximumFractionDigits: 2,
              })}
          </div>
        </span>
      </div>
    </div>
  );
}

export default SmallChartContainer;
