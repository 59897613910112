import { create } from "zustand";

const addQuery = (blockchain, res, name, source) => {
  var newQuery = null;
  if (source === null) {
    newQuery = {
      key: [blockchain, res, name],
      url: "/" + blockchain + "/" + res + "/" + name,
    };
  } else {
    newQuery = {
      key: [blockchain, res, name, source],
      url: "/" + blockchain + "/" + res + "/" + name + "/" + source,
    };
  }
  return newQuery;
};

const remove = (index, state) => {
  const newState = [...state];
  newState.splice(index, 1);
  return newState;
};

const removeMulti = (start, end, state) => {
  const newState = [...state];
  newState.splice(start, end);
  return newState;
};

// console.log(queryUrl)
export const useStoreQuery = create((set) => ({
  queryUrl: [],
  addQuery: (blockchain, res, name, source) =>
    set((state) => ({
      queryUrl: [...state.queryUrl, addQuery(blockchain, res, name, source)],
    })),
  removeQuery: (index) =>
    set((state) => ({ queryUrl: remove(index, state.queryUrl) })),
  removeMultiQuery: (start, end) =>
    set((state) => ({ queryUrl: removeMulti(start, end, state.queryUrl) })),
  removeAll: () => set(() => ({ queryUrl: [] })),
}));
