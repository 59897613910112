import React, { useState, useEffect } from "react";
import useWindowDimensions from "../../Hooks/useWindowDimensions.js";
import { ArrowLeftIcon, Bars3Icon, XMarkIcon } from "@heroicons/react/24/solid";
import SidebarItem from "./SidebarItem";
import SidebarIcon from "./SidebarIcon";
import BlockchainDropdown from "../TablePage/BlockchainDropdown.js"; // Adjust the path as necessary

const SidebarWrapper = ({
  tableStores = [],
  onTableSelect,
  isLoading,
  blockchainData,
  onBlockchainChange,
}) => {
  const [sidebar, setSidebar] = useState(true);
  const { width } = useWindowDimensions();
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredTables, setFilteredTables] = useState(tableStores);

  useEffect(() => {
    if (width <= 768) {
      setSidebar(false);
    } else {
      setSidebar(true);
    }
  }, [width]);

  useEffect(() => {
    if (searchQuery) {
      setFilteredTables(
        tableStores.filter((table) =>
          table.name.toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
    } else {
      setFilteredTables(tableStores);
    }
  }, [searchQuery, tableStores]);

  const categorizedTables = filteredTables.reduce((acc, table) => {
    const { category, icon } = table;
    if (!acc[category]) {
      acc[category] = { category, icon, tables: [] }; // Initialize with category and icon
    }
    acc[category].tables.push(table); // Push the table into the tables array
    return acc;
  }, {});

  const handleTableSelect = (table) => {
    if (typeof onTableSelect === "function") {
      onTableSelect(table);
    }
  };
  return (
    <div className="flex flex-col h-full">
      <div className="md:hidden flex items-center justify-between bg-surface p-2">
        <button
          onClick={() => setSidebar(!sidebar)}
          className="bg-surfaceVariant rounded-xl p-2 border-2 group border-primaryContainer hover:bg-primary hover:text-white transition duration-0"
        >
          {sidebar ? (
            <XMarkIcon className="h-8 w-8 group-hover:text-white text-onSurfaceVariant" />
          ) : (
            <Bars3Icon className="h-8 w-8 group-hover:text-white text-onSurfaceVariant" />
          )}
        </button>
      </div>
      <div
        className={`${
          sidebar ? "block w-full md:w-[248px]" : "hidden md:block md:w-20"
        } h-full md:h-[90vh] px-1 pb-4 transition-all font-roboto bg-surface`}
      >
        <div className="hidden md:flex md:justify-between md:items-center md:px-2 md:py-2">
          <button
            onClick={() => setSidebar(!sidebar)}
            className="bg-surfaceVariant rounded-xl p-2 border-2 group border-primaryContainer hover:bg-primary hover:text-white transition duration-0"
          >
            <ArrowLeftIcon
              className={`${
                sidebar
                  ? "transition duration-75"
                  : "rotate-180 transition duration-75"
              } object-scale-down h-8 w-8 group-hover:text-white text-onSurfaceVariant`}
            />
          </button>
          {sidebar && (
            <div className="relative w-full ml-2">
              <input
                type="text"
                name="search"
                id="search"
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full px-4 h-12 rounded-xl border border-primaryContainer focus:border-primary focus:outline-none"
              />
            </div>
          )}
        </div>
        {sidebar && blockchainData && onBlockchainChange && (
          <div className="p-2">
            <BlockchainDropdown
              blockchainData={blockchainData}
              onBlockchainChange={onBlockchainChange}
            />
          </div>
        )}
        <div className="h-full overflow-auto">
          {sidebar ? (
            <div className="mx-auto w-full p-2 space-y-2 max-h-full">
              {isLoading ? (
                <div>Loading...</div>
              ) : (
                Object.entries(categorizedTables).map(
                  ([category, { icon, tables }]) => (
                    <SidebarItem
                      key={category}
                      category={category}
                      icon={icon}
                      tables={tables}
                      sidebar={sidebar}
                      onTableSelect={handleTableSelect}
                    />
                  )
                )
              )}
            </div>
          ) : (
            <div className="mx-auto w-full rounded-xl p-2 space-y-4">
              {Object.keys(categorizedTables).map((category) => (
                <SidebarIcon
                  key={category}
                  category={category}
                  setSidebar={setSidebar}
                  sidebar={sidebar}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SidebarWrapper;
