// Created By Matt Webb
// handles the coinbase checkout
import dayjs from "dayjs";
import { useNavigate } from "react-router";
var isBetween = require("dayjs/plugin/isBetween");
dayjs.extend(isBetween);

const coinbasePayment = ({ packageName, cartItem }) => {
  const navigate = useNavigate();
  function getToken() {
    // var token = JSON.parse(localStorage.getItem("user")).accessToken;
    if (localStorage.getItem("user") != null) {
      return JSON.parse(localStorage.getItem("user")).accessToken;
    } else {
      return "";
    }
  }
  const handleCheckout = async () => {
    try {
      const url = process.env.API + "/coinbase/createCharge";
      const options = {
        method: "POST",
        headers: {
          accept: "application/json",
          "content-type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({
          id: cartItem,
          quantity: 1,
        }),
      };
      const response = await fetch(url, options);
      const data = await response.json();
      window.open(data.hosted_url);
    } catch (error) {
      console.error(error);
    }
  };

  // gets the users info from stored data
  function getTier() {
    if (localStorage.getItem("user") == null) {
      return "NoUser";
      // return JSON.parse(localStorage.getItem("user")).tier > 0;
    } else if (JSON.parse(localStorage.getItem("user")).tier > 0) {
      return "Active";
    } else {
      return "NotActive";
    }
  }

  // checks when the users subscrption was paid
  function getPaidBy() {
    return JSON.parse(localStorage.getItem("user")).paidBy;
  }

  // if the subscription ends within 5 days let them pay again if they paid with crypto
  function subsctionEnds() {
    return dayjs(
      JSON.parse(localStorage.getItem("user")).subscriptionEnd
    ).isBetween(dayjs(), dayjs().add(5, "day"), "day");
  }

  // changes how buttons behave based on the users plan or lack of plan
  if (getTier() === "NoUser") {
    return (
      <button
        onClick={() => navigate("/signin")}
        className="w-full rounded-md bg-primary py-2 text-white hover:bg-wevr-Accent-Light hover:shadow-md duration-75 "
      >
        Buy {packageName}
      </button>
    );
  }
  if (getTier() === "Active") {
    return (
      <div>
        {getPaidBy() === "Coinbase" ? (
          <div>
            {" "}
            {subsctionEnds() ? (
              <button
                onClick={() => handleCheckout()}
                className="w-full rounded-md bg-primary py-2 text-white hover:bg-wevr-Accent-Light hover:shadow-md duration-75"
              >
                Upgrade {packageName}
              </button>
            ) : (
              <button
                onClick={() => navigate("/UserSettings")}
                className="w-full rounded-md bg-primary py-2 text-white hover:bg-wevr-Accent-Light hover:shadow-md duration-75"
              >
                Active subscription
              </button>
            )}
          </div>
        ) : (
          <div>
            {" "}
            {subsctionEnds() ? (
              <button
                onClick={() => handleCheckout()}
                className="w-full rounded-md bg-primary py-2 text-white hover:bg-wevr-Accent-Light hover:shadow-md duration-75"
              >
                Upgrade {packageName}
              </button>
            ) : (
              <button
                onClick={() => navigate("/UserSettings")}
                className="w-full rounded-md bg-primary py-2 text-white hover:bg-wevr-Accent-Light hover:shadow-md duration-75"
              >
                Active Stripe subscription
              </button>
            )}
          </div>
        )}
      </div>
    );
  }
  return (
    <div>
      <button
        onClick={() => {
          {
            handleCheckout();
          }
        }}
        className="w-full rounded-md bg-primary py-2 text-white hover:bg-wevr-Accent-Light hover:shadow-md duration-75"
      >
        Buy {packageName}
      </button>
    </div>
  );
};

export default coinbasePayment;
