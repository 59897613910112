import React from "react";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "../Ui/hover-card.js";
import { formatDistanceToNow } from "date-fns";

const InfoIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 16V12"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 8H12.01"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const TitleSection = ({
  name,
  description,
  lastEdited,
  isGrid,
  interval = "days",
  blockchain,
}) => {
  const formatLastEdited = (date) => {
    return formatDistanceToNow(new Date(date), { addSuffix: true });
  };
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const generateLink = (blockchain, tableName, interval) => {
    return `/Explore/Tables?blockchain=${capitalizeFirstLetter(
      blockchain
    )}&name=${encodeURIComponent(tableName)}&interval=${interval}`;
  };

  if (isGrid) {
    return (
      <div className="mb-6 flex items-center gap-1 relative justify-between">
        <div className="flex items-center gap-1 w-full">
          <div
            className="w-1 bg-primary rounded-full absolute left-0"
            style={{ height: "75%", top: "25%" }}
          />
          <div className="flex items-center gap-2 pl-8 w-full truncate">
            <h1 className="text-xl font-bold truncate py-1">
              <a
                className="py-1"
                href={generateLink(blockchain, name, interval)}
              >
                {name}
              </a>
            </h1>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="mb-6 flex items-center justify-between">
      <div className="flex items-center relative">
        <div
          className="w-1 bg-primary rounded-full absolute left-0"
          style={{ height: "75%", top: "25%" }}
        />
        <h1 className="text-2xl font-bold pl-8 flex items-end">
          {name}
          <span className="hidden xl:inline-flex items-center ml-2 mb-1">
            <HoverCard>
              <HoverCardTrigger>
                <InfoIcon />
              </HoverCardTrigger>
              <HoverCardContent className="w-80 p-2 bg-white rounded-md shadow-lg border border-gray-200">
                <p className="text-sm text-gray-900">{description}</p>
              </HoverCardContent>
            </HoverCard>
          </span>
        </h1>
      </div>
      {lastEdited && (
        <div className="flex items-center">
          <HoverCard>
            <HoverCardTrigger>
              <span className="text-sm text-gray-600 hover:underline cursor-pointer">
                Last updated: {formatLastEdited(lastEdited)}
              </span>
            </HoverCardTrigger>
            <HoverCardContent className="w-60 p-2 bg-white rounded-md shadow-lg border border-gray-200">
              <p className="text-sm text-gray-900">
                {new Date(lastEdited).toLocaleString()}
              </p>
            </HoverCardContent>
          </HoverCard>
        </div>
      )}
    </div>
  );
};

export default TitleSection;
