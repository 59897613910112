import { useQuery } from "@tanstack/react-query";
import request from "../../../Api/Api.js";

export function useMaterializedViews() {
  return useQuery({
    queryKey: ["materializedViews"],
    queryFn: () => {
      return request
        .get("/getMaterializedViews")
        .then((response) => response.data)
        .catch((err) => {
          console.error("Error fetching materialized views:", err);
          throw err;
        });
    },
  });
}
