import React from "react";
import { motion } from "framer-motion";

const AnimatedBadge = ({ children, href, newLabel }) => {
  return (
    <motion.div
      className="mb-1.5 rounded-full bg-neutral-50  p-px text-sm md:shadow-2xl shadow-zinc-900"
      whileHover={{ rotate: -2 }}
      transition={{ duration: 0.3 }}
    >
      <a
        href={href}
        target="_blank"
        rel=" noreferrer noopener"
        className="group cursor-pointer relative rounded-full no-underline inline-block w-fit mx-auto"
      >
        <span className="absolute inset-0 overflow-hidden rounded-full">
          <span className="absolute inset-0 rounded-full opacity-0 transition-opacity duration-500 group-hover:opacity-100 bg-primary" />
        </span>
        <div className="relative flex items-center z-10 rounded-full bg-neutral-100  py-1.5 px-4 ring-1 ring-white/10">
          {newLabel && (
            <span className="rounded-full bg-secondary-new px-2 py-0.5 text-neutral-700 mr-2 font-bold">
              {newLabel}
            </span>
          )}
          <span className="text-neutral-700  font-bold">
            {children}
          </span>
          <svg
            fill="none"
            height="16"
            viewBox="0 0 24 24"
            width="16"
            xmlns="http://www.w3.org/2000/svg"
            className="ml-2"
          >
            <path
              d="M10.75 8.75L14.25 12L10.75 15.25"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
            />
          </svg>
        </div>
        <span className="absolute -bottom-0 left-[1.125rem] h-px w-[calc(100%-2.25rem)] bg-gradient-to-r from-neutral-400/0 via-neutral-400/90 to-neutral-400/0 transition-opacity duration-500 group-hover:opacity-40" />
      </a>
    </motion.div>
  );
};

const App = () => {
  return (
    <div className="p-8">
      <AnimatedBadge href="query" newLabel="New">
        Query is live!
      </AnimatedBadge>
    </div>
  );
};

export default App;
