import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import SidebarWrapper from "../Ui/SidebarWrapper";
import TableView from "./TableView";
import TableGrid from "./TableGrid";
import { useBlockchainData } from "./UseBlockchainData.js";
import { useSelectedTable } from "./UseSelectedTable.js";
import DataBar from "./DataBar";
import LoadingSpinner from "../Ui/loadingSpinner.js";
import newLogoV2 from "../../Assets/WevrLogoPrimary.svg";

function getToken() {
  if (localStorage.getItem("user") != null) {
    return JSON.parse(localStorage.getItem("user")).accessToken;
  } else {
    return "";
  }
}

const TablePage = () => {
  const {
    blockchain,
    searchParams,
    setSearchParams,
    tableStoresLoading,
    tableStoresError,
    tableStoresErrorDetails,
    blockchainLoading,
    blockchainData,
    blockchainErrorState,
    currentTableStores,
    setCurrentTableStores,
  } = useBlockchainData();

  const { selectedTable, setSelectedTable } = useSelectedTable(
    currentTableStores,
    blockchain
  );
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    const token = getToken();
    setIsLoggedIn(token !== "");
  }, []);

  useEffect(() => {
    if (!searchParams.get("blockchain")) {
      setSearchParams({ blockchain: "Ethereum" });
    }
  }, [searchParams, setSearchParams]);

  const handleGranularityChange = (granularity) => {
    setSearchParams((prevParams) => ({
      ...prevParams,
      blockchain,
      name: selectedTable.name,
      interval: granularity,
    }));
  };

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
  };

  if (blockchainErrorState) {
    return <div>Error loading blockchains: {blockchainErrorState.message}</div>;
  }

  if (tableStoresError) {
    return (
      <div>Error loading table stores: {tableStoresErrorDetails.message}</div>
    );
  }

  if (blockchainLoading || tableStoresLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-pulse flex items-center justify-center">
          <img
            src={newLogoV2}
            alt="Company Logo"
            className="max-h-16 md:max-h-40 pl-14"
          />
        </div>
      </div>
    );
  }

  const tableName = searchParams.get("name");

  return (
    <div className="flex flex-col h-full">
      {tableName ? (
        selectedTable ? (
          <TableView
            selectedTable={selectedTable}
            blockchain={blockchain.toLowerCase()}
            onGranularityChange={handleGranularityChange}
            onFilterChange={handleFilterChange}
            filters={filters}
            isLoggedIn={isLoggedIn}
          />
        ) : (
          <LoadingSpinner />
        )
      ) : (
        <TableGrid
          blockchain={blockchain.toLowerCase()}
          isLoggedIn={isLoggedIn}
          tableStores={currentTableStores}
          onGranularityChange={handleGranularityChange}
          onFilterChange={handleFilterChange}
        />
      )}
    </div>
  );
};

export default TablePage;
