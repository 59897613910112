// created by james launder
// similar to small chart container from charting
// minor tweaks to make it work for news format

import { useQuery } from "@tanstack/react-query";
import NewsChart from "./NewsChart";
import request from "../../Api/Api.js";
import { useNavigate } from "react-router-dom";
import watermarkIcon from "../../Assets/WevrLogoSecondary.svg";

function NewsChartContainer(props) {
  const navigate = useNavigate();
  const colour = (first, last) => {
    if (first > last) {
      return false;
    }
    return true;
  };
  const graphData = useQuery({
    queryKey: ["news-chart-" + props.graphName + "-" + props.blockchain],
    queryFn: () => {
      const data = request
        .get(props.blockchain + "/Day/LimitAmount/" + props.graphName + "/14")
        .catch(function (error) {
          return error.response.status;
        });
      return data;
    },

    enabled: props.isSuccess,
  });
  return (
    <div className="width-fit">
      {graphData.isLoading ? (
        <div
          className=" h-auto
         rounded-xl animate-pulse flex items-center justify-center"
        >
          <img
            src={watermarkIcon}
            alt="..."
            className="h-[100px] md:h-[140px]"
          />
        </div>
      ) : (
        <div>
          {graphData.isError || graphData.data === 404 ? (
            <div>Error!</div>
          ) : (
            <button
              className="flex flex-col divide-y w-full justify-center"
              onClick={() => {
                navigate({
                  pathname: "/Explore",
                  search:
                    "?blockchain=" +
                    props.blockchain +
                    "&name=" +
                    props.graphName,
                });
              }}
            >
              {/* Header */}
              <div className="text-center font-bold font-roboto px-3 py-2 w-full">
                {props.graphName + "-" + props.blockchain}
              </div>
              <div className="w-full">
                <NewsChart
                  data={graphData.data.data}
                  name={props.graphName}
                  height={props.graphHeight}
                  // width={ref.current.offsetWidth || 0}
                />
              </div>
              <div className="flex justify-around py-2 w-full">
                <span className="flex flex-row text-center align-baseline px-3 py-2">
                  <div className="font-roboto align-baseline mb-[0.5] text-sm">
                    {props.timePeriod}
                    {": "}
                  </div>
                  <div
                    className={`${
                      colour(
                        graphData.data.data.graphData[0]?.values.slice(
                          -1
                        )[0][1],
                        graphData.data.data.graphData[0]?.values.slice(0)[0][1]
                      )
                        ? "text-green-600"
                        : "text-red-600"
                    } font-monda font-bold align-baseline text-sm`}
                  >
                    {(
                      ((graphData.data.data.graphData[0]?.values.slice(
                        0
                      )[0][1] -
                        graphData.data.data.graphData[0]?.values.slice(
                          -1
                        )[0][1]) /
                        graphData.data.data.graphData[0]?.values.slice(
                          0
                        )[0][1]) *
                      100
                    ).toFixed(1)}
                    %
                  </div>
                </span>
                <span className="flex flex-row text-center align-baseline px-3 py-2">
                  <div className="font-roboto  align-baseline mb-[0.5] text-sm">
                    Last:{" "}
                  </div>
                  <div className="font-monda font-bold align-baseline text-sm">
                    {graphData.data.data.graphData[0]?.values
                      .slice(0)[0][1]
                      .toLocaleString("en-US", {
                        maximumFractionDigits: 2,
                      })}
                  </div>
                </span>
              </div>
            </button>
          )}
        </div>
      )}
    </div>
  );
}

export default NewsChartContainer;
