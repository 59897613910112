// created by James Launder
// form for contact us to be sent to middleware to pass onto our inbox

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import request from "../../Api/Api.js";
import CompletePopUp from "./CompletePopUp.js";
import Background from "../Background.js";
const schema = yup
  .object({
    name: yup.string().required(),
    email: yup.string().email().required(),
    subject: yup.string().required(),
    message: yup.string().required(),
  })
  .required();
function Contact() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [isLoading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const mutation = useMutation({
    mutationFn: async ({ name, email, subject, message }) => {
      return await request.post("/contactEmail", {
        email: email,
        name: name,
        subject: subject,
        message: message,
      });
    },

    onSuccess: () => {
      // setSuccessful(true);
      setLoading(false);
      setShow(true);
    },
    onError: (error) => {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      setMessage(resMessage);
      setLoading(false);
    },
  });
  const onSubmit = (data) => {
    // console.log("done");
    setLoading(true);
    mutation.mutate({
      email: data.email,
      name: data.name,
      subject: data.subject,
      message: data.message,
    });
  };
  return (
    <div className="grid bg-surface w-full font-roboto relative z-0 overflow-hidden ">
      <div>
        <div className="relative z-10 top-[190px] left-[350px] mx-auto max-w-6xl md:block hidden">
          <div className="relative">
            <Background />
          </div>
        </div>
      </div>
      <CompletePopUp show={show} setShow={setShow} />
      <div className="relative z-20 grid pb-8 pt-4 place-items-center w-full">
        {/* <Background /> */}
        <div className="grid grid-flow-row gap-8  content-center py-4 w-4/5 md:w-2/3 lg:w-1/2 ">
          <h1 className=" font-semibold text-center text-4xl sm:text-6xl ml-2 pb-2 text-gray-900">
            Get in touch
          </h1>
          <div className="flex md:flex-row flex-col gap-4 md:gap-20 lg:gap-40 justify-around">
            <div className="flex flex-col bg-white shadow-md border border-primaryContainer p-4 py-6 text-lg rounded-lg grow items-center ">
              <div className="font-semibold">Matt Webb</div>
              <div className="font-light pb-4">Co-founder</div>
              <p className="pb-4 font-medium text-indigo-600">matt@wevr.ai</p>
            </div>
            <div className="flex flex-col bg-white shadow-md border border-primaryContainer p-4 py-6 text-lg rounded-lg grow items-center">
              <div className="font-semibold">James Launder</div>
              <div className="font-light pb-4">Co-founder</div>
              <p className="pb-4 font-medium text-indigo-600">
                jlaunder@wevr.ai
              </p>
            </div>
          </div>
        </div>

        <div className="bg-white shadow-md border border-primaryContainer px-8 py-4 rounded-lg w-4/5 md:w-2/3 lg:w-1/2">
          <form
            className="flex flex-col pt-4 gap-4"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="flex flex-col">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label className="block text-md font-roboto font-light">
                Name:
              </label>
              <input
                type="text"
                name="name"
                placeholder="Name"
                className={`block border border-onSurfaceVariant w-full p-3 rounded-lg mb-4 focus:border-primary focus:outline-none form-control ${
                  errors.name ? "is-invalid" : ""
                }`}
                {...register("name")}
              />
              <div className="font-semibold text-red-500 invalid-feedback -mt-2">
                {errors.name?.message}
              </div>
            </div>
            <div className="flex flex-col">
              <label className="block text-md font-roboto font-light">
                Email:{" "}
              </label>
              <input
                name="email"
                type="email"
                placeholder="Email"
                className={`block border border-onSurfaceVariant w-full p-3 rounded-lg mb-4 focus:border-primary focus:outline-none form-control ${
                  errors.email ? "is-invalid" : ""
                }`}
                {...register("email")}
              />
              <div className="font-semibold text-red-500 invalid-feedback -mt-2">
                {errors.email?.message}
              </div>
            </div>
            <div className="flex flex-col">
              <label className="block text-md font-roboto font-light">
                Subject:{" "}
              </label>
              <input
                name="subject"
                type="text"
                placeholder="Subject"
                className={`block border border-onSurfaceVariant w-full p-3 rounded-lg mb-4 focus:border-primary focus:outline-none form-control ${
                  errors.subject ? "is-invalid" : ""
                }`}
                {...register("subject")}
              />
              <div className="font-semibold text-red-500 invalid-feedback -mt-2">
                {errors.subject?.message}
              </div>
            </div>
            <div className="flex flex-col">
              <label className="block text-md font-roboto font-light">
                Message:{" "}
              </label>
              <textarea
                name="message"
                type="text"
                //   placeholder="Message"
                rows={6}
                className={`block border border-onSurfaceVariant w-full p-3 rounded-lg mb-4 focus:border-primary focus:outline-none form-control ${
                  errors.message ? "is-invalid" : ""
                }`}
                {...register("message")}
              />
              <div className="font-semibold text-red-500 invalid-feedback -mt-2">
                {errors.message?.message}
              </div>
            </div>

            <div className="flex justify-center py-4">
              {isLoading ? (
                <div>
                  <svg
                    aria-hidden="true"
                    className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-primary"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <button
                  type="submit"
                  className="px-6 py-2 w-full font-roboto font-light rounded-lg bg-surfaceVariant border border-primaryContainer shadow-md hover:text-white hover:bg-primary"
                >
                  Submit
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;
