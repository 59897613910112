export default function GoToAddress(addressInput, navigate) {
  // Remove the leading and trailing slashes to ensure consistent splitting
  if (!addressInput.trim()) {
    alert("Please enter an address.");
    return; // Stop the function execution if no address is entered
  }
  const trimmedPath = location.pathname.replace(/^\/|\/$/g, "");
  const pathSegments = trimmedPath.split("/");

  // The tab is the second segment after 'Footprint'
  let currentTab =
    pathSegments.length > 1 ? pathSegments[1].toLowerCase() : "portfolio";

  // If currentTab is 'footprint', set it to 'portfolio'
  if (currentTab === "footprint") {
    currentTab = "portfolio";
  }

  const newPath = `/Footprint/${currentTab}?address=${addressInput || ""}`;
  navigate(newPath);
}
