// boilerplate stuff in
import { Button } from "../Ui/button";
import BottomTable from "./BottomTable";
import { PlusIcon } from "@heroicons/react/24/outline";
import React, { useState, useEffect, useRef } from "react";
import Sidebar from "./Sidebar/Sidebar.js";
import TextArea from "./TextField/Textarea";
import useCustomQueryMutation from "./API/Query.js";
import MinimizeButton from "./TextField/MinimizeButton.js";
import { Card } from "../Ui/card.js";
import useQueryStates from "./useQueryStates";
import SaveQueryDialog from "./Sidebar/SaveQueryDialog.js";

import usePremadeQueries from "./API/PremadeQueries";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "../Ui/hover-card.js";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
export default function QueryPage() {
  const bottomTableRef = useRef(null);

  const [isMinimized, setIsMinimized] = useState(false);

  const toggleEditorSize = () => {
    setIsMinimized(!isMinimized);
  };
  const editorHeight = isMinimized ? "100px" : `${15 * 1.6}em`; // Adjust the editor height based on isMinimized
  const {
    openDialog,
    setOpenDialog,
    queryName,
    setQueryName,
    queryDescription,
    setQueryDescription,
    selectedSavedQuery,
    isCreatingSavedQuery,
    isCreateSavedQueryError,

    isUpdatingSavedQuery,
    isUpdateSavedQueryError,
    isDeletingSavedQuery,
    isDeleteSavedQueryError,
    handleOpenDialog,
    handleSaveQuery,
    handleUpdateQuery,
    handleDeleteQuery,
  } = useQueryStates();

  const {
    data: premadeQueries,
    isLoading: isLoadingPremadeQueries,
    isError: isLoadingPremadeQueriesError,
  } = usePremadeQueries();

  const [userQuery, setUserQuery] = useState("");
  const [timer, setTimer] = useState(0);
  const [timerActive, setTimerActive] = useState(false); // New state to control timer
  const [showTimer, setShowTimer] = useState(false); // State to control timer visibility
  const [isSavedQuery, setIsSavedQuery] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const handleOpenModal = () => setIsOpen(true);
  const handleCloseModal = () => setIsOpen(false);
  const [chartConfig, setChartConfig] = useState(null);
  const [activeTab, setActiveTab] = useState("table"); // State for active tab

  const handleOnSubmit = (config) => {
    const series = (config.yAxes || []).map((yAxisConfig) => {
      const seriesData = queryData.data.map((item) => {
        // Check if the timestamp is in seconds and convert to milliseconds if necessary
        const timestamp = item[config.xAxisVariable];
        const isSeconds = timestamp.toString().length <= 10; // Assumes it's in seconds if length <= 10
        const dateInMilliseconds = isSeconds ? timestamp * 1000 : timestamp;

        // Convert each item to a 2D array [x, y]
        return [
          new Date(dateInMilliseconds).getTime(), // Use the adjusted timestamp as x
          parseFloat(item[yAxisConfig.variable]), // Parse the y-value as a float and use it as y
        ];
      });
      seriesData.sort((a, b) => a[0] - b[0]);

      return {
        name: yAxisConfig.variable,
        data: seriesData,
        color: yAxisConfig.color, // Optionally, set the color for the series if specified
        title: config.title,
      };
    });

    // Here you would typically use the 'series' array, e.g., to configure a chart

    // Update the chartConfig state with the transformed series data
    setChartConfig({
      chartType: config.chartType,
      xAxisVariable: config.xAxisVariable,
      series: series, // This will be an array of series now,
      title: config.title,
    });

    handleCloseModal(); // Close the modal after submitting
  };

  // useMutation is used here due to the nature of POST requests,
  // even if the operation is fetching data without side effects.

  const {
    mutate: executeQuery,
    data: queryData,
    isPending,
  } = useCustomQueryMutation({
    setTimer,
    setTimerActive,
    setShowTimer,
  });

  useEffect(() => {
    let intervalId;

    if (timerActive) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 100);
    } else if (!timerActive && intervalId) {
      clearInterval(intervalId); // Clear interval if timer is not active
    }

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, [timerActive]);

  const handleRunQuery = () => {
    executeQuery(userQuery, {
      onSuccess: (queryData) => {
        if (
          chartConfig &&
          chartConfig.series &&
          chartConfig.series.length > 0 &&
          isSavedQuery
        ) {
          updateChartConfigWithData(queryData);
        }
      },
    });
  };

  const updateChartConfigWithData = (queryData) => {
    if (!queryData || !queryData.data) return;

    // Sort the query data by the xAxisVariable
    const sortedData = queryData.data.sort((a, b) => {
      return (
        new Date(a[chartConfig.xAxisVariable]).getTime() -
        new Date(b[chartConfig.xAxisVariable]).getTime()
      );
    });

    const updatedSeries = chartConfig.series.map((series) => {
      const seriesDataPoints = sortedData.map((item) => {
        const timestamp = item[chartConfig.xAxisVariable];
        const isSeconds = timestamp.toString().length <= 10;
        const dateInMilliseconds = isSeconds ? timestamp * 1000 : timestamp;

        return [
          new Date(dateInMilliseconds).getTime(),
          parseFloat(item[series.name]),
        ];
      });

      return {
        ...series,
        data: seriesDataPoints,
      };
    });

    setChartConfig((prevConfig) => ({
      ...prevConfig,
      series: updatedSeries,
    }));

    setChartConfig((prevConfig) => ({
      ...prevConfig,
      series: updatedSeries,
    }));
  };

  // get keys
  let allKeys = [];
  if (queryData && queryData.data && Array.isArray(queryData.data)) {
    queryData.data.forEach((item) => {
      Object.keys(item).forEach((key) => {
        if (!allKeys.includes(key)) {
          allKeys.push(key);
        }
      });
    });
  }

  const columns = allKeys.map((key) => ({
    accessorKey: key,
    header: key.charAt(0).toUpperCase() + key.slice(1), // Capitalize the header label for display
  }));

  const handleTabSwitch = (tabName) => {
    setActiveTab(tabName);
  };

  const loadChartSettings = (settings) => {
    if (settings) {
      setChartConfig({
        chartType: settings.chartType || "",
        xAxisVariable: settings.xAxisVariable || "",
        title: settings.title || "",
        series: settings.yAxes
          ? settings.yAxes.map((yAxis) => ({
              name: yAxis.variable,
              color: yAxis.color,
            }))
          : [],
      });
      setIsSavedQuery(true);
    }
  };

  //passing click in
  const handleAddColumnToQuery = (columnName) => {
    setUserQuery((currentQuery) => {
      // Check if query already ends with a comma or space, and append accordingly
      const separator = currentQuery.trim().endsWith(",") ? " " : ", ";
      // Avoid adding a comma if the query is empty
      const newQuery = currentQuery
        ? `${currentQuery}${separator}${columnName}`
        : columnName;
      return newQuery;
    });
  };

  return (
    <div className="flex h-full ">
      <Sidebar
        onAddColumn={handleAddColumnToQuery}
        queryText={userQuery}
        chartConfig={chartConfig}
        setChartConfig={setChartConfig}
        onLoadChartSettings={loadChartSettings}
        premadeQueries={premadeQueries}
        isLoadingPremadeQueries={isLoadingPremadeQueries}
        isLoadingPremadeQueriesError={isLoadingPremadeQueriesError}
      />
      {/* Main content area */}
      <div className="flex-1 p-4 overflow-y-hidden">
        {/* Main content header */}
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-2xl font-bold">Query</h1>
          <div className="flex items-center gap-2">
            {(!userQuery.toLowerCase().includes("limit") ||
              (userQuery.toLowerCase().match(/limit\s+(\d+)/i) &&
                parseInt(
                  userQuery.toLowerCase().match(/limit\s+(\d+)/i)[1],
                  10
                ) > 100000)) && (
              <HoverCard>
                <HoverCardTrigger>
                  <InformationCircleIcon className="h-6 w-6 text-black" />
                </HoverCardTrigger>
                <HoverCardContent className="bg-white">
                  Warning: No LIMIT clause found or LIMIT exceeds 100,000. A
                  default limit will be applied.
                </HoverCardContent>
              </HoverCard>
            )}
            <Button
              variant="outline"
              onClick={handleRunQuery}
              disabled={isPending}
              className="ml-2"
            >
              {isPending ? (
                <div className="flex items-center">
                  {/* Loading spinner JSX */}
                  <span>Loading...</span>
                </div>
              ) : (
                "Run"
              )}
            </Button>
          </div>
        </div>

        <div className="flex flex-col h-full">
          <Card className="p-4 flex-shrink-0">
            <TextArea
              height={editorHeight}
              value={userQuery}
              onChange={setUserQuery}
              className="px-4"
            />
            <MinimizeButton
              isMinimized={isMinimized}
              toggle={toggleEditorSize}
              className=""
            />
            <Button
              variant="outline"
              onClick={handleOpenDialog}
              className="ml-2"
            >
              <PlusIcon className="h-4 w-4 mr-2" />
              Save
            </Button>
            <SaveQueryDialog
              open={openDialog}
              setOpen={setOpenDialog}
              queryName={queryName}
              setQueryName={setQueryName}
              queryDescription={queryDescription}
              setQueryDescription={setQueryDescription}
              handleSaveQuery={(showChartSettings) =>
                handleSaveQuery(showChartSettings, userQuery, chartConfig)
              }
              isCreating={isCreatingSavedQuery}
              isError={isCreateSavedQueryError}
              isEdit={selectedSavedQuery !== null}
              handleUpdateQuery={(showChartSettings) =>
                handleUpdateQuery(showChartSettings, userQuery, chartConfig)
              }
              isUpdating={isUpdatingSavedQuery}
              isUpdateError={isUpdateSavedQueryError}
              handleDeleteQuery={handleDeleteQuery}
              isDeleting={isDeletingSavedQuery}
              isDeleteError={isDeleteSavedQueryError}
              selectedQueryId={selectedSavedQuery?._id}
              chartConfig={chartConfig}
              setChartConfig={setChartConfig}
            />
            {queryData && queryData.data && queryData.data.length > 0 && <></>}
            <div className="flex justify-between items-center mt-4">
              {showTimer && (
                <div>Query Duration: {(timer / 10).toFixed(1)} seconds</div>
              )}
              {!showTimer && <div></div>}
            </div>
          </Card>
          {queryData && queryData.data && (
            <div className="flex-grow mt-8 ">
              <BottomTable
                ref={bottomTableRef}
                activeTab={activeTab}
                queryData={queryData}
                handleTabSwitch={handleTabSwitch}
                chartConfig={chartConfig}
                isOpen={isOpen}
                handleCloseModal={handleCloseModal}
                handleOpenModal={handleOpenModal}
                handleOnSubmit={handleOnSubmit}
                allKeys={allKeys}
                columns={columns}
                isMinimized={isMinimized}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
