// SidebarIcon.js
import React from "react";
import { CubeIcon } from "@heroicons/react/24/solid";

const SidebarIcon = ({ category, setSidebar, sidebar }) => (
  <div>
    <button
      onClick={() => setSidebar(!sidebar)}
      className="flex justify-center items-center w-full bg-white p-2 rounded-lg border-2 border-primaryContainer"
    >
      <CubeIcon className="h-6 w-6 text-primary" />
    </button>
  </div>
);

export default SidebarIcon;
